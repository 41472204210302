import { TVoteType } from "interfaces/IVote";
import { ReactComponent as PollSvg } from "public/poll.svg";
import { ReactComponent as VoteSvg } from "public/vote.svg";

export default (pollType: TVoteType) => {
	switch (pollType) {
		case "POLL":
			return "Опрос";
		case "VOTE":
			return "Голосование";
		default:
			return "Неизвестно";
	}
};
