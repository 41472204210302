import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";

import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";

type TUseRefreshCitizenAfterUpdateArgs = {
	setNewFiles: (value: React.SetStateAction<File[]>) => void;
	setIsEdit: (value: React.SetStateAction<boolean>) => void;
};

const useRefreshCitizenAfterUpdate = (args: TUseRefreshCitizenAfterUpdateArgs) => {
	const dispose = () => {
		queryClient.invalidateQueries([QueriesKeys.clientsDocuments, CitizensDrawerStore.selectedUser.id]);
		queryClient.invalidateQueries([QueriesKeys.clients]);
		args.setNewFiles([]);
		args.setIsEdit(false);
	};

	return dispose;
};

export default useRefreshCitizenAfterUpdate;
