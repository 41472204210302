import React, { FC } from "react";

import { TNotificationType } from "interfaces/IVote";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Chip, FormItem } from "tap2visit-ui-kit";

import PollsEditStore from "../../../store/PollsItem.store";

interface IPollSendModalContent {}

const PollSendModalContent: FC<IPollSendModalContent> = (props) => {
	const initialNotificationTypes = new Set(PollsEditStore.poll.notificationTypes);
	const [items, setItems] = React.useState([
		{
			selected: initialNotificationTypes.has("PUSH"),
			key: "PUSH" as TNotificationType,
			text: "Моб. приложение",
		},
		{
			selected: initialNotificationTypes.has("EMAIL"),
			key: "EMAIL" as TNotificationType,
			text: "Эл. почта",
		},
		{
			selected: initialNotificationTypes.has("SMS"),
			key: "SMS" as TNotificationType,
			text: "SMS",
		},
	]);

	const onChipClick = (key: TNotificationType) => {
		PollsEditStore.changeNotificationTypes({ notificationType: key });
		setItems((items) =>
			items.map((item) => {
				if (item.key === key) {
					return {
						...item,
						selected: !item.selected,
					};
				}
				return item;
			}),
		);
	};

	return (
		<FormItem label="Отправить с помощью">
			<Row>
				<>
					{items.map((item) => (
						<Chip key={item.key} onClick={() => onChipClick(item.key)} title={item.text} selected={item.selected} size={"m"} />
					))}
				</>
			</Row>
		</FormItem>
	);
};

export default observer(PollSendModalContent);

const Row = styled.div`
	display: flex;
	gap: 4px;
`;
