export const mainSectionsHeader = [
	{
		key: "mobileApp" as string,
		label: "Запрос на доступ с мобильного приложения" as string,
	},
	{
		key: "archive" as string,
		label: "Архив запросов" as string,
	},
];
