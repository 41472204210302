export default (isMe: boolean) => {
	const text = {
		title: "",
		description: "",
	};

	text.title = isMe ? "Вы уверены, что хотите удалить собственную учетную запись?" : " Вы уверены, что хотите удалить сотрудника?";
	text.description = isMe
		? "Ваша учетная запись будет удалена, доступ к личному кабинету УК закрыт."
		: "Сотрудник будет удален, доступ к личному кабинету УК будет закрыт.";

	return text;
};
