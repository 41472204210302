import { MIN_TABLE_HEIGHT, MIN_TABLE_WIDTH } from "constants/constants.common";
import { roles } from "constants/constants.roles";

import { FC, useState } from "react";
import React from "react";

import { getUsersApi } from "api/api.employee";
import { getRolesApi } from "api/api.realEstate";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import dayjs from "dayjs";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import useWindowWidth from "hooks/useWindowWidth";
import { QueriesKeys } from "interfaces/queriesKeys";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactComponent as CheckSvg } from "public/checkCircle.svg";
import { ReactComponent as UnCheckSvg } from "public/uncheckCircle.svg";
import { useQuery } from "react-query";
import SideBarStore from "store/SideBar.store";
import styled from "styled-components";
import { Loader, Pagination, TTableColumn, Table, colors } from "tap2visit-ui-kit";
import formatPhone from "utils/format/formatPhone";
import { getOnlyDigitsString } from "utils/getOnlyDigitsString";
import getUserRoleByKey from "utils/getUserRoleByKey";

import EmployeeDrawer from "./components/Employee.drawer";
import EmployeeDrawerStore from "./store/Employee.drawer.store";
import EmployeeTableFilterStore from "./store/Employee.tableFilter.store";
import Page from "components/layout/Page";

const Employee: FC = () => {
	const [loading, setLoading] = useState(false);
	const paginationData = useTablePaginationLogic();
	const windowWidth = useWindowWidth();

	const firstLoginSort = toJS(EmployeeTableFilterStore.sort.find((sort) => sort.key === "firstLogin"));
	const lastLoginSort = toJS(EmployeeTableFilterStore.sort.find((sort) => sort.key === "lastLogin"));
	const fioSort = toJS(EmployeeTableFilterStore.sort.find((sort) => sort.key === "fio"));

	const employee = useQuery({
		queryFn: () =>
			getUsersApi({
				email: EmployeeTableFilterStore.login,
				fio: EmployeeTableFilterStore.initials,
				mobilePN: getOnlyDigitsString(EmployeeTableFilterStore.phone),
				roles: EmployeeTableFilterStore.selectedRolesKeys,
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				sort: EmployeeTableFilterStore.sort,
			}),
		queryKey: [
			QueriesKeys.employee,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			EmployeeTableFilterStore.initials,
			EmployeeTableFilterStore.login,
			EmployeeTableFilterStore.phone,
			EmployeeTableFilterStore.selectedRolesKeys,
			firstLoginSort?.direction,
			lastLoginSort?.direction,
			fioSort?.direction,
		],
	});

	const isVisiblePagination = !employee.isFetching && employee.data;

	React.useEffect(() => {
		if (employee.data) {
			paginationData.setClientsTotal(employee.data.totalPages);
		}
	}, [
		employee.data?.page,
		EmployeeTableFilterStore.initials,
		EmployeeTableFilterStore.phone,
		EmployeeTableFilterStore.login,
		paginationData.clientsSize,
		EmployeeTableFilterStore.selectedRolesKeys,
	]);

	const tableColumns: TTableColumn[] = [
		{
			key: "initials",
			dataIndex: "initials",
			title: "ФИО",
			onSearch: (search) => {
				EmployeeTableFilterStore.setInitials(search);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(employee.data.size);
			},
			initialSearchString: EmployeeTableFilterStore.initials,
			columnCellStyles: {
				maxWidth: "270px",
				minWidth: "270px",
			},
			style: {},
			initialSortDirection: EmployeeTableFilterStore.getSortByKey("fio"),
			onSortedBy: (value) => EmployeeTableFilterStore.setSort({ direction: value, key: "fio" }),
		},
		{
			key: "role",
			dataIndex: "role",
			title: "Роль",
			filters: roles,
			onChangeFilter: (filters) => {
				EmployeeTableFilterStore.setSelectedRoles(filters.map((filter) => filter.key));
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(employee.data.size);
			},
			initialSelectedFiltersKey: EmployeeTableFilterStore.selectedRolesKeys,
			columnCellStyles: {
				maxWidth: "200px",
				minWidth: "200px",
			},
			style: {},
		},
		{
			key: "login",
			dataIndex: "login",
			title: "Логин",
			render: (email: string) => (
				<Link onClick={(e) => e.stopPropagation()} href={`mailto:${email}`}>
					{email}
				</Link>
			),
			onSearch: (search) => {
				EmployeeTableFilterStore.setLogin(search);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(employee.data.size);
			},
			initialSearchString: EmployeeTableFilterStore.login,
			columnCellStyles: {
				maxWidth: "200px",
				minWidth: "200px",
			},
			style: {},
		},
		{
			key: "phone",
			dataIndex: "phone",
			title: "Телефон",
			onSearch: (search) => {
				EmployeeTableFilterStore.setPhone(search);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(employee.data.size);
			},
			initialSearchString: EmployeeTableFilterStore.phone,
			onSearchByFormatterString: (v) => v.replace(/[^\d+]/g, ""),
			columnCellStyles: {
				maxWidth: "150px",
				minWidth: "150px",
			},
			style: {},
		},
		{
			key: "isPasswordSet",
			dataIndex: "isPasswordSet",
			title: "Пароль задан",
			render: (value: boolean) => (
				<PasswordSetContainer style={{ width: "90px" }}>{value ? <CheckSvg /> : <UnCheckSvg />}</PasswordSetContainer>
			),
			columnCellStyles: {
				width: "90px",
			},
			style: {},
		},
		{
			key: "firstLogin",
			dataIndex: "firstLogin",
			title: "Первый вход",
			columnCellStyles: {
				maxWidth: "160px",
				minWidth: "160px",
			},
			style: {},
			initialSortDirection: EmployeeTableFilterStore.getSortByKey("firstLogin"),
			onSortedBy: (value) => EmployeeTableFilterStore.setSort({ direction: value, key: "firstLogin" }),
		},
		{
			key: "lastLogin",
			dataIndex: "lastLogin",
			title: "Последний вход",
			columnCellStyles: {
				maxWidth: "170px",
				minWidth: "170px",
			},
			style: {},
			initialSortDirection: EmployeeTableFilterStore.getSortByKey("lastLogin"),
			onSortedBy: (value) => EmployeeTableFilterStore.setSort({ direction: value, key: "lastLogin" }),
		},
	];

	const tableData = employee.data?.content?.map((user) => ({
		initials: `${user.lastName} ${user.firstName} ${user.middleName ?? ""}`,
		role: getUserRoleByKey(user.role),
		login: user.email,
		phone: formatPhone(user.mobilePN),
		isPasswordSet: user.isPasswordSet,
		id: user.id,
		firstLogin: user.firstLogin ? dayjs(user.firstLogin).utcOffset(180).format("DD.MM.YYYY в HH:mm") : "Данные отсутствуют",
		lastLogin: user.lastLogin ? dayjs(user.lastLogin).utcOffset(180).format("DD.MM.YYYY в HH:mm") : "Данные отсутствуют",
	}));

	if (employee.isLoading || employee.isFetching)
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	return (
		<Page>
			<Table
				data={tableData ?? []}
				columns={tableColumns ?? []}
				size="m"
				isFullWidth
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth})`,
					overflowX: "hidden",
					minHeight: MIN_TABLE_HEIGHT,
					borderTop: "none",
				}}
				onRowClick={(userRow, idx) => {
					EmployeeDrawerStore.openDrawer({ mode: "visible", user: employee.data?.content?.[idx], userId: userRow.id });
				}}
				emptyComponent={employee.isFetching ? TableLoader : TableEmptySlug}
			/>
			{isVisiblePagination && (
				<PaginationContainer>
					<Pagination
						onChangeSize={paginationData.setClientsSize}
						currentPage={paginationData.clientsCurrentPage}
						setCurrentPage={paginationData.setClientsCurrentPage}
						total={paginationData.clientsTotal}
						size={paginationData.clientsSize as 10 | 20 | 50 | 100}
					/>
				</PaginationContainer>
			)}
			<EmployeeDrawer />
		</Page>
	);
};

export default observer(Employee);

const LoaderContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 55%;
`;

const PasswordSetContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Link = styled.a`
	color: ${colors.textInfoDefault};
`;
