import React, { FC, useEffect } from "react";

import { Spacer } from "components/layout/Spacer";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { DatePicker, showToast } from "tap2visit-ui-kit";

import ReceiptsDrawerStore from "../../store/ReceiptsDrawer.store";
import Label from "../components/Label";
import ReceiptsPeriodStore from "../store/ReceiptsPeriod.store";

interface ICalendar {}

const Calendar: FC<ICalendar> = (props) => {
	const [isOpenPicker, setIsOpenPicker] = React.useState(false);
	const [dates, setDates] = React.useState<Date[]>([]);

	const setFormattedDates = (dates: Date[]) => {
		const formattedDates = dates?.map((date) => {
			if (!date) {
				return null;
			}
			return new Date(date).toISOString();
		});
		if (formattedDates) {
			ReceiptsDrawerStore.setDates(formattedDates);
		}
	};

	React.useEffect(() => {
		setFormattedDates(dates);
	}, [dates]);

	React.useEffect(() => {
		setDates([]);
		ReceiptsDrawerStore.setDates([]);
	}, [ReceiptsPeriodStore.selectedPeriodType]);

	useEffect(() => {
		if (dates.length > 0) {
			const oneMonth = new Date(dates[0]).getMonth() + 1;
			const twoMonth = new Date(dates[1]).getMonth() + 1;
			const oneYear = new Date(dates[0]).getFullYear();
			const twoYear = new Date(dates[1]).getFullYear();

			if (oneMonth > twoMonth && ReceiptsPeriodStore.selectedPeriodType === "month") {
				showToast({
					description: "Первый месяц должен быть больше второго",
					type: "danger",
				});
			}
			if (oneYear > twoYear && ReceiptsPeriodStore.selectedPeriodType === "year") {
				showToast({
					description: "Первый год должен быть больше второго",
					type: "danger",
				});
			}
		}
	}, [dates]);

	return (
		<>
			<Label text="Задать период" />
			<Spacer px={4} />
			<DatePicker
				disabled={ReceiptsDrawerStore.isCreationReportInProgress}
				setOpen={setIsOpenPicker}
				isOpen={isOpenPicker}
				startYear={2013}
				increase={0}
				sizeInput={"medium"}
				iconPosition="right"
				dates={dates}
				isDateRange={ReceiptsPeriodStore.selectedPeriodType === "week"}
				isMonthRange={ReceiptsPeriodStore.selectedPeriodType === "month"}
				isYearsRange={ReceiptsPeriodStore.selectedPeriodType === "year"}
				setDates={setDates}
			/>
		</>
	);
};

export default observer(Calendar);
