import apiClient from "./api.client";

export type TArchiveAnnouncementApi = (args: { path: { id: string } }) => Promise<void>;

export const archiveAnnouncementApi: TArchiveAnnouncementApi = async (args) => {
	try {
		const res = await apiClient.post(`announcements/v1/announcements/${args.path.id}/archive`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteAnnouncementByIdApi = (args: { path: { id: string } }) => Promise<void>;

export const deleteAnnouncementByIdApi: TDeleteAnnouncementByIdApi = async (args) => {
	try {
		await apiClient.delete(`announcements/v1/announcements/${args.path.id}`);
	} catch (error) {
		return Promise.reject(error);
	}
};
