export default (type: string) => {
	switch (type) {
		case "ORDINARY":
			return "Объявление";
		case "EMERGENCY":
			return "ЧП";
		default:
			return "Неизвестный тип";
	}
};
