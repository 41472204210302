import { getDocumentsByIdApi } from "api/api.documents";
import { TDocumentType } from "interfaces/IDocuments";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";

import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";

type TUseCitizenDocumentByType = {
	type: TDocumentType[];
	targetId?: string;
	sourceId?: string;
	deps?: boolean;
};

const useCitizenDocumentByType = (args: TUseCitizenDocumentByType) => {
	const queryKeyDeps = args.targetId ?? args.sourceId ?? CitizensDrawerStore.selectedUser.id;

	const documents = useQuery({
		queryFn: () => {
			const params: { targetId?: string; sourceId?: string } = {};

			if (!args.targetId && !args.sourceId) {
				params.targetId = CitizensDrawerStore.selectedUser.id;
			}

			if (args.targetId) {
				params.targetId = args.targetId;
			}

			if (args.sourceId) {
				params.sourceId = args.sourceId;
			}

			return getDocumentsByIdApi({ params });
		},
		queryKey: [QueriesKeys.clientsDocuments, queryKeyDeps],
		enabled: args.deps ?? !!CitizensDrawerStore.selectedUser.id,
	});

	const citizenDocument = documents.data?.find((doc) => args.type.includes(doc.type));

	return citizenDocument;
};

export default useCitizenDocumentByType;
