import { IQuestionAnswerDto } from "interfaces/IVote";

export const getAnswers = (question: IQuestionAnswerDto) => {
	const answersChartData =
		question?.answerOptions?.map((answer) => ({
			name: answer.value,
			value: answer.answerCount,
		})) ?? [];
	const answersCount = answersChartData.reduce((sum, answerChartData) => sum + answerChartData.value, 0);

	return {
		answersChartData,
		answersCount,
	};
};
