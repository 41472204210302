import { makeAutoObservable, toJS } from "mobx";
import { validation } from "modules/validation/utils/validation";

import PollsEditStore from "./PollsItem.store";

class PollsEditValidationStore {
	isValidPollName = true;
	notValidQuestionNameIds: string[] = [];
	notValidQuestionOptions: string[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	checkPollValidation = () => {
		this.dispose();
		this.isValidPollName = validation.isNotEmpty(PollsEditStore.poll.name) && validation.isLessThen(PollsEditStore.poll.name, 255);

		PollsEditStore.poll.questions.forEach((question) => {
			const isValidQuestionName = validation.isNotEmpty(question.value);
			if (!isValidQuestionName) {
				this.notValidQuestionNameIds = [...this.notValidQuestionNameIds, question.id];
			}

			question.answerOptions
				.filter((answer) => !answer.isFree)
				.forEach((answer) => {
					const isValidQuestionOption = validation.isNotEmpty(answer.value);
					if (!isValidQuestionOption) {
						this.notValidQuestionOptions = [...this.notValidQuestionOptions, answer.id];
					}
				});
		});

		return this.isValidPollName && this.notValidQuestionNameIds.length === 0 && this.notValidQuestionOptions.length === 0;
		// && this.notValidQuestionOptions.length === 0; // TODO Need to return validation on question items
	};

	validPollName = () => (this.isValidPollName = true);
	validQuestionName = (id: string) => {
		this.notValidQuestionNameIds = this.getListWithoutElementWithId(id, this.notValidQuestionNameIds);
	};
	validQuestionOption = (id: string) => {
		this.notValidQuestionOptions = this.getListWithoutElementWithId(id, this.notValidQuestionOptions);
	};

	private getListWithoutElementWithId = (id: string, list: string[]) => {
		const validIndexElement = list.findIndex((i) => i === id);
		return [...list.slice(0, validIndexElement), ...list.slice(validIndexElement + 1)];
	};

	dispose = () => {
		this.isValidPollName = true;
		this.notValidQuestionNameIds = [];
		this.notValidQuestionOptions = [];
	};
}

export default new PollsEditValidationStore();
