import React, { FC, useEffect } from "react";

import { getBuildingsBaseApi } from "api/api.gateway";
import { getComplexApi, getEntranceApi, getFloorsApi, getRolesApi } from "api/api.realEstate";
import { IRole } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { ReactComponent as EraserSvg } from "public/eraser.svg";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FormItem, Select, Switch, Button } from "tap2visit-ui-kit";

import useIsDisabledFilters from "./hooks/useIsDisabledFilters";
import useSelectBuildingData from "./hooks/useSelectBuildingData";
import useSelectStateIsOpen from "./hooks/useSelectStateIsOpen";
import { useTableFilterContext } from "./TableFilter.context";

interface ICitizensFilters {
	customSwitchOptions?: {
		label?: string;
		callback?: () => void;
		isActive?: boolean;
		switchLabel?: string;
		isVisible?: boolean;
	};
}

const TableFilter: FC<ICitizensFilters> = (props) => {
	const tableFilterStore = useTableFilterContext();
	const [isOpenComplexesSelect, toggleIsOpenComplexesSelect] = useSelectStateIsOpen();
	const [isOpenBuildingsSelect, toggleIsOpenBuildingsSelect] = useSelectStateIsOpen();
	const [isOpenEntrancesSelect, toggleIsOpenEntrancesSelect] = useSelectStateIsOpen();
	const [isOpenFloorsSelect, toggleIsOpenFloorsSelect] = useSelectStateIsOpen();
	const [isOpenBuildingObjectsSelect, toggleIsOpenBuildingObjectsSelect] = useSelectStateIsOpen();
	const location = useLocation();
	useSelectBuildingData({ filterStore: tableFilterStore });
	const { isBuildingsSelectDisabled, isEntrancesSelectDisabled, isFloorsSelectDisabled, isBuildingObjectsDisabled } = useIsDisabledFilters({
		filterStore: tableFilterStore,
	});

	const complexes = useQuery({
		queryFn: () => getComplexApi(),
		queryKey: [QueriesKeys.complexes],
	});

	const buildings = useQuery({
		queryFn: () => getBuildingsBaseApi({ params: { complexIds: tableFilterStore.selectedComplexIds } }),
		queryKey: [QueriesKeys.buildingsBase, ...(tableFilterStore.selectedComplexIds ?? [])],
		enabled: !!tableFilterStore.selectedComplexIds.length,
	});

	const entrances = useQuery({
		queryFn: () => getEntranceApi({ params: { buildingIds: tableFilterStore.selectedBuildingsIds } }),
		queryKey: [QueriesKeys.entrances, ...(tableFilterStore.selectedBuildingsIds ?? [])],
		enabled: !!tableFilterStore.selectedBuildingsIds.length,
	});

	const floors = useQuery({
		queryFn: () => getFloorsApi({ params: { entranceIds: tableFilterStore.selectedEntrancesIds } }),
		queryKey: [QueriesKeys.floors, ...(tableFilterStore.selectedEntrancesIds ?? [])],
		enabled: !!tableFilterStore.selectedEntrancesIds.length,
	});

	const setInitRoles = (data: IRole[]) => {
		const ownerRoleID = { role: "OWNER", id: "" };
		const trustedPerson = { role: "TRUSTED_PERSON", id: "" };
		data.forEach((item) => {
			if (item.name === "OWNER") ownerRoleID.id = item.id;
			if (item.name === "TRUSTED_PERSON") trustedPerson.id = item.id;
		});

		tableFilterStore.setInitRole([ownerRoleID, trustedPerson], "roles");
	};

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
		onSuccess: setInitRoles,
		// retryOnMount: true, // TODO workaround | bug with open announcements page (empty roles list)
		staleTime: Infinity,
	});

	React.useEffect(() => {
		if (roles.data) {
			setInitRoles(roles.data);
		}
	}, []);

	useEffect(() => {
		if (location.state?.selectedId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.selectedId,
					focused: false,
					text: complexes.data?.find((v) => v.id === location.state.selectedId).name,
				},
				"complexes",
			);
		}
		if (location.state?.buildingId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.buildingId,
					focused: false,
					text: buildings.data?.find((v) => v.id === location.state.buildingId).addressDto.value,
				},
				"buildings",
			);
		}
		if (location.state?.entranceId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.entranceId,
					focused: false,
					text: String(entrances.data?.find((v) => v.id === location.state.entranceId).number),
				},
				"entrances",
			);
		}
		if (location.state?.floorId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.floorId,
					focused: false,
					text: String(floors.data?.find((v) => v.id === location.state.floorId).number),
				},
				"floors",
			);
		}
	}, [location.state]);

	const filterRole = (state) => {
		if (!tableFilterStore.initRole.length) return;
		if (state) {
			tableFilterStore.setSelectItems([tableFilterStore.initRole.find((i) => i.role === "OWNER").id] || [], "roles");
		} else {
			tableFilterStore.setSelectItems(
				tableFilterStore.initRole.map((i) => i?.id),
				"roles",
			);
		}
	};

	return (
		<Container>
			<FormItem label="Жилой комплекс">
				<Select
					onClickSelect={toggleIsOpenComplexesSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "complexes");
						toggleIsOpenComplexesSelect();
					}}
					items={tableFilterStore.selectComplexes ?? []}
					isOpened={isOpenComplexesSelect}
					placeholder="Выберите"
					onClose={toggleIsOpenComplexesSelect}
					style={{
						width: "180px",
					}}
					size="s"
				/>
			</FormItem>

			<FormItem label="Дом">
				<Select
					onClickSelect={toggleIsOpenBuildingsSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "buildings");
						toggleIsOpenBuildingsSelect();
					}}
					items={tableFilterStore.selectBuildings ?? []}
					isOpened={isOpenBuildingsSelect}
					placeholder="Выберите"
					onClose={toggleIsOpenBuildingsSelect}
					isDisabled={isBuildingsSelectDisabled}
					dropdownTextStyle={{
						whiteSpace: "nowrap",
					}}
					style={{
						width: "180px",
					}}
					size="s"
				/>
			</FormItem>

			<FormItem label="Подъезд">
				<Select
					onClickSelect={toggleIsOpenEntrancesSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "entrances");
						toggleIsOpenEntrancesSelect();
					}}
					items={tableFilterStore.selectEntrances ?? []}
					isOpened={isOpenEntrancesSelect}
					placeholder="Выберите"
					onClose={toggleIsOpenEntrancesSelect}
					isDisabled={isEntrancesSelectDisabled}
					style={{
						width: "180px",
					}}
					size="s"
				/>
			</FormItem>

			<FormItem label="Этаж">
				<Select
					onClickSelect={toggleIsOpenFloorsSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "floors");
						toggleIsOpenFloorsSelect();
					}}
					items={tableFilterStore.selectFloors ?? []}
					isOpened={isOpenFloorsSelect}
					placeholder="Выберите"
					onClose={toggleIsOpenFloorsSelect}
					isDisabled={isFloorsSelectDisabled}
					style={{
						width: "180px",
					}}
					size="s"
				/>
			</FormItem>

			<FormItem label="Номер квартиры">
				<Select
					onClickSelect={toggleIsOpenBuildingObjectsSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "buildingObjects");
						toggleIsOpenBuildingObjectsSelect();
					}}
					items={tableFilterStore.selectBuildingObjects ?? []}
					isOpened={isOpenBuildingObjectsSelect}
					placeholder="Выберите"
					onClose={toggleIsOpenBuildingObjectsSelect}
					isDisabled={isBuildingObjectsDisabled}
					style={{
						width: "180px",
					}}
					size="s"
				/>
			</FormItem>

			{props.customSwitchOptions && props.customSwitchOptions?.isVisible && (
				<FormItem label={props.customSwitchOptions.label}>
					<Switch
						label={props.customSwitchOptions.switchLabel}
						labelPosition="right"
						size={"medium"}
						isActive={props.customSwitchOptions.isActive}
						onChangeActive={props.customSwitchOptions.callback}
						style={{
							gap: 0,
						}}
					/>
				</FormItem>
			)}

			{!props.customSwitchOptions && (
				<AlignToCenterSwitch>
					<Switch
						label="Показать только собственников"
						labelPosition="right"
						size={"medium"}
						isActive={tableFilterStore.selectRoles.length === 1}
						onChangeActive={filterRole}
						style={{
							gap: 0,
						}}
					/>
				</AlignToCenterSwitch>
			)}

			<AlignToCenterButton>
				<Button onClick={tableFilterStore.clearFilter} typeButton="secondary" icon={EraserSvg} size="small">
					Сбросить
				</Button>
			</AlignToCenterButton>
		</Container>
	);
};

export default observer(TableFilter);

const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 16px;
`;

const AlignToCenterSwitch = styled.div`
	width: 211px;
	transform: translateY(50%);
`;

const AlignToCenterButton = styled.div`
	transform: translateY(38%);
	button {
		padding-left: 2px;
	}
`;
