import React from "react";

import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import TableFilter from "../../components/TableFilter/TableFilter";
import { TableFilterContext } from "../../components/TableFilter/TableFilter.context";

import AnnouncementsDrawer from "./components/Announcements.drawer";
import ExclamationTab from "./components/AnnouncementTab";
import ExclamationTable from "./components/AnnouncementTable";

const TableFilterInstanceStore = new BuildingFilterStore();

const Announcement = () => (
	<TableFilterContext.Provider value={TableFilterInstanceStore}>
		<ExclamationWrapper>
			<ExclamationTab />
			<ExclamationFilterWrapper>
				<TableFilter />
			</ExclamationFilterWrapper>
			<ExclamationTable />
		</ExclamationWrapper>

		<AnnouncementsDrawer />
	</TableFilterContext.Provider>
);

const ExclamationWrapper = styled.div``;

const ExclamationFilterWrapper = styled.div`
	padding: 32px 12px;
`;

export default observer(Announcement);
