import React, { FC } from "react";

import styled from "styled-components";
import { Checkbox, Text } from "tap2visit-ui-kit";

interface ITreeSendToEveryOne {
	visible: boolean;
	checked: boolean;
	changeChecked: (checked: boolean) => void;
	disabled?: boolean;
}

const TreeSendToEveryOne: FC<ITreeSendToEveryOne> = (props) => (
	<>
		{props.visible && (
			<SendToEveryoneContainer>
				<Checkbox checked={props.checked} changeChecked={props.changeChecked} disabled={props.disabled} />
				<Text type="capital-normal">Отправить всем</Text>
			</SendToEveryoneContainer>
		)}
	</>
);

export default TreeSendToEveryOne;

const SendToEveryoneContainer = styled.div`
	display: flex;
	padding-left: 16px;
	gap: 4px;
	align-items: center;
`;
