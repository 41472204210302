import { FC, useEffect, useState } from "react";

import { CustomText, FormItemsContainer } from "components/DrawerFormFields/DrawerFormFields";
import { Spacer } from "components/layout/Spacer";
import { ICreateClientInformation, TClientContactsType } from "interfaces/IClient";
import { observer } from "mobx-react-lite";
import { colors } from "tap2visit-ui-kit";

import CitizenRealtyCreate from "../../../components/Citizens/components/CitizenRealtyCreate";
import UserEditInput from "../../../components/Citizens/components/UserEdit.input";
import CitizenCreateStore from "../../../components/Citizens/store/CitizenCreate.store";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";

import CitizenAddButton from "./CitizenAddButton";
import CitizenContact from "./CitizenContact/CitizenContact";
import CitizenInn, { TCitizenInnOnChange } from "./CitizenInn";
import CitizenPassport, { TCitizenPassportOnDataChange } from "./CitizenPassport";
import CitizenSnils, { TCitizenSnilsOnDataChange } from "./CitizenSnils";
import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";

const DrawerUserEdit: FC = () => {
	const [openCreateRealty, setOpenCreateRealty] = useState(false);

	useEffect(() => {
		let isEmptyEmails = true;
		let isEmptyPhones = true;
		let isAbsentDefaultPhone = true;

		for (const contact of CitizensDrawerStore.selectedUser.contacts) {
			if (contact.type === "EMAIL") {
				isEmptyEmails = false;
			}
			if (contact.type === "PHONE" && !contact.isDefault) {
				isEmptyPhones = false;
			}

			if (contact.isDefault && contact.type === "PHONE") {
				isAbsentDefaultPhone = false;
			}

			if (!isEmptyEmails && !isEmptyPhones && !isAbsentDefaultPhone) {
				break;
			}
		}
	}, []);

	const onChangeClientValue = (value: Partial<ICreateClientInformation>) => {
		CitizenCreateStore.updateClient(value);
	};

	const loginContact = CitizenCreateStore.contacts?.find((contact) => contact.isDefault);
	const phoneContacts = CitizenCreateStore.contacts.filter((contact) => !contact.isDefault && contact.type === "PHONE");
	const emailContacts = CitizenCreateStore.contacts.filter((contact) => !contact.isDefault && contact.type === "EMAIL");

	const onDeleteContact = (value: string) => {
		const deleteContactFromIndex = CitizenCreateStore.contacts.findIndex((contact) => contact.value === value);

		if (deleteContactFromIndex !== -1) {
			CitizenCreateStore.deleteContact(deleteContactFromIndex);
		}
	};

	const onSaveContact = (value: string, description: string, type: TClientContactsType) => {
		const lastContactIndex = CitizenCreateStore.contacts.length - 1;

		CitizenCreateStore.updateClientsContacts({
			contact: {
				description,
				type,
				value,
			},
			index: lastContactIndex + 1,
		});
	};

	const onChangePassportData: TCitizenPassportOnDataChange = (data) => {
		const { dateOfBirth, dateOfIssue, files: newFiles, ...otherData } = data;
		CitizenCreateStore.changeClientFiles({
			passport: newFiles,
		});
		CitizenCreateStore.changeDocument({
			...otherData,
			type: "PASSPORT",
			dateOfBirth: dateOfBirth?.format("YYYY-MM-DD"),
			dateOfIssue: dateOfIssue?.format("YYYY-MM-DD"),
			fileIds: [],
			sourceId: "",
			targetId: "",
		});
	};

	const onChangeInsuranceNumber: TCitizenSnilsOnDataChange = (data) => {
		CitizenCreateStore.changeClientFiles({ insuranceNumber: data.files });
		onChangeClientValue({ insuranceNumber: data.insuranceNumber });
		CitizenCreateStore.changeDocument({
			type: "SNILS",
			fileIds: [],
			number: data.insuranceNumber,
		});
	};

	const onChangeInn: TCitizenInnOnChange = (data) => {
		CitizenCreateStore.changeClientFiles({ inn: data.files });
		onChangeClientValue({ inn: data.inn });
		CitizenCreateStore.changeDocument({
			type: "INN",
			fileIds: [],
			number: data.inn,
		});
	};

	const inputValidation = CitizenCreateStore.checkInputsValidation();
	const { isNeedToShowInputErrors } = CitizenCreateStore;

	return (
		<>
			<CitizenRealtyCreate setOpen={setOpenCreateRealty} open={openCreateRealty} />
			<CitizenDrawerTextHeaderBlock text={"Персональная информация"} />
			<FormItemsContainer>
				<UserEditInput
					placeholder="Иванов"
					label={"Фамилия"}
					required
					value={CitizenCreateStore.client.lastName}
					onChange={(value) => onChangeClientValue({ lastName: value })}
					isError={isNeedToShowInputErrors && !inputValidation.fields.lastName}
				/>

				<UserEditInput
					placeholder="Иван"
					label={"Имя"}
					required
					value={CitizenCreateStore.client.firstName}
					onChange={(value) => onChangeClientValue({ firstName: value })}
					isError={isNeedToShowInputErrors && !inputValidation.fields.firstName}
				/>

				<UserEditInput
					placeholder="Иванович"
					label={"Отчество"}
					value={CitizenCreateStore.client.middleName}
					onChange={(value) => onChangeClientValue({ middleName: value })}
				/>

				<UserEditInput
					label={"Адрес проживания"}
					as="textarea"
					value={CitizenCreateStore.client.residentialAddress}
					onChange={(value) => onChangeClientValue({ residentialAddress: value })}
				/>
				<CustomText type="small-regular" color={colors.textNeutralPlaceholder}>
					Введите адрес фактического проживания, адрес по прописке указывается в разделе "Паспорт"
				</CustomText>
			</FormItemsContainer>
			<Spacer px={16} />

			<CitizenDrawerTextHeaderBlock text={"Недвижимость"} />
			<FormItemsContainer>
				<Spacer px={8} />
				<CitizenAddButton
					text="Добавить недвижимость"
					onClick={() => {
						setOpenCreateRealty(true);
					}}
				/>
			</FormItemsContainer>

			<Spacer px={16} />

			<CitizenContact
				contactsCollection={phoneContacts}
				title="Номера телефонов"
				onSave={(value, description) => onSaveContact(value, description, "PHONE")}
				modalTitle="Добавить номер телефона"
				modalTitlePlaceholder="+7 000 000-00-00"
				modalTitleLabel="Телефон"
				modalDescriptionPlaceholder="Например: телефон собственника"
				modalTitleMask="+7 (999) 999 99 99"
				buttonText="Добавить номер телефона"
				type="PHONE"
				onDeleteContact={onDeleteContact}
				isCreateNewCitizen
			/>

			<Spacer px={16} />

			<CitizenContact
				contactsCollection={emailContacts}
				title="Эл. почта"
				onSave={(value, description) => onSaveContact(value, description, "EMAIL")}
				modalTitle="Добавить эл.почту"
				modalTitlePlaceholder="example@example.com"
				modalTitleLabel="Эл. почта"
				modalDescriptionPlaceholder="Например: почта собственника"
				buttonText="Добавить эл. почту"
				type="EMAIL"
				onDeleteContact={onDeleteContact}
				isCreateNewCitizen
			/>

			<Spacer px={16} />

			<CitizenPassport onlyEditable onDataChange={onChangePassportData} />

			<Spacer px={16} />
			<CitizenSnils onlyEditable onDataChange={onChangeInsuranceNumber} />
			<Spacer px={16} />

			<Spacer px={16} />
			<CitizenInn onlyEditable onDataChange={onChangeInn} />
		</>
	);
};

export default observer(DrawerUserEdit);
