import { makeAutoObservable } from "mobx";

type TSortObject = { key: string; direction: string };

class EmployeeTableFilterStore {
	initials = "";
	selectedRolesKeys: string[] = [];
	login = "";
	phone = "";
	sort: TSortObject[] = [{ direction: "asc", key: "fio" }];

	constructor() {
		makeAutoObservable(this);
	}

	getSortByKey = (key: string) => {
		const currentSort = this.sort.find((s) => s.key === key);
		return currentSort?.direction as "asc" | "desc";
	};

	setSelectedRoles = (rolesKeys: string[]) => {
		this.selectedRolesKeys = rolesKeys;
	};

	setLogin = (login: string) => {
		this.login = login;
	};

	setPhone = (phone: string) => {
		this.phone = phone;
	};

	setInitials = (initials: string) => {
		this.initials = initials;
	};

	setSort = (sortObj: TSortObject) => {
		const changedObjectIndex = this.sort.findIndex((obj) => obj.key === sortObj.key);

		if (changedObjectIndex === -1) {
			this.sort = [...this.sort, sortObj];
		} else {
			if (sortObj.direction) {
				this.sort = [...this.sort.slice(0, changedObjectIndex), sortObj, ...this.sort.slice(changedObjectIndex + 1)];
			} else {
				this.sort = [...this.sort.slice(0, changedObjectIndex), ...this.sort.slice(changedObjectIndex + 1)];
			}
		}
	};
}

export default new EmployeeTableFilterStore();
