import React, { FC } from "react";

import { Divider } from "antd";
import BaseModal from "components/Modals/BaseModal";
import dayjs from "dayjs";
import { IWorkingTimeCreate, IWorkingTimeReceptionHours } from "interfaces/IOffice";
import { ReactComponent as Delete } from "public/delete.svg";
import { ReactComponent as Plus } from "public/plus.svg";
import styled from "styled-components";
import { Switch, FormItem, TimePickerRange, Button, colors, Text, Input } from "tap2visit-ui-kit";

import { week } from "../constants";

import { getDayJsFromString } from "./PageEdit.utils";
import { ITimeAction } from "./PageEditTime/hooks/useTimeState";

interface IPageEditWeekDayCard {
	shouldISpecifyOfficeHours: boolean;
	weekDay: IWorkingTimeCreate;
	dispatchTimeState: React.Dispatch<ITimeAction>;
}

const workingTimeToUTC = (time: string) => dayjs.utc(time, "HH:mm").format("HH:mm[Z]");
const getUpdateReceptionHours = (
	receptionHours: IWorkingTimeReceptionHours[],
	idx: number,
	payload: { newDescription?: string; periodOfTimes?: [string, string] },
) => {
	const updatedReceptionHours = {
		...receptionHours[idx],
	};

	if (payload.newDescription !== undefined) {
		updatedReceptionHours.description = payload.newDescription;
	}
	if (payload.periodOfTimes) {
		updatedReceptionHours.timePeriod.startTime = workingTimeToUTC(payload.periodOfTimes[0]);
		updatedReceptionHours.timePeriod.endTime = workingTimeToUTC(payload.periodOfTimes[1]);
	}

	return [...receptionHours.slice(0, idx), updatedReceptionHours, ...receptionHours.slice(idx + 1)];
};
const getReceptionHoursWithoutRemoved = (receptionHours: IWorkingTimeReceptionHours[], removeIdx: number) => [
	...receptionHours.slice(0, removeIdx),
	...receptionHours.slice(removeIdx + 1),
];

const PageEditWeekDayCard: FC<IPageEditWeekDayCard> = (props) => {
	const [isVisibleDeletionSpecialTimeModal, setIsVisibleDeletionSpecialTimeModal] = React.useState(false);
	const confirmSpecialTimeCallback = React.useRef<() => void>();

	return (
		<>
			<WeekCard>
				<WeekTitle>
					<p>{week[props.weekDay.dayOfWeek]}</p>
					<Switch
						isActive={props.weekDay.isWeekend}
						onChangeActive={(status) => props.dispatchTimeState({ payload: { ...props.weekDay, isWeekend: status } })}
						label={"Выходной"}
						size={"large"}
						labelPosition={"left"}
					/>
				</WeekTitle>
				{!props.weekDay.isWeekend && (
					<>
						<WeekTimeTable>
							<TimeSetting>
								<FormItem label={"Часы работы"} labelPosition={"vertical"}>
									<TimePickerRange
										size={"small"}
										initialStartTime={getDayJsFromString(props.weekDay.workingTimePeriod?.startTime)}
										initialEndTime={getDayJsFromString(props.weekDay.workingTimePeriod?.endTime)}
										onChange={(time) =>
											props.dispatchTimeState({
												payload: {
													...props.weekDay,
													workingTimePeriod: {
														startTime: workingTimeToUTC(time[0].format("HH:mm")),
														endTime: workingTimeToUTC(time[1].format("HH:mm")),
													},
												},
											})
										}
									/>
								</FormItem>
							</TimeSetting>
							<TimeSetting>
								<FormItem label={"Перерыв"} labelPosition={"vertical"}>
									<TimePickerRange
										size={"small"}
										initialStartTime={getDayJsFromString(props.weekDay.breakTimePeriod?.startTime)}
										initialEndTime={getDayJsFromString(props.weekDay.breakTimePeriod?.endTime)}
										onChange={(time) => {
											props.dispatchTimeState({
												payload: {
													...props.weekDay,
													breakTimePeriod: {
														startTime: workingTimeToUTC(time[0].format("HH:mm")),
														endTime: workingTimeToUTC(time[1].format("HH:mm")),
													},
												},
											});
										}}
									/>
								</FormItem>
							</TimeSetting>

							{props.weekDay.receptionHours?.map((time, idx) => (
								<>
									<div>
										<Divider style={{ position: "absolute", marginLeft: "-12px" }} />
										<HeadSetting>
											<Text type={"small-medium"}>Приемные часы</Text>
										</HeadSetting>
										<div>
											<FormItem label={"Заголовок"} labelPosition={"vertical"}>
												<Input
													value={time.description}
													onChange={(e) => {
														props.dispatchTimeState({
															payload: {
																...props.weekDay,
																receptionHours: getUpdateReceptionHours(props.weekDay.receptionHours, idx, {
																	newDescription: e.target.value,
																}),
															},
														});
													}}
													sizeInput={"small"}
												/>
											</FormItem>
										</div>
									</div>
									<ActionSetting>
										<FormItem label={"Часы приема"} labelPosition={"vertical"}>
											<TimePickerRange
												size={"small"}
												initialStartTime={getDayJsFromString(time.timePeriod.startTime)}
												initialEndTime={getDayJsFromString(time.timePeriod.endTime)}
												onChange={(time) =>
													props.dispatchTimeState({
														payload: {
															...props.weekDay,
															receptionHours: getUpdateReceptionHours(props.weekDay.receptionHours, idx, {
																periodOfTimes: time.map((t) => t.format("HH:mm")) as [string, string],
															}),
														},
													})
												}
											/>
										</FormItem>
										<Button
											onClick={() => {
												confirmSpecialTimeCallback.current = () => {
													props.dispatchTimeState({
														payload: {
															...props.weekDay,
															receptionHours: getReceptionHoursWithoutRemoved(props.weekDay.receptionHours, idx),
														},
													});
													setIsVisibleDeletionSpecialTimeModal(false);
												};
												setIsVisibleDeletionSpecialTimeModal(true);
											}}
											icon={Delete}
											typeButton={"default"}
											size={"medium"}
											variant={"red"}
											shape="square"
										/>
									</ActionSetting>
								</>
							))}
						</WeekTimeTable>
						{props.shouldISpecifyOfficeHours && (
							<ButtonAddHours
								icon={Plus}
								typeButton={"link"}
								onClick={() =>
									props.dispatchTimeState({
										payload: {
											...props.weekDay,
											receptionHours: [
												...(props.weekDay.receptionHours ?? []),
												{
													timePeriod: {
														startTime: "",
														endTime: "",
													},
													description: "",
												},
											],
										},
									})
								}>
								Добавить приемные часы
							</ButtonAddHours>
						)}
					</>
				)}
			</WeekCard>
			{isVisibleDeletionSpecialTimeModal && (
				<BaseModal
					title={"Удалить приёмные часы"}
					description={"Вы уверены, что хотите удалить приёмные часы?"}
					okText={"Да"}
					cancelText="Нет"
					isVisible
					status="delete"
					onCancel={() => setIsVisibleDeletionSpecialTimeModal(false)}
					onOk={confirmSpecialTimeCallback.current}
					onExit={() => setIsVisibleDeletionSpecialTimeModal(false)}
				/>
			)}
		</>
	);
};

export default PageEditWeekDayCard;

const ButtonAddHours = styled(Button)`
	p {
		font-size: 12px;
	}
`;
const WeekCard = styled.div`
	border-radius: 8px;
	border: 1px solid ${colors.strokeDivider};
	min-width: 450px;

	> button {
		margin-top: -4px;
		margin-bottom: 12px;
	}
`;

const WeekTitle = styled.div`
	padding: 20px 20px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background: ${colors.surfaceNeutralBg2};
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	> :first-child {
		font-weight: 700;
		font-size: 20px;
		line-height: 28px; //TODO: fix color
		color: #42474f;
	}
`;

const WeekTimeTable = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	position: relative;
	padding: 12px;
	gap: 12px;
`;

const TimeSetting = styled.div`
	max-width: 250px;
`;

const HeadSetting = styled.div`
	display: inline-flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 6px;
	height: 42px;
	align-items: center;
	margin-top: 20px;
`;

const ActionSetting = styled.div`
	display: inline-flex;
	margin-top: 65px;

	&& button {
		margin-top: 20px;
		margin-left: 16px;
	}
`;
