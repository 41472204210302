import { createIntl, createIntlCache } from "react-intl";

import translateJson from "./translate.json";

export const LOCALES = {
	RUSSIAN: "ru-RU",
	ENGLISH: "en-US",
};

export const messages: Record<string, any> = translateJson;

class CustomIntl {
	private cache = createIntlCache();

	private intl = createIntl(
		{
			locale: LOCALES.RUSSIAN,
			messages: messages[LOCALES.RUSSIAN],
		},
		this.cache,
	);

	getMessage = (id: string) => this.intl.formatMessage({ id });
}

export const intl = new CustomIntl();
