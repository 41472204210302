import React, { FC } from "react";

import styled from "styled-components";
import { colors } from "tap2visit-ui-kit";

interface IPollsEditContentCard {
	children: JSX.Element;
	style?: React.CSSProperties;
}

const PollsEditContentCard: FC<IPollsEditContentCard> = (props) => <Container style={props.style}>{props.children}</Container>;

export default PollsEditContentCard;

const Container = styled.div`
	width: 50%;
	border: 0.5px solid ${colors.strokeLine};
	border-radius: 12px;
	padding-left: 24px;
	padding-right: 24px;
`;
