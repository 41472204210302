/* eslint-disable no-case-declarations */
import { IBuildingBase } from "interfaces/IBuilding";
import { makeAutoObservable, toJS } from "mobx";
import { TContextMenuItem } from "tap2visit-ui-kit";

type TSetSelectItemType = "complexes" | "buildings" | "entrances" | "floors" | "buildingObjects" | "roles";
export type TSetSelectedItems = (data: any[], type: TSetSelectItemType) => void;

const transformFromDataToSelectItemStructure = (data: any[], prevData?: TContextMenuItem[]) =>
	data.map((item) => ({
		focused: prevData?.find((currItem) => currItem.key === item.id)?.focused ?? false,
		key: item.id,
		text: item.name ?? item.number,
	}));

const transformFromDataBuildingToSelectItemStructure = (data: IBuildingBase[], prevData?: TContextMenuItem[]) =>
	data.map((item) => ({
		focused: prevData?.find((currItem) => currItem.key === item.id)?.focused ?? false,
		key: item.id,
		text: item.addressDto.value,
	}));

const resetAllFocusedSelectItemStructure = (data: TContextMenuItem[]) =>
	data.map((item) => ({
		focused: false,
		key: item.key,
		text: item.text,
	}));

class BuildingFilterStore {
	selectComplexes: TContextMenuItem[] = [];
	selectBuildings: TContextMenuItem[] = [];
	selectEntrances: TContextMenuItem[] = [];
	selectFloors: TContextMenuItem[] = [];
	selectBuildingObjects: TContextMenuItem[] = [];
	selectRoles: string[] = [];
	initRole: { role: string; id: string }[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	get selectedComplexIds() {
		return this.selectComplexes.filter((i) => i.focused).map((i) => i.key);
	}

	get selectedBuildingsIds() {
		return this.selectBuildings.filter((i) => i.focused).map((i) => i.key);
	}

	get selectedEntrancesIds() {
		return this.selectEntrances.filter((i) => i.focused).map((i) => i.key);
	}

	get selectedFloorsIds() {
		return this.selectFloors.filter((i) => i.focused).map((i) => i.key);
	}

	get selectedBuildingObjectIds() {
		return this.selectBuildingObjects.filter((i) => i.focused).map((i) => i.key);
	}

	setSelectItems: TSetSelectedItems = (data, type) => {
		switch (type) {
			case "complexes":
				this.selectComplexes = transformFromDataToSelectItemStructure(data, this.selectComplexes);
				break;
			case "buildings":
				this.selectBuildings = transformFromDataBuildingToSelectItemStructure(data, this.selectBuildings);

				break;
			case "entrances":
				this.selectEntrances = transformFromDataToSelectItemStructure(data, this.selectEntrances);

				break;
			case "floors":
				this.selectFloors = transformFromDataToSelectItemStructure(data, this.selectFloors);
				break;
			case "buildingObjects":
				this.selectBuildingObjects = transformFromDataToSelectItemStructure(data, this.selectBuildingObjects);
				return;
			case "roles":
				this.selectRoles = data;
				break;
		}
	};

	toggleFocused = (item: TContextMenuItem, type: TSetSelectItemType) => {
		switch (type) {
			case "complexes":
				const itemComplexIdx = this.selectComplexes.findIndex((i) => i.key === item.key);
				this.selectComplexes = [
					...this.selectComplexes.slice(0, itemComplexIdx),
					{ ...item, focused: !item.focused },
					...this.selectComplexes.slice(itemComplexIdx + 1),
				];
				this.selectBuildings = resetAllFocusedSelectItemStructure(this.selectBuildings);
				this.selectEntrances = resetAllFocusedSelectItemStructure(this.selectEntrances);
				this.selectFloors = resetAllFocusedSelectItemStructure(this.selectFloors);
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "buildings":
				const itemBuildingIdx = this.selectBuildings.findIndex((i) => i.key === item.key);
				this.selectBuildings = [
					...this.selectBuildings.slice(0, itemBuildingIdx),
					{ ...item, focused: !item.focused },
					...this.selectBuildings.slice(itemBuildingIdx + 1),
				];
				this.selectEntrances = resetAllFocusedSelectItemStructure(this.selectEntrances);
				this.selectFloors = resetAllFocusedSelectItemStructure(this.selectFloors);
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "entrances":
				const itemEntranceIdx = this.selectEntrances.findIndex((i) => i.key === item.key);
				this.selectEntrances = [
					...this.selectEntrances.slice(0, itemEntranceIdx),
					{ ...item, focused: !item.focused },
					...this.selectEntrances.slice(itemEntranceIdx + 1),
				];
				this.selectFloors = resetAllFocusedSelectItemStructure(this.selectFloors);
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "floors":
				const itemFloorsIdx = this.selectFloors.findIndex((i) => i.key === item.key);
				this.selectFloors = [
					...this.selectFloors.slice(0, itemFloorsIdx),
					{ ...item, focused: !item.focused },
					...this.selectFloors.slice(itemFloorsIdx + 1),
				];
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "buildingObjects":
				const itemBuildingObjectsIdx = this.selectBuildingObjects.findIndex((i) => i.key === item.key);
				this.selectBuildingObjects = [
					...this.selectBuildingObjects.slice(0, itemBuildingObjectsIdx),
					{ ...item, focused: !item.focused },
					...this.selectBuildingObjects.slice(itemBuildingObjectsIdx + 1),
				];
		}
	};

	/**
	 * @description toggle select only single element
	 */
	toggleSingleItem = (item: TContextMenuItem, type: TSetSelectItemType) => {
		switch (type) {
			case "complexes":
				const itemComplexIdx = this.selectComplexes.findIndex((i) => i.key === item.key);
				this.selectComplexes = [
					...resetAllFocusedSelectItemStructure(this.selectComplexes.slice(0, itemComplexIdx)),
					{ ...item, focused: !item.focused },
					...resetAllFocusedSelectItemStructure(this.selectComplexes.slice(itemComplexIdx + 1)),
				];
				this.selectBuildings = resetAllFocusedSelectItemStructure(this.selectBuildings);
				this.selectEntrances = resetAllFocusedSelectItemStructure(this.selectEntrances);
				this.selectFloors = resetAllFocusedSelectItemStructure(this.selectFloors);
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "buildings":
				const itemBuildingIdx = this.selectBuildings.findIndex((i) => i.key === item.key);
				this.selectBuildings = [
					...resetAllFocusedSelectItemStructure(this.selectBuildings.slice(0, itemBuildingIdx)),
					{ ...item, focused: !item.focused },
					...resetAllFocusedSelectItemStructure(this.selectBuildings.slice(itemBuildingIdx + 1)),
				];
				this.selectEntrances = resetAllFocusedSelectItemStructure(this.selectEntrances);
				this.selectFloors = resetAllFocusedSelectItemStructure(this.selectFloors);
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "entrances":
				const itemEntranceIdx = this.selectEntrances.findIndex((i) => i.key === item.key);
				this.selectEntrances = [
					...resetAllFocusedSelectItemStructure(this.selectEntrances.slice(0, itemEntranceIdx)),
					{ ...item, focused: !item.focused },
					...resetAllFocusedSelectItemStructure(this.selectEntrances.slice(itemEntranceIdx + 1)),
				];
				this.selectFloors = resetAllFocusedSelectItemStructure(this.selectFloors);
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "floors":
				const itemFloorsIdx = this.selectFloors.findIndex((i) => i.key === item.key);
				this.selectFloors = [
					...resetAllFocusedSelectItemStructure(this.selectFloors.slice(0, itemFloorsIdx)),
					{ ...item, focused: !item.focused },
					...resetAllFocusedSelectItemStructure(this.selectFloors.slice(itemFloorsIdx + 1)),
				];
				this.selectBuildingObjects = resetAllFocusedSelectItemStructure(this.selectBuildingObjects);
				break;
			case "buildingObjects":
				const itemBuildingObjectsIdx = this.selectBuildingObjects.findIndex((i) => i.key === item.key);
				this.selectBuildingObjects = [
					...resetAllFocusedSelectItemStructure(this.selectBuildingObjects.slice(0, itemBuildingObjectsIdx)),
					{ ...item, focused: !item.focused },
					...resetAllFocusedSelectItemStructure(this.selectBuildingObjects.slice(itemBuildingObjectsIdx + 1)),
				];
		}
	};

	clearFilter = () => {
		this.selectComplexes = this.selectComplexes.map((i) => ({ ...i, focused: false }));
		this.selectBuildings = this.selectBuildings.map((i) => ({ ...i, focused: false }));
		this.selectEntrances = this.selectEntrances.map((i) => ({ ...i, focused: false }));
		this.selectFloors = this.selectFloors.map((i) => ({ ...i, focused: false }));
		this.selectBuildingObjects = this.selectBuildingObjects.map((i) => ({ ...i, focused: false }));
		this.selectRoles = this.initRole.map((i) => i.id);
	};

	setInitRole: TSetSelectedItems = (data, type) => {
		if (type === "roles") {
			this.initRole = data;
			this.selectRoles = data.map((i) => i.id);
		}
	};
}

export const RealEstateBuildingFilterStore = new BuildingFilterStore();

export default BuildingFilterStore;
