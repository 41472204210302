import React, { FC } from "react";

import { Text, colors } from "tap2visit-ui-kit";

interface ILabel {
	text: string;
}

const Label: FC<ILabel> = (props) => (
	<Text color={colors.textNeutralSecondary} type="table-cell-m-regular">
		{props.text}
	</Text>
);

export default Label;
