import React from "react";

import { downloadFileApi } from "api/api.storage";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";

import officeSlug from "../../../public/officeSlug.webp";

const useOfficeLogo = (args: { isDefaultImage?: boolean; avatarId: string | undefined }) => {
	const [img, setImg] = React.useState<File>();

	const officeLogo = useQuery({
		queryFn: () => downloadFileApi(args.avatarId),
		enabled: !!args.avatarId,
		queryKey: [QueriesKeys.downloadFile, args.avatarId],
	});

	React.useEffect(() => {
		if (officeLogo.data) {
			const blob = new Blob([officeLogo.data]);
			const imgFile = new File([blob], "office_log.jpg", { type: "image/jpeg" });
			setImg(imgFile);
		}
	}, [officeLogo?.data]);

	React.useEffect(() => {
		(async () => {
			if (args.isDefaultImage && !officeLogo.isFetching) {
				await getDefaultImage(setImg);
			}
		})();
		return () => {
			setImg(undefined);
		};
	}, [args.isDefaultImage, officeLogo.isFetching]);

	return { img, isFetching: officeLogo.isFetching };
};

export default useOfficeLogo;

const getDefaultImage = async (setImageFile: (file) => void) => {
	const response = await fetch(officeSlug);
	const blob = await response.blob();

	const file = new File([blob], officeSlug, { type: "image/jpeg" });
	setImageFile(file);
};
