import React, { FC } from "react";

import { deleteBuildingObjectClientApi } from "api/api.realEstate";
import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { FormItem, Select, Upload, Text, TContextMenuItem } from "tap2visit-ui-kit";

import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";

interface ICitizenDetachModals {
	isVisibleDeleteOwnerModal: boolean;
	setIsVisibleDeleteOwnerModal: (value: React.SetStateAction<boolean>) => void;

	isVisibleDeleteTrustedClientModal: boolean;
	setIsVisibleDeleteTrustedClientModal: (value: React.SetStateAction<boolean>) => void;
	onDeleteUserConfirm?: () => void;

	buildingIdForDeletion: string;
}

type TReasonForDeletionKey = "other" | "sell" | "death";

const CitizenDetachModals: FC<ICitizenDetachModals> = (props) => {
	const [selectedReason, setSelectedReason] = React.useState<TReasonForDeletionKey>("other");
	const [isOpenedSelectReasonForDeletion, setIsOpenedSelectReasonForDeletion] = React.useState(false);

	const deleteUserFromBuilding = useMutation(deleteBuildingObjectClientApi);

	const onDeleteUserConfirm = async () => {
		if (props.onDeleteUserConfirm) {
			props.onDeleteUserConfirm();
			return;
		}

		await deleteUserFromBuilding.mutateAsync({
			path: {
				buildingObjectId: props.buildingIdForDeletion,
				clientId: CitizensDrawerStore.selectedUser.id,
			},
		});

		props.setIsVisibleDeleteOwnerModal(false);
		props.setIsVisibleDeleteTrustedClientModal(false);

		await queryClient.invalidateQueries([QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id]);
	};

	const listOfReasonForDeletion: TContextMenuItem[] = [
		{
			focused: selectedReason === "other",
			key: "other",
			text: "Иное",
		},
		{
			focused: selectedReason === "sell",
			key: "sell",
			text: "Продажа",
		},
		{
			focused: selectedReason === "death",
			key: "death",
			text: "Смерть",
		},
	];

	const onClickSelectReasonItem = (selectItem: TContextMenuItem) => {
		setSelectedReason(selectItem.key as TReasonForDeletionKey);
		setIsOpenedSelectReasonForDeletion(false);
	};

	return (
		<>
			<BaseModal
				isVisible={props.isVisibleDeleteTrustedClientModal}
				title={"Удалить жителя?"}
				okText="Да"
				cancelText="Нет"
				status="delete"
				onOk={onDeleteUserConfirm}
				onCancel={() => {
					props.setIsVisibleDeleteTrustedClientModal(false);
				}}
				onExit={() => props.setIsVisibleDeleteTrustedClientModal(false)}>
				<Text type="base-regular">Житель будет удален безвозвратно</Text>
			</BaseModal>

			<BaseModal
				isVisible={props.isVisibleDeleteOwnerModal}
				title={"Удаление собственника"}
				okText="Продолжить"
				cancelText="Отменить"
				onOk={onDeleteUserConfirm}
				onCancel={() => props.setIsVisibleDeleteOwnerModal(false)}
				onExit={() => props.setIsVisibleDeleteOwnerModal(false)}>
				<>
					<Text type="base-regular">Укажите причину удаления.</Text>

					<Spacer px={14} />
					<FormItem label="Причина удаления">
						<Select
							items={listOfReasonForDeletion}
							isOpened={isOpenedSelectReasonForDeletion}
							onClickSelect={() => setIsOpenedSelectReasonForDeletion(true)}
							onClickSelectItem={onClickSelectReasonItem}
							onClose={() => setIsOpenedSelectReasonForDeletion(false)}
						/>
					</FormItem>

					<Spacer px={4} />
					<Text>Приложите фото документа о Купле-Продаже</Text>
					<FormItem label="Приложите фото документа о Купле-Продаже">
						<Upload
							type="preview"
							accept="image/jpeg,image/png,image/jpg"
							height="100px"
							width={"512px"}
							containerStyle={{ alignItems: "flex-start", paddingLeft: "24px", borderRadius: "12px" }}
						/>
					</FormItem>
				</>
			</BaseModal>
		</>
	);
};

export default observer(CitizenDetachModals);
