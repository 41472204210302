import { colors } from "tap2visit-ui-kit";
import shuffle from "utils/shuffle";

export default () => {
	const filteredColor = Object.entries(colors)
		.filter(([colorName, color]) => {
			if (colorName.indexOf("icon") !== -1 && color.indexOf("121212") === -1 && color.indexOf("FFFF") === -1) {
				return true;
			}
		})
		.map(([colorName, color]) => color);

	return shuffle(filteredColor);
};
