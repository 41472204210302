import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import { Text, colors } from "tap2visit-ui-kit";

interface IChartDescription {
	title: string;
	description: string;
}

const ChartDescription: FC<IChartDescription> = (props) => (
	<>
		<Text type="large-bold" color={colors.textNeutralPrimary}>
			{props.title}
		</Text>
		<Spacer px={4} />
		<Text type="base-regular" color={colors.textNeutralSecondary}>
			{props.description}
		</Text>
	</>
);

export default ChartDescription;
