import { TDrawerMode } from "interfaces/IDrawerMode";
import { Text } from "tap2visit-ui-kit";

export const getTitleCitizensDrawer = (drawerMode: TDrawerMode) => {
	switch (drawerMode) {
		case "create":
			return "Новый житель";
		case "edit":
			return "Карточка жителя (Редактирование)";
		case "visible":
			return "Житель";
	}
};

export const getEmptyStringIfDataIsEmpty = (data: any[], children: JSX.Element) => {
	if (data.length === 0) {
		return <Text type="small-regular">Данных по этому разделу нет</Text>;
	}

	return children;
};
