import dayjs from "dayjs";
import { TAnnouncementType } from "interfaces/IAnnouncements";
import { makeAutoObservable } from "mobx";
import { TContextMenuItem } from "tap2visit-ui-kit";

export type TTypeOfRealEstate = "complex" | "building" | "entrance" | "floor" | "buildingObject";

type TEstateSelectedFields = {
	selectedComplexesIds?: string[];
	selectedBuildingsIds?: string[];
	selectedEntrancesIds?: string[];
	selectedFloorsIds?: string[];
	selectedBuildingObjectsIds?: string[];
};

const DEFAULT_TYPE_ANNOUNCEMENTS = [
	{ key: "ORDINARY", text: "Объявление", focused: false },
	{ key: "EMERGENCY", text: "ЧП", focused: false },
];

class AnnouncementsViewDataStore {
	startDate: dayjs.Dayjs = dayjs();
	startTime: dayjs.Dayjs = dayjs();
	endDate: dayjs.Dayjs = dayjs();
	endTime: dayjs.Dayjs = dayjs();
	text = "";
	selectedRoles: TContextMenuItem[] = [];
	typeAnnouncements: TContextMenuItem[] = DEFAULT_TYPE_ANNOUNCEMENTS;

	constructor() {
		makeAutoObservable(this);
	}

	setAllFields = (self: Readonly<typeof this>) => {
		this.startDate = self.startDate;
		this.startTime = self.startTime;
		this.endDate = self.endDate;
		this.endTime = self.startDate;
		this.text = self.text;
		this.selectedRoles = self.selectedRoles;
		this.typeAnnouncements = self.typeAnnouncements;
	};

	setInitialTypeAnnouncement = (announcementType: TAnnouncementType) => {
		this.typeAnnouncements = this.typeAnnouncements.map((v) => ({
			...v,
			focused: v.key === announcementType || v.focused,
		}));
	};

	setTypeAnnouncements = (newTypeAnnouncements: TContextMenuItem[]) => {
		this.typeAnnouncements = newTypeAnnouncements;
	};

	setText = (text: string) => {
		this.text = text;
	};

	setStartDate = (date: dayjs.Dayjs) => {
		this.startDate = date;
	};

	setStartTime = (date: dayjs.Dayjs) => {
		this.startTime = date.add(3, "minute");
	};

	setEndDate = (date: dayjs.Dayjs) => {
		if (date) {
			this.endDate = date;
		} else return this.endDate;
	};

	setEndTime = (date: dayjs.Dayjs) => {
		this.endTime = date.add(3, "minute");
	};

	private onChangeChecked = (checkedObjects: string[], id: string) => {
		if (checkedObjects.includes(id)) {
			const filteredArr = checkedObjects.filter((item) => item !== id);
			checkedObjects.length = 0;
			checkedObjects.push(...filteredArr);
		} else {
			checkedObjects.push(id);
		}
	};

	setSelectedRoles = (roles: TContextMenuItem[]) => {
		this.selectedRoles = roles;
	};

	unselectedRole = (role: TContextMenuItem) => {
		const founedRoleIdx = this.selectedRoles.findIndex((selectedRole) => selectedRole.key === role.key);

		if (founedRoleIdx !== -1) {
			this.selectedRoles = [
				...this.selectedRoles.slice(0, founedRoleIdx),
				{ ...role, focused: false },
				...this.selectedRoles.slice(founedRoleIdx + 1),
			];
		}
	};

	clearRoles = () => {
		this.selectedRoles = [];
	};

	dispose = () => {
		this.clearRoles();
		this.typeAnnouncements = DEFAULT_TYPE_ANNOUNCEMENTS;
		this.text = "";
	};
}

export default new AnnouncementsViewDataStore();
