import React, { FC } from "react";

import { Space } from "antd";
import { archiveAnnouncementApi, deleteAnnouncementByIdApi } from "api/api.announcements";
import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { ReactComponent as DeleteIcon } from "public/delete.svg";
import { ReactComponent as EditIcon } from "public/edit-icon.svg";
import { ReactComponent as RefreshSvg } from "public/refresh.svg";
import { ReactComponent as RemovePostIcon } from "public/removePost.svg";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "tap2visit-ui-kit";
import { Drawer } from "tap2visit-ui-kit";

import AnnouncementsTabStore from "../Announcements.tab.store";
import AnnouncementsStore from "../store/Announcements.currentItem.store";
import getMightVisibilityAnnouncementActions from "../utils/getMightVisibilityAnnouncementActions";

import DrawerCollapse from "./Drawer.collapse";
import DrawerStore from "./Drawer.store";

interface IAnnouncementsDrawer {}

const AnnouncementsDrawer: FC<IAnnouncementsDrawer> = (props) => {
	const navigate = useNavigate();

	const [isVisibleModalConfirmMoveAnnouncementToArchive, setIsVisibleModalConfirmMoveAnnouncementToArchive] = React.useState(false);
	const [isVisibleModalConfirmDeleteAnnouncement, setIsVisibleModalConfirmDeleteAnnouncement] = React.useState(false);

	const visibilityActionsButton = getMightVisibilityAnnouncementActions(DrawerStore.selectedAnnouncement);

	const onSuccessArchiveOrDeleteHandler = () => {
		setIsVisibleModalConfirmMoveAnnouncementToArchive(false);
		queryClient.invalidateQueries([QueriesKeys.announcements]);
		DrawerStore.closeDrawer();
	};

	const archiveAnnouncement = useMutation({
		mutationFn: () =>
			archiveAnnouncementApi({
				path: {
					id: DrawerStore.selectedAnnouncement.id,
				},
			}),
		onSuccess: onSuccessArchiveOrDeleteHandler,
	});
	const deleteAnnouncement = useMutation({
		mutationFn: () =>
			deleteAnnouncementByIdApi({
				path: {
					id: DrawerStore.selectedAnnouncement.id,
				},
			}),
		onSuccess: onSuccessArchiveOrDeleteHandler,
	});

	const navigateToAnnouncementEditOrRecreate = () => {
		DrawerStore.closeDrawer();
		AnnouncementsStore.setAnnouncementsItem(DrawerStore.selectedAnnouncement);
		navigate(`/announcement/edit/${DrawerStore.selectedAnnouncement.id}`);
	};

	return (
		<>
			<Drawer
				visible={DrawerStore.isOpenDrawer}
				title={DrawerStore.selectedAnnouncement.type === "EMERGENCY" ? "Чрезвычайное происшествие" : "Объявление"}
				showCancel={false}
				// TODO change color from colors ui-kit
				iconButtonOk={() => <RefreshSvg fill="white" />}
				onClose={DrawerStore.closeDrawer}
				textOk={AnnouncementsTabStore.selectedTab === "ACTIVE" ? "Снять публикацию" : "Повторить объявление"}
				showOk={false}
				width="700px"
				headerSlot={
					<Space>
						{visibilityActionsButton.edit && (
							<Button
								icon={() => <EditIcon fill="#12121299" />}
								typeButton="secondary"
								onClick={() => {
									DrawerStore.closeDrawer();
									AnnouncementsStore.setAnnouncementsItem(DrawerStore.selectedAnnouncement);
									navigate(`/announcement/edit/${DrawerStore.selectedAnnouncement.id}`);
								}}>
								Редактировать
							</Button>
						)}

						{visibilityActionsButton.delete && (
							<Button icon={DeleteIcon} variant="red" onClick={() => setIsVisibleModalConfirmDeleteAnnouncement(true)}>
								Удалить
							</Button>
						)}

						{visibilityActionsButton.archive && (
							<Button icon={RemovePostIcon} variant="red" onClick={() => setIsVisibleModalConfirmMoveAnnouncementToArchive(true)}>
								Cнять публикацию
							</Button>
						)}

						{visibilityActionsButton.repeat && (
							<Button icon={RefreshSvg} onClick={navigateToAnnouncementEditOrRecreate}>
								Повторить объявление
							</Button>
						)}
					</Space>
				}>
				<Text h="h5">Текст объявления</Text>
				<Text type="base-regular">{DrawerStore.selectedAnnouncement.text}</Text>

				<Spacer px={24} />

				<DrawerCollapse />
			</Drawer>

			<BaseModal
				isVisible={isVisibleModalConfirmMoveAnnouncementToArchive}
				okText="Да"
				onCancel={() => setIsVisibleModalConfirmMoveAnnouncementToArchive(false)}
				onOk={archiveAnnouncement.mutateAsync}
				cancelText="Нет"
				status="delete"
				title="Вы уверены, что хотите снять объявление с публикации?"
			/>
			<BaseModal
				isVisible={isVisibleModalConfirmDeleteAnnouncement}
				okText="Да"
				onCancel={() => setIsVisibleModalConfirmDeleteAnnouncement(false)}
				onOk={deleteAnnouncement.mutateAsync}
				cancelText="Нет"
				status="delete"
				title="Вы уверены, что хотите удалить объявление?"
			/>
		</>
	);
};

export default observer(AnnouncementsDrawer);
