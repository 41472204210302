import { makeAutoObservable } from "mobx";
import { validation } from "modules/validation/utils/validation";

export enum ValidationStatuses {
	"REQUIRED" = "REQUIRED",
	"LESS_THEN" = "LESS_THEN",
	"EQUALS" = "EQUALS",
	"VALID" = "VALID",
}

type TValidationFieldStatuses = keyof typeof ValidationStatuses;

type TValidationErrorMessages<T> = Record<keyof T, Partial<Record<TValidationFieldStatuses, string>>>;

export type TValidationRules<T> = Partial<Record<keyof T, (value: string) => TValidationFieldStatuses | null>>;

type TCheckRulesValidationOptions = {
	[ValidationStatuses.REQUIRED]?: boolean;
	[ValidationStatuses.EQUALS]?: number;
	[ValidationStatuses.LESS_THEN]?: number;
};
export type TCheckRules<T> = Record<keyof T, TCheckRulesValidationOptions>;

class ValidationStore<T> {
	validationData: Record<keyof T, keyof typeof ValidationStatuses>;
	errorMessages: TValidationErrorMessages<T>;
	validationRules: TCheckRules<T>;

	constructor(
		initialValidationData: Record<keyof T, keyof typeof ValidationStatuses>,
		errorMessages: TValidationErrorMessages<T>,
		validationRules: TCheckRules<T>,
	) {
		this.validationData = initialValidationData;
		this.errorMessages = errorMessages;
		this.validationRules = validationRules;
		makeAutoObservable(this);
	}

	getMessageIfError = (key: keyof T) => {
		const validationStatus = this.validationData[key];
		const isValid = validationStatus === ValidationStatuses.VALID;
		if (!isValid && key in this.errorMessages) {
			return this.errorMessages[key][validationStatus];
		}
		return null;
	};

	checkValidation = (fieldsToValidate: Record<keyof T, string>) => {
		Object.keys(this.validationData).forEach((key) => {
			const currentKey = key as keyof T;
			const currentValue = fieldsToValidate[currentKey];
			const rules = this.validationRules[currentKey];

			// ? REQUIRED field must be first in list for correct work // TODO
			for (const rule of Object.entries(rules)) {
				const [ruleName, ruleValue] = rule;

				if (ruleName === ValidationStatuses.REQUIRED) {
					if (validation.isEmpty(currentValue)) {
						this.validationData[currentKey] = ValidationStatuses.REQUIRED;
						break;
					}
				}

				if (ruleName === ValidationStatuses.EQUALS) {
					if (!validation.isEquals(currentValue, ruleValue as number)) {
						this.validationData[currentKey] = ValidationStatuses.EQUALS;
						break;
					}
				}

				if (ruleName === ValidationStatuses.LESS_THEN) {
					if (!validation.isLessThen(currentValue, ruleValue as number)) {
						this.validationData[currentKey] = ValidationStatuses.LESS_THEN;
						break;
					}
				}

				this.validationData[currentKey] = ValidationStatuses.VALID;
			}
		});

		return Object.values(this.validationData).every((status) => status === ValidationStatuses.VALID);
	};

	validateField = (key: keyof T) => {
		this.validationData[key] = ValidationStatuses.VALID;
	};

	resetValidation = () => {
		Object.keys(this.validationData).forEach((key) => {
			this.validationData[key as keyof T] = ValidationStatuses.VALID;
		});
	};
}

export default ValidationStore;
