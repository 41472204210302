import { makeAutoObservable } from "mobx";

class SideBarStore {
	sideBarWidth = "auto";

	constructor() {
		makeAutoObservable(this);
	}

	setSideBarWidth = (value: string) => {
		this.sideBarWidth = value;
	};
}

export default new SideBarStore();
