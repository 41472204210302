export type TRoleTypes = "INTEREST_HOLDER" | "OWNER" | "PAYER" | "TRUSTED_PERSON" | "TENANT";
export enum RoleTypes {
	"INTERESE_HOLDER" = "INTEREST_HOLDER",
	"OWNER" = "OWNER",
	"PAYER" = "PAYER",
	"TRUSTED_PERSON" = "TRUSTED_PERSON",
	"TENANT" = "TENANT",
}

export interface IRole {
	id: string;
	name: TRoleTypes;
}

export interface IClient {
	id: string;
	clientId: string;
	buildingObjectId: string;
	roles: IRole[];
}
