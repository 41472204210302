import { makeAutoObservable } from "mobx";

export type TPollsTabs = "active" | "archive" | "template";

class PollsTabsStore {
	activeTab: TPollsTabs = "active";
	onlyActiveStatus = false;

	constructor() {
		makeAutoObservable(this);
	}

	setActiveTab = (tabKey: TPollsTabs) => {
		this.activeTab = tabKey;
	};

	toggleOnlyActiveStatus = () => {
		this.onlyActiveStatus = !this.onlyActiveStatus;
	};
}

export default new PollsTabsStore();
