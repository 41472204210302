import { useLocation } from "react-router-dom";

const usePollIdFromURL = () => {
	const location = useLocation();
	const [pollLocationType, pollId] = location.pathname.split("/").slice(-2);

	return [pollLocationType, pollId];
};

export default usePollIdFromURL;
