/* eslint-disable arrow-body-style */
import { mask } from "constants/constants.mask";
import { emailRegExp } from "constants/constants.regexp";
import { roles } from "constants/constants.roles";

import { FC } from "react";

import { CustomInput, Divider, FormItemContainer, FormItemsContainer } from "components/DrawerFormFields/DrawerFormFields";
import { Spacer } from "components/layout/Spacer";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { FormItem, Radio, Text, TextArea } from "tap2visit-ui-kit";

import EmployeeDrawerStore from "../store/Employee.drawer.store";
import EmployeeValidationStore from "../store/Employee.validation.store";

const DrawerUserEdit: FC = observer(() => {
	const onChangeLastName = (value: string) => {
		EmployeeDrawerStore.changeUserInfo({ key: "lastName", value });
		EmployeeValidationStore.validUserField("lastName");
	};

	const onChangeFirstName = (value: string) => {
		EmployeeDrawerStore.changeUserInfo({ key: "firstName", value });
		EmployeeValidationStore.validUserField("firstName");
	};

	const onChangeLogin = (value: string) => {
		EmployeeDrawerStore.changeUserInfo({ key: "email", value });
		EmployeeValidationStore.validUserField("email");
	};

	const onChangePhone = (value: string) => {
		EmployeeDrawerStore.changeUserInfo({ key: "mobilePN", value });
		EmployeeValidationStore.validUserField("mobilePN");
	};

	const onChangeMiddleName = (value: string) => {
		EmployeeDrawerStore.changeUserInfo({ key: "middleName", value });
		EmployeeValidationStore.validUserField("middleName");
	};

	const onChangeComment = (value: string) => {
		EmployeeDrawerStore.changeUserInfo({ key: "comment", value });
		EmployeeValidationStore.validUserField("comment");
	};

	return (
		<>
			<Text h="h5">Персональная информация</Text>
			<FormItemsContainerWrapper>
				<FormItemContainer>
					<FormItem
						state={EmployeeValidationStore.user.lastName ? "default" : "error"}
						message={!EmployeeValidationStore.user.lastName && "Поле должно быть заполнено и содержать менее 50 символов."}
						label="Фамилия:"
						isRequiredStarLeft
						required
						labelPosition="horizontal">
						<CustomInputWrapper
							value={EmployeeDrawerStore.selectedUser.lastName}
							sizeInput={"medium"}
							state={EmployeeValidationStore.user.lastName ? "default" : "error"}
							onChangeValue={onChangeLastName}
							placeholder="Иванов"
						/>
					</FormItem>
				</FormItemContainer>
				<FormItemContainer>
					<FormItem
						state={EmployeeValidationStore.user.firstName ? "default" : "error"}
						message={!EmployeeValidationStore.user.firstName && "Поле должно быть заполнено и содержать менее 50 символов."}
						label="Имя:"
						isRequiredStarLeft
						required
						labelPosition="horizontal">
						<CustomInputWrapper
							value={EmployeeDrawerStore.selectedUser.firstName}
							sizeInput={"medium"}
							onChangeValue={onChangeFirstName}
							state={EmployeeValidationStore.user.firstName ? "default" : "error"}
							placeholder="Иван"
						/>
					</FormItem>
				</FormItemContainer>
				<FormItemContainer>
					<FormItem
						state={EmployeeValidationStore.user.middleName ? "default" : "error"}
						message={!EmployeeValidationStore.user.middleName && "Поле должно содержать менее 50 символов"}
						label="Отчество:"
						labelPosition="horizontal">
						<CustomInputWrapper
							value={EmployeeDrawerStore.selectedUser.middleName}
							sizeInput={"medium"}
							state={EmployeeValidationStore.user.middleName ? "default" : "error"}
							onChangeValue={onChangeMiddleName}
							placeholder="Иванович"
						/>
					</FormItem>
				</FormItemContainer>
			</FormItemsContainerWrapper>

			<Divider />

			<Spacer px={28} />

			<Text h="h5">Роль</Text>

			<RolesContainer>
				{roles.map((role) => {
					return (
						<CheckboxContainer key={role.key}>
							<RadioContainer>
								<Radio
									checked={EmployeeDrawerStore.selectedUser?.role.includes(role.key)}
									onChange={() => EmployeeDrawerStore.changeUserInfo({ key: "role", value: role.key })}
								/>
							</RadioContainer>

							<Text type="base-regular">{role.text}</Text>
						</CheckboxContainer>
					);
				})}
			</RolesContainer>

			<Divider />

			<Spacer px={28} />

			<Text h="h5">Логин и телефон</Text>

			<FormItemsContainerWrapper>
				<FormItemContainer>
					<FormItem
						isRequiredStarLeft
						label="Логин (эл. почта):"
						required
						labelPosition="horizontal"
						state={EmployeeValidationStore.user.email ? "default" : "error"}
						message={
							!EmployeeValidationStore.user.email &&
							EmployeeDrawerStore.selectedUser.email &&
							!EmployeeDrawerStore.selectedUser.email.match(emailRegExp) &&
							"Эл. почта некорректна"
						}>
						<CustomInputWrapper
							value={EmployeeDrawerStore.selectedUser.email}
							sizeInput={"medium"}
							onChangeValue={onChangeLogin}
							state={EmployeeValidationStore.user.email ? "default" : "error"}
							placeholder="example@example.com"
						/>
					</FormItem>
				</FormItemContainer>
				<FormItemContainer>
					<FormItem isRequiredStarLeft label="Телефон:" required labelPosition="horizontal">
						<CustomInputWrapper
							value={EmployeeDrawerStore.selectedUser.mobilePN}
							sizeInput={"medium"}
							onChangeValue={onChangePhone}
							state={EmployeeValidationStore.user.mobilePN ? "default" : "error"}
							mask={mask.phone}
							maskChair=""
							placeholder={mask.phone}
						/>
					</FormItem>
				</FormItemContainer>
			</FormItemsContainerWrapper>

			<Divider />

			<Spacer px={28} />

			<Text h="h5">Комментарий</Text>
			<FormItemsContainerWrapper>
				<FormItemContainer>
					<FormItem
						state={EmployeeValidationStore.user.comment ? "default" : "error"}
						message={!EmployeeValidationStore.user.comment && "Поле должно содержать менее 255 символов"}
						label="Комментарий:"
						labelPosition="horizontal">
						<CustomTextareaWrapper
							placeholder="Текст"
							state={EmployeeValidationStore.user.comment ? "default" : "error"}
							value={EmployeeDrawerStore.selectedUser.comment}
							onChangeValue={onChangeComment}
						/>
					</FormItem>
				</FormItemContainer>
			</FormItemsContainerWrapper>

			<Divider />
		</>
	);
});

export default DrawerUserEdit;

const RolesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px 24px 20px 0px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	gap: 1px;
`;

const RadioContainer = styled.div`
	width: 30px;
`;

const FormItemsContainerWrapper = styled(FormItemsContainer)`
	align-items: flex-end;
	background: transparent;
`;

export const CustomTextareaWrapper = styled(TextArea)`
	width: 450px;
	font-size: 14px;
`;
export const CustomInputWrapper = styled(CustomInput)`
	width: 440px;
`;
