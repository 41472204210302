import React from "react";

import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";

export const TableFilterContext = React.createContext<BuildingFilterStore>(undefined);

export const useTableFilterContext = () => {
	const context = React.useContext(TableFilterContext);

	return context;
};
