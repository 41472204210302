import React from "react";

const useNewFileState = () => {
	const [newFiles, setNewFiles] = React.useState<File[]>([]);
	const [needToRemoveFileIds, setNeedToRemoveFileIds] = React.useState<string[]>([]);

	return {
		newFiles,
		setNewFiles,
		needToRemoveFileIds,
		setNeedToRemoveFileIds,
	};
};

export default useNewFileState;
