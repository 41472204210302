import axios, { AxiosResponse } from "axios";
import { ErrorsCode } from "interfaces/errorsCode";
import { ICodeCheck, IIsExistEmail } from "interfaces/IAuth";

import { ITokens } from "../interfaces/IToken";
import AuthStore from "../store/Auth.Store";

import apiClient from "./api.client";

const auth = btoa(process.env.REACT_APP_BASIC_EAA);
const authToken = btoa(process.env.REACT_APP_BASIC_EAA_TOKEN);

export type TGetIsExistEmailApi = (path: { email: string }) => Promise<IIsExistEmail>;

export const getIsExistEmailApi: TGetIsExistEmailApi = async (path) => {
	try {
		const res = await apiClient.get(`eaa/basic/v1/users/email/${path.email}/exists`, {
			headers: {
				Authorization: `Basic ${auth}`,
				"Content-Type": "application/json",
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCheckCodeFromEmailApi = (body: { email: string; code: string }) => Promise<ICodeCheck>;

export const checkCodeFromEmailApi: TCheckCodeFromEmailApi = async (body) => {
	try {
		const res = await apiClient.post(
			"eaa/basic/v1/users/code/check",
			{
				email: body.email,
				code: body.code,
			},
			{
				headers: {
					Authorization: `Basic ${auth}`,
					"Content-Type": "application/json",
				},
			},
		);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TSendCodeOnEmailApi = (body: { email: string }) => Promise<void>;

export const sendCodeOnEmailApi: TSendCodeOnEmailApi = async (body) => {
	try {
		const res = await apiClient.post(
			"eaa/basic/v1/users/code/send",
			{
				email: body.email,
			},
			{
				headers: {
					Authorization: `Basic ${auth}`,
					"Content-Type": "application/json",
				},
			},
		);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TAddPasswordAccountApi = (body: { email: string; code: string; password: string }) => Promise<void>;

export const addPasswordAccountApi: TAddPasswordAccountApi = async (body) => {
	try {
		const res = await apiClient.post(
			"/eaa/basic/v1/users/password",
			{
				email: body.email,
				code: body.code,
				password: body.password,
			},
			{
				headers: {
					Authorization: `Basic ${auth}`,
					"Content-Type": "application/json",
				},
			},
		);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TLoginToAppApi = (body: { email: string; password: string }) => Promise<ITokens>;

export const loginToAppApi: TLoginToAppApi = async (body) => {
	try {
		const res = await apiClient.post(
			"/eaa/v1/token",
			{
				username: body.email,
				credentials: body.password,
				grantType: "EMAIL_PASSWORD",
			},
			{
				headers: {
					Authorization: `Basic ${authToken}`,
					"Content-Type": "application/json",
				},
			},
		);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetTokensApi = (auth_code: string) => Promise<ITokens>;

export type TUpdateTokensApi = () => Promise<ITokens>;

export const updateTokensApi: TUpdateTokensApi = async () => {
	try {
		const refresh_token = localStorage.getItem("refresh_token");

		const data = new URLSearchParams();
		data.append("refreshToken", refresh_token);

		const res: AxiosResponse<ITokens> = await apiClient.post(
			"/eaa/v1/token/refresh",
			{ refreshToken: AuthStore.getTokens().refresh_token },
			{
				headers: {
					Authorization: `Basic ${authToken}`,
					"Content-Type": "application/json",
					"X-Requested-With": "XMLHttpRequest",
				},
				validateStatus: function (status) {
					return status < 500;
				},
			},
		);

		if (res.status === ErrorsCode.Unauthorized) {
			return Promise.reject({
				response: {
					status: ErrorsCode.Unauthorized,
				},
			});
		}
		if (res.status === ErrorsCode.Dismissed_Code) {
			AuthStore.logout();
		}

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
