import { HOME_PAGE } from "constants/constants.paths";

import { updateTokensApi } from "api/api.auth";
import { switchProfileUKApi } from "api/api.eaa";
import { queryClient } from "index";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { List } from "tap2visit-ui-kit";

import YKStore from "../../../store/UK.Store";
import UKStore from "../../../store/UK.Store";
import ProfileYKVisibilityStore from "../../profileUK/profileUKVisibility.store";

const ChoiceYKList = ({ onClose }: { onClose?: () => void }) => {
	const navigate = useNavigate();

	const switchProfileUK = useMutation({
		mutationFn: switchProfileUKApi,
	});

	const onItemClick = async (item: any) => {
		const currentUK = UKStore.getUK();
		if (item.id === currentUK.id) {
			onClose?.();
			return;
		}

		await switchProfileUK.mutateAsync({ path: { profileId: item.id } });
		await updateTokensApi();
		YKStore.setYK(item);

		ProfileYKVisibilityStore.clearActiveOffice();
		navigate(HOME_PAGE);
		onClose?.();
		// ! The updated data from modx star comes later than the update of all queries
		setTimeout(() => {
			queryClient.invalidateQueries();
		}, 0);
	};

	if (!YKStore.getCompanies().length) return null;
	return <List items={YKStore.getCompanies()} size="large" onClickItem={onItemClick} width="auto" />;
};

export default observer(ChoiceYKList);
