import React from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Space } from "tap2visit-ui-kit";

import { getEntrancesApi } from "../../api/api.gateway";
import PageLoader from "../../components/PageLoader";
import { QueriesKeys } from "../../interfaces/queriesKeys";

import BuildingsEntranceCard from "./BuildingsEntranceCard";
import BuildingsSliderEntrance from "./BuildingsSliderEntrance";

const BuildingsEntrance = () => {
	const { id } = useParams();

	const buildings = useQuery({
		queryFn: () => getEntrancesApi({ params: { requestStatus: "NEW", announcementStatus: "NEW", buildingId: id } }),
		queryKey: [QueriesKeys.advancedEntrances, id],
	});

	if (buildings.isFetching) return <PageLoader isLoading={buildings.isFetching} />;
	return (
		<BuildingsEntranceWrapper>
			<BuildingsSliderEntrance />
			<Space direction="column" align="start">
				<Space align="start">{buildings?.data?.map((v) => <BuildingsEntranceCard item={v} />)}</Space>
			</Space>
		</BuildingsEntranceWrapper>
	);
};

const BuildingsEntranceWrapper = styled.div`
	padding: 16px;
`;
export default BuildingsEntrance;
