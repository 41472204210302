import { getOrganizationApi } from "api/api.organization";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import UKStore from "store/UK.Store";

import AuthStore from "../../../store/Auth.Store";

const useAvatarLetters = () => {
	const organizationId = UKStore.getUK()?.managingOrganizationId;

	const uk = useQuery({
		queryFn: () => getOrganizationApi(organizationId),
		queryKey: [QueriesKeys.idOrganization, organizationId],
		enabled: AuthStore.checkAuth() && !!organizationId,
	});

	const firstLetterOrganizationName = uk.data?.shortName ? uk.data?.shortName?.[0] : uk.data?.name?.[0];
	const secondLetterOrganizationName = "";

	return {
		firstLetter: firstLetterOrganizationName,
		secondLetter: secondLetterOrganizationName,
	};
};

export default useAvatarLetters;
