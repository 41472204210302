import React from "react";

import styled from "styled-components";
import { Space } from "tap2visit-ui-kit";

import { buildingsFooterItems } from "./utils/buildingsFooterItems";

const BuildingsFooter = () => (
	<BuildingsFooterWrapper>
		<Space>
			{buildingsFooterItems.map((v) => (
				<Space>
					<SquareWrapper isBordered={v.isBordered} background={v.background} />
					<BuildingsFooterTitle>{v.title}</BuildingsFooterTitle>
				</Space>
			))}
			<Space>
				<NotificationWrapper />
				<BuildingsFooterTitle>Уведомление</BuildingsFooterTitle>
			</Space>
		</Space>
	</BuildingsFooterWrapper>
);

const BuildingsFooterWrapper = styled.div`
	padding: 12px 16px;
	border-radius: 12px;
	background: white;
	width: 550px;
	margin-top: 30px;
`;

const NotificationWrapper = styled.div`
	height: 8px;
	width: 8px;
	background: #ca504a;
	border-radius: 100%;
`;

const SquareWrapper = styled.div<{ background: string; isBordered?: boolean }>`
	width: 28px;
	height: 28px;
	background: ${({ background }) => background};
	border: ${({ isBordered }) => (isBordered ? "1px solid #12121229" : "none")};
	border-radius: 6px;
`;

const BuildingsFooterTitle = styled.p`
	font-size: 16px;
	font-weight: 450;
`;
export default BuildingsFooter;
