export const editOrCreateCitizenInputMaxLength = {
	series: 4,
	number: 6,
	departmentCode: 6,
	dateOfIssue: 10,
	dateOfBirth: 10,
	insuranceNumber: 11,
	inn: 12,
	phone: 11,
};
