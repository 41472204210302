import { useEffect, useState } from "react";

import TextHeader from "components/TextHeader";
import Input from "components/ui/Input/Input";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Button, FormItem } from "tap2visit-ui-kit";

import { checkCodeFromEmailApi, sendCodeOnEmailApi } from "../../../api/api.auth";
import { intl } from "../../../utils/translate";
import { TAuthProps } from "../Authorization";

import { ButtonLinkContainer } from "./SecondStageAuth";

const RestorePassword = ({ email, setCode, nextStage }: TAuthProps) => {
	const [codeLocal, setCodeLocal] = useState("");
	const [timer, setTimer] = useState(59);
	const code = codeLocal.replace("-", "");
	const [isErrorCode, setIsErrorCode] = useState(false);

	useQuery({
		queryFn: () => checkCodeFromEmailApi({ email, code: codeLocal.replace("-", "") }),
		queryKey: [QueriesKeys.checkCodeFromEmail, code],
		enabled: code.length === 4,
		onSuccess: (res) => {
			if (res.value) {
				setCode(codeLocal);
				nextStage(3);
			} else {
				setIsErrorCode(true);
			}
		},
	});

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.checkCodeFromEmail]);
	}, [code]);

	const sendCodeOnEmail = useMutation(sendCodeOnEmailApi);

	useEffect(() => {
		if (timer === 0) return;
		const timerID = setInterval(() => {
			setTimer(timer - 1);
		}, 1000);

		return () => clearInterval(timerID);
	}, [timer]);

	const resendCode = () => {
		sendCodeOnEmail.mutateAsync({ email });
		setTimer(59);
		setCodeLocal("");
		setCode("");
		setIsErrorCode(false);
	};

	return (
		<>
			<TextHeader>
				<FormattedMessage id="forgotYourPassword" />
			</TextHeader>
			<FormItem label={intl.getMessage("email")} labelTextStyle={{ fontSize: "12px" }}>
				<Input sizeInput={"large"} value={email} disabled={true} />
			</FormItem>
			<FormItem
				label={intl.getMessage("codeFromEmail")}
				message={isErrorCode ? "Код некорректен" : ""}
				state={isErrorCode ? "error" : "default"}
				labelTextStyle={{ fontSize: "12px" }}>
				<>
					<Input
						sizeInput={"large"}
						value={codeLocal}
						onChangeValue={(v) => {
							setIsErrorCode(false);
							setCodeLocal(v);
						}}
						mask="99-99"
						maskChair=""
						state={isErrorCode ? "error" : "default"}
					/>
					<div style={{ position: "relative" }}>
						<ButtonContainer isErrorState={isErrorCode}>
							<Button size="small" typeButton="link" disabled={!!timer} onClick={resendCode}>
								{`${intl.getMessage("codeRequestAgain")} ${timer ? `через ${timer} сек.` : ""}`}
							</Button>
						</ButtonContainer>
					</div>
				</>
			</FormItem>
		</>
	);
};

export default RestorePassword;

const ButtonContainer = styled.div<{ isErrorState: boolean }>`
	position: absolute;
	right: 0;
	top: ${(props) => (props.isErrorState ? "14px" : "0")};

	@media (max-width: 1640px) {
		top: ${(props) => (props.isErrorState ? "16px" : "0")};
	}
`;
