import { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import PollsEditStore from "pages/polls/store/PollsItem.store";
import { Droppable, DragDropContext, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";

import QuestionItem from "./QuestionItem/QuestionItem";
import QuestionItemEditable from "./QuestionItem/QuestionItemEditable";
import { ContentContainer } from "./QuestionItem/styles/common";

interface IPollsDetailListQuestions {
	editable?: boolean;
}

const PollsDetailListQuestions: FC<IPollsDetailListQuestions> = (props) => {
	const questions = PollsEditStore.poll.questions;

	return (
		<Container editable={props.editable}>
			<>
				{questions.map((question, index) => (
					<div key={question.id}>
						{props.editable && <QuestionItemEditable question={question} index={index} />}
						{!props.editable && <QuestionItem question={question} />}
						<Spacer px={16} />
					</div>
				))}
			</>
		</Container>
	);
};

export default observer(PollsDetailListQuestions);

const Container: FC<{ editable?: boolean; children: JSX.Element }> = (props) => {
	if (props.editable) {
		const reorderQuestions = (dropResult: DropResult) => {
			PollsEditStore.reorderQuestions({ endDragResult: dropResult });
		};

		return (
			<DroppableContainer>
				<ContentContainer>
					<DragDropContext onDragEnd={reorderQuestions}>
						<Droppable droppableId="polls" direction="vertical">
							{(provided) => (
								<PollsContainer ref={provided.innerRef} {...provided.droppableProps}>
									{props.children}
									{provided.placeholder}
								</PollsContainer>
							)}
						</Droppable>
					</DragDropContext>
				</ContentContainer>
			</DroppableContainer>
		);
	}

	return (
		<ContentContainer>
			<PollsContainer>{props.children}</PollsContainer>
		</ContentContainer>
	);
};

const PollsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const DroppableContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
