import React, { FC } from "react";

import styled, { keyframes } from "styled-components";

interface IRenderByCondition {
	children: JSX.Element;
	condition: boolean;
}

const RenderByCondition: FC<IRenderByCondition> = (props) => <ConditionBlock isVisible={props.condition}>{props.children}</ConditionBlock>;

export default RenderByCondition;

const fadeOut = keyframes`
	from {
		opacity: 1;
		transform: translateY(0);
		display: block;
	}
	to {
		opacity: 0;
		transform: translateY(-10px);
		height: 0;
		display: none;
	}
`;

const fadeIn = keyframes`
	from {
		opacity:0;
		transform: translateY(-10px);
		
	}
	to {
		opacity:1;
		transform: translateY(0);
		display: block;

	}
`;

const ConditionBlock = styled.div<{ isVisible: boolean }>`
	animation-fill-mode: forwards;
	animation-name: ${(props) => (props.isVisible ? fadeIn : fadeOut)};
	animation-duration: 0.4s;
`;
