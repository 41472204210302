import { makeAutoObservable } from "mobx";

class PollsConfirmStore {
	isVisibleConfirmArchiveModal = false;
	isVisibleConfirmDeleteModal = false;

	confirmCallback: () => Promise<void> = undefined;

	actionPollName: string = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	openConfirmArchiveModal = (args: { callback: () => Promise<void>; name: string }) => {
		this.confirmCallback = args.callback;
		this.actionPollName = args.name;
		this.isVisibleConfirmArchiveModal = true;
	};

	openConfirmDeleteModal = (args: { callback: () => Promise<void>; name: string }) => {
		this.confirmCallback = args.callback;
		this.actionPollName = args.name;
		this.isVisibleConfirmDeleteModal = true;
	};

	cancel = () => {
		this.dispose();
	};

	confirm = async () => {
		await this.confirmCallback();
		this.dispose();
	};

	dispose = () => {
		this.isVisibleConfirmArchiveModal = false;
		this.isVisibleConfirmDeleteModal = false;
		this.confirmCallback = undefined;
		this.actionPollName = undefined;
	};
}

export default new PollsConfirmStore();
