import React, { FC } from "react";

import { downloadFileApi } from "api/api.storage";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import { getUid } from "tap2visit-ui-kit";

interface IUseDownloadedFilesArgs {
	fileIds?: string[];
}

const useDownloadedFiles = (args: IUseDownloadedFilesArgs) => {
	const downloadedFiles = useQuery({
		queryFn: async () => {
			const result: { file: File; fileId: string }[] = [];
			for await (const fileId of args.fileIds) {
				try {
					const file = await downloadFileApi(fileId);
					if (file) {
						const blob = new Blob([file]);
						const imgFile = new File([blob], `${getUid("document_file_")}.jpeg`, { type: "image/jpeg" });
						result.push({ file: imgFile, fileId: fileId });
					}
					// eslint-disable-next-line no-empty
				} catch {}
			}
			return result;
		},
		enabled: !!args?.fileIds?.length,
		queryKey: [QueriesKeys.downloadFile, ...(args?.fileIds ?? [])],
	});

	return downloadedFiles;
};

export default useDownloadedFiles;
