import React from "react";

import { getPollAnswersApi } from "api/api.votes";
import { QueriesKeys } from "interfaces/queriesKeys";
import usePollIdFromURL from "pages/polls/hooks/usePollIdFromURL";
import useRedirect from "pages/polls/hooks/useRedirect";
import PollsItemStore from "pages/polls/store/PollsItem.store";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

const usePollAnswers = () => {
	const [pollLocationType, pollId] = usePollIdFromURL();
	const { pollHasBeenChecked } = useRedirect();

	const pollAnswers = useQuery({
		queryFn: () => getPollAnswersApi({ path: { pollId } }),
		queryKey: [QueriesKeys.pollAnswers, pollId],
		enabled: pollLocationType === "view" && !!pollId && pollHasBeenChecked,
	});

	return pollAnswers;
};

export default usePollAnswers;
