import styled from "styled-components";

const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-right: 10px;
	padding-bottom: 20px;
	padding-top: 30px;
`;

export default PaginationContainer;
