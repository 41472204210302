import React from "react";

import { Modal } from "tap2visit-ui-kit";

import ChoiceUKList from "../../authorization/components/ChoiceUKList";

interface ITemplateBasicOrganizationProps {
	setOpen: (v: boolean) => void;
}
export const TemplateBasicOrganization = ({ setOpen }: ITemplateBasicOrganizationProps) => (
	<Modal onOk={() => setOpen(false)} okText="Закрыть" title="Выбрать УК" onExit={() => setOpen(false)}>
		<ChoiceUKList onClose={() => setOpen(false)} />
	</Modal>
);
