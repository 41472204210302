import { FC } from "react";
import React from "react";

import { getAnnouncementsByIdApi } from "api/api.gateway";
import { Spacer } from "components/layout/Spacer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import TreeSendToEveryOne from "components/TreeSendToEveryOne";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import useRealEstateTreeData from "hooks/useRealEstateTreeData/useRealEstateTreeData";
import { IAnnouncement } from "interfaces/IAnnouncements";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { ReactComponent as CloseSvg } from "public/circleClose.svg";
import { useQuery } from "react-query";
import styled from "styled-components";
import { TTableColumn, Table, Tag, Text, colors } from "tap2visit-ui-kit";

import AnnouncementsCurrentItemStore from "../store/Announcements.currentItem.store";
import AnnouncementsViewDataStore from "../store/Announcements.viewData.store";
import getInitialExpandedRowsKeys from "../utils/getInitialExpandedRowsKeys";

const columns: TTableColumn[] = [
	{
		key: "realEstateObject",
		dataIndex: "realEstateObject",
		title: "",
		width: "100%",
		textStyle: {
			display: "flex",
			alignItems: "center",
		},
		onCell: () => {
			const props: React.TdHTMLAttributes<HTMLTableCellElement> = {};
			props.style = { background: "white", borderRight: 0 };
			return props;
		},
	},
];

type TCheckedItem = {
	id: string;
	checked: boolean;
	children?: TCheckedItem[];
};

type TEstates = Pick<IAnnouncement, "complexIds" | "buildingIds" | "entranceIds" | "floorIds" | "buildingObjectIds">;

interface IAnnouncementCreateTree {
	announcementId?: string;
}

const AnnouncementCreateTree: FC<IAnnouncementCreateTree> = (props) => {
	const announcementEditItem = useQuery({
		queryFn: () => getAnnouncementsByIdApi({ id: props.announcementId }),
		queryKey: [QueriesKeys.announcement, props.announcementId],
		enabled: !!props.announcementId,
		staleTime: Infinity,
	});

	const initialExpandedKeys = getInitialExpandedRowsKeys(announcementEditItem.data);

	const updateAllEstateCheckboxes = (items: TEstates) => {
		RealEstateTreeStore.setInitialSelectedIds({
			ids: {
				complexIds: items?.complexIds ?? [],
				buildingIds: items?.buildingIds ?? [],
				entranceIds: items?.entranceIds ?? [],
				floorsIds: items?.floorIds ?? [],
				buildingObjectIds: items?.buildingObjectIds ?? [],
			},
		});
	};

	React.useEffect(() => {
		updateAllEstateCheckboxes({
			complexIds: announcementEditItem.data?.complexIds,
			buildingIds: announcementEditItem.data?.buildingIds,
			entranceIds: announcementEditItem.data?.entranceIds,
			floorIds: announcementEditItem.data?.floorIds,
			buildingObjectIds: announcementEditItem.data?.buildingObjectIds,
		});
	}, [announcementEditItem.data]);

	React.useEffect(() => {
		if (AnnouncementsCurrentItemStore.currentItem === "editTemplate" || AnnouncementsCurrentItemStore.currentItem === "fillFromTemplate") {
			updateAllEstateCheckboxes({
				complexIds: AnnouncementsCurrentItemStore.templateItem.complexIds,
				buildingIds: AnnouncementsCurrentItemStore.templateItem.buildingIds,
				entranceIds: AnnouncementsCurrentItemStore.templateItem.entranceIds,
				floorIds: AnnouncementsCurrentItemStore.templateItem.floorIds,
				buildingObjectIds: AnnouncementsCurrentItemStore.templateItem.buildingObjectIds,
			});
		}
	}, [AnnouncementsCurrentItemStore.currentItem]);

	const { treeData, sendToEveryOneData } = useRealEstateTreeData();

	const selectedRoles = AnnouncementsViewDataStore.selectedRoles.filter((role) => role.focused);

	const onSendToEveryoneCheckboxHandler = () => {
		sendToEveryOneData.toggle();
	};

	return (
		<Wrapper>
			<Container>
				<Text h="h5">Выбранные роли получателей</Text>
				{!selectedRoles.length && (
					<NoRolesSelectedContainer>
						<Text type="base-medium">Нет выбранных ролей получателей объявлений</Text>
					</NoRolesSelectedContainer>
				)}

				<Spacer px={8} />

				<TagContainer>
					{selectedRoles.map((role) => (
						<Tag
							text={role.text as string}
							color={"grey"}
							rightElement={() => (
								<CloseSvg
									onClick={() => {
										AnnouncementsViewDataStore.unselectedRole(role);
									}}
								/>
							)}
						/>
					))}
				</TagContainer>

				<Spacer px={20} />

				<TreeSendToEveryOne
					visible={!!treeData.length}
					checked={sendToEveryOneData.value}
					changeChecked={onSendToEveryoneCheckboxHandler}
				/>
			</Container>
			<Table
				data={treeData}
				columns={columns ?? []}
				size={"m"}
				showHeader={false}
				isFullWidth
				initialExpandedRowKeys={initialExpandedKeys}
				emptyComponent={announcementEditItem.isFetching ? TableLoader : TableEmptySlug}
			/>
		</Wrapper>
	);
};

export default observer(AnnouncementCreateTree);

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 480px;
	min-height: 100vh;
`;

const Container = styled.div`
	min-width: 480px;
	padding: 12px;
	background-color: ${colors.surfaceNeutralBgWhite};
	overflow-y: auto;
`;

const TagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;

const SendToEveryoneContainer = styled.div`
	display: flex;
	padding-left: 16px;
	gap: 4px;
	align-items: center;
`;

const NoRolesSelectedContainer = styled.div`
	padding-top: 8px;
	padding-left: 2px;
`;
