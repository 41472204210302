export const formatData = (date: string) => {
	const currDate = new Date(date);

	const year = currDate.getFullYear().toString().slice(2);
	const month = (currDate.getMonth() + 1).toString().padStart(2, "0");
	const day = currDate.getDate().toString().padStart(2, "0");

	const hours = currDate.getHours().toString().padStart(2, "0");
	const minutes = currDate.getMinutes().toString().padStart(2, "0");

	return `${day}.${month}.${year}  ${hours}:${minutes}`;
};
