import { FC } from "react";

import { getBuildingObjectIdsByUserIdApi } from "api/api.realEstate";
import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import EmployeeDrawerStore from "pages/employee/store/Employee.drawer.store";
import { ReactComponent as SmartPhone } from "public/SmartPhone.svg";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Badge, Button, Text, colors } from "tap2visit-ui-kit";
import getRoleTranslate from "utils/getRoleTranslate";
import getUserRoleByKey from "utils/getUserRoleByKey";

import TextHeader from "./TextHeader";

interface IUserAvatarAndInitials {
	fullName?: string;
	role: string;
}

const UserAvatarAndInitials: FC<IUserAvatarAndInitials> = (props) => {
	const location = useLocation().pathname;

	const isCitizenPage = location === "/citizens";

	const citizenBuildingObjectsIds = useQuery({
		queryFn: () => getBuildingObjectIdsByUserIdApi({ path: { clientId: CitizensDrawerStore.selectedUser.id } }),
		enabled: !!CitizensDrawerStore.selectedUser.id,
		queryKey: [QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id],
	});

	const roles = Array.from(
		citizenBuildingObjectsIds.data
			?.flatMap((item) => item.roles)
			// ? withour duplicates
			.filter((role, idx, arr) => arr.slice(0, idx).every((currRole) => role.name !== currRole.name)) ?? [],
	);

	const [lastName, firstName] = props.fullName?.split(" ") ?? ["", ""];

	return (
		<UserInfoContainer>
			<AvatarContainer>
				<Avatar
					size={isCitizenPage ? "80" : "96"}
					text={`${firstName?.[0]} ${lastName?.[0]}`}
					badgeIcon={isCitizenPage && SmartPhone}
					badgeIconBackground="white"
				/>
			</AvatarContainer>
			<TextInfoContainer>
				<TextWrapper>
					<CustomTextHeader>{props.fullName}</CustomTextHeader>
				</TextWrapper>

				<Badge size="medium" status="green" type={"status"} text={getUserRoleByKey(EmployeeDrawerStore.selectedUser.role)} />
			</TextInfoContainer>
		</UserInfoContainer>
	);
};

export default observer(UserAvatarAndInitials);

const UserInfoContainer = styled.div`
	display: flex;
	gap: 16px;
	align-items: flex-start;
`;

const ButtonWrapper = styled(Button)`
	p {
		font-weight: 600;
	}
`;

const TextInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 80%;
	overflow: hidden;
`;

const RolesInfoContainer = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`;

const AvatarContainer = styled.div`
	border: 4px solid ${colors.strokeFloatBorder};
	border-radius: 50%;

	box-shadow: 4px 4px 29px 0px rgba(16, 24, 40, 0.08);
`;

const TextWrapper = styled.div``;

const CustomTextHeader = styled(TextHeader)`
	width: 100%;
	color: ${colors.textNeutralPrimary};
	white-space: break-spaces;
	word-break: break-all;
`;
