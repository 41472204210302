import React, { useState } from "react";

import { Space } from "antd";
import { ReactComponent as DeleteDropdown } from "public/deleteDropdown.svg";
import { ReactComponent as DropdownIcon } from "public/dropdownIcon.svg";
import { ReactComponent as RentDropdown } from "public/rentDropdown.svg";
import styled from "styled-components";
import { DropDown, Button } from "tap2visit-ui-kit";

import BaseModal from "../../Modals/BaseModal";

import CitizenRealtyCreate from "./CitizenRealtyCreate";

const CitizensEditHeaderSlot = () => {
	const [openCreateRealty, setOpenCreateRealty] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const [open, setOpen] = useState(false);
	// TODO
	// startregion Legacy solution | bug with click on drawer button and drawer reopening instead closing
	const blockedOpenDrawer = React.useRef(false);

	const closeDrawer = () => {
		setOpen(false);
		blockedOpenDrawer.current = true;
	};
	// endregion

	return (
		<Space>
			<CitizenRealtyCreate setOpen={setOpenCreateRealty} open={openCreateRealty} />
			<BaseModal
				title="Удалить жителя?"
				description="Житель будет удален безвозвратно"
				okText={"Да"}
				cancelText="Нет"
				isVisible={openDeleteModal}
				status="delete"
				onCancel={() => setOpenDeleteModal(false)}
				onOk={() => setOpenDeleteModal(false)}
				onExit={() => setOpenDeleteModal(false)}
			/>
			{/* Temporary hidden */}
			{/* <div style={{ position: "relative" }}>
				<Button
					icon={DropdownIcon}
					onClick={() => {
						if (!blockedOpenDrawer.current) {
							setOpen(true);
						}
						blockedOpenDrawer.current = false;
					}}
					shape="square"
					typeButton="secondary"
				/>
				<DropDownContainer>
					<DropDown
						as="context menu"
						isVisible={open}
						items={[
							{
								icon: RentDropdown,
								meta: {
									id: "1",
								},
								text: "Добавить недвижимость",
							},
							{
								icon: DeleteDropdown,
								meta: {
									id: "2",
								},
								text: "Удалить жителя",
							},
						]}
						onClick={(item) => {
							if (item.meta.id === "1") {
								setOpenCreateRealty(true);
							} else {
								setOpenDeleteModal(true);
							}
						}}
						onClickOutside={closeDrawer}
					/>
				</DropDownContainer>
			</div> */}
		</Space>
	);
};

export default CitizensEditHeaderSlot;

const DropDownContainer = styled.div`
	position: relative;
	top: 60px;
`;
