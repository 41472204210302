import { TDrawerMode } from "interfaces/IDrawerMode";
import { IUser } from "interfaces/User/IUser";
import { makeAutoObservable } from "mobx";

import EmployeeValidationStore from "./Employee.validation.store";

const defaultUser: IUser = {
	email: "",
	firstName: "",
	id: "",
	isPasswordSet: false,
	lastName: "",
	middleName: "",
	mobilePN: "",
	role: "ADMINISTRATOR",
	residentialAddress: "",
	series: "",
	number: "",
	issueBy: "",
	dateOfIssue: "",
	departmentCode: "",
	dateOfBirth: "",
	expirationDate: "",
	share: "",
	address: "",
	firstLogin: "",
	lastLogin: "",
	comment: "",
};

class EmployeeStore {
	isVisibleDrawer = false;
	drawerMode: TDrawerMode | undefined = undefined;
	selectedUser: IUser = defaultUser;
	isEditedNewUser = false;
	isNewUserEdit = false; // TODO NEED IT ?
	userId = "";

	/**
	 * description when we edit user, need to save user without changes to return actual user data
	 */
	private previousSelectedUser: IUser = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	get isRoleHasBeenChanged() {
		return this.selectedUser.role !== this.previousSelectedUser.role;
	}

	openDrawer = (args: { mode: TDrawerMode; user?: IUser; isNewUserEdit?: boolean; userId?: string }) => {
		if (args.mode === "edit") {
			this.previousSelectedUser = JSON.parse(JSON.stringify(args.user));
		}

		this.drawerMode = args.mode;
		this.selectedUser = args.user ?? defaultUser;
		this.isVisibleDrawer = true;
		this.isNewUserEdit = args.isNewUserEdit;
		this.userId = args.userId;
	};

	closeDrawer = () => {
		this.drawerMode = undefined;
		this.selectedUser = defaultUser;
		this.isVisibleDrawer = false;
		EmployeeValidationStore.dispose();
	};

	/**
	 * description when we close editing user and return to user view
	 */
	cancelDrawer = () => {
		this.selectedUser = this.previousSelectedUser;
		this.previousSelectedUser = undefined;
		this.drawerMode = "visible";
		EmployeeValidationStore.dispose();
	};

	changeUserInfo = (args: { key: keyof IUser; value: string }) => {
		if (typeof args.value === "boolean") {
			return;
		}

		(this.selectedUser[args.key] as IUser[keyof IUser]) = args.value;
	};
}

export default new EmployeeStore();
