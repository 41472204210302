import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TCitizenPassportValidationFields = {
	insuranceNumber: keyof typeof ValidationStatuses;
};

const initialValidationData: TCitizenPassportValidationFields = {
	insuranceNumber: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TCitizenPassportValidationFields, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	insuranceNumber: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, EQUALS: "Поле должно содержать 11 символов" },
};

const checkRules = {
	insuranceNumber: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.EQUALS]: 11,
	},
};

const CitizenInsuranceNumberValidationStore = new ValidationStore<TCitizenPassportValidationFields>(
	initialValidationData,
	ERROR_MESSAGES,
	checkRules,
);

export default CitizenInsuranceNumberValidationStore;
