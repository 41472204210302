import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import { observer } from "mobx-react-lite";
import useSavePoll from "pages/polls/hooks/useSavePoll";

import PollsEditSendModalStore from "../../store/PollsSendModal.store";
import PollsPeriodOfTime from "../PollsPeriodOfTime";
import PollsRoles from "../PollsRoles";
import PollsTreeBuildings from "../PollsTreeBuildings";

import PollSendModalContent from "./components/PollsEditContent.sendUsing";

interface IPollsSendModal {}

const PollsSendModal: FC<IPollsSendModal> = (props) => {
	const savePoll = useSavePoll();

	React.useEffect(() => () => RealEstateTreeStore.dispose(), []);

	return (
		<BaseModal
			isVisible={PollsEditSendModalStore.isVisibleModal}
			onCancel={() => PollsEditSendModalStore.changeStatusModal(false)}
			onExit={() => PollsEditSendModalStore.changeStatusModal(false)}
			onOk={savePoll}
			title={"Отправка опроса"}
			contentContainerStyle={{ maxHeight: "80vh", overflow: "scroll" }}
			okText="Отправить"
			cancelText="Отмена">
			<>
				<PollsPeriodOfTime />

				<Spacer px={16} />

				<PollSendModalContent />

				<Spacer px={16} />

				<PollsRoles editable={true} />

				<Spacer px={16} />

				<PollsTreeBuildings editable={true} />
			</>
		</BaseModal>
	);
};

export default observer(PollsSendModal);
