import { INewEditableOffice, IOffice } from "interfaces/IOffice";
import { makeAutoObservable } from "mobx";

export type TActiveTabType = "offices" | "information";

class ProfileUKVisibilityStore {
	isEditModeEnabled = false;
	currentActiveTab: TActiveTabType = "offices";
	editableOffice: IOffice | null = null;
	activeOfficeId: string | null = null;
	isThereAtLeastOneOffice = false;
	newEditableOffice: INewEditableOffice | null = null;
	newAddressId: string | null = null;

	private prevActiveOfficeId: string | null = null;

	get isVisibleInfoOffice() {
		return this.currentActiveTab === "information";
	}

	get isVisiblePageEdit() {
		return this.currentActiveTab === "offices" && this.isEditModeEnabled;
	}

	get isVisibleOfficeDetail() {
		return !this.isVisibleInfoOffice && !this.isVisiblePageEdit;
	}

	constructor() {
		makeAutoObservable(this);
	}

	setNewAddressId = (value: string | null) => {
		this.newAddressId = value;
	};

	setNewEditableOffice = (newEditableOffice: INewEditableOffice | null) => {
		this.newEditableOffice = newEditableOffice;
	};

	setIsEditModeEnabled = (status: boolean) => {
		this.isEditModeEnabled = status;
	};

	changeCurrentActiveTab = (tab: TActiveTabType) => {
		this.currentActiveTab = tab;
	};

	createNewOffice = () => {
		if (this.activeOfficeId) {
			this.prevActiveOfficeId = this.activeOfficeId;
		}

		this.editableOffice = null;
		this.isEditModeEnabled = true;
		this.activeOfficeId = null;
	};

	editOffice = (office: IOffice | null) => {
		this.editableOffice = office;
		this.currentActiveTab === "offices";
		this.isEditModeEnabled = true;
	};

	clearActiveOffice = () => {
		this.editableOffice = null;
		this.activeOfficeId = null;
		this.isThereAtLeastOneOffice = false;
	};

	cancelEdit = (activeOfficeId?: string) => {
		this.isEditModeEnabled = false;
		this.editableOffice = null;

		if (this.prevActiveOfficeId) {
			this.activeOfficeId = activeOfficeId ?? this.prevActiveOfficeId;
			this.prevActiveOfficeId = null;
		}
	};

	setActiveOfficeId = (officeId: string | null) => {
		this.activeOfficeId = officeId;
	};

	setIsThereAtLeastOneOffice = (status: boolean) => {
		if (!status) {
			this.isEditModeEnabled = true;
		}
		this.isThereAtLeastOneOffice = status;
	};
}

export default new ProfileUKVisibilityStore();
