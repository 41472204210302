import { makeAutoObservable } from "mobx";

export type TActiveTab = "mobileApp" | "archive";

class AccessRequestTabStore {
	activeTab: TActiveTab = "mobileApp";

	constructor() {
		makeAutoObservable(this);
	}

	setActiveTab = (key: TActiveTab) => {
		this.activeTab = key;
	};
}

export default new AccessRequestTabStore();
