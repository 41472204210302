import React, { useState } from "react";

import TextHeader from "components/TextHeader";
import Input from "components/ui/Input/Input";
import InputIconContainer from "components/ui/Input/InputIconСontainer";
import InputPassword from "components/ui/Input/InputPassword";
import { ReactComponent as CloseImage } from "public/eye_off.svg";
import { ReactComponent as OpenImage } from "public/eye_on.svg";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-query";
import AuthStore from "store/Auth.Store";
import { Button, FormItem } from "tap2visit-ui-kit";
import { intl } from "utils/translate";

import { addPasswordAccountApi, loginToAppApi } from "../../../api/api.auth";

import { ButtonContainer } from "./FirstStageAuth";

const AddPassword = ({ email, code, navigateToChoiceUK }) => {
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showSecondPassword, setShowSecondPassword] = useState(false);
	const [state, setState] = useState<"error" | "default">("default");
	const addPasswordAccount = useMutation(addPasswordAccountApi);

	const loginToApp = useMutation(loginToAppApi, {
		onSuccess: (res) => {
			AuthStore.saveTokens(res);
		},
	});

	const addPasswordUser = async () => {
		if (password !== repeatPassword || !password || !repeatPassword) {
			setState("error");
		}
		if (password === repeatPassword && password && repeatPassword) {
			setState("default");
			await addPasswordAccount.mutateAsync({ email, code: code.replace("-", ""), password });
			const tokens = await loginToApp.mutateAsync({ email, password });
			AuthStore.saveTokens(tokens);

			navigateToChoiceUK();
		}
	};

	return (
		<>
			<TextHeader>
				<FormattedMessage id="createPassword" />
			</TextHeader>
			<FormItem label={intl.getMessage("password")}>
				<InputPassword
					state={state}
					sizeInput={"large"}
					value={password}
					onChangeValue={(value) => {
						setPassword(value);
						setState("default");
					}}
				/>
			</FormItem>
			<FormItem
				state={state}
				message={state === "error" && password && repeatPassword && "Пароли не совпадают"}
				label={intl.getMessage("repeatPassword")}>
				<InputPassword
					state={state}
					sizeInput={"large"}
					value={repeatPassword}
					onChangeValue={(value) => {
						setRepeatPassword(value);
						setState("default");
					}}
				/>
			</FormItem>
			<ButtonContainer>
				<Button size="large" typeButton="primary" onClick={addPasswordUser}>
					Войти
				</Button>
			</ButtonContainer>
		</>
	);
};

export default AddPassword;
