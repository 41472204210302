import { Row } from "antd";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import styled from "styled-components";

import AccessRequestTable from "./components/AccessRequestTable";
import ModalCommentRejection from "./components/ModalCommentRejection/ModalCommentRejection";
import Tabs from "./components/Tabs/Tabs";
import ModalConfirmEditCitizen from "./components/ModalConfirmEditCitizen/ModalConfirmEditCitizen";

const TableFilterInstanceStore = new BuildingFilterStore();

const AccessRequest = () => (
	<>
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<AccessRequestWrapper>
				<PaddingOffset>
					<Tabs />
				</PaddingOffset>

				<AccessRequestFilterWrapper>
					<TableFilter />
				</AccessRequestFilterWrapper>
				<AccessRequestTable />
			</AccessRequestWrapper>
		</TableFilterContext.Provider>
		<ModalCommentRejection />
		<ModalConfirmEditCitizen />
	</>
);

export default AccessRequest;

const AccessRequestWrapper = styled.div`
	height: 90vh;
`;

const AccessRequestFilterWrapper = styled.div`
	margin: 32px 12px;
`;

const ButtonWrapper = styled(Row)`
	margin: 20px auto;
`;

const PaddingOffset = styled.div`
	padding: 0 24px;
	padding-top: 32px;
`;
