import React, { FC } from "react";

import { createPortal } from "react-dom";
import { Modal } from "tap2visit-ui-kit";

interface IPageEditDialogAreYouSureDeleteOffice {
	isVisible: boolean;
	onDelete: () => void;
	onCancel: () => void;
}

const PageEditDialogAreYouSureDeleteOffice: FC<IPageEditDialogAreYouSureDeleteOffice> = (props) => (
	<>
		{props.isVisible &&
			createPortal(
				<Modal
					okText="Да"
					onCancel={props.onCancel}
					onExit={props.onCancel}
					onOk={props.onDelete}
					cancelText="Отмена"
					status="delete"
					title="Удалить офис">
					Вы уверены, что хотите удалить офис?
				</Modal>,
				document.getElementById("modal"),
			)}
	</>
);

export default PageEditDialogAreYouSureDeleteOffice;
