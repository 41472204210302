import { TObjectType } from "interfaces/IBuilding";

export const getBuildingObjectType = (type: TObjectType) => {
	switch (type) {
		case "BASEMENT":
			return "Подвал";
		case "FLAT":
			return "Квартира";
		case "OFFICE":
			return "Оффис";
		case "STORAGE":
			return "Кладовка";
		case "PARKING":
			return "Парковка";
	}
};

export const getTextLivingArea = (isLivingSpace: boolean) => (isLivingSpace ? "Жилое" : "Не жилое");
