import chunk from "utils/chunk";

export const getFormtattedSum = (sum: string) => {
	if (sum.length <= 3) {
		return `${sum} ₽`;
	}

	const dividerIndex = sum.indexOf(".");
	const splittedSum = sum.slice(0, dividerIndex !== -1 ? dividerIndex : sum.length).split("");
	const pennies = dividerIndex !== -1 ? sum.slice(dividerIndex + 1) : undefined;
	const reversedSplittedSum = splittedSum.reverse();

	const chunkSum = chunk(reversedSplittedSum, 3)
		.map((chunkArr) => chunkArr.reverse().reduce((acc, num) => acc + num, ""))
		.reverse();

	if (pennies) {
		return `${chunkSum.join(" ")}.${pennies.slice(0, 2)}  ₽`;
	}
	return `${chunkSum.join(" ")} ₽`;
};
