import { FC, useEffect } from "react";
import React from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled, { css } from "styled-components";
import { Pagination, TTableColumn, Table, Text, colors } from "tap2visit-ui-kit";

import { getUserByIdApi } from "api/api.clients";
import { TGetClientApiParams, getClientApi } from "api/api.gateway";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import { MIN_TABLE_HEIGHT, MIN_TABLE_WIDTH } from "constants/constants.common";
import { emailRegExp } from "constants/constants.regexp";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import useWindowWidth from "hooks/useWindowWidth";
import { queryClient } from "index";
import { IClient } from "interfaces/IClient";
import { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import SideBarStore from "store/SideBar.store";

import CitizensDrawer from "../../components/Citizens/Citizens.drawer";
import CitizensDrawerStore from "../../components/Citizens/store/Citizens.drawer.store";
import formatPhoneNumber from "../../utils/format/formatPhone";
import Page from "components/layout/Page";

const TableFilterInstanceStore = new BuildingFilterStore();

const CELL_HEIGHT = 46;

const Citizens: FC = () => {
	const [selectedUserId, setSelectedUserId] = React.useState<string | undefined>();
	const [searchInitials, setSearchInitials] = React.useState("");
	const [searchPhone, setSearchPhone] = React.useState("");
	const [searchEmail, setSearchEmail] = React.useState("");
	const [sortedBy, setSortedBy] = React.useState<TSortingDirection | undefined>();
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const windowWidth = useWindowWidth();

	// eslint-disable-next-line prefer-const
	const paginationData = useTablePaginationLogic();
	// const [searchAddressProperty, setSearchAddressProperty] = React.useState(""); // TODO need in future
	// const [searchAddressRegistration, setSearchAddressRegistration] = React.useState(""); // TODO need in future

	const currentUser = useQuery({
		queryFn: () => getUserByIdApi({ path: { id: selectedUserId } }),
		queryKey: [QueriesKeys.user, selectedUserId],
		enabled: !!selectedUserId,
		staleTime: Infinity,
	});

	React.useEffect(() => {
		if (selectedUserId && currentUser.data) {
			const buildingsArr = clients.data?.content.filter((v) => v.id === currentUser.data.id)[0].accounts[0].buildingObjectAddresses;

			CitizensDrawerStore.openDrawer({ mode: "visible", user: currentUser.data });
			CitizensDrawerStore.changeSelectedUserBuildings(buildingsArr);
		}
	}, [selectedUserId, currentUser.isFetching, currentUser.data, openDrawer]);

	React.useEffect(() => {
		if (!CitizensDrawerStore.isVisibleDrawer) {
			setSelectedUserId(undefined);
		}
	}, [CitizensDrawerStore.isVisibleDrawer]);

	useEffect(() => {
		paginationData.setClientsCurrentPage(1);
	}, [
		searchInitials,
		searchPhone,
		searchEmail,
		TableFilterInstanceStore.selectedComplexIds.join(","),
		TableFilterInstanceStore.selectedBuildingsIds.join(","),
		TableFilterInstanceStore.selectedEntrancesIds.join(","),
		TableFilterInstanceStore.selectedFloorsIds.join(","),
		TableFilterInstanceStore.selectedBuildingObjectIds.join(","),
		TableFilterInstanceStore.selectRoles.join(","),
	]);

	const clients = useQuery({
		queryFn: () => {
			const params: TGetClientApiParams = {
				sort: sortedBy,
				emailSearch: searchEmail,
				fullNameSearch: searchInitials,
				phoneNumberSearch: searchPhone,
				complexIds: TableFilterInstanceStore.selectedComplexIds,
				buildingIds: TableFilterInstanceStore.selectedBuildingsIds,
				entranceIds: TableFilterInstanceStore.selectedEntrancesIds,
				floorIds: TableFilterInstanceStore.selectedFloorsIds,
				buildingObjectIds: TableFilterInstanceStore.selectedBuildingObjectIds,

				roleIds: TableFilterInstanceStore.selectRoles,
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
			};

			!searchEmail && delete params.emailSearch;
			!searchInitials && delete params.fullNameSearch;
			!searchPhone && delete params.phoneNumberSearch;

			return getClientApi({
				params,
			});
		},
		enabled: TableFilterInstanceStore.selectRoles.length > 0,
		queryKey: [
			QueriesKeys.clients,
			sortedBy,
			searchEmail,
			searchInitials,
			searchPhone,
			TableFilterInstanceStore.selectedComplexIds.join(","),
			TableFilterInstanceStore.selectedBuildingsIds.join(","),
			TableFilterInstanceStore.selectedEntrancesIds.join(","),
			TableFilterInstanceStore.selectedFloorsIds.join(","),
			TableFilterInstanceStore.selectedBuildingObjectIds.join(","),
			TableFilterInstanceStore.selectRoles.join(","),
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
	});

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.clients]);
	}, [sortedBy]);

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.clients]);
	}, [TableFilterInstanceStore.selectRoles]);

	const isVisiblePagination = !clients.isFetching && clients.data;

	React.useEffect(() => {
		if (clients.data) {
			paginationData.setClientsTotal(clients.data.totalPages);
		}
	}, [clients.data?.page, searchInitials, searchPhone, searchEmail]);

	const tableColumns: TTableColumn[] = [
		{
			key: "initials",
			dataIndex: "initials",
			title: "ФИО",
			columnCellStyles: {
				width: "250px",
			},
			onSearch: setSearchInitials,
		},
		{
			key: "phone",
			dataIndex: "phone",
			title: "Телефон",
			style: {
				width: "150px",
			},
			onSearch: setSearchPhone,
			onSearchByFormatterString: (v) => v.replace(/[^\d]/g, ""),
			render: (data) =>
				data?.map((phone, idx) => (
					<Text key={idx} type="base-medium">
						{formatPhoneNumber(phone)}
					</Text>
				)),
		},
		{
			key: "email",
			dataIndex: "email",
			title: "Эл. почта",
			columnCellStyles: {
				width: "200px",
			},
			render: (email: string) =>
				emailRegExp.test(email) ? (
					<Link
						onClick={(e) => {
							e.stopPropagation();
						}}
						href={`mailto:${email}`}>
						{email}
					</Link>
				) : (
					<Text type="base-medium">{email}</Text>
				),
			onSearch: setSearchEmail,
		},
		{
			key: "personal account",
			dataIndex: "personal account",
			title: "Лицевой счет",
			style: {
				minWidth: "225px",
			},
			onSortedBy: setSortedBy,
			cellStyle: {
				padding: 0,
			},
			render: (data) =>
				data.map((account, idx) => (
					<MultipleCell height={`${account.addressesCount * CELL_HEIGHT}px`} isFirst={idx === 0}>
						<Text type="base-medium">{account.number}</Text>
					</MultipleCell>
				)),
		},
		{
			key: "apartment address",
			dataIndex: "apartment address",
			title: "Адрес недвижимости",
			style: {
				width: "225px",
			},
			cellStyle: {
				padding: 0,
			},
			render: (data) =>
				data.map((addressArr, addressArrIdx) =>
					addressArr.map((address, addressIdx) => (
						<MultipleCell isFirst={addressIdx === 0 && addressArrIdx === 0}>
							<Text type="base-medium">{address}</Text>
						</MultipleCell>
					)),
				),
			// onSearch: setSearchAddressProperty, // TODO need in future
		},
		{
			key: "registered address",
			dataIndex: "registered address",
			title: "Адрес по прописке",
			style: {
				width: "300px",
			},
			// onSearch: setSearchAddressRegistration, // TODO need in future
		},
	];

	const tableData = clients.data?.content?.map((user) => {
		const phoneToShow = user.phones.find((phone) => phone.isDefault)?.value || user.phones[0]?.value || "Данные отсутствуют";

		const emailToShow = user.emails.find((email) => email.isDefault)?.value || user.emails[0]?.value || "Данные отсутствуют";

		return {
			initials: user.fullName,
			phone: [phoneToShow],
			email: emailToShow,
			"personal account": user.accounts.map((account) => ({
				number: account.number,
				addressesCount: account.buildingObjectAddresses.length,
			})),
			"apartment address": user.accounts.map((account: any) => {
				const addresses = account?.buildingObjectAddresses?.map((building) => building?.addressValue);
				return addresses;
			}),
			"registered address": "",
		};
	});

	const onRowClick = (clientRow: IClient, idx: number) => {
		const currentUserId = clients.data.content?.[idx].id;
		if (currentUserId) {
			setOpenDrawer(true);
			setSelectedUserId(currentUserId);
		}
	};

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.clients]);
	}, [sortedBy, searchInitials, searchPhone, searchEmail]);

	return (
		<Page>
			<FiltersContainer sideBarWidth={SideBarStore.sideBarWidth}>
				<TableFilterContext.Provider value={TableFilterInstanceStore}>
					<TableFilter />
				</TableFilterContext.Provider>
			</FiltersContainer>

			<Table
				data={tableData ?? []}
				columns={tableColumns ?? []}
				size="m"
				isFullWidth
				onRowClick={onRowClick}
				emptyComponent={clients.isFetching || TableFilterInstanceStore.selectRoles.length === 0 ? TableLoader : TableEmptySlug}
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth} )`,
					overflowX: "auto",
					minHeight: MIN_TABLE_HEIGHT,
				}}
			/>

			{isVisiblePagination && (
				<PaginationContainer>
					<Pagination
						onChangeSize={paginationData.setClientsSize}
						currentPage={paginationData.clientsCurrentPage}
						setCurrentPage={paginationData.setClientsCurrentPage}
						total={paginationData.clientsTotal}
						size={paginationData.clientsSize as 10 | 20 | 50 | 100}
					/>
				</PaginationContainer>
			)}

			<CitizensDrawer />
		</Page>
	);
};

export default observer(Citizens);

const FiltersContainer = styled.div<{ sideBarWidth: string }>`
	position: relative;
	padding: 32px 12px;
	transition: all 0.5s;
	padding-bottom: 15px;
	width: 100%;
`;

const MultipleCell = styled.div<{ isFirst: boolean; height?: string }>`
	padding-right: 12px;
	padding-left: 12px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	${(props) => {
		if (!props.isFirst) {
			return css`
				border-top: 1px solid #12121229;
				width: 100%;
			`;
		}
	}};

	${(props) => css`
		height: ${props.height ?? `${CELL_HEIGHT}px`};
	`}
`;

const Link = styled.a`
	color: ${colors.textInfoDefault};
	text-decoration: none;
`;
