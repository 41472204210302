import { getUserByIdApi } from "api/api.clients";
import { getRolesApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import getRoleTranslate from "utils/getRoleTranslate";

const useUserRole = (args: { roleId: string; clientId: string }) => {
	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});
	const currentUserRole = roles.data?.find((role) => role.id === args.roleId);

	if (currentUserRole) {
		return getRoleTranslate(currentUserRole.name);
	}
};

export default useUserRole;
