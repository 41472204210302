import React, { FC, useState } from "react";

import { CollapseProps } from "antd";
import Collapse from "components/Collapse/Collapse";
import { ReactComponent as Trash } from "public/trash_citizen.svg";
import { useMutation } from "react-query";
import AuthStore from "store/Auth.Store";
import styled from "styled-components";
import { Button, Modal, TinyTable, Text, colors } from "tap2visit-ui-kit";
import formatPhone from "utils/format/formatPhone";

import { deleteUserApi } from "../../../api/api.employee";
import { queryClient } from "../../../index";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import useIsCurrentEditedUser from "../hooks/useIsCurrentEditedUser";
import EmployeeDrawerStore from "../store/Employee.drawer.store";
import getModalDeleteConfirmText from "../utils/getModalDeleteConfirmText";

interface IDrawerUserInfoCollapse {}

const DrawerUserInfoCollapse: FC<IDrawerUserInfoCollapse> = (props) => {
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const deleteUser = useMutation(deleteUserApi);

	const { isMe, isEmployee } = useIsCurrentEditedUser();
	const modalDeleteText = getModalDeleteConfirmText(isMe);

	const deleteUserFunc = async () => {
		await deleteUser.mutateAsync({ path: { id: EmployeeDrawerStore.selectedUser.id } });
		if (isMe) {
			AuthStore.logout();
		} else {
			await queryClient.invalidateQueries([QueriesKeys.employee]);
			setOpenDeleteModal(false);
			EmployeeDrawerStore.closeDrawer();
		}
	};
	const collapseItems: CollapseProps["items"] = [
		{
			key: "1",
			label: <InfoLabel>Логин и телефон</InfoLabel>,
			children: (
				<TinyTable
					rows={[
						[
							() => (
								<Text style={{ width: "50%", overflow: "hidden" }} color={colors.textNeutralSecondary} type="table-cell-m-regular">
									Логин
								</Text>
							),
							() => (
								<CustomTableTinyText color={colors.textNeutralPrimary} type="table-cell-m-medium">
									{EmployeeDrawerStore.selectedUser.email}
								</CustomTableTinyText>
							),
						],
						[
							() => (
								<Text color={colors.textNeutralSecondary} type="table-cell-m-regular">
									Телефон
								</Text>
							),
							() => (
								<CustomTableTinyText color={colors.textNeutralPrimary} type="table-cell-m-medium">
									{formatPhone(EmployeeDrawerStore.selectedUser.mobilePN)}
								</CustomTableTinyText>
							),
						],
					]}
					containerWidth={"840px"}
				/>
			),
		},
		{
			key: "2",
			label: <InfoLabel>Комментарий</InfoLabel>,
			children: (
				<TinyTable
					rows={[
						[
							() => (
								<Text style={{ width: "100%", whiteSpace: "break-spaces", wordBreak: "break-all" }} type="base-medium">
									{EmployeeDrawerStore.selectedUser.comment || "Данные отсутствуют"}
								</Text>
							),
						],
					]}
					containerWidth={"840px"}
				/>
			),
		},
		{
			key: "3",
			label: <InfoLabel>Доступные действия</InfoLabel>,
			children: (
				<>
					{!isEmployee && (
						<Button icon={TrashStyled} onClick={() => setOpenDeleteModal(true)} size="small" typeButton="secondary" variant="red">
							Удалить
						</Button>
					)}
				</>
			),
		},
	];

	return (
		<>
			{openDeleteModal && (
				<Modal
					okText="Удалить"
					onCancel={() => setOpenDeleteModal(false)}
					onOk={deleteUserFunc}
					cancelText="Отменить"
					status="delete"
					title={modalDeleteText.title}>
					{modalDeleteText.description}
				</Modal>
			)}

			<Collapse defaultActiveKey={["1", "2", "3"]} items={collapseItems} />
		</>
	);
};

export default DrawerUserInfoCollapse;

const TrashStyled = styled(Trash)`
	margin-top: -2px;
	margin-right: -2px;
`;
const InfoLabel = styled.p`
	font-weight: 700;
`;

const CustomTableTinyText = styled(Text)`
	white-space: break-spaces;
	word-break: break-all;
`;
