import { showToast } from "tap2visit-ui-kit";

const ignoreApiErrorEndpoints = [
	{
		url: "geocoder/v1/aliases",
		method: "post",
	},
];

const checkOnIgnore = (error: any) => {
	const isExistInIgnoreList = ignoreApiErrorEndpoints.find(
		(endpoint) => endpoint.url === error?.config?.url && endpoint.method === error?.config?.method,
	);

	return isExistInIgnoreList;
};

export default (error: any) => {
	const message = error?.response?.data?.message;
	const isIgnoreError = checkOnIgnore(error);

	if (isIgnoreError) {
		return;
	}

	if (message) {
		showToast({
			description: message,
			type: "danger",
		});
	}
};
