import React, { FC } from "react";

import BaseModal from "components/Modals/BaseModal";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { cancelRequestApi } from "pages/accessRequest/api/api.request";
import AccessRequestStore from "pages/accessRequest/store/AccessRequest.Store";
import AccessRequestRejectModalStore from "pages/accessRequest/components/ModalCommentRejection/store/AccessRequestRejectModal.store";
import { useMutation } from "react-query";
import emitter from "services/emitter";
import styled from "styled-components";
import { Button, FormItem, TextArea } from "tap2visit-ui-kit";

interface IModalCommentRejection {}

const ModalCommentRejection: FC<IModalCommentRejection> = (props) => {
	const [comment, setComment] = React.useState(AccessRequestStore.accessRequestItem.comment);
	const [isVisibleCancelEditModal, setIsVisibleCancelEditModal] = React.useState(false);

	const cancelRequest = useMutation({
		mutationFn: cancelRequestApi,
	});

	const onExitCommentModal = () => {
		setIsVisibleCancelEditModal(true);
	};

	const onReturnToCommentModal = () => {
		setIsVisibleCancelEditModal(false);
	};

	const onCloseModal = () => {
		AccessRequestRejectModalStore.closeModal();
		setComment("");
	};

	const onSendButtonClick = async () => {
		setIsVisibleCancelEditModal(false);
		await cancelRequest.mutateAsync({
			body: {
				comment: comment,
			},
			path: {
				requestId: AccessRequestRejectModalStore.requestId,
			},
		});
		onCloseModal();
		emitter.emit("cancelAccessRequest");
		queryClient.invalidateQueries([QueriesKeys.requests]);
	};

	return (
		<>
			<BaseModal
				isVisible={AccessRequestRejectModalStore.isVisible}
				title={"Комментарий отказа"}
				footerSlot={
					<Footer>
						<Button
							disabled={AccessRequestStore.accessRequestItem.status !== "NEW" || !comment || cancelRequest.isLoading}
							onClick={onSendButtonClick}>
							Отправить
						</Button>
					</Footer>
				}
				onOk={onSendButtonClick}
				onCancel={onCloseModal}
				onCloseButtonClick={onCloseModal}
				onExit={onExitCommentModal}>
				<FormItem label="Комментарий">
					<CustomTextArea
						value={comment}
						onChangeValue={setComment}
						disabled={AccessRequestStore.accessRequestItem.status !== "NEW"}
						style={{ height: "200px" }}
					/>
				</FormItem>
			</BaseModal>

			<BaseModal
				isVisible={isVisibleCancelEditModal}
				title={"Вы внесли изменения и не сохранили их"}
				status="delete"
				footerSlot={
					<ConfirmExitModalButtonContainer>
						<Button typeButton="secondary" onClick={onReturnToCommentModal}>
							Отменить
						</Button>
						<Button
							disabled={AccessRequestStore.accessRequestItem.status !== "NEW" || !comment || cancelRequest.isLoading}
							onClick={onSendButtonClick}>
							Сохранить
						</Button>
					</ConfirmExitModalButtonContainer>
				}
				onOk={onSendButtonClick}
				onCancel={onReturnToCommentModal}></BaseModal>
		</>
	);
};

export default observer(ModalCommentRejection);

const CustomTextArea = styled(TextArea)`
	width: 100%;
`;

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const ConfirmExitModalButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 8px;
`;
