import React, { FC } from "react";

import { createPortal } from "react-dom";
import { IModal, Modal } from "tap2visit-ui-kit";

interface IBaseModal extends IModal {
	isVisible: boolean;
	description?: string;
	children?: JSX.Element;
}

const BaseModal: FC<IBaseModal> = (props) => {
	const { isVisible, description, ...modalProps } = props;

	return <>{isVisible && createPortal(<Modal {...modalProps}>{description ?? props.children}</Modal>, document.getElementById("modal"))}</>;
};

export default BaseModal;
