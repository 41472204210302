import React, { FC } from "react";

import styled from "styled-components";
import { Text, colors } from "tap2visit-ui-kit";

import PollsEditContentCard from "../PollsEditContent.card";

interface IPollAnswersEmpty {}

const PollAnswersEmpty: FC<IPollAnswersEmpty> = (props) => (
	<CenterContainer style={{ padding: "24px" }}>
		<Text type="base-regular" color={colors.textNeutralSecondary}>
			Ожидание ответов от пользователей
		</Text>
	</CenterContainer>
);

export default PollAnswersEmpty;

const CenterContainer = styled.div`
	width: 50%;

	display: flex;
	justify-content: center;

	border: 0.5px solid ${colors.strokeLine};
	border-radius: 12px;
	padding-left: 24px;
	padding-right: 24px;
`;
