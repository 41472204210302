import apiClient from "api/api.client";
import { IGetStatisticParams, IStatistic } from "interfaces/IPayment";

export type TGetPaymentsPageApi = (args: { params: IGetStatisticParams }) => Promise<IStatistic>;

export const getPaymentsStatisticApi: TGetPaymentsPageApi = async (args) => {
	try {
		const {
			buildingIds,
			complexIds,
			entranceIds,
			floorIds,
			buildingObjectIds,
			accountNumberSearch,
			clientFullNameSearch,
			endCreationTime,
			startCreationTime,
			amount,

			...otherParams
		} = args.params;

		const path = "api-gateway/v1/payments/statistics";

		const params: Record<string, any> = {
			...otherParams,
		};

		if (complexIds?.length) {
			params.complexIds = complexIds.join(",");
		}

		if (buildingIds?.length) {
			params.buildingIds = buildingIds.join(",");
		}

		if (entranceIds?.length) {
			params.entranceIds = entranceIds.join(",");
		}

		if (floorIds?.length) {
			params.floorIds = floorIds.join(",");
		}
		if (buildingObjectIds?.length) {
			params.buildingObjectIds = buildingObjectIds.join(",");
		}

		if (accountNumberSearch) {
			params.accountNumberSearch = accountNumberSearch;
		}

		if (clientFullNameSearch) {
			params.clientFullNameSearch = clientFullNameSearch;
		}

		if (endCreationTime) {
			params.endCreationTime = endCreationTime;
		}

		if (startCreationTime) {
			params.startCreationTime = startCreationTime;
		}

		if (amount) {
			params.amount = amount;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
