import React, { FC } from "react";

import { styled } from "styled-components";
import { IText, Text } from "tap2visit-ui-kit";

const TextHeader: FC<IText> = (props) => {
	const { ref, ...otherProps } = props;

	return (
		<CustomTextHeader {...otherProps} h={"h4"}>
			{props.children}
		</CustomTextHeader>
	);
};

export default TextHeader;

const CustomTextHeader = styled(Text)`
	color: #42474f; // TODO Change on var
	white-space: nowrap;
`;
