import { TObjectType } from "interfaces/IBuilding";

export const buttonsArrayHeaderRealty = [
	{ title: "Все", active: true, key: ["FLAT", "OFFICE", "STORAGE", "PARKING", "BASEMENT"] as TObjectType[] },
	{ title: "Квартиры", active: false, key: ["FLAT"] as TObjectType[] },
	{ title: "Кладовки", active: false, key: ["STORAGE"] as TObjectType[] },
	{ title: "Парковки", active: false, key: ["PARKING"] as TObjectType[] },
	{ title: "Цоколи", active: false, key: ["BASEMENT"] as TObjectType[] },
];

export const mockRealtyData = [
	{
		title: "г. Краснодар, ул. Восточно-Кругликовкая, 152/2, 44",
		type: "Квартира",
		active: true,
	},
	{
		title: "г. Краснодар, ул. Восточно-Кругликовкая, 152/2, 44",
		type: "Квартира",
		active: false,
	},
];

export const citizenPersonalInformation = [
	{
		label: "Адрес помещения",
		as: "textarea",
		required: true,
	},
	{
		label: "Количество комнат",
		as: "input",
		required: true,
	},
	{
		label: "Этаж",
		as: "input",
		required: true,
	},
	{
		label: "Общая площадь, м²",
		as: "input",
	},
	{
		label: "Жилая площадь/площадь БТИ, м² т",
		as: "input",
		required: true,
	},
	{
		label: "Балкон, м²",
		as: "input",
		required: true,
	},
	{
		label: "Тип помещения",
		as: "input",
	},
	{
		label: "Тип недвижимости",
		as: "input",
		required: true,
	},
];

export const citizenPassportInformation = [
	{
		title: "Cерия Номер",
		value: "Данные отсутствуют",
	},
	{
		title: "Код подразделения",
		value: "Данные отсутствуют",
	},
	{
		title: "Дата выдачи",
		value: "Данные отсутствуют",
	},
	{
		title: "Дата рождения",
		value: "Данные отсутствуют",
	},
	{
		title: "Кем выдан",
		value: "Данные отсутствуют",
	},
	{
		title: "Адрес по прописке",
		value: "Данные отсутствуют",
	},
];

export const citizenPassportInformationInput = [
	{
		title: "Cерия Номер",
		value: "series",
	},
	{
		title: "Код подразделения",
		value: "departmentCode",
	},
	{
		title: "Дата выдачи",
		value: "expirationDate",
	},
	{
		title: "Дата рождения",
		value: "dateOfBirth",
	},
	{
		title: "Кем выдан",
		value: "share",
	},
	{
		title: "Адрес по прописке",
		value: "address",
	},
];
