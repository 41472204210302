import React, { FC } from "react";

import Tab from "components/ui/Tab/Tab";
import { observer } from "mobx-react-lite";

import PollsTabsStore, { TPollsTabs } from "../store/Polls.tabs.store";

interface IPollsTabs {}

const tabs: { key: TPollsTabs; label: string }[] = [
	{
		key: "active",
		label: "Действующие",
	},
	{
		key: "archive",
		label: "Проведенные",
	},
	{
		key: "template",
		label: "Шаблоны",
	},
];

const PollsTabs: FC<IPollsTabs> = (props) => (
	<Tab
		activeKey={PollsTabsStore.activeTab}
		items={tabs}
		paddingItem={"12px"}
		onTabClick={(key: TPollsTabs) => PollsTabsStore.setActiveTab(key)}
	/>
);

export default observer(PollsTabs);
