import React from "react";

import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, UIKitWrapper } from "tap2visit-ui-kit";

import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./dayjs.config";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { messages, LOCALES } from "utils/translate";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LIGHT_THEME } from "./theme/colors";

import { StyleProvider } from "@ant-design/cssinjs";

import "tap2visit-ui-kit/dist/global.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

export const queryClient = new QueryClient();

queryClient.setDefaultOptions({
	queries: {
		staleTime: 1 * (60 * 1000), // 1 min
		refetchOnWindowFocus: false,
		refetchInterval: false,
		retry: 1,
	},
});

root.render(
	<ThemeProvider theme={{ theme: LIGHT_THEME, typography: {} }}>
		<ToastContainer />
		<StyleProvider>
			<BrowserRouter basename={"/"}>
				<UIKitWrapper theme="light">
					<div className="App">
						<IntlProvider messages={messages[LOCALES.RUSSIAN]} locale={LOCALES.RUSSIAN} defaultLocale={LOCALES.RUSSIAN}>
							<QueryClientProvider client={queryClient}>
								<App />
							</QueryClientProvider>
						</IntlProvider>
					</div>
				</UIKitWrapper>
			</BrowserRouter>
		</StyleProvider>
	</ThemeProvider>,
);

reportWebVitals();
