import React, { FC } from "react";

import styled, { css } from "styled-components";
import { Checkbox, Text, colors } from "tap2visit-ui-kit";

interface ITreeItem {
	text: string;
	checked: boolean;
	onChangeChecked: () => void;
	disabled?: boolean;
	isBuildingObject?: boolean;
}

const TreeItem: FC<ITreeItem> = (props) => (
	<>
		<DataItem>
			<CheckboxContainer isBuildingObject={props.isBuildingObject}>
				<Checkbox checked={props.checked} changeChecked={props.onChangeChecked} />
			</CheckboxContainer>
			<Text type="base-regular">{props.text}</Text>
		</DataItem>
		<Wrapper isChecked={props.checked} />
	</>
);

export default TreeItem;

const DataItem = styled.div`
	display: flex;
	gap: 8px;
	z-index: 2;
`;

const CheckboxContainer = styled.div<{ isBuildingObject?: boolean }>`
	margin-left: 4px;
	margin-left: ${({ isBuildingObject }) => (isBuildingObject ? "30px" : "4px")};
`;

const Wrapper = styled.div<{ isChecked: boolean }>`
	position: absolute;
	width: calc(100%);
	height: calc(100% + 2px); // ? 2px - borders
	z-index: -2;

	top: -1px; // ? with borders
	left: 0;

	${(props) => {
		const backgroundColor = props.isChecked ? colors.surfaceBrandTransparent : "transparent";

		return css`
			background-color: ${backgroundColor};
		`;
	}};
`;
