import React, { FC } from "react";

import { override } from "mobx";
import { ITab as IUIKitTab, Tab as UIKitTab } from "tap2visit-ui-kit";

interface ITab extends Omit<IUIKitTab, "gap"> {
	gap?: number;
}

const Tab: FC<ITab> = (props) => <UIKitTab {...props} gap={props.gap ?? 24} />;

export default Tab;
