import React, { FC } from "react";

import { IInput, Input as UIKitInput } from "tap2visit-ui-kit";

interface ICustomInput extends IInput {
	onChangeValue?: (value: string) => void;
}

const Input: FC<ICustomInput> = (props) => (
	<UIKitInput {...props} onChange={(e) => (props.onChange ? props.onChange(e) : props.onChangeValue?.(e.target.value))} />
);

export default Input;
