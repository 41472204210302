// ! temporary

export const roles = [
	{
		text: "Главный администратор",
		key: "ADMINISTRATOR",
	},
	{
		text: "Сотрудник",
		key: "EMPLOYEE",
	},
];
