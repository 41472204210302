import profileUKVisibilityStore from "../profileUKVisibility.store";
import DialogAreYouSureCancelEditOfficeStore from "../store/DialogAreYouSureCancelEditOffice.store";

const checkTimeState = () => {
	let isEditedTimeState = false;

	profileUKVisibilityStore.newEditableOffice.timeState.forEach((item1) => {
		const matchingItem = profileUKVisibilityStore.editableOffice.timetables.find((item) => item.dayOfWeek === item1.dayOfWeek);

		if (matchingItem) {
			const workingTimePeriod1 = item1.workingTimePeriod;
			const workingTimePeriod2 = matchingItem.workingTimePeriod;
			const breakTimePeriod1 = item1.breakTimePeriod;
			const breakTimePeriod2 = matchingItem.breakTimePeriod;
			const receptionHours1 = item1.receptionHours;
			const receptionHours2 = matchingItem.receptionHours;

			const startTimeDiffers = workingTimePeriod1?.startTime !== workingTimePeriod2?.startTime;
			const endTimeDiffers = workingTimePeriod1?.endTime !== workingTimePeriod2?.endTime;
			const startTimeDiffersBreakTime = breakTimePeriod1?.startTime !== breakTimePeriod2?.startTime;
			const endTimeDiffersBreakTime = breakTimePeriod1?.endTime !== breakTimePeriod2?.endTime;
			const startTimeDiffersReceptionHours = receptionHours1.length !== receptionHours2.length;

			if (startTimeDiffers || endTimeDiffers || startTimeDiffersBreakTime || endTimeDiffersBreakTime || startTimeDiffersReceptionHours)
				return (isEditedTimeState = true);
		}
	});
	return isEditedTimeState;
};
export const showModalProfileEditExistIfThereChanges = ({ onContinue }: { onContinue: (key?: any) => void }) => {
	if (profileUKVisibilityStore.activeOfficeId) {
		if (
			profileUKVisibilityStore.editableOffice.name !== profileUKVisibilityStore.newEditableOffice.officeName ||
			profileUKVisibilityStore.editableOffice.description !== profileUKVisibilityStore.newEditableOffice.additionalInformation ||
			profileUKVisibilityStore.editableOffice.executives.length !== profileUKVisibilityStore.newEditableOffice.positions.length ||
			profileUKVisibilityStore.editableOffice.phones.length !== profileUKVisibilityStore.newEditableOffice.phones.length ||
			profileUKVisibilityStore.editableOffice.emails.length !== profileUKVisibilityStore.newEditableOffice.emails.length ||
			checkTimeState()
		)
			DialogAreYouSureCancelEditOfficeStore.setIsVisible(true);
		else onContinue();
	} else {
		if (
			profileUKVisibilityStore.newEditableOffice.officeName !== "" ||
			profileUKVisibilityStore.newEditableOffice.additionalInformation !== "" ||
			profileUKVisibilityStore.newEditableOffice.positions.length > 0 ||
			profileUKVisibilityStore.newEditableOffice.phones.length > 0 ||
			profileUKVisibilityStore.newEditableOffice.emails.length > 0
		)
			DialogAreYouSureCancelEditOfficeStore.setIsVisible(true);
		else onContinue();
	}
};
