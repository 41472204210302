import { HOME_PAGE } from "constants/constants.paths";

import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import AuthStore from "store/Auth.Store";
import UKStore from "store/UK.Store";

type THook = (args: { publicPaths: string[] }) => void;

const useRedirect: THook = (args) => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const isPublicLocation = (args.publicPaths ?? []).includes(location.pathname);
		const managingOrganizationId = UKStore.getUK().managingOrganizationId;
		const isAuthenticated = AuthStore.checkAuth();

		if (isAuthenticated && !managingOrganizationId) {
			if (location.pathname !== "/login") {
				navigate("/login?selectUK=true");
			}
			return;
		}

		if (!isAuthenticated && !isPublicLocation) {
			navigate("/login");
			return;
		}

		if (location.pathname === "/") {
			navigate(HOME_PAGE);
		}
	}, [location.pathname, args.publicPaths]);
};

export default useRedirect;
