import React, { FC } from "react";

import { Row, Divider } from "antd";
import { Spacer } from "components/layout/Spacer";
import { IQuestionDto } from "interfaces/IVote";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Select, colors, Button, Text, Input } from "tap2visit-ui-kit";

import useSelectItems from "./hooks/useSelectItems";
import QuestionItemOption from "./QuestionItem.option";
import { Container, OuterContainer } from "./styles/common";

interface IQuestionItem {
	question: IQuestionDto;
}

const QuestionItem: FC<IQuestionItem> = (props) => {
	const { PollTypeIcon } = useSelectItems({ question: props.question });

	const options = props.question.answerOptions.filter((option) => !option.isFree);
	const freeOption = props.question.answerOptions.find((option) => option.isFree);

	return (
		<OuterContainer>
			<Container>
				<Row id="row">
					<CustomInput
						placeholder="Вопрос"
						value={props.question.value}
						sizeInput={"medium"}
						contentContainerStyle={{ width: "100%" }}
						disabled={true}
					/>
				</Row>

				<Spacer px={20} />
				{props.question.type === "FREE_CHOICE" && (
					<Text type="base-regular" color={colors.textNeutralDisabled}>
						Ответ пользователя в свободной форме
					</Text>
				)}
				{props.question.type !== "FREE_CHOICE" && (
					<>
						<div>
							{options.map((option, idx) => (
								<QuestionItemOption
									key={option.id}
									icon={PollTypeIcon}
									option={option}
									index={idx}
									placeholder={`Вариант ${idx + 1}`}
									editable={false}
								/>
							))}
						</div>

						{freeOption && (
							<>
								<QuestionItemOption icon={PollTypeIcon} option={freeOption} placeholder={"Свой ответ..."} isFreeAnswer={true} />
							</>
						)}
					</>
				)}
			</Container>

			<Spacer px={8} />
		</OuterContainer>
	);
};

export default QuestionItem;

const CustomInput = styled(Input)`
	width: 100%;
`;
