import React from "react";

import {
	changeAnnouncementsTemplatesApi,
	getAnnouncementsByIdApi,
	postAnnouncementsApi,
	postAnnouncementsTemplatesApi,
	putAnnouncementsApi,
} from "api/api.gateway";
import { getRolesApi } from "api/api.realEstate";
import dayjs from "dayjs";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import { queryClient } from "index";
import { IAnnouncement, IAnnouncementsTemplatesItem, TAnnouncementType } from "interfaces/IAnnouncements";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { showToast, TContextMenuItem } from "tap2visit-ui-kit";
import getRoleTranslate from "utils/getRoleTranslate";

import AnnouncementModeStore from "../store/Announcement.mode.store";
import AnnouncementsCurrentItemStore from "../store/Announcements.currentItem.store";
import AnnouncementsViewDataStore from "../store/Announcements.viewData.store";
import areEqualsStringArrays from "../utils/areEqualsStringArrays";
import mergeDateAndGetIsoDate from "../utils/mergeDateAndGetIsoDate";
import mergeDateWithTimeAndGetIsBefore from "../utils/mergeDateWithTimeAndGetIsBefore";

const useAnnouncementLogic = () => {
	const navigate = useNavigate();

	// #region queries
	const putAnnouncements = useMutation(putAnnouncementsApi);

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});

	const announcementEditItem = useQuery({
		queryFn: () => getAnnouncementsByIdApi({ id: AnnouncementsCurrentItemStore.announcementsItem.id }),
		queryKey: [QueriesKeys.announcement, AnnouncementsCurrentItemStore.announcementsItem?.id],
		enabled: !!AnnouncementsCurrentItemStore.announcementsItem?.id,
		staleTime: Infinity,
	});

	const postAnnouncements = useMutation(postAnnouncementsApi);
	const postAnnouncementsTemplates = useMutation(postAnnouncementsTemplatesApi);
	const changeAnnouncementsTemplates = useMutation(changeAnnouncementsTemplatesApi);

	// #endregion

	// #region requests to api
	const getBodyOfAnnouncementCreate = () => {
		const realEstateIds = RealEstateTreeStore.bodyBuildingIds;

		return {
			roleIds: AnnouncementsViewDataStore.selectedRoles?.filter((v) => v.focused).map((v) => v.key),
			text: AnnouncementsViewDataStore.text,
			type: AnnouncementsViewDataStore.typeAnnouncements?.find((i) => i.focused).key as TAnnouncementType,
			complexIds: realEstateIds.complex,
			buildingIds: realEstateIds.buildings,
			entranceIds: realEstateIds.entrances,
			floorIds: realEstateIds.floors,
			buildingObjectIds: realEstateIds.buildingObjects,
			startDateTime: mergeDateAndGetIsoDate(AnnouncementsViewDataStore.startDate, AnnouncementsViewDataStore.startTime),
			endDateTime: mergeDateAndGetIsoDate(AnnouncementsViewDataStore.endDate, AnnouncementsViewDataStore.endTime),
		};
	};

	const isNotEqualStartCurrentEditedTimeWithSaved =
		mergeDateAndGetIsoDate(AnnouncementsViewDataStore.startDate, AnnouncementsViewDataStore.startTime) !==
		announcementEditItem.data?.startDateTime;
	const isNotEqualEndCurrentEditedTimeWithSaved =
		mergeDateAndGetIsoDate(AnnouncementsViewDataStore.endDate, AnnouncementsViewDataStore.endTime) !==
		announcementEditItem.data?.endDateTime;

	const changeAnnouncement = async () => {
		await putAnnouncements.mutateAsync({
			...getBodyOfAnnouncementCreate(),
			id: announcementEditItem.data?.id,
			editedComplex: !areEqualsStringArrays(
				RealEstateTreeStore.selectedComplexesIds.map((v) => v.id),
				announcementEditItem.data.complexIds,
			),
			editedBuilding: !areEqualsStringArrays(
				RealEstateTreeStore.selectedBuildingsIds.map((v) => v.id),
				announcementEditItem.data.buildingIds,
			),
			editedEntrance: !areEqualsStringArrays(
				RealEstateTreeStore.selectedEntrancesIds.map((v) => v.id),
				announcementEditItem.data.entranceIds,
			),
			editedFloor: !areEqualsStringArrays(
				RealEstateTreeStore.selectedFloorsIds.map((v) => v.id),
				announcementEditItem.data.floorIds,
			),
			editedBuildingObject: !areEqualsStringArrays(
				RealEstateTreeStore.selectedBuildingObjectsIds.map((v) => v.id),
				announcementEditItem.data.buildingObjectIds,
			),
			editedDateTimePublication: isNotEqualStartCurrentEditedTimeWithSaved || isNotEqualEndCurrentEditedTimeWithSaved,
		});

		queryClient.invalidateQueries([QueriesKeys.announcements]);
		queryClient.invalidateQueries([QueriesKeys.announcement, AnnouncementsCurrentItemStore.announcementsItem.id]);
	};

	const createAnnouncement = async () => {
		const typeOfAnnouncement = AnnouncementsViewDataStore.typeAnnouncements
			.filter((v) => v.focused)
			.map((v) => v.key)[0] as TAnnouncementType;

		await postAnnouncements.mutateAsync(getBodyOfAnnouncementCreate());

		if (typeOfAnnouncement === "EMERGENCY") {
			queryClient.invalidateQueries([QueriesKeys.countOfEmergency]);
		}

		queryClient.invalidateQueries([QueriesKeys.announcements]);
	};

	const createTemplate = async (name: string) => {
		await postAnnouncementsTemplates.mutateAsync({
			...getBodyOfAnnouncementCreate(),
			name,
		});
		AnnouncementModeStore.setIsOpenCreateTemplateDrawer(false);
	};

	const changeTemplate = async () => {
		await changeAnnouncementsTemplates.mutateAsync({
			...getBodyOfAnnouncementCreate(),
			name: AnnouncementsCurrentItemStore.templateItem.name,
			id: AnnouncementsCurrentItemStore.templateItem.id,
		});

		navigate("/announcement");
	};

	const onSave = async () => {
		if (AnnouncementsCurrentItemStore.currentItem === "editTemplate") {
			await changeTemplate();
			AnnouncementsCurrentItemStore.setCurrentItem(undefined);
			return;
		}
		if (AnnouncementsCurrentItemStore.announcementsItem?.id && AnnouncementsCurrentItemStore.announcementsItem?.status !== "ARCHIVE") {
			await changeAnnouncement();
		} else {
			await createAnnouncement();
		}

		navigate("/announcement");
		showToast({
			description: "Объявление сохранено",
			type: "success",
		});
	};
	// #endregion

	// #region initialize default data
	const setRolesFromApi = (roleNames: string[]) => {
		AnnouncementsViewDataStore.setSelectedRoles(
			roles.data.map((v) => ({
				key: v.id,
				text: getRoleTranslate(v.name),
				focused: roleNames?.includes(v.name),
			})),
		);
	};

	const setDataFromEntity = (item: IAnnouncement | IAnnouncementsTemplatesItem) => {
		AnnouncementsViewDataStore.setText(item?.text);
		AnnouncementsViewDataStore.setStartDate(dayjs(item?.startDateTime));
		AnnouncementsViewDataStore.setStartTime(dayjs(item?.startDateTime));
		if (announcementEditItem.data?.startDateTime) {
			AnnouncementsViewDataStore.setEndDate(dayjs(item?.endDateTime));
			AnnouncementsViewDataStore.setEndTime(dayjs(item?.endDateTime));
		} else {
			AnnouncementsViewDataStore.setEndDate(dayjs().add(1, "day"));
		}

		AnnouncementsViewDataStore.setInitialTypeAnnouncement(item?.type);
	};

	React.useEffect(() => {
		if (AnnouncementsCurrentItemStore.currentItem) {
			return;
		}

		if (roles.isSuccess) {
			setRolesFromApi(AnnouncementsCurrentItemStore.announcementsItem?.roleNames);
		}

		setDataFromEntity(announcementEditItem.data);
	}, [roles.isSuccess, announcementEditItem.data]);

	React.useEffect(() => {
		if (AnnouncementsCurrentItemStore.currentItem === "editTemplate" || AnnouncementsCurrentItemStore.currentItem === "fillFromTemplate") {
			if (roles.isSuccess) {
				AnnouncementsViewDataStore.setSelectedRoles(
					AnnouncementsViewDataStore.selectedRoles.map((v) => ({
						...v,
						focused: AnnouncementsCurrentItemStore.templateItem.roleIds.includes(v.key),
					})),
				);
			}

			setDataFromEntity(AnnouncementsCurrentItemStore.templateItem);
		}
	}, [AnnouncementsCurrentItemStore.templateItem, AnnouncementsCurrentItemStore.currentItem, roles.isSuccess]);
	// #endregion

	const onChangeStartTime = (time: dayjs.Dayjs) => {
		const firstDateIsBeforeThenEndDate = mergeDateWithTimeAndGetIsBefore(
			AnnouncementsViewDataStore.startDate,
			time,
			AnnouncementsViewDataStore.endDate,
			AnnouncementsViewDataStore.endTime,
		);

		if (!firstDateIsBeforeThenEndDate) {
			AnnouncementsViewDataStore.setEndDate(AnnouncementsViewDataStore.startDate);
			AnnouncementsViewDataStore.setEndTime(time.add(1, "hour"));
		}
		AnnouncementsViewDataStore.setStartTime(time);
	};

	const onChangeStartDate = (date: Date[]) => {
		const changedDate = dayjs(date[0]); // TODO Need to handle null
		const firstDateIsBeforeThenEndDate = mergeDateWithTimeAndGetIsBefore(
			changedDate,
			AnnouncementsViewDataStore.startTime,
			AnnouncementsViewDataStore.endDate,
			AnnouncementsViewDataStore.endTime,
		);

		if (!firstDateIsBeforeThenEndDate) {
			AnnouncementsViewDataStore.setEndDate(changedDate.add(1, "day"));
			AnnouncementsViewDataStore.setEndTime(AnnouncementsViewDataStore.startTime);
		}
		AnnouncementsViewDataStore.setStartDate(changedDate);
	};

	const changeSelectItem = ({
		setChangeArray,
		changeArray,
		item,
		single,
	}: {
		setChangeArray: (v: TContextMenuItem[]) => void;
		changeArray: TContextMenuItem[];
		item: TContextMenuItem;
		single?: boolean;
	}) => {
		setChangeArray(
			changeArray.map((v) => {
				const updatedStatus = !item.focused;

				if (item.key === v.key) {
					return {
						...v,
						focused: !item.focused,
					};
				}

				if (updatedStatus && single) {
					return {
						...v,
						focused: false,
					};
				}
				return v;
			}),
		);
	};

	return {
		saveMethods: {
			changeAnnouncement,
			createTemplate,
			createAnnouncement,
			onSave,
		},
		changeMethods: {
			onChangeStartTime,
			onChangeStartDate,
			changeSelectItem,
		},
		data: {
			isSaveLoading: postAnnouncements.isLoading || changeAnnouncementsTemplates.isLoading || putAnnouncements.isLoading,
		},
	};
};

export default useAnnouncementLogic;
