import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TCitizenPassportValidationFields = {
	inn: keyof typeof ValidationStatuses;
};

const initialValidationData: TCitizenPassportValidationFields = {
	inn: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TCitizenPassportValidationFields, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	inn: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, EQUALS: "Поле должно содержать 12 символов" },
};

const checkRules = {
	inn: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.EQUALS]: 12,
	},
};

const CitizenInnValidationStore = new ValidationStore<TCitizenPassportValidationFields>(initialValidationData, ERROR_MESSAGES, checkRules);

export default CitizenInnValidationStore;
