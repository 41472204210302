import React from "react";

import PollsItemStore from "pages/polls/store/PollsItem.store";
import { useLocation, useNavigate } from "react-router-dom";

const useRedirect = () => {
	const location = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!PollsItemStore.isPoppulate || PollsItemStore.haslocationBeenChecked) {
			return;
		}

		const splittedLocation = location.pathname.split("/");
		const isView = splittedLocation?.[2] === "view";
		const isEdit = splittedLocation?.[2] === "edit";

		const { status, isTemplate } = PollsItemStore.poll;

		if (isView) {
			if (status === "NEW" || isTemplate) {
				const newLocation = location.pathname.replace("view", "edit");
				navigate(newLocation, { replace: true });
			}
		}

		if (isEdit) {
			if (status === "ARCHIVE" || status === "ACTIVE") {
				const newLocation = location.pathname.replace("edit", "view");
				navigate(newLocation, { replace: true });
			}
		}

		PollsItemStore.setCheckedLocation();
	}, [PollsItemStore.isPoppulate, PollsItemStore.poll.status, location.pathname, navigate]);

	return { pollHasBeenChecked: PollsItemStore.haslocationBeenChecked };
};

export default useRedirect;
