import React, { FC } from "react";

import { getAddressesApi } from "api/api.aliases";
import { getBuildingObjectIdsByUserIdApi, getBuildingObjectsApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";

import CitizensDrawerStore from "../../../../components/Citizens/store/Citizens.drawer.store";

import RealEstateRowsItem from "./RealEstateRows.item";

interface IRealEstateRows {
	setOpenRealtyDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const RealEstateRows: FC<IRealEstateRows> = (props) => {
	const citizenBuildingObjectsIds = useQuery({
		queryFn: () => getBuildingObjectIdsByUserIdApi({ path: { clientId: CitizensDrawerStore.selectedUser.id } }),
		enabled: !!CitizensDrawerStore.selectedUser.id,
		queryKey: [QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id],
	});

	const citizenBuildingObjectsIdsMap = citizenBuildingObjectsIds.data?.map((i) => i.buildingObjectId);

	const citizenBuildingObjects = useQuery({
		queryFn: () => {
			if (citizenBuildingObjectsIdsMap.length) {
				return getBuildingObjectsApi({ params: { buildingObjectIds: citizenBuildingObjectsIdsMap } });
			}
			return [];
		},
		queryKey: [QueriesKeys.citizenBuildingObjects, ...(citizenBuildingObjectsIdsMap ?? [])],
		enabled: citizenBuildingObjectsIds.isFetched,
	});

	const citizenAddressesIds = citizenBuildingObjects.data?.map((i) => i.addressId);

	const addresses = useQuery({
		queryFn: () => getAddressesApi({ params: { addressIds: citizenAddressesIds } }),
		queryKey: [QueriesKeys.listAddresses, ...(citizenAddressesIds ?? [])],
		enabled: !!citizenAddressesIds?.length,
	});

	return (
		<>
			{citizenBuildingObjects.data?.map((buildingObject) => {
				const roles = citizenBuildingObjectsIds.data?.find((buildingObjectIds) => buildingObjectIds.buildingObjectId === buildingObject.id);

				return <RealEstateRowsItem buildingObject={buildingObject} addresses={addresses.data} roles={roles} />;
			})}
		</>
	);
};

export default RealEstateRows;
