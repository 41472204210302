import { useState } from "react";
import React from "react";

import { Divider, Space } from "antd";
import DatePicker from "components/DatePicker";
import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import dayjs from "dayjs";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Button, colors, FormItem, Select, showToast, TextArea, TimePicker } from "tap2visit-ui-kit";

import useSelectStateIsOpen from "../../components/TableFilter/hooks/useSelectStateIsOpen";
import PageInfoDialogAreYouSureCancelEditOffice from "../profileUK/components/PageInfo.DialogAreYouSureCancelEditOffice";

import AnnouncementCreateTree from "./components/AnnouncementCreate.tree";
import ModalCreateTemplate from "./components/ModalCreateTemplate";
import TemplatesDrawer from "./components/Templates.drawer";
import useAnnouncementLogic from "./hooks/useAnnouncementLogic";
import AnnouncementAreYouSureDialogStore from "./store/Announcement.areYouSureDialog.store";
import AnnouncementModeStore from "./store/Announcement.mode.store";
import AnnouncementsCurrentItemStore from "./store/Announcements.currentItem.store";
import AnnouncementsViewDataStore from "./store/Announcements.viewData.store";

const AnnouncementChange = () => {
	const [isOpenType, toggleIsOpenType] = useSelectStateIsOpen();
	const [isOpenRoleNames, toggleIsOpenRoleNames] = useSelectStateIsOpen();
	const [isError, setIsError] = useState({ isTextError: false, isTypeError: false, isRoleError: false, isBuildingSelectedError: false });
	const location = useLocation();
	const announcementId = location.pathname.split("/").pop();
	const [isChangeDateStart, setIsChangeDateStart] = useState(false);
	const [isChangeDateEnd, setIsChangeDateEnd] = useState(false);

	const [checkClickSaveButton, setCheckClickSaveButton] = useState(false);
	const [isOpenTemplateDrawer, setIsOpenTemplateDrawer] = useState(false);

	const announcemetLogic = useAnnouncementLogic();

	React.useEffect(() => {
		if (!AnnouncementsCurrentItemStore.announcementsItem) {
			AnnouncementsViewDataStore.setTypeAnnouncements(
				AnnouncementsViewDataStore.typeAnnouncements.map((announcement) => {
					if (announcement.key === "EMERGENCY" && AnnouncementModeStore.checkEmergencyButtonClick) {
						return {
							...announcement,
							focused: true,
						};
					}

					if (announcement.key === "ORDINARY" && !AnnouncementModeStore.checkEmergencyButtonClick) {
						return {
							...announcement,
							focused: true,
						};
					}

					return announcement;
				}),
			);
		}

		return () => {
			AnnouncementsViewDataStore.dispose();
			AnnouncementsCurrentItemStore.dispose();
			AnnouncementModeStore.dispose();
			RealEstateTreeStore.dispose();
		};
	}, []);

	const checkValues = ({ method }: { method: () => void }) => {
		if (!AnnouncementsViewDataStore.text) {
			setIsError({ ...isError, isTextError: true });
			showToast({
				description: "Заполните поле 'Текст объявления'",
				type: "danger",
			});
		} else if (AnnouncementsViewDataStore.typeAnnouncements?.find((i) => i.focused) === undefined) {
			setIsError({ ...isError, isTypeError: true });
			showToast({
				description: "Выберите тип объявления",
				type: "danger",
			});
		} else if (AnnouncementsViewDataStore.selectedRoles?.filter((v) => v.focused).map((v) => v.key).length === 0) {
			setIsError({ ...isError, isRoleError: true });
			showToast({
				description: "Выберите роль получателей объявления",
				type: "danger",
			});
		} else if (RealEstateTreeStore.selectedBuildingObjectsIds.length === 0) {
			setIsError({ ...isError, isBuildingSelectedError: true });
			showToast({
				description: "Выберите недвижимость",
				type: "danger",
			});
		} else {
			method();
		}
	};

	const onChangeSelect = (item) => {
		announcemetLogic.changeMethods.changeSelectItem({
			setChangeArray: AnnouncementsViewDataStore.setSelectedRoles,
			changeArray: AnnouncementsViewDataStore.selectedRoles,
			item,
		});
	};
	return (
		<>
			<Container>
				<PageInfoDialogAreYouSureCancelEditOffice />
				<TemplatesDrawer isOpenTemplateDrawer={isOpenTemplateDrawer} onClose={() => setIsOpenTemplateDrawer(false)} />

				{AnnouncementModeStore.isOpenCreateTemplateDrawer && (
					<ModalCreateTemplate
						checkClickSaveButton={checkClickSaveButton}
						createAnnouncements={announcemetLogic.saveMethods.createAnnouncement}
						createTemplate={announcemetLogic.saveMethods.createTemplate}
					/>
				)}
				<ExclamationCreateWrapper>
					<Button onClick={() => setIsOpenTemplateDrawer(true)} typeButton="secondary">
						Выбрать шаблон объявления
					</Button>
					<Divider />
					<FilterContainer>
						<FormItem label="Тип объявления">
							<Select
								onClickSelectItem={(item) => {
									announcemetLogic.changeMethods.changeSelectItem({
										setChangeArray: AnnouncementsViewDataStore.setTypeAnnouncements,
										changeArray: AnnouncementsViewDataStore.typeAnnouncements,
										item,
										single: true,
									});
									setIsError({ ...isError, isTypeError: false });
								}}
								isError={isError.isTypeError}
								items={AnnouncementsViewDataStore.typeAnnouncements}
								onClickSelect={toggleIsOpenType}
								isOpened={isOpenType}
								placeholder="-"
								tag={false}
								onClose={toggleIsOpenType}
							/>
						</FormItem>
						<FormItem label="Роль получателей объявления">
							<Select
								onClickSelectItem={(item) => {
									onChangeSelect(item);
									setIsError({ ...isError, isRoleError: false });
								}}
								onClickByTag={(item) => {
									onChangeSelect(item);
								}}
								isError={isError.isRoleError}
								items={AnnouncementsViewDataStore.selectedRoles}
								onClickSelect={toggleIsOpenRoleNames}
								isOpened={isOpenRoleNames}
								placeholder="-"
								tag
								onClose={toggleIsOpenRoleNames}
							/>
						</FormItem>
					</FilterContainer>
					<Divider />
					<FormItem label="Текст объявления">
						<TextAreaWrapper
							onChangeValue={(value) => {
								AnnouncementsViewDataStore.setText(value);
								setIsError({ ...isError, isTextError: false });
							}}
							style={{
								border: isError.isTextError ? "1px #CA504A solid" : "solid 0.5px #1212123D",
							}}
							placeholder="Текст"
							value={AnnouncementsViewDataStore.text}
							showLengthValue={false}
							maxLength={500}
						/>
					</FormItem>

					<Spacer px={24} />

					<DateTimeContainer>
						<DateContainer>
							<div style={{ width: "174px" }}>
								<FormItem label="Дата начала">
									<DatePicker
										currentDate={AnnouncementsViewDataStore.startDate}
										onChangeDate={announcemetLogic.changeMethods.onChangeStartDate}
										minDate={dayjs(new Date())}
									/>
								</FormItem>
							</div>

							<FormItem label="Время начала">
								<TimePicker
									onChange={(time) => {
										announcemetLogic.changeMethods.onChangeStartTime(time);
										setIsChangeDateStart(true);
									}}
									size="medium"
									initialTime={isChangeDateStart && AnnouncementsViewDataStore.startTime}
								/>
							</FormItem>
						</DateContainer>

						<Spacer px={24} horizontal />
						<Divider type="vertical" style={{ height: "48px" }} orientation="right" />
						<Spacer px={24} horizontal />

						<DateContainer>
							<div style={{ width: "174px" }}>
								<FormItem label="Дата конца">
									<DatePicker
										currentDate={AnnouncementsViewDataStore.endDate}
										// TODO Need to handle null
										onChangeDate={(date) => AnnouncementsViewDataStore.setEndDate(dayjs(date[0]))}
										minDate={AnnouncementsViewDataStore.startDate}
									/>
								</FormItem>
							</div>

							<FormItem label="Время конца">
								<TimePicker
									onChange={(time) => {
										AnnouncementsViewDataStore.setEndTime(time);
										setIsChangeDateEnd(true);
									}}
									size="medium"
									initialTime={isChangeDateEnd && AnnouncementsViewDataStore.endTime}
								/>
							</FormItem>
						</DateContainer>
					</DateTimeContainer>

					<Divider />
					<Space size={20}>
						<Button
							disabled={announcemetLogic.data.isSaveLoading}
							onClick={() => {
								checkValues({ method: () => announcemetLogic.saveMethods.onSave() });
							}}>
							Сохранить
						</Button>
						<Button
							disabled={announcemetLogic.data.isSaveLoading}
							typeButton="secondary"
							onClick={() => {
								checkValues({
									method: () => {
										setCheckClickSaveButton(true);
										AnnouncementModeStore.setIsOpenCreateTemplateDrawer(true);
									},
								});
							}}>
							Сохранить объявление и шаблон
						</Button>
						<Button
							disabled={announcemetLogic.data.isSaveLoading}
							typeButton="secondary"
							onClick={() => {
								checkValues({ method: () => AnnouncementModeStore.setIsOpenCreateTemplateDrawer(true) });
							}}>
							Сохранить шаблон
						</Button>
					</Space>
				</ExclamationCreateWrapper>
				<AnnouncementCreateTree announcementId={announcementId === "create" ? undefined : announcementId} />
			</Container>
			<BaseModal
				title="Закрыть редактор?"
				description="Не сохраненные изменения будут потеряны"
				okText={"Продолжить"}
				cancelText="Отменить"
				isVisible={AnnouncementAreYouSureDialogStore.isVisible}
				status="delete"
				onCancel={() => AnnouncementAreYouSureDialogStore.setIsVisible(false)}
				onOk={AnnouncementAreYouSureDialogStore.execute}
				onExit={() => AnnouncementAreYouSureDialogStore.setIsVisible(false)}
			/>
		</>
	);
};

export default observer(AnnouncementChange);

const TextAreaWrapper = styled(TextArea)`
	width: 500px;
	height: 100px;
`;
const ExclamationCreateWrapper = styled.div`
	padding: 24px 16px;
	background: ${() => colors.surfaceNeutralBg2};
	width: 100%;
	min-height: 100vh;
	justify-content: space-between;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const DateContainer = styled.div`
	display: flex;
	gap: 16px;
`;

const DateTimeContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;

const FilterContainer = styled.div`
	display: flex;
	gap: 20px;
`;
