import { ICreateClient, ICreateClientContacts } from "interfaces/IClient";
import { IDocumentCreate } from "interfaces/IDocuments";
import { makeAutoObservable } from "mobx";
import { validation } from "modules/validation/utils/validation";

import { editOrCreateCitizenInputMaxLength } from "../constants/InputCitizen.constants";

type TClientFiles = { insuranceNumber: File[]; inn: File[]; passport: File[] };

export const citizenCreateStoreInputMask = {
	phone: "+7 (999) 999 99 99",
	insuranceNumber: "999-999-999 99",
	passportSeries: "99 99",
	departmentCode: "999-999",
};

export const citizenCreateStoreInputMaxLength = {
	inn: editOrCreateCitizenInputMaxLength.inn,
	passportNumber: editOrCreateCitizenInputMaxLength.number,
};

const recursiveCheckOnValidation = (obj: Record<string, boolean | object>) => {
	for (const key in obj) {
		if (typeof obj[key] === "object") {
			const nestedObject = obj[key] as Record<string, object>;
			if (!recursiveCheckOnValidation(nestedObject)) {
				return false;
			}
		} else {
			if (!obj[key]) {
				return false;
			}
		}
	}
	return true;
};

const initialClient: ICreateClient["client"] = {
	firstName: "",
	lastName: "",
	middleName: "",
	inn: "",
	insuranceNumber: "",
	residentialAddress: "",
};

const initialDocuments: ICreateClient["documents"] = [
	{
		type: "PASSPORT",
		fileIds: [],
		number: "",
	},
	{
		type: "SNILS",
		fileIds: [],
		number: "",
	},
	{
		type: "INN",
		fileIds: [],
		number: "",
	},
];

const initialContacts: ICreateClientContacts[] = [
	{
		description: "",
		type: "PHONE",
		value: "",
		isDefault: true,
	},
];

class CitizenCreateStore {
	client = initialClient;
	documents = initialDocuments;
	contacts = initialContacts;

	isNeedToShowInputErrors = false;

	checkInputsValidation = () => {
		// TODO Passport check validation is deprecated and moved to CitizenPassportValidationStore
		const passport = this.documents.find((doc) => doc.type === "PASSPORT");
		const insurance = this.documents.find((doc) => doc.type === "SNILS");
		const inn = this.documents.find((doc) => doc.type === "INN");

		const validatedFields = {
			firstName: validation.isNotEmpty(this.client.firstName),
			lastName: validation.isNotEmpty(this.client.lastName),
			passport: {
				series: validation.isMaskCorrect({
					mask: citizenCreateStoreInputMask.passportSeries,
					value: passport.series,
				}),
				number: validation.isEquals(passport.number, citizenCreateStoreInputMaxLength.passportNumber),
				departmentCode: validation.isMaskCorrect({
					value: passport.departmentCode,
					mask: citizenCreateStoreInputMask.departmentCode,
				}),
				dateOfIssue: validation.isNotEmpty(passport.dateOfIssue),
				dateOfBirth: validation.isNotEmpty(passport.dateOfBirth),
				issueBy: validation.isNotEmpty(passport.issueBy),
				address: validation.isNotEmpty(passport.address),
			},
			insuranceNumber: validation.isMaskCorrect({
				value: insurance.number,
				mask: citizenCreateStoreInputMask.insuranceNumber,
			}),
			inn: validation.isEquals(inn.number, citizenCreateStoreInputMaxLength.inn),
		};

		return {
			fields: validatedFields,
			isCanCreateCitizen: recursiveCheckOnValidation(validatedFields),
		};
	};
	clientFiles: { insuranceNumber: File[]; inn: File[]; passport: File[] } = {
		insuranceNumber: [],
		inn: [],
		passport: [],
	};

	// ? add a red border for inputs that are not valid
	showErrorInputs = () => {
		this.isNeedToShowInputErrors = true;
	};

	constructor() {
		makeAutoObservable(this);
	}

	updateClient = (client: Partial<ICreateClient["client"]>) => {
		this.client = {
			...this.client,
			...client,
		};
	};

	updateClientsContacts = (args: { contact: ICreateClientContacts; index: number }) => {
		this.contacts[args.index] = {
			...this.contacts[0],
			isDefault: args.index === 0,
			...args.contact,
		};
	};

	deleteContact = (index: number) => {
		this.contacts = [...this.contacts.slice(0, index), ...this.contacts.slice(index + 1)];
	};

	changeDocument = (document: IDocumentCreate) => {
		const documentIndex = this.documents.findIndex((doc) => doc.type === document.type);
		this.documents = [...this.documents.slice(0, documentIndex), document, ...this.documents.slice(documentIndex + 1)];
	};

	changeClientFiles = (fileData: Partial<TClientFiles>) => {
		this.clientFiles = {
			...this.clientFiles,
			...fileData,
		};
	};

	dispose = () => {
		this.client = initialClient;
		this.documents = initialDocuments;
		this.contacts = initialContacts;
		this.isNeedToShowInputErrors = false;
	};
}

export default new CitizenCreateStore();
