// eslint-disable-next-line import/order
import { queryClient } from "index";
import { makeAutoObservable } from "mobx";

import { ITokens } from "../interfaces/IToken";
import { IUser } from "../interfaces/User/IUser";

import UKStore from "./UK.Store";

class AuthStore {
	isAuthenticated = undefined;
	currentUser = undefined;
	constructor() {
		makeAutoObservable(this);
	}

	checkAuth = () => {
		if (typeof this.isAuthenticated === "undefined") {
			this.isAuthenticated = !!localStorage.getItem("refresh_token");
			return this.isAuthenticated;
		}

		return this.isAuthenticated;
	};

	login = () => {
		const redirectUri = encodeURIComponent(`https://${window.location.host}/callback`);
		// eslint-disable-next-line max-len
		window.location.href = `${process.env.REACT_APP_AUTH_URL}/oauth2/authorize?response_type=code&client_id=web-client&scope=openid&redirect_uri=${redirectUri}`;
	};

	saveTokens = (tokens: ITokens) => {
		const { accessToken, refreshToken } = tokens;
		if (accessToken && refreshToken) {
			localStorage.setItem("access_token", tokens.accessToken);
			localStorage.setItem("refresh_token", tokens.refreshToken);
			this.isAuthenticated = true;
		}
	};

	logout = () => {
		queryClient.invalidateQueries();
		UKStore.dispose();
		localStorage.clear();
		window.location.pathname = "/login";
	};

	getTokens = () => ({
		access_token: localStorage.getItem("access_token"),
		refresh_token: localStorage.getItem("refresh_token"),
	});

	saveUser = (user: IUser) => (this.currentUser = user);
}

export default new AuthStore();
