import { getUserApi, getUserByIdApi } from "api/api.employee";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import AuthStore from "store/Auth.Store";

import EmployeeDrawerStore from "../store/Employee.drawer.store";

const useIsCurrentEditedUser = () => {
	const currentUser = useQuery({
		queryFn: () => getUserByIdApi({ params: { id: EmployeeDrawerStore.selectedUser.id } }),
		queryKey: [QueriesKeys.user, EmployeeDrawerStore.selectedUser.id],
		enabled: !!EmployeeDrawerStore.selectedUser.id,
	});

	const userMe = useQuery({
		queryFn: () => getUserApi(),
		enabled: AuthStore.checkAuth(),
	});

	const isMe = currentUser.data?.id === userMe.data?.id;
	const isEmployee = userMe.data?.additionalProperties?.role === "EMPLOYEE";

	return { isMe, isEmployee };
};

export default useIsCurrentEditedUser;
