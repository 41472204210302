import { makeAutoObservable } from "mobx";

class AccessRequestCanApproveRequestStore {
	canApproveRequest = false;
	isNeedToShowConfirmApproveModal = false;

	constructor() {
		makeAutoObservable(this);
	}

	setCanApproveRequest = () => {
		this.canApproveRequest = true;
	};

	setIsNeedToShowConfirmApproveModal = (status: boolean) => {
		this.isNeedToShowConfirmApproveModal = status;
	};

	dispose = () => {
		this.canApproveRequest = false;
		this.isNeedToShowConfirmApproveModal = false;
	};
}

export default new AccessRequestCanApproveRequestStore();
