import { getBuildingsBaseApi } from "api/api.gateway";
import { getEntranceApi, getFloorsApi, getBuildingObjectsApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";

import BuildingFilterStore from "../BuildingFilter.store";

const useIsDisabledFilters = (args: { filterStore: BuildingFilterStore }) => {
	const buildings = useQuery({
		queryFn: () => getBuildingsBaseApi({ params: { complexIds: args.filterStore.selectedComplexIds } }),
		queryKey: [QueriesKeys.buildingsBase, ...(args.filterStore.selectedComplexIds ?? [])],
		enabled: !!args.filterStore.selectedComplexIds.length,
	});

	const entrances = useQuery({
		queryFn: () => getEntranceApi({ params: { buildingIds: args.filterStore.selectedBuildingsIds } }),
		queryKey: [QueriesKeys.entrances, ...(args.filterStore.selectedBuildingsIds ?? [])],
		enabled: !!args.filterStore.selectedBuildingsIds.length,
	});

	const floors = useQuery({
		queryFn: () => getFloorsApi({ params: { entranceIds: args.filterStore.selectedEntrancesIds } }),
		queryKey: [QueriesKeys.floors, ...(args.filterStore.selectedEntrancesIds ?? [])],
		enabled: !!args.filterStore.selectedEntrancesIds.length,
	});

	const buildingObjects = useQuery({
		queryFn: () => getBuildingObjectsApi({ params: { floorIds: args.filterStore.selectedFloorsIds } }),
		queryKey: [QueriesKeys.buildingObjects, ...(args.filterStore.selectedFloorsIds ?? [])],
		enabled: !!args.filterStore.selectedFloorsIds.length,
	});

	const isBuildingsSelectDisabled = !(args.filterStore.selectedComplexIds.length && buildings.data?.length);
	const isEntrancesSelectDisabled = !(args.filterStore.selectedBuildingsIds.length && entrances.data?.length);
	const isFloorsSelectDisabled = !(args.filterStore.selectedEntrancesIds.length && floors.data?.length);
	const isBuildingObjectsDisabled = !(args.filterStore.selectedFloorsIds.length && buildingObjects.data?.length);

	return {
		isBuildingsSelectDisabled,
		isEntrancesSelectDisabled,
		isFloorsSelectDisabled,
		isBuildingObjectsDisabled,
	};
};

export default useIsDisabledFilters;
