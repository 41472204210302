import { useEffect, useMemo, useState } from "react";

import { Space } from "antd";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Avatar, Button, IItemList, List } from "tap2visit-ui-kit";

import { getAddressById } from "api/api.aliases";
import { queryClient } from "index";

import { getOrganizationApi } from "../../../api/api.organization";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import { ReactComponent as ChangeInformationIcon } from "../../../public/changeInformation.svg";
import UKStore from "../../../store/UK.Store";
import useAvatarLetters from "../hooks/useAvatarLetters";
import useLogoUK from "../hooks/useLogoUK";
import profileUKVisibilityStore from "../profileUKVisibility.store";

import { DrawerChangeInformation } from "./DrawerChangeInformation";

const PageEditInformation = () => {
	const [companies, setCompanies] = useState<IItemList[]>([]);
	const [open, setOpen] = useState(false);
	const organizationId = UKStore.getUK()?.managingOrganizationId;

	const { logoUKLink } = useLogoUK();
	const avatarLetters = useAvatarLetters();

	const { data } = useQuery({
		queryFn: () => getOrganizationApi(organizationId),
		queryKey: [QueriesKeys.idOrganization, organizationId],
		enabled: !!organizationId,
	});

	const actualAddressId = useMemo(() => data?.actualAddressId, [data?.actualAddressId]);

	const address = useQuery({
		queryFn: () => getAddressById({ params: { id: actualAddressId } }),
		queryKey: [QueriesKeys.officeAddress, profileUKVisibilityStore.editableOffice?.id],
		enabled: !!actualAddressId,
	});

	const companyInformation = useMemo(() => {
		if (data) {
			const infoAboutCompany = [
				{ title: data.shortName ?? "-", description: "Сокращенное наименование" },
				{ title: data.name, description: "Организация" },
				{ title: address.data?.value ?? "-", description: "Юридический адрес" },
				{ title: data.inn ?? "-", description: "ИНН" },
				{ title: data.ogrn ?? "-", description: "ОГРН" },
				{ title: data.phones[0]?.number ?? "-", description: "Телефон" },
				{ title: data.emails[0]?.address ?? "-", description: "Эл. почта" },
			];

			const defaultPhone = data.phones?.find((phone) => phone.isDefault);
			const defaultEmail = data.emails?.find((email) => email.isDefault);

			if (defaultPhone) {
				infoAboutCompany.push({ title: defaultPhone.number, description: "Телефон" });
			}

			if (defaultEmail) {
				infoAboutCompany.push({ title: defaultEmail.address, description: "Эл. почта" });
			}

			return infoAboutCompany;
		}
		return [];
	}, [data, address.data]);

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.downloadFile]);
	}, [data]);

	useEffect(() => {
		if (companyInformation.length > 0) {
			setCompanies(companyInformation);
		}
	}, [companyInformation]);

	return (
		<PageEditInformationWrapper>
			<SurfaceInformationWrapper>
				<SpaceWrapper direction="vertical">
					<AvatarWrapper>
						<Avatar
							styleAvatarContainer={styleAvatarContainer}
							img={logoUKLink}
							size="160"
							firstName={avatarLetters.firstLetter}
							lastName={avatarLetters.secondLetter}
						/>
					</AvatarWrapper>
					<List
						isHoverListItem={false}
						styleText={{ fontSize: "16px", fontWeight: 500 }}
						styleDescription={{ fontSize: "14px" }}
						items={companies}
						size={"large"}
						width={"auto"}
					/>
					<ButtonWrapper>
						<Button onClick={() => setOpen(true)} icon={ChangeInformationIcon} size="large" typeButton="primary">
							Редактировать
						</Button>
					</ButtonWrapper>
				</SpaceWrapper>
			</SurfaceInformationWrapper>
			<DrawerChangeInformation open={open} setOpen={setOpen} />
		</PageEditInformationWrapper>
	);
};

export default observer(PageEditInformation);

const styleAvatarContainer = {
	border: "4px solid white",
	borderRadius: "100%",
	boxShadow: "0px 12px 16px -4px #10182814",
};

const SpaceWrapper = styled(Space)`
	width: 100%;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const PageEditInformationWrapper = styled.div``;

const SurfaceInformationWrapper = styled.div`
	padding: 24px;
	border: 1px solid #12121229;
	border-radius: 16px;
	background-color: #ffffff;
	box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
	backdrop-filter: blur(20px);
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	width: 440px;
`;
