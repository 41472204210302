import { IClient, IClientContact, IComments, ICreateClientContact, ICreateComment, ICreateOrUpdateClient } from "interfaces/IClient";
import { joinArrParams } from "utils/joinParams";

import apiClient from "./api.client";

const auth = btoa(process.env.REACT_APP_BASIC_CLIENTS);

export type TGetAllClientsListApi = () => Promise<IClient[]>;

export const getAllClientsListApi: TGetAllClientsListApi = async () => {
	try {
		const res = await apiClient.get("/clients/basic/v1/clients/list", {
			headers: {
				Authorization: `Basic ${auth}`,
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetAllClientsApi = (args: { params: { clientIds: string } }) => Promise<IClient[]>;

export type TGetAllCommentsApi = (args: { params: { clientId: string } }) => Promise<IComments[]>;

export const getComments: TGetAllCommentsApi = async (args) => {
	try {
		const res = await apiClient.get("/clients/v1/comments", {
			params: args.params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const createCommentApi: TCreateCommentApi = async (args) => {
	try {
		const res = await apiClient.post("clients/v1/comments", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateCommentApi = (args: { body: ICreateComment }) => Promise<IComments>;

export type TUpdateCommentByIdApi = (args: { path: { id: string }; body: { comment: string } }) => Promise<IComments>;

export const updateCommentByIdApi: TUpdateCommentByIdApi = async (args) => {
	try {
		const res = await apiClient.patch(`clients/v1/comments/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteCommentByIdApi = (args: { path: { id: string } }) => Promise<IComments>;

export const deleteCommentByIdApi: TDeleteCommentByIdApi = async (args) => {
	try {
		const res = await apiClient.delete(`clients/v1/comments/${args.path.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllClientsApi: TGetAllClientsApi = async (args) => {
	try {
		const res = await apiClient.get("/clients/basic/v1/clients", {
			headers: {
				Authorization: `Basic ${auth}`,
			},
			params: args.params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateClientApi = (args: { body: IClient }) => Promise<IClient>;

export const createClientApi: TCreateClientApi = async (args) => {
	try {
		const res = await apiClient.post("clients/v1/clients", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetUserByIdApi = (args: { path: { id: string } }) => Promise<IClient>;

export const getUserByIdApi: TGetUserByIdApi = async (args) => {
	try {
		const res = await apiClient.get(`clients/v1/clients/${args.path.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUpdateUserByIdApi = (args: { path: { id: string }; body: ICreateOrUpdateClient }) => Promise<IClient>;

export const updateUserByIdApi: TUpdateUserByIdApi = async (args) => {
	try {
		const res = await apiClient.patch(`clients/v1/clients/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetClientsListApi = (args: { params: { clientIds: string[] } }) => Promise<IClient[]>;

export const getClientsListApi: TGetClientsListApi = async (args) => {
	try {
		const params: Record<string, string> = {};

		params.clientIds = joinArrParams(args.params.clientIds);

		const res = await apiClient.get("clients/v1/clients", {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateContactApi = (args: { body: ICreateClientContact }) => Promise<IClientContact>;

export const createContactApi: TCreateContactApi = async (args) => {
	try {
		const res = await apiClient.post("clients/v1/contacts", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteClientContactApi = (args: { path: { id: string } }) => Promise<void>;

export const deleteClientContactApi: TDeleteClientContactApi = async (args) => {
	try {
		const res = await apiClient.delete(`clients/v1/contacts/${args.path.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
