import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, colors, Text } from "tap2visit-ui-kit";

import DialogAreYouSureCancelEditOfficeStore from "../../profileUK/store/DialogAreYouSureCancelEditOffice.store";

interface IExclamationCreateHeader {
	mode: "edit" | "create";
}
const AnnouncementCreateHeader: React.FC<IExclamationCreateHeader> = (props) => {
	const [crumbs, setCrumbs] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const crumb = [
			{
				path: undefined,
				title: (
					<Text type="small-regular" color={colors.textBrandDefault}>
						Объявления
					</Text>
				),
				onClick: () => {
					DialogAreYouSureCancelEditOfficeStore.setIsVisible(true);
					DialogAreYouSureCancelEditOfficeStore.setVisibleDialogWithCallbackAfterExiting(() => navigate("/announcement"));
				},
			},
			{
				path: "/announcement/create",
				title: (
					<Text type="small-regular" color={props.mode === "create" ? colors.iconNeutralSecondary : colors.textBrandDefault}>
						Новое объявление
					</Text>
				),
			},
		];
		setCrumbs(
			props.mode === "create"
				? crumb
				: [
						...crumb,
						{
							path: `/announcement/edit/${location.pathname.split("/")[3]}`,
							title: "Редактирование шаблона",
						},
					],
		);
	}, [props.mode]);

	return (
		<Breadcrumbs
			location={props.mode === "create" ? "/announcement/create" : `/announcement/edit/${location.pathname.split("/")[3]}`}
			crumbs={crumbs}
		/>
	);
};

export default observer(AnnouncementCreateHeader);
