import React, { FC } from "react";

import styled from "styled-components";
import { Loader, colors } from "tap2visit-ui-kit";

interface IPageLoader {
	isLoading?: boolean;
}

const PageLoader: FC<IPageLoader> = (props) => (
	<>
		{props.isLoading && (
			<Container>
				<Loader />
			</Container>
		)}
	</>
);

export default PageLoader;

const Container = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	background-color: ${colors.surfaceNeutralBg24};
`;
