import React, { FC } from "react";

import { Button, Modal, Space } from "tap2visit-ui-kit";

import UserEditInput from "../../../../components/Citizens/components/UserEdit.input";

export type TCitizenContactModalOnSave = (value: string, description: string) => void;

interface ICitizenContactModal {
	onSave: TCitizenContactModalOnSave;

	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

	title: string;
	titlePlaceholder: string;
	titleLabel: string;
	descriptionPlaceholder: string;
	titleMask?: string;
}

const CitizenContactModal: FC<ICitizenContactModal> = (props) => {
	const [value, setValue] = React.useState("");
	const [description, setDescription] = React.useState("");

	const onSave = () => {
		props.onSave(value, description);
		props.setIsOpen(false);
	};

	return (
		<Modal
			onExit={() => props.setIsOpen(false)}
			footerSlot={
				<Space width="510px" justify="flex-end" align="end">
					<Button typeButton="secondary" onClick={() => props.setIsOpen(false)}>
						Отменить
					</Button>
					<Button onClick={onSave}>Сохранить</Button>
				</Space>
			}
			title={props.title}>
			<UserEditInput
				label={props.titleLabel}
				required
				placeholder={props.titlePlaceholder}
				value={value}
				mask={props.titleMask}
				onChange={setValue}
			/>
			<UserEditInput
				placeholder={props.descriptionPlaceholder}
				required
				label={"Комментарий"}
				as="textarea"
				value={description}
				onChange={setDescription}
			/>
		</Modal>
	);
};

export default CitizenContactModal;
