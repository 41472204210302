import React, { FC } from "react";

import { IQuestionAnswerDto } from "interfaces/IVote";
import { List } from "tap2visit-ui-kit";

import ChartDescription from "./ChartDescription/ChartDescription";
import ChartEmpty from "./ChartEmpty/ChartEmpty";
import { getAnswers } from "./CircleChart/utils/getAnswers";
import { getAnswerText } from "./utils/getAnswerText";

interface IFreeAnswer {
	question: IQuestionAnswerDto;
}

const FreeAnswer: FC<IFreeAnswer> = (props) => {
	const { answersCount, answersChartData } = getAnswers(props.question);

	return (
		<>
			<ChartDescription title={"Нет поля: name"} description={`${answersCount} ${getAnswerText(answersCount)}`} />

			{answersCount === 0 && <ChartEmpty />}

			{answersCount !== 0 && <List items={answersChartData.map((item) => ({ title: item.name }))} />}
		</>
	);
};

export default FreeAnswer;
