import { makeAutoObservable } from "mobx";

export type TTabOptions = "ACTIVE" | "ARCHIVE";

class AnnouncementsTabStore {
	selectedTab: TTabOptions = "ACTIVE";
	sortedByTypeStore: string[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	setSelectedTab = (tapKey: TTabOptions) => {
		this.selectedTab = tapKey;
	};
	setSortedByTypeStore = (val: string[]) => {
		this.sortedByTypeStore = val;
	};
}

export default new AnnouncementsTabStore();
