import { TTabOptions } from "../Announcements.tab.store";

export const mainSectionsHeader = [
	{
		key: "ACTIVE" as TTabOptions,
		label: "Действующие объявления",
	},
	{
		key: "ARCHIVE" as TTabOptions,
		label: "Архив объявлений",
	},
];
