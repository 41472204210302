import React from "react";

const useSelectStateIsOpen = (): [boolean, () => void] => {
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleIsOpen = () => {
		setIsOpen((status) => !status);
	};
	return [isOpen, toggleIsOpen];
};

export default useSelectStateIsOpen;
