import { ReactComponent as PhoneIcon } from "public/SmartPhone.svg";
import styled from "styled-components";
import { Space } from "tap2visit-ui-kit";

import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import {
	CitizenRealtySubTitle,
	CitizenRealtyWrapper,
	CitizenRealtyWrapperFlex,
	PersonalInformationWrapper,
	CitizenRealtyTitleAppLogin,
} from "../../../components/Citizens/style/Citizen.styled";

import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";

const CitizenAppLogin = () => {
	const citizenLogin = CitizensDrawerStore.selectedUser.contacts?.find((contact) => contact.isDefault)?.value ?? "Данные отсутствуют";

	return (
		<Space direction="column">
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={"Логин в приложении"} />
			</PersonalInformationWrapper>

			<Space width="100%">
				<CitizenRealtyWrapper>
					<CitizenAppLoginWrapperFlex>
						<PhoneIcon />
						<Space size={1} direction="column" align="start">
							<CitizenRealtyTitleAppLogin>{citizenLogin}</CitizenRealtyTitleAppLogin>
							<CitizenRealtySubTitle>Номер телефона для входа в приложение</CitizenRealtySubTitle>
						</Space>
					</CitizenAppLoginWrapperFlex>
				</CitizenRealtyWrapper>
			</Space>
		</Space>
	);
};

const CitizenAppLoginWrapperFlex = styled(CitizenRealtyWrapperFlex)`
	padding: 8px 12px;
	justify-content: start;
	svg {
		margin-right: 10px;
	}
`;
export default CitizenAppLogin;
