import React, { FC } from "react";

import { ReactComponent as AlertCircle } from "public/AlertCircleIcon.svg";
import { ReactComponent as ArrowRight } from "public/arrowRight.svg";
import { ReactComponent as BarrierIcon } from "public/BarrierIcon.svg";
import { ReactComponent as ChatIcon } from "public/Chat.svg";
import { ReactComponent as DocumentIcon } from "public/Document.svg";
import { ReactComponent as OffsetIcon } from "public/offset.svg";
import { ReactComponent as UserCheckedIcon } from "public/UserCheckedIcon.svg";
import { ReactComponent as UserDeleteIcon } from "public/UserDeleteIcon.svg";
import { ReactComponent as MenuSvg } from "public/verticale_dot.svg";
import { ReactComponent as VideoCameraIcon } from "public/VideoCameraIcon.svg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Badge, Button, colors, Space } from "tap2visit-ui-kit";

import { IComplexes } from "../../interfaces/IComplexes";

import BuildingsStore from "./Buildings.store";

interface IBuildingsCard {
	item: IComplexes;
}
const BuildingsCard: FC<IBuildingsCard> = ({ item }) => {
	const navigate = useNavigate();

	const buildingsList = [
		{
			title: "Дать объявление",
			icon: <ChatIcon />,
			onClick: () => {
				navigate("/announcement/create", { state: { selectedId: item.id } });
			},
		},
		{
			title: "Запрос на доступ",
			isAccessKey: item.requestCount,
			icon: <UserCheckedIcon />,
			onClick: () => {
				navigate("/access-request", { state: { selectedId: item.id } });
			},
		},
		{
			title: "Список объявлений",
			isListAnnouncementKey: !!item.emergencyAnnouncementCount,
			icon: <AlertCircle />,
			onClick: () => {
				navigate("/announcement", { state: { selectedId: item.id } });
			},
		},
		{
			title: "Список должников",
			icon: <UserDeleteIcon />,
		},
		{
			title: "Документы ЖК",
			icon: <DocumentIcon />,
		},
		{
			title: "Камеры ЖК",
			icon: <VideoCameraIcon />,
		},
		{
			title: "Шлагбаумы",
			icon: <BarrierIcon />,
		},
	];

	return (
		<BuildingsCardWrapper>
			{!!item.emergencyAnnouncementCount && (
				<OffsetIconWrapper>
					<OffsetIcon />
				</OffsetIconWrapper>
			)}

			<BuildingsHeader>
				<BuildingsTitle>{item.name}</BuildingsTitle>
				<BuildingsSubTitle>{item.address}</BuildingsSubTitle>
			</BuildingsHeader>

			{buildingsList.map((v) => (
				<BuildingsListWrapper onClick={v.onClick}>
					<Space justify="space-between">
						<Space>
							{v.icon}
							<BuildingsListTitle>{v.title}</BuildingsListTitle>
						</Space>
						<Space>
							{v.isAccessKey ? <Badge status="grey" text={String(item.requestCount)} type="status" /> : ""}
							{v.isListAnnouncementKey && (
								<Badge
									background={colors.strokeDangerDefault}
									color={colors.surfaceNeutralBgWhite}
									text={`${item.emergencyAnnouncementCount} ЧП`}
									type="status"
								/>
							)}
							<ArrowRight />
						</Space>
					</Space>
				</BuildingsListWrapper>
			))}

			<BuildingsFooter>
				<Space justify="space-between">
					<Button
						onClick={() => {
							navigate(`/buildings/house/${item.id}`);
							BuildingsStore.setSelectedId(item.id);
						}}
						typeButton="secondary">
						Список домов
					</Button>
					<Button typeButton="secondary" shape="square" icon={MenuSvg} />
				</Space>
			</BuildingsFooter>
		</BuildingsCardWrapper>
	);
};

const BuildingsFooter = styled.div`
	padding: 16px;
	border-top: 1px solid #12121229;
	width: 100%;
`;

const BuildingsListWrapper = styled.div`
	border-top: 1px solid #12121229;
	width: 100%;
	padding: 8px 16px;
	cursor: pointer;
`;

const BuildingsHeader = styled.div`
	padding: 16px;
`;

const BuildingsListTitle = styled.p`
	font-weight: 500;
`;

const BuildingsTitle = styled.p`
	font-weight: 700;
	font-size: 20px;
`;

const BuildingsSubTitle = styled.p`
	font-weight: 450;
	font-size: 16px;
`;
const BuildingsCardWrapper = styled.div`
	background: white;
	border: 1px solid #12121229;
	border-radius: 10px;
	height: 410px;
	width: 400px;
	position: relative;
`;

const OffsetIconWrapper = styled.div`
	position: absolute;
	right: -9px;
`;

export default BuildingsCard;
