import React from "react";

import { IQuestionDto, TQuestionType } from "interfaces/IVote";
import { toJS } from "mobx";
import PollsEditStore from "pages/polls/store/PollsItem.store";
import { ReactComponent as CircleIcon } from "public/circle.svg";
import { ReactComponent as FreeAnswerIcon } from "public/free_answer.svg";
import { ReactComponent as SquareIcon } from "public/square.svg";
import { TContextMenuItem } from "tap2visit-ui-kit";

const initialVoteSelectedItems: TContextMenuItem[] = [
	{
		focused: true,
		key: "SINGLE_CHOICE" as TQuestionType,
		icon: () => <CircleIcon />,
		text: "Один вариант ответа",
	},
	{
		focused: false,
		key: "MULTIPLE_CHOICE" as TQuestionType,
		icon: () => <SquareIcon />,
		text: "Несколько вариантов ответа",
	},
];

const initialPollSelectedItems: TContextMenuItem[] = [
	{
		focused: true,
		key: "SINGLE_CHOICE" as TQuestionType,
		icon: () => <CircleIcon />,
		text: "Один вариант ответа",
	},
	{
		focused: false,
		key: "MULTIPLE_CHOICE" as TQuestionType,
		icon: () => <SquareIcon />,
		text: "Несколько вариантов ответа",
	},
	{
		focused: false,
		key: "FREE_CHOICE" as TQuestionType,
		icon: () => <FreeAnswerIcon />,
		text: "Свободный ответ",
	},
];

const useSelectItems = (args: { question: IQuestionDto }) => {
	const [selectItems, setSelectItems] = React.useState<TContextMenuItem[]>(initialPollSelectedItems);
	const selectedItem = selectItems.find((item) => item.focused);
	const PollTypeIcon = selectedItem.icon;

	React.useEffect(() => {
		if (PollsEditStore.poll.type === "POLL") {
			setSelectItems(
				initialPollSelectedItems.map((pollSelect) => ({
					...pollSelect,
					focused: args.question.type === pollSelect.key,
				})),
			);
		}

		if (PollsEditStore.poll.type === "VOTE") {
			const freeAnswerOptionIdx = args.question.answerOptions.findIndex((answer) => answer.isFree);

			if (freeAnswerOptionIdx !== -1) {
				PollsEditStore.removeQuestionOption({
					option: { index: freeAnswerOptionIdx },
					question: {
						id: args.question.id,
					},
				});
			}
			if (selectedItem.key === "FREE_CHOICE") {
				PollsEditStore.changePollQuestion({
					question: {
						type: "SINGLE_CHOICE",
						id: args.question.id,
					},
				});
			}
			setSelectItems(
				initialVoteSelectedItems.map((pollSelect) => ({
					...pollSelect,
					focused: args.question.type === pollSelect.key,
				})),
			);
		}
	}, [PollsEditStore.poll.type]);

	React.useEffect(() => {
		setSelectItems((selectItems) =>
			selectItems.map((selectItem) => ({
				...selectItem,
				focused: selectItem.key === args.question.type,
			})),
		);
	}, [args.question.type]);

	return {
		selectItems,
		selectedItem,
		PollTypeIcon,
	};
};

export default useSelectItems;
