import { FC } from "react";

import { observer } from "mobx-react-lite";

import PollsEditContentCard from "../PollsEditContent.card";

import CircleChart from "./CircleChart/CircleChart";
import CustomBarChart from "./CustomBarChart";
import usePollAnswers from "./hooks/usePollAnswers";
import PollAnswersEmpty from "./PollAnswers.empty";
import FreeAnswer from "./PollAnswers.FreeAnswer";

interface IPollAnswersCharts {}

const PollAnswersCharts: FC<IPollAnswersCharts> = (props) => {
	const pollAnswers = usePollAnswers();

	if ((pollAnswers.data?.countAnswers ?? 0) === 0) {
		return <PollAnswersEmpty />;
	}

	return (
		<>
			{pollAnswers.data?.questions?.map((question) => {
				if (question.type === "SINGLE_CHOICE") {
					return (
						<PollsEditContentCard style={{ padding: "24px" }}>
							<CircleChart question={question} />
						</PollsEditContentCard>
					);
				}

				if (question.type === "MULTIPLE_CHOICE") {
					return (
						<PollsEditContentCard style={{ padding: "24px" }}>
							<CustomBarChart question={question} />
						</PollsEditContentCard>
					);
				}

				if (question.type === "FREE_CHOICE") {
					return (
						<PollsEditContentCard style={{ padding: "24px" }}>
							<FreeAnswer question={question} />
						</PollsEditContentCard>
					);
				}
			})}
		</>
	);
};

export default observer(PollAnswersCharts);
