import { makeAutoObservable } from "mobx";

type TCallbackAfterExiting = () => any | undefined;

class DialogAreYouSure {
	isVisible = false;
	callbackAfterExiting: TCallbackAfterExiting = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	setIsVisible = (status: boolean) => {
		this.isVisible = status;
	};

	setCallbackAfterExitEditMode = (callback: TCallbackAfterExiting) => {
		this.callbackAfterExiting = callback;
	};

	setVisibleDialogWithCallbackAfterExiting = (callback: TCallbackAfterExiting) => {
		this.isVisible = true;
		this.callbackAfterExiting = callback;
	};

	execute = async () => {
		this.isVisible = false;
		await this.callbackAfterExiting?.();
		this.callbackAfterExiting = undefined;
	};
}

export default DialogAreYouSure;
