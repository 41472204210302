export const getJustify = (arr: any[]) => {
	if (arr?.length > 2) {
		return "space-between";
	}
	if (arr?.length > 1) {
		return "start";
	}

	return "center";
};
