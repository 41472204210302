import { makeAutoObservable } from "mobx";

class TableStore {
	clientFullNameSearch = "";
	accountNumberSearch = "";
	startCreationTime: Date | undefined = undefined;
	endCreationTime: Date | undefined = undefined;
	amount = "";
	commission = "";
	amountWithoutCommission = "";

	constructor() {
		makeAutoObservable(this);
	}

	setClientFullNameSearch = (value: string) => {
		this.clientFullNameSearch = value;
	};

	setAccountNumberSearch = (value: string) => {
		this.accountNumberSearch = value;
	};

	setStartCreationTime = (value: Date | undefined) => {
		this.startCreationTime = value;
	};

	setEndCreationTime = (value: Date | undefined) => {
		this.endCreationTime = value;
	};

	setAmount = (value: string) => {
		this.amount = value;
	};
	setCommission = (value: string) => {
		this.commission = value;
	};
	setAmountWithoutCommission = (value: string) => {
		this.amountWithoutCommission = value;
	};
}

export default new TableStore();
