import { FC } from "react";

import { RealEstateBuildingFilterStore } from "components/TableFilter/BuildingFilter.store";
import useIsDisabledFilters from "components/TableFilter/hooks/useIsDisabledFilters";
import useSelectBuildingData from "components/TableFilter/hooks/useSelectBuildingData";
import useSelectStateIsOpen from "components/TableFilter/hooks/useSelectStateIsOpen";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Button, Select } from "tap2visit-ui-kit";

interface ICitizenRealityFilters {}

const CitizenRealityFilters: FC<ICitizenRealityFilters> = (props) => {
	useSelectBuildingData({ filterStore: RealEstateBuildingFilterStore });

	const [isOpenComplexesSelect, toggleIsOpenComplexesSelect] = useSelectStateIsOpen();
	const [isOpenBuildingsSelect, toggleIsOpenBuildingsSelect] = useSelectStateIsOpen();
	const [isOpenEntrancesSelect, toggleIsOpenEntrancesSelect] = useSelectStateIsOpen();
	const [isOpenFloorsSelect, toggleIsOpenFloorsSelect] = useSelectStateIsOpen();

	const { isBuildingsSelectDisabled, isEntrancesSelectDisabled, isFloorsSelectDisabled } = useIsDisabledFilters({
		filterStore: RealEstateBuildingFilterStore,
	});

	return (
		<Container>
			<Select
				onClickSelect={toggleIsOpenComplexesSelect}
				onClickSelectItem={(item) => {
					RealEstateBuildingFilterStore.toggleFocused(item, "complexes");
				}}
				items={RealEstateBuildingFilterStore.selectComplexes}
				isOpened={isOpenComplexesSelect}
				placeholder="ЖК"
				onClose={toggleIsOpenComplexesSelect}
				dropdownTextStyle={{
					whiteSpace: "nowrap",
				}}
				style={{
					width: "90px",
				}}
				tag
				size="s"
			/>
			<Select
				onClickSelect={toggleIsOpenBuildingsSelect}
				onClickSelectItem={(item) => {
					RealEstateBuildingFilterStore.toggleFocused(item, "buildings");
				}}
				items={RealEstateBuildingFilterStore.selectBuildings}
				isOpened={isOpenBuildingsSelect}
				placeholder="Дом"
				onClose={toggleIsOpenBuildingsSelect}
				dropdownTextStyle={{
					whiteSpace: "nowrap",
				}}
				isDisabled={isBuildingsSelectDisabled}
				style={{
					width: "90px",
				}}
				tag
				size="s"
			/>
			<Select
				onClickSelect={toggleIsOpenEntrancesSelect}
				onClickSelectItem={(item) => {
					RealEstateBuildingFilterStore.toggleFocused(item, "entrances");
				}}
				items={RealEstateBuildingFilterStore.selectEntrances}
				isOpened={isOpenEntrancesSelect}
				placeholder="Подъезд"
				onClose={toggleIsOpenEntrancesSelect}
				dropdownTextStyle={{
					whiteSpace: "nowrap",
				}}
				isDisabled={isEntrancesSelectDisabled}
				style={{
					width: "90px",
				}}
				tag
				size="s"
			/>
			<Select
				onClickSelect={toggleIsOpenFloorsSelect}
				onClickSelectItem={(item) => {
					RealEstateBuildingFilterStore.toggleFocused(item, "floors");
				}}
				items={RealEstateBuildingFilterStore.selectFloors}
				isOpened={isOpenFloorsSelect}
				placeholder="Этаж"
				onClose={toggleIsOpenFloorsSelect}
				dropdownTextStyle={{
					whiteSpace: "nowrap",
				}}
				isDisabled={isFloorsSelectDisabled}
				style={{
					width: "90px",
				}}
				tag
				size="s"
			/>

			<Button onClick={RealEstateBuildingFilterStore.clearFilter} typeButton="secondary" size="small">
				Сбросить
			</Button>
		</Container>
	);
};

export default observer(CitizenRealityFilters);

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
