import React, { FC } from "react";

import useDownloadedFiles from "components/Citizens/hooks/useDownloadedFiles";
import styled from "styled-components";
import { Upload } from "tap2visit-ui-kit";

import { CitizenImgWrapper, CitizenImgWrapperTitle } from "../style/Citizen.styled";

interface ICitizenFiles {
	disabled?: boolean;
	fileIds?: string[];

	newFiles?: File[];
	setNewFiles?: React.Dispatch<React.SetStateAction<File[]>>;

	needToRemoveFileIds?: string[];
	setNeedToRemoveFileIds?: React.Dispatch<React.SetStateAction<string[]>>;

	title?: string;

	background?: string;
}

const CitizenFiles: FC<ICitizenFiles> = (props) => {
	const downloadedFiles = useDownloadedFiles({ fileIds: props.fileIds });

	const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files[0];
		const updatedNewFiles = [...props.newFiles, file];
		props.setNewFiles(updatedNewFiles);
	};

	const onRemoveNewFiles = (fileIdx: number) => {
		const updatedNewFiles = [...props.newFiles.slice(0, fileIdx), ...props.newFiles.slice(fileIdx + 1)];
		props.setNewFiles(updatedNewFiles);
	};

	const onRemoveDownloadedFile = (fileId: string) => {
		const newArrNeedToRemoveFileIds = [...props.needToRemoveFileIds, fileId];
		props.setNeedToRemoveFileIds(newArrNeedToRemoveFileIds);
	};

	const getFilteredDownloadFilesWithoutDeleted = () => {
		if (props.disabled) {
			return downloadedFiles.data;
		}
		return downloadedFiles.data?.filter((fileObject) => !props.needToRemoveFileIds?.includes(fileObject.fileId));
	};

	return (
		<CitizenImgWrapper background={props.background}>
			{props.title && <CitizenImgWrapperTitle>{props.title}</CitizenImgWrapperTitle>}
			<FilesContainer>
				{getFilteredDownloadFilesWithoutDeleted()?.map((fileObject) => (
					<Upload
						files={[fileObject.file]}
						onChangeFiles={(e) => undefined}
						accept="image/jpeg,image/png"
						type="image"
						disabled={props.disabled}
						onRemove={() => onRemoveDownloadedFile(fileObject.fileId)}
					/>
				))}
				{props.newFiles?.map((file, idx) => (
					<Upload
						files={[file]}
						onChangeFiles={(e) => undefined}
						accept="image/jpeg,image/png"
						type="image"
						disabled={props.disabled}
						onRemove={() => onRemoveNewFiles(idx)}
					/>
				))}
				{!props.disabled && (
					<Upload onChangeFiles={onChangeFiles} accept="image/jpeg,image/png,image/jpg,image/webp" type="image" disabled={props.disabled} />
				)}
			</FilesContainer>
		</CitizenImgWrapper>
	);
};

export default CitizenFiles;

const FilesContainer = styled.div`
	display: flex;
	gap: 12px;
`;
