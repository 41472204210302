import { FC } from "react";

import { IQuestionAnswerDto } from "interfaces/IVote";
import { BarChart, Bar, XAxis, YAxis } from "recharts";
import { colors } from "tap2visit-ui-kit";

import ChartDescription from "./ChartDescription/ChartDescription";
import ChartEmpty from "./ChartEmpty/ChartEmpty";
import { getAnswers } from "./CircleChart/utils/getAnswers";
import { getAnswerText } from "./utils/getAnswerText";

interface IBarChart {
	question: IQuestionAnswerDto;
}

const CustomBarChart: FC<IBarChart> = (props) => {
	const { answersCount, answersChartData } = getAnswers(props.question);

	return (
		<>
			<ChartDescription title={"Нет поля: name"} description={`${answersCount} ${getAnswerText(answersCount)}`} />

			{answersCount === 0 && <ChartEmpty />}

			{answersCount !== 0 && (
				<BarChart
					width={600}
					height={50 * answersChartData.length}
					data={answersChartData}
					layout="vertical"
					margin={{
						top: 5,
						right: 30,
						left: 10,
						bottom: 5,
					}}>
					<XAxis type="number" axisLine={false} tickLine={false} />
					<YAxis dataKey="name" type="category" width={160} axisLine={false} tickLine={false} tick={{ textAnchor: "end", dx: -16 }} />
					<Bar dataKey="value" barSize={20} fill={colors.iconInfoDefault} radius={[0, 6, 6, 0]} />
				</BarChart>
			)}
		</>
	);
};

export default CustomBarChart;
