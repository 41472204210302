import { constants } from "buffer";
import { emailRegExp } from "constants/constants.regexp";

import { useState } from "react";

import TextHeader from "components/TextHeader";
import Input from "components/ui/Input/Input";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Button, FormItem, Text } from "tap2visit-ui-kit";
import { intl } from "utils/translate";

import { getIsExistEmailApi, sendCodeOnEmailApi } from "../../../api/api.auth";
import { TAuthProps } from "../Authorization";

const FirstStageAuth = ({ email, setEmail, nextStage, setHavePassword }: TAuthProps) => {
	const [emailLocal, setEmailLocal] = useState(email);
	const errorMessage = intl.getMessage("emailNotFoundInTheSystem");
	const [state, setState] = useState<"default" | "error">("default");
	const [stateErrorMessage, setStateErrorMessage] = useState(false);

	const sendCodeOnEmail = useMutation(sendCodeOnEmailApi, {
		onSuccess: () => {
			nextStage(2);
		},
	});

	const getIsExistEmail = useMutation(getIsExistEmailApi, {
		onSuccess: (res) => {
			if (res.isExists) {
				setEmail(emailLocal);
				if (res.isPasswordSet) {
					setHavePassword(true);
					nextStage(5);
				} else {
					sendCodeOnEmail.mutateAsync({ email: emailLocal });
				}
				setState("default");
			} else {
				setState("error");
			}
		},
	});

	const checkEmail = () => getIsExistEmail.mutateAsync({ email: emailLocal });

	const checkEmailFunc = () => {
		const isError = String(emailLocal).toLowerCase().match(emailRegExp);
		if (!isError) {
			setState("error");
			setStateErrorMessage(true);
		} else {
			setState("default");
			setStateErrorMessage(false);
		}

		if (isError) {
			checkEmail();
		}
	};
	const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === "Enter") {
			checkEmailFunc();
		}
	};

	return (
		<>
			<TextHeader>
				<FormattedMessage id="entrance" />
			</TextHeader>
			<FormItem
				label={intl.getMessage("email")}
				message={state === "error" && stateErrorMessage ? "Эл. почта некорректна" : state === "error" && errorMessage}
				state={state}>
				{/* <Wrapper> */}
				<Input
					onKeyDown={onEnter}
					value={emailLocal}
					sizeInput={"large"}
					style={{ width: "100%" }}
					contentContainerStyle={{ width: "100%" }}
					state={state}
					onChange={(e) => {
						setEmailLocal(e.target.value);
						setState("default");
					}}
					placeholder={intl.getMessage("emailPlaceholder")}
				/>
				{/* </Wrapper> */}
			</FormItem>
			<ButtonContainer>
				<Button size="large" typeButton="primary" onClick={checkEmailFunc}>
					<FormattedMessage id="further" />
				</Button>
			</ButtonContainer>
		</>
	);
};
export default FirstStageAuth;

const Wrapper = styled.div`
	input {
		width: 100%;
	}

	> * {
		&:last-child {
			p {
				line-height: 20px;
			}
		}
	}
`;

export const ButtonContainer = styled.div`
	display: inline-flex;
	justify-content: end;
`;
