import { makeAutoObservable } from "mobx";

class PollsSendModalStore {
	isVisibleModal = false;
	isTemplate = false;

	constructor() {
		makeAutoObservable(this);
	}

	changeStatusModal = (status: boolean, isTemplate?: boolean) => {
		this.isVisibleModal = status;
		this.isTemplate = isTemplate ?? false;
		if (!status) {
			this.isTemplate = false;
		}
	};
}

export default new PollsSendModalStore();
