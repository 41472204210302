import React from "react";

import { getOrganizationApi } from "api/api.organization";
import { downloadFileApi } from "api/api.storage";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import UKStore from "store/UK.Store";

import AuthStore from "../../../store/Auth.Store";

const useLogoUK = () => {
	const organizationId = UKStore.getUK()?.managingOrganizationId;

	const [img, setImg] = React.useState<string>();
	const [imgFile, setImgFile] = React.useState<File>();

	const { data } = useQuery({
		queryFn: () => getOrganizationApi(organizationId),
		queryKey: [QueriesKeys.idOrganization, organizationId],
		enabled: AuthStore.checkAuth() && !!organizationId,
	});

	useQuery({
		queryFn: () => {
			if (data.logoId) {
				return downloadFileApi(data?.logoId);
			}
			setImg(undefined);
			setImgFile(undefined);
		},
		enabled: !!data,
		queryKey: [QueriesKeys.downloadFile, data?.logoId],
		onSuccess: (data) => {
			if (data) {
				const blob = new Blob([data]);
				const imgLink = URL.createObjectURL(blob);
				const file = new File([blob], "logoUK.img", { type: "image/jpeg" });
				setImg(imgLink);
				setImgFile(file);
				return;
			}
			setImg(undefined);
			setImgFile(undefined);
		},
	});

	return { logoUKLink: img, logoUKImgFile: imgFile };
};

export default useLogoUK;
