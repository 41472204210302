import { FC } from "react";

import { observer } from "mobx-react-lite";

import { Spacer } from "../../../components/layout/Spacer";

import CitizenAppLogin from "./CitizenAppLogin";
import CitizenCommentsCollapse from "./CitizenComments.collapse";
import CitizenEmail from "./CitizenEmail";
import CitizenInn from "./CitizenInn";
import CitizenPassport from "./CitizenPassport";
import CitizenPersonalInformation from "./CitizenPersonalInformation";
import CitizenPhone from "./CitizenPhone";
import CitizenRealty from "./CitizenRealty";
import CitizenSnils from "./CitizenSnils";

const DrawerUserInfoCollapse: FC = () => (
	<>
		<CitizenCommentsCollapse type="citizen" />
		<Spacer px={32} />
		<CitizenRealty />
		<Spacer px={32} />
		<CitizenPersonalInformation />
		<Spacer px={32} />
		<CitizenAppLogin />
		<Spacer px={32} />
		<CitizenPhone />
		<Spacer px={32} />
		<CitizenEmail />
		<Spacer px={32} />
		<CitizenPassport />
		<Spacer px={32} />
		<CitizenSnils />
		<Spacer px={32} />
		<CitizenInn />
	</>
);

export default observer(DrawerUserInfoCollapse);
