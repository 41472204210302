import { getOfficeApi } from "api/office/api.office";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import UKStore from "store/UK.Store";

import profileUKVisibilityStore from "../profileUKVisibility.store";

const useSelectedOffice = () => {
	const offices = useQuery({
		queryKey: [QueriesKeys.offices],
		queryFn: () => getOfficeApi({ params: { managingOrganizationId: UKStore.getUK().managingOrganizationId } }),
	});

	const selectedActiveOffice = offices.data?.find((office) => office.id === profileUKVisibilityStore.activeOfficeId);

	return { selectedActiveOffice, offices };
};

export default useSelectedOffice;
