import { FC } from "react";

import DatePicker from "components/DatePicker";
import { FormItemContainer, CustomInput, CustomTextArea, CustomInputSmall } from "components/DrawerFormFields/DrawerFormFields";
import dayjs from "dayjs";
import { FormItem } from "tap2visit-ui-kit";

interface IUserEditInput<T> {
	label: string;
	required?: boolean;
	value: T;
	onChange: (value: T) => void;
	as?: "textarea" | "input";
	type?: "small";
	placeholder?: string;
	maxLength?: number;
	mask?: string;
	disabled?: boolean;
	message?: string;
	isError?: boolean;
}

const UserEditInput: FC<IUserEditInput<string>> = (props) => {
	const InputComponent = props.type === "small" ? CustomInputSmall : CustomInput;
	const inputType = props.as ?? "input";

	return (
		<FormItemContainer>
			<FormItem
				message={props.message}
				label={props.label}
				required={props.required}
				labelPosition="vertical"
				state={props.isError ? "error" : "default"}>
				<>
					{inputType === "input" && (
						<InputComponent
							maxLength={props.maxLength}
							placeholder={props.placeholder}
							value={props.value}
							sizeInput={"large"}
							onChangeValue={(value) => props.onChange(value)}
							mask={props.mask}
							disabled={props.disabled}
							state={props.isError ? "error" : "default"}
						/>
					)}
					{inputType === "textarea" && (
						<CustomTextArea
							onChangeValue={(value) => props.onChange(value)}
							value={props.value}
							showLengthValue={false}
							placeholder={props.placeholder}
							size="large"
							disabled={props.disabled}
							state={props.isError ? "error" : "default"}
						/>
					)}
				</>
			</FormItem>
		</FormItemContainer>
	);
};

export const UserEditDate: FC<IUserEditInput<dayjs.Dayjs>> = (props) => {
	return (
		<FormItemContainer>
			<FormItem
				label={props.label}
				required={props.required}
				labelPosition="vertical"
				state={props.isError ? "error" : "default"}
				message={props.message}>
				<DatePicker
					currentDate={props.value as any}
					onChangeDate={props.onChange as any}
					width="100%"
					placeholder={props.placeholder}
					isError={props.isError}
				/>
			</FormItem>
		</FormItemContainer>
	);
};

export default UserEditInput;
