import { IFile } from "interfaces/IFile";
import { TBucket } from "interfaces/IStorage";

import apiClient from "./api.client";

export const postFile: (url: string) => Promise<any> = async (url) => {
	try {
		const res = await apiClient.post("s3-storage/v1/file", {
			url,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TPostFileUploadApi = (args?: { file: FormData; bucket: TBucket }) => Promise<IFile>;

export const postFileUploadApi: TPostFileUploadApi = async (args) => {
	try {
		const res = await apiClient.post(`s3-storage/v1/file/upload?bucket=${args.bucket}`, args.file, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUploadFilesApi = (args: { files: File[]; bucket: TBucket }) => Promise<IFile[]>;

export const uploadFilesApi: TUploadFilesApi = async (args) => {
	try {
		const formDataList = args.files.map((file) => {
			const formData = new FormData();
			formData.append("file", file);
			return formData;
		});

		const res = await Promise.all(formDataList.map((formData) => postFileUploadApi({ bucket: args.bucket, file: formData })));

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getFileInformation: (id: string) => Promise<any> = async (id) => {
	try {
		const res = await apiClient.get(`s3-storage/v1/file/${id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const downloadFileApi: (id: string) => Promise<any> = async (id) => {
	try {
		const res = await apiClient.get(`s3-storage/v1/file/download/${id}`, {
			headers: {
				Accept: "application/octet-stream",
			},
			responseType: "blob",
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteFileApi: (id: string) => Promise<any> = async (id) => {
	try {
		const res = await apiClient.delete(`s3-storage/v1/file/${id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
