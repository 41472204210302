import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import { makeAutoObservable, toJS } from "mobx";

import { IAnnouncement, IAnnouncementsTemplatesItem } from "../../../interfaces/IAnnouncements";

import AnnouncementsViewDataStore from "./Announcements.viewData.store";

type TCurrentItem = "editTemplate" | "fillFromTemplate";

class AnnouncementsCurrentItemStore {
	announcementsItem: IAnnouncement = undefined;
	templateItem: IAnnouncementsTemplatesItem = undefined;
	currentItem: TCurrentItem | undefined = undefined;

	previousViewData: Readonly<typeof AnnouncementsViewDataStore> | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	setTemplateItem = (value: IAnnouncementsTemplatesItem) => {
		this.templateItem = value;
	};

	setCurrentItem = (currentItem: TCurrentItem) => {
		if (currentItem === "editTemplate") {
			this.previousViewData = toJS(AnnouncementsViewDataStore);
		}

		if (this.currentItem === "editTemplate" && !currentItem) {
			AnnouncementsViewDataStore.setAllFields(this.previousViewData);

			this.previousViewData = undefined;
		}

		this.currentItem = currentItem;
	};

	setAnnouncementsItem = (v: IAnnouncement) => {
		this.announcementsItem = v;
	};

	dispose = () => {
		this.announcementsItem = undefined;
		this.templateItem = undefined;
		this.currentItem = undefined;
	};
}

export default new AnnouncementsCurrentItemStore();
