import { makeAutoObservable } from "mobx";

import { IAccessRequestArray, IRequestUser } from "../../../interfaces/IRequest";
import { requestUser } from "mocks/mocks.request";

class AccessRequestStore {
	accessRequestArray: IAccessRequestArray[] = [];
	accessRequestItem: IRequestUser = {} as IRequestUser;

	constructor() {
		makeAutoObservable(this);
	}

	getAccessRequestArray = () => this.accessRequestArray;

	setAccessRequestArray = (data: IAccessRequestArray[]) => {
		this.accessRequestArray = data;
	};

	setAccessRequestItem = (v: IRequestUser) => {
		this.accessRequestItem = v;
	};
}

export default new AccessRequestStore();
