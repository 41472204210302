import React from "react";

import { getBuildingsBaseApi } from "api/api.gateway";
import { getComplexApi, getEntranceApi, getFloorsApi, getBuildingObjectsApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";

import BuildingFilterStore from "../BuildingFilter.store";

const useSelectBuildingData = (args: { filterStore: BuildingFilterStore }) => {
	const complexes = useQuery({
		queryFn: () => getComplexApi(),
		queryKey: [QueriesKeys.complexes],
	});

	const buildings = useQuery({
		queryFn: () => getBuildingsBaseApi({ params: { complexIds: args.filterStore.selectedComplexIds } }),
		queryKey: [QueriesKeys.buildingsBase, ...(args.filterStore.selectedComplexIds ?? [])],
		enabled: !!args.filterStore.selectedComplexIds.length,
	});

	const entrances = useQuery({
		queryFn: () => getEntranceApi({ params: { buildingIds: args.filterStore.selectedBuildingsIds } }),
		queryKey: [QueriesKeys.entrances, ...(args.filterStore.selectedBuildingsIds ?? [])],
		enabled: !!args.filterStore.selectedBuildingsIds.length,
	});

	const floors = useQuery({
		queryFn: () => getFloorsApi({ params: { entranceIds: args.filterStore.selectedEntrancesIds } }),
		queryKey: [QueriesKeys.floors, ...(args.filterStore.selectedEntrancesIds ?? [])],
		enabled: !!args.filterStore.selectedEntrancesIds.length,
	});

	const buildingObjects = useQuery({
		queryFn: () => getBuildingObjectsApi({ params: { floorIds: args.filterStore.selectedFloorsIds } }),
		queryKey: [QueriesKeys.buildingObjects, ...(args.filterStore.selectedFloorsIds ?? [])],
		enabled: !!args.filterStore.selectedFloorsIds.length,
	});

	// #region set all possible filter options
	React.useEffect(() => {
		args.filterStore.setSelectItems(complexes.data ?? [], "complexes");
	}, [complexes.data]);

	React.useEffect(() => {
		args.filterStore.setSelectItems(buildings.data ?? [], "buildings");
	}, [buildings.data]);

	React.useEffect(() => {
		args.filterStore.setSelectItems(entrances.data ?? [], "entrances");
	}, [entrances.data]);

	React.useEffect(() => {
		args.filterStore.setSelectItems(floors.data ?? [], "floors");
	}, [floors.data]);

	React.useEffect(() => {
		args.filterStore.setSelectItems(buildingObjects.data ?? [], "buildingObjects");
	}, [buildingObjects.data]);
	// #endregion

	return [];
};

export default useSelectBuildingData;
