import { TService } from "interfaces/ICounter";

export const getType = (type: TService) => {
	switch (type) {
		case "COLD_WATER_1":
			return "Холодная вода (ХВС) №1";
		case "HEATING_1":
			return "Отопление №1";
		case "HOT_WATER_1":
			return "Горячая вода (ГВС) №1";
		case "ELECTRICITY_1":
			return "Электричество №1";
		case "ELECTRICITY_DAY_1":
			return "Электричество (ДЕНЬ) №1";
		case "ELECTRICITY_NIGHT_1":
			return "Электричество (НОЧЬ) №1";
		case "COLD_WATER_2":
			return "Холодная вода (ХВС) №2";
		case "HOT_WATER_2":
			return "Горячая вода (ГВС) №2";
		case "ELECTRICITY_2":
			return "Электричество №2";
		case "HEATING_2":
			return "Отопление №2";
		default:
			return "";
	}
};

export const serviceTypeArray = [
	{ text: "Холодная вода 1", key: "COLD_WATER_1" },
	{ text: "Отопление 1", key: "HEATING_1" },
	{ text: "Электричество 1", key: "ELECTRICITY_1" },
	{ text: "Электричество день 1", key: "ELECTRICITY_DAY_1" },
	{ text: "Электричество ночь 1", key: "ELECTRICITY_NIGHT_1" },
	{ text: "Холодная вода 2", key: "COLD_WATER_2" },
	{ text: "Горячая вода 2", key: "HOT_WATER_2" },
	{ text: "Горячая вода 1", key: "HOT_WATER_1" },
	{ text: "Электричество 2", key: "ELECTRICITY_2" },
	{ text: "Отопление 2", key: "HEATING_2" },
];
