import { createOfficeApi, updateOfficeByIdApi } from "api/office/api.office";
import { queryClient } from "index";
import { IOfficeCreateOrUpdate } from "interfaces/IOffice";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useMutation } from "react-query";
import { showToast } from "tap2visit-ui-kit";

import useSelectedOffice from "./useSelectedOffice";

const useCreateAndUpdateOfficeMutations = (args?: { state?: Partial<IOfficeCreateOrUpdate> }) => {
	const { selectedActiveOffice } = useSelectedOffice();

	const getBodyForCreateOrUpdateMutations = (funcArg: Partial<IOfficeCreateOrUpdate>) => ({
		name: funcArg.name ?? args?.state?.name,
		avatarId: funcArg.avatarId ?? args?.state?.avatarId,
		managingOrganizationId: funcArg.managingOrganizationId ?? args?.state?.managingOrganizationId,
		addressId: funcArg.addressId ?? args?.state?.addressId,
		description: funcArg.description ?? args?.state?.description,
		isShownToUsers: funcArg.isShownToUsers ?? args?.state?.isShownToUsers,
		executives: funcArg.executives ?? args?.state?.executives,
		phones: funcArg.phones ?? args?.state?.phones,
		emails: funcArg.emails ?? args?.state?.emails,
		timetables: funcArg.timetables ?? args?.state?.timetables,
	});

	const onSuccess = () => {
		queryClient.invalidateQueries([QueriesKeys.offices]);
	};

	const isWorkTimeValid = (funcArg: Partial<IOfficeCreateOrUpdate>) => {
		const times = funcArg.timetables ?? args?.state?.timetables;
		const workTimes = times.filter((t) => !t.isWeekend);
		const allTimesSet = workTimes.every((t) => t?.workingTimePeriod);

		if (!allTimesSet) {
			showToast({
				type: "danger",
				// eslint-disable-next-line quotes
				description: 'Все дни недели должны быть заполнены, или не выбранные дни должны быть отмечены как "выходные"',
			});
			return Promise.reject("Должны быть заполнены все дни недели");
		}
	};

	const updateOffice = useMutation({
		mutationFn: (funcArg: Partial<IOfficeCreateOrUpdate>) => {
			const isError = isWorkTimeValid(funcArg);
			if (isError) {
				return isError;
			}

			return updateOfficeByIdApi({
				body: getBodyForCreateOrUpdateMutations(funcArg),
				path: {
					id: selectedActiveOffice.id,
				},
			});
		},
		onSuccess: onSuccess,
	});

	const createOffice = useMutation({
		mutationFn: (funcArg: Partial<IOfficeCreateOrUpdate>) => {
			const isError = isWorkTimeValid(funcArg);
			if (isError) {
				return isError;
			}

			return createOfficeApi({
				body: getBodyForCreateOrUpdateMutations(funcArg),
			});
		},
		onSuccess: onSuccess,
	});

	return { updateOffice, createOffice };
};

export default useCreateAndUpdateOfficeMutations;
