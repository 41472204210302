import dayjs from "dayjs";

import { mergeDateWithTime } from "./mergeDateAndGetIsoDate";

export default (date1: dayjs.Dayjs, time1: dayjs.Dayjs, date2: dayjs.Dayjs, time2: dayjs.Dayjs) => {
	const firstDate = mergeDateWithTime(date1, time1);
	const secondDate = mergeDateWithTime(date2, time2);

	return firstDate.isBefore(secondDate);
};
