/* eslint-disable arrow-body-style */
import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import { TVoteType } from "interfaces/IVote";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactComponent as ExcludeSvg } from "public/exclude.svg";
import { ReactComponent as VoteSvg } from "public/vote.svg";
import styled from "styled-components";
import { FormItem, Input, Segmented, Select, TSegmentedArray, TSegmentedItem, Text, colors } from "tap2visit-ui-kit";

import PollsEditValidationStore from "../../../../store/PollsEdit.validation.store";
import PollsEditStore from "../../../../store/PollsItem.store";
import PollsEditContentCard from "../../../PollsEditContent.card";

import usePollTemplatesSelectItems from "./hooks/usePollTemplatesSelectItems";

interface IPollsDetailNameCard {
	editable?: boolean;
}

const segmentedItems: TSegmentedArray = [
	{
		title: "Опрос",
		icon: <ExcludeSvg />,
		key: "POLL",
	},
	{
		title: "Голосование",
		icon: <VoteSvg />,
		key: "VOTE",
	},
];

const PollsDetailNameCard: FC<IPollsDetailNameCard> = (props) => {
	const templatePollsSelectItems = usePollTemplatesSelectItems();

	const onChangePollName = (e: React.ChangeEvent<HTMLInputElement>) => {
		PollsEditValidationStore.validPollName();
		PollsEditStore.changePoll({ key: "name", value: e.target.value });
	};

	const onChangePollType = (segmentedItem: TSegmentedItem) => {
		PollsEditStore.changePoll({ key: "type", value: segmentedItem.key as TVoteType });
	};

	return (
		<>
			<PollsEditContentCard style={{ paddingTop: "24px", paddingBottom: "32px" }}>
				<>
					<FormItem label="Название">
						<CustomInput
							value={PollsEditStore.poll.name}
							onChange={onChangePollName}
							sizeInput={"large"}
							placeholder="Название опроса"
							state={PollsEditValidationStore.isValidPollName ? "default" : "error"}
							disabled={!props.editable}
						/>
					</FormItem>

					{props.editable && (
						<>
							<Spacer px={10} />

							<Row>
								<SelectWrapper>
									<Text color={colors.textNeutralSecondary} type="table-cell-m-regular">
										Шаблон:{" "}
									</Text>
									<Select
										placeholder="Без шаблона"
										onClickSelect={templatePollsSelectItems.onClickSelect}
										onClickSelectItem={templatePollsSelectItems.onChangeTemplate}
										items={templatePollsSelectItems.items}
										isOpened={templatePollsSelectItems.isOpenSelect}
										size="m"
										contentContainerStyle={{ width: "100%" }}
										style={{ width: "100%" }}
									/>
								</SelectWrapper>

								<Spacer px={10} horizontal />

								<Segmented
									selectedKey={PollsEditStore.poll.type}
									items={segmentedItems}
									direction={"horizontal"}
									size="m"
									onClick={onChangePollType}
								/>
							</Row>
						</>
					)}
				</>
			</PollsEditContentCard>
		</>
	);
};

export default observer(PollsDetailNameCard);

const CustomInput = styled(Input)`
	width: 100%;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const SelectWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	width: 100%;
`;
