import React from "react";

import { IntlProvider } from "react-intl";

import MainRouter from "./routes/MainRouter";
import { LOCALES, messages } from "./utils/translate";

function App() {
	const [locale, setLocale] = React.useState(LOCALES.RUSSIAN);

	return <MainRouter />;
}

export default App;
