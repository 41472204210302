import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import Tab from "components/ui/Tab/Tab";
import styled from "styled-components";
import { BoldDocumentpaper3Lines } from "t2v-icons";
import { Button, TTabItem, colors, showToast } from "tap2visit-ui-kit";

import ReceiptsDrawerStore from "./store/ReceiptsDrawer.store";

interface ITabs {}

const tabItems: TTabItem[] = [
	{
		key: "receipts" as string,
		label: "Квитанции" as string,
	},
	{
		key: "amountOfReceipts" as string,
		label: "Поступления" as string,
	},
];

const Tabs: FC<ITabs> = (props) => {
	const onTabClick = (key: string) => {
		if (key === "receipts") {
			showToast({ type: "info", description: 'Раздел "Квитанции" находится в разработке ' });
		}
	};

	return (
		<Row>
			<TabContainer>
				<Tab
					style={styleTab}
					withLine={true}
					paddingItem={"12px 0"}
					items={tabItems}
					direction={"horizontal"}
					activeKey={"amountOfReceipts"}
					onTabClick={onTabClick}
				/>
			</TabContainer>
			<Button size="medium" icon={BoldDocumentpaper3Lines} onClick={ReceiptsDrawerStore.toggleIsOpenDrawer}>
				Сформировать отчет
			</Button>
			<Spacer px={24} horizontal />
		</Row>
	);
};

export default Tabs;

const styleTab = {
	borderBottom: "none",
	marginLeft: "14px",
};

const Row = styled.div`
	display: flex;
	width: calc(100% + 14px);
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${colors.strokeDivider};
	position: relative;
	left: -14px;
`;

const TabContainer = styled.div`
	flex-grow: 1;
`;
