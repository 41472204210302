import React from "react";

import { useQuery } from "react-query";
import styled from "styled-components";
import { Space } from "tap2visit-ui-kit";

import { getComplexesApi } from "../../api/api.gateway";
import PageLoader from "../../components/PageLoader";
import { QueriesKeys } from "../../interfaces/queriesKeys";

import BuildingsCard from "./BuildingsCard";

const Buildings = () => {
	const complexes = useQuery({
		queryFn: () => getComplexesApi({ params: { requestStatus: "NEW", announcementStatus: "NEW" } }),
		queryKey: [QueriesKeys.advancedComplexes],
		staleTime: Infinity,
	});

	if (complexes.isFetching) return <PageLoader isLoading={complexes.isFetching} />;
	return (
		<BuildingsWrapper>
			<Space size={20}>{complexes?.data?.map((v) => <BuildingsCard item={v} />)}</Space>
		</BuildingsWrapper>
	);
};

const BuildingsWrapper = styled.div`
	padding: 16px;
`;

export default Buildings;
