import React, { FC } from "react";

import { ReactComponent as CheckIcon } from "public/check.svg";
import { Button } from "tap2visit-ui-kit";

interface ICitizenChoiceButton {
	onClick: () => void;
	isActive: boolean;
}

const CitizenChoiceButton: FC<ICitizenChoiceButton> = (props) => (
	<Button size="small" icon={props.isActive && CheckIcon} typeButton={props.isActive ? "primary" : "secondary"} onClick={props.onClick}>
		{props.isActive ? "Выбран" : "Выбрать"}
	</Button>
);

export default CitizenChoiceButton;
