import { makeAutoObservable, toJS } from "mobx";

export type TTypeOfRealEstate = "complex" | "building" | "entrance" | "floor" | "buildingObject";

type TEstateSelectedFields = {
	selectedComplexesIds?: TSelectedIds[];
	selectedBuildingsIds?: TSelectedIds[];
	selectedEntrancesIds?: TSelectedIds[];
	selectedFloorsIds?: TSelectedIds[];
	selectedBuildingObjectsIds?: TSelectedIds[];
};
export type TSelectedIds = { id: string; parentId: string | null };

class RealEstateTreeStore {
	selectedComplexesIds: TSelectedIds[] = [];
	selectedBuildingsIds: TSelectedIds[] = [];
	selectedEntrancesIds: TSelectedIds[] = [];
	selectedFloorsIds: TSelectedIds[] = [];
	selectedBuildingObjectsIds: TSelectedIds[] = [];

	initialSelectedComplexesIds: string[] = [];
	initialSelectedBuildingIds: string[] = [];
	initialSelectedEntrancesIds: string[] = [];
	initialSelectedFloorsIds: string[] = [];
	initialSelectedBuildingObjectsIds: string[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	get bodyBuildingIds() {
		const complexIds = new Set(this.selectedComplexesIds.map((complex) => complex.id));
		const buildingsIds = new Set(this.selectedBuildingsIds.map((building) => building.id));
		const entrancessIds = new Set(this.selectedEntrancesIds.map((entrance) => entrance.id));
		const floorsIds = new Set(this.selectedFloorsIds.map((floor) => floor.id));

		const filteredBuildingsIds = this.selectedBuildingsIds.filter((building) => !complexIds.has(building.parentId));
		const filteredEntrancesIds = this.selectedEntrancesIds.filter((entrance) => !buildingsIds.has(entrance.parentId));
		const filteredFloorsIds = this.selectedFloorsIds.filter((floor) => !entrancessIds.has(floor.parentId));
		const filteredBuildingObjectsIDs = this.selectedBuildingObjectsIds.filter((buildingObject) => !floorsIds.has(buildingObject.parentId));

		return {
			complex: Array.from(complexIds),
			buildings: filteredBuildingsIds.map((v) => v.id),
			entrances: filteredEntrancesIds.map((v) => v.id),
			floors: filteredFloorsIds.map((v) => v.id),
			buildingObjects: filteredBuildingObjectsIDs.map((v) => v.id),
		};
	}

	setInitialSelectedIds = (args: {
		ids: { complexIds: string[]; buildingIds: string[]; entranceIds: string[]; floorsIds: string[]; buildingObjectIds: string[] };
	}) => {
		this.initialSelectedComplexesIds = args.ids.complexIds;
		this.initialSelectedBuildingIds = args.ids.buildingIds;
		this.initialSelectedEntrancesIds = args.ids.entranceIds;
		this.initialSelectedFloorsIds = args.ids.floorsIds;
		this.initialSelectedBuildingObjectsIds = args.ids.buildingObjectIds;
	};

	getRealEstateIdsByType = (type: TTypeOfRealEstate) => {
		switch (type) {
			case "complex":
				return this.selectedComplexesIds;
			case "building":
				return this.selectedBuildingsIds;
			case "entrance":
				return this.selectedEntrancesIds;
			case "floor":
				return this.selectedFloorsIds;
			case "buildingObject":
				return this.selectedBuildingObjectsIds;
		}
	};

	updateAllSelectedEstate = (args: Partial<TEstateSelectedFields>) => {
		this.selectedComplexesIds = args.selectedComplexesIds ?? this.selectedComplexesIds;
		this.selectedBuildingsIds = args.selectedBuildingsIds ?? this.selectedBuildingsIds;
		this.selectedEntrancesIds = args.selectedEntrancesIds ?? this.selectedEntrancesIds;
		this.selectedFloorsIds = args.selectedFloorsIds ?? this.selectedFloorsIds;
		this.selectedBuildingObjectsIds = args.selectedBuildingObjectsIds ?? this.selectedBuildingObjectsIds;
	};

	clearAllSelectedEstate = () => {
		this.selectedComplexesIds = [];
		this.selectedBuildingsIds = [];
		this.selectedEntrancesIds = [];
		this.selectedFloorsIds = [];
		this.selectedBuildingsIds = [];

		this.initialSelectedComplexesIds = [];
		this.initialSelectedBuildingIds = [];
		this.initialSelectedEntrancesIds = [];
		this.initialSelectedFloorsIds = [];
		this.initialSelectedBuildingObjectsIds = [];
	};

	setInitialSelectedEstateWithParentId = (args: TEstateSelectedFields) => {
		this.selectedComplexesIds = args.selectedComplexesIds;
		this.selectedBuildingsIds = args.selectedBuildingsIds;
		this.selectedEntrancesIds = args.selectedEntrancesIds;
		this.selectedFloorsIds = args.selectedFloorsIds;
		this.selectedBuildingObjectsIds = args.selectedBuildingObjectsIds;
	};

	onClickCheckbox = (data: { id: string; parentId: string; type: TTypeOfRealEstate }) => {
		const currentData = { id: data.id, parentId: data.parentId };
		switch (data.type) {
			case "complex":
				this.onChangeChecked(this.selectedComplexesIds, currentData);
				break;
			case "building":
				this.onChangeChecked(this.selectedBuildingsIds, currentData);
				break;
			case "entrance":
				this.onChangeChecked(this.selectedEntrancesIds, currentData);
				break;
			case "floor":
				this.onChangeChecked(this.selectedFloorsIds, currentData);
				break;
			case "buildingObject":
				this.onChangeChecked(this.selectedBuildingObjectsIds, currentData);
				break;
		}
	};

	private onChangeChecked = (checkedObjects: TSelectedIds[], data: TSelectedIds) => {
		if (checkedObjects.some((d) => d.id === data.id)) {
			const filteredArr = checkedObjects.filter((item) => item.id !== data.id);
			checkedObjects.length = 0;
			checkedObjects.push(...filteredArr);
		} else {
			checkedObjects.push(data);
		}
	};

	dispose = () => {
		this.clearAllSelectedEstate();
	};
}

export default new RealEstateTreeStore();
