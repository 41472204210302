import { FC } from "react";

import { Text, colors } from "tap2visit-ui-kit";

interface ICitizenText {
	text: string | number;
}

export const CitizenDrawerTextHeaderBlock: FC<ICitizenText> = (props) => (
	<Text color={colors.textNeutralPrimary} h="h5">
		{props.text}
	</Text>
);

export const CitizenDrawerBlockItemHeader: FC<ICitizenText> = (props) => (
	<Text type="table-cell-l-medium" color={colors.textNeutralPrimary}>
		{props.text}
	</Text>
);

export const CitizenDrawerBlockItemDescription: FC<ICitizenText> = (props) => (
	<Text type="table-cell-m-medium" color={colors.textNeutralSecondary}>
		{props.text}
	</Text>
);

export const CitizenDrawerInformationBlockHeader: FC<ICitizenText> = (props) => (
	<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
		{props.text}
	</Text>
);

export const CitizenDrawerInformationBlockDescription: FC<ICitizenText> = (props) => (
	<Text type="table-cell-m-medium" color={colors.textNeutralPrimary}>
		{props.text}
	</Text>
);

export const CitizenDrawerButtonAddText: FC<ICitizenText> = (props) => (
	<Text type="table-cell-l-medium" color={colors.textNeutralPrimary} style={{ paddingLeft: "8px" }}>
		{props.text}
	</Text>
);

export const CitizenImgHeader: FC<ICitizenText> = (props) => (
	<Text type="base-regular" color={colors.textNeutralSecondary}>
		{props.text}
	</Text>
);
