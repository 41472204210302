import React, { FC } from "react";

import { createReportApi, createReportMvpApi } from "api/api.gateway";
import { Divider } from "components/DrawerFormFields/DrawerFormFields";
import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Button, Drawer, LoaderV2 } from "tap2visit-ui-kit";

import ReceiptsDrawerStore from "../store/ReceiptsDrawer.store";

import Calendar from "./Calendar/Calendar";
import DownloadFileList, { TDownloadFile } from "./DownloadFileList/DownloadFileList";
import Period from "./Period/Period";
import ReceiptsPeriodStore from "./store/ReceiptsPeriod.store";
import getExcelFile from "./utils/getExcelFile";

interface IReceiptsDrawer {}

const ReceiptsDrawer: FC<IReceiptsDrawer> = (props) => {
	const [downloadReports, setDownloadReports] = React.useState<TDownloadFile[]>([]);
	const [listReportsDates, setListReportsDates] = React.useState<string[]>([]);
	const [isVisibleCloseDrawerConfirm, setIsVisibleCloseDrawerConfirm] = React.useState(false);

	const fromSelectedDate = ReceiptsDrawerStore.dates?.[0] ?? null;
	const toSelectedDate = ReceiptsDrawerStore.dates?.[1] ?? null;
	const fullSelectedDateString = `${fromSelectedDate},${toSelectedDate}`;

	const getCreationTime = () => {
		const isEqualsDates = fromSelectedDate === toSelectedDate;
		if (ReceiptsPeriodStore.selectedPeriodType === "week") {
			return {
				params: {
					startCreationTime: dayjs(fromSelectedDate).toISOString(),
					endCreationTime: dayjs(toSelectedDate).toISOString(),
				},
			};
		}
		if (ReceiptsPeriodStore.selectedPeriodType === "month") {
			return {
				params: {
					startCreationTime: dayjs(fromSelectedDate).toISOString(),
					endCreationTime: dayjs(toSelectedDate).toISOString(),
				},
			};
		}
		if (ReceiptsPeriodStore.selectedPeriodType === "year") {
			return {
				params: {
					startCreationTime: dayjs(fromSelectedDate).toISOString(),
					endCreationTime: dayjs(toSelectedDate).toISOString(),
				},
			};
		}
	};

	const createReport = useMutation({
		mutationFn: () => createReportApi(getCreationTime()),
		onSuccess: (data) => {
			const { url, from, to, size } = getExcelFile({
				data,
				from: fromSelectedDate,
				to: toSelectedDate,
			});

			setListReportsDates((list) => [...list, fullSelectedDateString]);

			setDownloadReports((reports) => [
				...reports,
				{
					fileName: `Отчет с ${from} по ${to}.xls`,
					url,
					size,
				},
			]);
		},
	});

	React.useEffect(() => {
		ReceiptsDrawerStore.setIsCreationReportInProgress(createReport.isLoading);
	}, [createReport.isLoading]);

	React.useEffect(
		() => () => {
			ReceiptsDrawerStore.dispose();
			ReceiptsPeriodStore.dispose();
		},
		[],
	);

	const onCreateReport = () => {
		createReport.mutateAsync();
	};

	const isDisabledButton = fullSelectedDateString.indexOf("null") !== -1 || listReportsDates.includes(fullSelectedDateString);

	const onAcceptCloseDrawer = () => {
		ReceiptsDrawerStore.toggleIsOpenDrawer();
	};

	const onCloseDrawer = () => {
		if (createReport.isLoading) {
			setIsVisibleCloseDrawerConfirm(true);
			return;
		}
		ReceiptsDrawerStore.toggleIsOpenDrawer();
	};

	return (
		<>
			<Drawer
				width="700px"
				visible={ReceiptsDrawerStore.isOpen}
				title={"Формирование отчета"}
				onClose={onCloseDrawer}
				onCancel={onCloseDrawer}
				showOk={false}
				showCancel={false}
				contentContainerStyle={{ height: "600px" }}
				footerSlot={
					<Footer>
						<CustomDivider />
						<ButtonsContainer>
							<Button onClick={onCloseDrawer} typeButton="secondary">
								Отменить
							</Button>
							<Button
								disabled={isDisabledButton}
								icon={() => <IconLoader isLoading={createReport.isLoading} />}
								typeButton="primary"
								onClick={onCreateReport}>
								Сформировать отчет
							</Button>
						</ButtonsContainer>
					</Footer>
				}>
				<>
					<Period />
					<Spacer px={26} />
					<Calendar />
					<Spacer px={26} />
					<DownloadFileList fileList={downloadReports} />
				</>
			</Drawer>
			<BaseModal
				title={"Отчет формируется"}
				description={"При выходе данные будут потеряны. Уверены, что хотите прервать формирование отчета?"}
				okText={"Да"}
				cancelText="Нет"
				isVisible={isVisibleCloseDrawerConfirm}
				status="warning"
				onCancel={() => setIsVisibleCloseDrawerConfirm(false)}
				onOk={onAcceptCloseDrawer}
				onExit={() => setIsVisibleCloseDrawerConfirm(false)}
			/>
		</>
	);
};

const IconLoader: FC<{ isLoading: boolean }> = (props) => {
	if (props.isLoading) {
		return <LoaderV2 />;
	}
};

export default observer(ReceiptsDrawer);

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	padding-top: 16px;
`;

const CustomDivider = styled(Divider)`
	width: calc(100% + 48px);
	transform: translateX(-24px);
`;

const Footer = styled.div`
	width: 100%;
`;
