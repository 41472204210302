import styled from "styled-components";
import { colors } from "tap2visit-ui-kit";

export const BuildingsHouseHeaderHeaderLink = styled.p`
	cursor: pointer;
	color: ${colors.textBrandDefault};
`;

export const BuildingsHouseHeaderDots = styled.div`
	background: #00000073;
	width: 3px;
	height: 3px;
	border-radius: 100%;
`;

export const CurrentBreadCrumb = styled.p`
	cursor: pointer;
	color: ${colors.textNeutralPrimary};
`;

export const BuildingsListWrapper = styled.div`
	border-top: 1px solid #12121229;
	width: 100%;
	padding: 8px 16px;
	cursor: pointer;
`;
export const BuildingsListTitle = styled.p`
	font-weight: 500;
`;

export const BuildingsHouseCardHeader = styled.div`
	padding: 16px;
`;
export const BuildingsHouseCardWrapper = styled.div`
	background: white;
	border: 1px solid #12121229;
	border-radius: 10px;
	width: 400px;
	position: relative;
`;

export const BuildingsParkingCardWrapper = styled.div`
	background: white;
	border: 1px solid #12121229;
	border-radius: 10px;
	max-height: 550px;
	width: 400px;
	position: relative;
`;
export const BuildingsHouseCardTitle = styled.p`
	font-size: 20px;
	font-weight: 700;
	width: 320px;
`;

export const BuildingsFooter = styled.div`
	padding: 16px;
	border-top: 1px solid #12121229;
	width: 100%;
`;

export const BuildingsSliderWrapper = styled.div`
	padding: 18px;
	background: ${colors.surfaceNeutralBg4};
	border-radius: 12px;
	margin-bottom: 16px;
	cursor: pointer;
`;
export const BuildingsSliderLabel = styled.p`
	font-size: 12px;
	font-weight: 500;
`;
export const BuildingsSliderLabelCenter = styled.p`
	font-size: 16px;
	font-weight: 500;
`;
