import React, { FC } from "react";

import { FormItemsContainer } from "components/DrawerFormFields/DrawerFormFields";
import { ICreateClientContacts, TContactType } from "interfaces/IClient";
import { ReactComponent as EmailIcon } from "public/Email.svg";
import { ReactComponent as PhoneIcon } from "public/Phone.svg";
import { ReactComponent as TrashIcon } from "public/Trash.svg";
import styled from "styled-components";
import { Button, Space, colors } from "tap2visit-ui-kit";

import {
	CitizenRealtyWrapper,
	CitizenRealtyTitleAppLogin,
	CitizenRealtySubTitle,
	CitizenRealtyDivider,
} from "../../../../components/Citizens/style/Citizen.styled";
import CitizenAddButton from "../CitizenAddButton";
import { CitizenDrawerTextHeaderBlock } from "../CitizenTexts";

import CitizenContactModal, { TCitizenContactModalOnSave } from "./CitizenContact.modal";

interface ICitizenContact {
	title: string;
	contactsCollection: ICreateClientContacts[];
	onSave: TCitizenContactModalOnSave;
	onDeleteContact: (value: string) => void;

	modalTitle: string;
	modalTitlePlaceholder: string;
	modalTitleLabel: string;
	modalDescriptionPlaceholder: string;
	modalTitleMask?: string;

	buttonText: string;

	type: TContactType;

	isCreateNewCitizen?: boolean; // ? when create a new citizen
}

const CitizenContact: FC<ICitizenContact> = (props) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Space direction="column" align="start">
			{isOpen && (
				<CitizenContactModal
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					onSave={props.onSave}
					title={props.title}
					titlePlaceholder={props.modalTitlePlaceholder}
					titleLabel={props.modalTitleLabel}
					titleMask={props.modalTitleMask}
					descriptionPlaceholder={props.modalDescriptionPlaceholder}
				/>
			)}
			<CitizenDrawerTextHeaderBlock text={props.title} />

			<CitizenContactContainer isCreateNewCitizen={props.isCreateNewCitizen}>
				<CitizenRealtyWrapper>
					{props.contactsCollection.map((v) => (
						<>
							<ContactWrapper>
								<Space width="100%" justify="space-between">
									<Space>
										{props.type === "PHONE" && <PhoneIcon />}
										{props.type === "EMAIL" && <EmailIcon />}
										<Space align="start" size={1} direction="column">
											<CitizenRealtyTitleAppLogin>{v.value}</CitizenRealtyTitleAppLogin>
											<CitizenRealtySubTitle>{v.description}</CitizenRealtySubTitle>
										</Space>
									</Space>
									<IconContainer>
										<Button typeButton="text" shape="square" icon={TrashIcon} onClick={() => props.onDeleteContact(v.value)} />
									</IconContainer>
								</Space>
							</ContactWrapper>
							<CitizenRealtyDivider />
						</>
					))}

					<CitizenAddButton onClick={() => setIsOpen(true)} text={props.buttonText} />
				</CitizenRealtyWrapper>
			</CitizenContactContainer>
		</Space>
	);
};

const CitizenContactContainer: FC<{ isCreateNewCitizen: boolean; children: React.ReactNode }> = (props) =>
	props.isCreateNewCitizen ? <FormItemsContainer style={{ width: "100%" }}>{props.children}</FormItemsContainer> : <>{props.children}</>;

export default CitizenContact;

const IconContainer = styled.div`
	cursor: pointer;
`;

const ContactWrapper = styled.div`
	padding: 12px;
`;
