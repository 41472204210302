import { makeAutoObservable } from "mobx";

class BuildingsStore {
	selectedId = "";
	entranceId = "";
	constructor() {
		makeAutoObservable(this);
	}

	setSelectedId = (v: string) => {
		this.selectedId = v;
	};
	setEntranceId = (v: string) => {
		this.entranceId = v;
	};
}

export default new BuildingsStore();
