import BaseModal from "components/Modals/BaseModal";
import { FC } from "react";
import ModalConfirmEditCitizenStore from "./store/ModalConfirmEditCitizen.store";
import { observer } from "mobx-react-lite";
import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";

interface IModalConfirmEditCititzen {}

const ModalConfirmEditCitizen: FC<IModalConfirmEditCititzen> = (props) => {
	const onOk = () => {
		CitizensDrawerStore.openDrawer({
			mode: "visible",
			user: ModalConfirmEditCitizenStore.clickedUser,
		});
		ModalConfirmEditCitizenStore.close();
	};

	return (
		<BaseModal
			isVisible={ModalConfirmEditCitizenStore.isVisible}
			title={"Перед тем как изменять параметры пользователя с ним необходимо связаться"}
			status="delete"
			okText="Ок"
			onOk={onOk}
			onExit={ModalConfirmEditCitizenStore.close}
		/>
	);
};

export default observer(ModalConfirmEditCitizen);
