import { TDataChangeInformation, TEmailChangeInformation, TOrganizationType, TPhoneChangeInformation } from "../interfaces/IOrganization";

import apiClient from "./api.client";

export const getOrganizationApi: (id: string) => Promise<TOrganizationType> = async (id) => {
	try {
		const res = await apiClient.get(`managing-organizations/v1/managing-organizations/${id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TChangeOrganizationApiApi = (body: TDataChangeInformation) => Promise<void>;

export const changeOrganizationApi: TChangeOrganizationApiApi = async (body) => {
	try {
		const res = await apiClient.patch(`managing-organizations/v1/managing-organizations/${body.id}`, {
			...body,
			logoId: body.logoId === undefined ? null : body.logoId,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreatePhoneOrganizationApi = (args: {
	path: { id: string };
	body: Omit<TPhoneChangeInformation, "id">;
}) => Promise<TPhoneChangeInformation>;

export const createPhoneOrganizationApi: TCreatePhoneOrganizationApi = async (args) => {
	try {
		const res = await apiClient.post(`managing-organizations/v1/managing-organizations/${args.path.id}/phones`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateEmailOrganizationApi = (args: {
	path: { id: string };
	body: Omit<TEmailChangeInformation, "id">;
}) => Promise<TEmailChangeInformation>;

export const createEmailOrganizationApi: TCreateEmailOrganizationApi = async (args) => {
	try {
		const res = await apiClient.post(`managing-organizations/v1/managing-organizations/${args.path.id}/emails`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
