import React, { FC } from "react";

import { observer } from "mobx-react-lite";

import PollAnswers from "../components/PollAnswers/PollAnswers";
import PollsDetail from "../components/PollsDetail/PollsDetail";
import PollsEditTabsStore from "../store/PollsItem.tabs.store";
import { Center } from "../styles/common";

interface IPollsView {}

const PollsView: FC<IPollsView> = (props) => (
	<Center>
		{PollsEditTabsStore.activeTab === "questions" && <PollsDetail editable={false} />}
		{PollsEditTabsStore.activeTab === "answers" && <PollAnswers />}
	</Center>
);

export default observer(PollsView);
