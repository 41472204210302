export const editOrCreateOfficeInputMaxLength = {
	officeName: 50,
	officeAddress: 255,
	additionalInformation: 300,
	position: 50,
	initials: 100,
	emailDescription: 50,
	email: 255,
	phoneDescription: 50,
	phone: 12,
	shortName: 100,
};
