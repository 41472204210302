export const getFlatType = new Map([
	["ONE_BEDROOM", "1-ком."],
	["TWO_BEDROOM", "2-ком."],
	["THREE_BEDROOM", "3-ком."],
	["FOUR_BEDROOM", "4-ком."],
	["FIVE_BEDROOM", "5-ком."],
	["EURO_ONE_BEDROOM", "Евро 1-ком."],
	["EURO_TWO_BEDROOM", "Евро 2-ком."],
	["EURO_THREE_BEDROOM", "Евро 3-ком."],
	["STUDIO", "Студия"],
]);
