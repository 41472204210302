import React, { FC } from "react";

import styled from "styled-components";
import { DownloadFile } from "tap2visit-ui-kit";

export type TDownloadFile = { url: string; fileName: string; size: string };

interface IDownloadFileList {
	fileList: TDownloadFile[];
}

const DownloadFileList: FC<IDownloadFileList> = (props) => (
	<Container>
		{props.fileList.map((file) => (
			<DownloadFile name={file.fileName} fileSize={file.size} size={"m"} url={file.url} />
		))}
	</Container>
);

export default DownloadFileList;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
