import dayjs, { Dayjs } from "dayjs";

export const updateInputOfInputArr = <T>(setInputs: React.Dispatch<React.SetStateAction<T[]>>, idx: number, newInputValue: T) => {
	setInputs((inputs) => [...inputs.slice(0, idx), newInputValue, ...inputs.slice(idx + 1)]);
};

export const addNewFieldInput = (setInputs: React.Dispatch<React.SetStateAction<string[]>>) => {
	setInputs((inputs) => [...inputs, ""]);
};

export const deleteFieldInput = <T>(setInputs: React.Dispatch<React.SetStateAction<T[]>>, idx: number) => {
	setInputs((inputs) => [...inputs.slice(0, idx), ...inputs.slice(idx + 1)]);
};

export const getDayJsFromString = (time: string | undefined) => {
	if (!time) {
		return;
	}

	const parsedTime = dayjs.utc(time, "HH:mm[Z]");

	const [hours, minutes, seconds] = parsedTime.format("HH:mm:ss").split(":");

	const dayjsTime = dayjs()
		.set("hours", Number(hours ?? 0))
		.set("minutes", Number(minutes ?? 0))
		.set("seconds", Number(seconds ?? 0));

	return dayjsTime;
};
