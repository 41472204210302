import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { ReactComponent as ArrowDownIcon } from "public/ArrowDownIcon.svg";
import { ReactComponent as Left } from "public/Left.svg";
import { ReactComponent as Right } from "public/Right.svg";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DropDown, getUid, Space } from "tap2visit-ui-kit";

import { getBuildingsApi } from "../../api/api.gateway";
import { QueriesKeys } from "../../interfaces/queriesKeys";

import BuildingsStore from "./Buildings.store";
import { BuildingsSliderLabel, BuildingsSliderLabelCenter, BuildingsSliderWrapper } from "./Buildings.styled";
import { getJustify } from "./utils/getJustify";

const DROPDOWN_BUTTON_ID = getUid("dropdown_slider_floor_button");

const BuildingsSliderFloor = () => {
	const [open, setOpen] = useState(false);

	const buildings = useQuery({
		queryFn: () => getBuildingsApi({ params: { requestStatus: "NEW", announcementStatus: "NEW", complexId: BuildingsStore.selectedId } }),
		queryKey: [QueriesKeys.advancedBuildings, BuildingsStore.selectedId],
		enabled: !BuildingsStore.selectedId,
	});
	const buildingsOtherArr = buildings.data?.filter((v) => v.id !== BuildingsStore.entranceId).sort(() => Math.random() - 0.5);
	const navigate = useNavigate();

	const getDropDownItems = () =>
		buildings.data.map((v) => ({
			meta: {
				id: v.id,
			},
			text: v.address,
		}));
	return (
		<BuildingsSliderWrapper>
			<Space justify={getJustify(buildings.data)}>
				{buildings.data?.length > 1 && (
					<BuildingsSliderWrapperLeft buildingsLength={buildings.data.length}>
						<Space size={2}>
							<Left />
							<BuildingsSliderLabel
								onClick={() => {
									navigate(`/buildings/entrance/${buildingsOtherArr[0].id}`);
								}}>
								{buildingsOtherArr[0]?.address}
							</BuildingsSliderLabel>
						</Space>
					</BuildingsSliderWrapperLeft>
				)}
				<Space size={2}>
					<BuildingsSliderLabelCenter id={DROPDOWN_BUTTON_ID} onClick={() => setOpen(!open)}>
						{buildings.data?.find((v) => v.id === BuildingsStore.entranceId)?.address}
					</BuildingsSliderLabelCenter>
					<ArrowDownIcon />
					<DropDown
						style={{ width: "350px", top: "130px" }}
						dropDownItemStyle={{ minWidth: "320px" }}
						onClick={(itemDropdown) => {
							navigate(`/buildings/entrance/${itemDropdown.meta.id}`);
							setOpen(false);
						}}
						onClickOutside={() => setOpen(false)}
						isVisible={open}
						items={getDropDownItems()}
						outerButtonDropdownId={DROPDOWN_BUTTON_ID}
					/>
				</Space>
				{buildings.data?.length > 2 && (
					<Space size={2}>
						<BuildingsSliderLabel onClick={() => navigate(`/buildings/entrance/${buildingsOtherArr[1].id}`)}>
							{buildingsOtherArr[1].address}
						</BuildingsSliderLabel>
						<Right />
					</Space>
				)}
			</Space>
		</BuildingsSliderWrapper>
	);
};

const BuildingsSliderWrapperLeft = styled.div<{ buildingsLength: number }>`
	margin-right: ${({ buildingsLength }) => (buildingsLength === 2 ? "20vw" : "0")};
`;

export default observer(BuildingsSliderFloor);
