import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import InputBorderLess from "components/ui/Input/InputBorderLess";
import { observer } from "mobx-react-lite";
import PollsEditValidationStore from "pages/polls/store/PollsEdit.validation.store";
import { ReactComponent as CloseSvg } from "public/close.svg";
import { ReactComponent as DraggableMenu } from "public/draggable_menu_vertical.svg";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { BorderLessInput, Button } from "tap2visit-ui-kit";

type TOption = { id: string; value: string };
interface IQuestionItemOption {
	icon: React.FC<React.SVGProps<SVGSVGElement>>;
	option: TOption;
	placeholder: string;
	onChangeOption?: (value: string) => void;
	onRemoveOption?: () => void;
	index?: number;
	isFreeAnswer?: boolean;
	editable?: boolean;
}

const QuestionItemOption: FC<IQuestionItemOption> = (props) => {
	const Icon = props.icon;
	const isValidQuestionOption = !PollsEditValidationStore.notValidQuestionOptions.includes(props.option.id);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		PollsEditValidationStore.validQuestionOption(props.option.id);
		props.onChangeOption(e.target.value);
	};

	if (props.isFreeAnswer) {
		return (
			<>
				<CheckboxContainer>
					<RowOption>
						<Icon />
						<BorderLessInput value={props.option.value} onChange={onChange} placeholder={props.placeholder} disabled />
					</RowOption>
					<Button onClick={props.onRemoveOption} icon={CloseSvg} shape="square" typeButton="text" />
				</CheckboxContainer>
				<Spacer px={10} />
			</>
		);
	}

	return (
		<Container optionId={props.option.id} index={props.index} editable={props.editable}>
			<CheckboxContainer>
				<RowOption>
					<DraggableIconContainer id="draggable_icon">
						<DraggableMenu />
					</DraggableIconContainer>
					<Icon />
					<InputBorderLess
						value={props.option.value}
						onChange={onChange}
						placeholder={props.placeholder}
						state={!isValidQuestionOption ? "error" : "default"}
						disabled={!props.editable}
						style={{ width: "500px" }}
					/>
				</RowOption>
				{props.editable && <Button onClick={props.onRemoveOption} icon={CloseSvg} shape="square" typeButton="text" />}
			</CheckboxContainer>
			<Spacer px={10} />
		</Container>
	);
};

export default observer(QuestionItemOption);

const Container: FC<{ editable?: boolean; optionId: string; index: number; children }> = (props) => {
	if (props.editable) {
		return (
			<Draggable draggableId={props.optionId} index={props.index}>
				{(provided) => (
					<DraggableSpace id="draggable_option" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
						{props.children}
					</DraggableSpace>
				)}
			</Draggable>
		);
	}

	return <>{props.children}</>;
};

const CheckboxContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const RowOption = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
`;

const DraggableSpace = styled.div`
	width: 100%;

	&:hover {
		& > div > div > #draggable_icon {
			left: -22px;
			opacity: 1;
		}
	}
`;

const DraggableIconContainer = styled.div`
	transition: 0.5s all;

	position: absolute;
	left: -22px;
	top: 2px;
	opacity: 0;
`;
