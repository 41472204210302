export default (str: string) => {
	let phone = "";

	for (const phoneDigit of str) {
		if (phoneDigit === "(" || phoneDigit === ")" || phoneDigit === " ") {
			continue;
		}
		phone += phoneDigit;
	}

	return phone;
};
