import React, { FC } from "react";

import TextHeader from "components/TextHeader";
import { ReactComponent as CreateEmployeeSvg } from "public/add_documents.svg";
import { Button, Text } from "tap2visit-ui-kit";

import useIsCurrentEditedUser from "./hooks/useIsCurrentEditedUser";
import EmployeeStore from "./store/Employee.drawer.store";

const EmployeeHeader: FC = () => {
	const { isEmployee } = useIsCurrentEditedUser();

	return (
		<>
			<TextHeader>Список сотрудников</TextHeader>
			{!isEmployee && (
				<Button onClick={() => EmployeeStore.openDrawer({ mode: "create", isNewUserEdit: true })} icon={CreateEmployeeSvg}>
					Создать сотрудника
				</Button>
			)}
		</>
	);
};

export default EmployeeHeader;
