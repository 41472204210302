import React from "react";

import { IWorkingTimeCreate, DayOfWeek } from "interfaces/IOffice";

export interface ITimeAction {
	payload: IWorkingTimeCreate;
	options?: {
		reset?: boolean;
	};
}

const timeReducer = (state: IWorkingTimeCreate[], action: ITimeAction) =>
	state.map((time) => {
		if (time.dayOfWeek === action.payload.dayOfWeek) {
			if (action.options?.reset) {
				return action.payload;
			}

			return {
				...time,
				...action.payload,
			};
		}
		return time;
	});

const initialTimeState: IWorkingTimeCreate[] = [
	{
		dayOfWeek: DayOfWeek.MONDAY,
		isWeekend: false,
	},
	{
		dayOfWeek: DayOfWeek.TUESDAY,
		isWeekend: false,
	},
	{
		dayOfWeek: DayOfWeek.WEDNESDAY,
		isWeekend: false,
	},
	{
		dayOfWeek: DayOfWeek.THURSDAY,
		isWeekend: false,
	},
	{
		dayOfWeek: DayOfWeek.FRIDAY,
		isWeekend: false,
	},
	{
		dayOfWeek: DayOfWeek.SATURDAY,
		isWeekend: false,
	},
	{
		dayOfWeek: DayOfWeek.SUNDAY,
		isWeekend: false,
	},
];

const useTimeState = () => {
	const [timeState, dispatchTimeState] = React.useReducer(timeReducer, initialTimeState);

	const resetTimeState = () => {
		initialTimeState.forEach((time) => {
			dispatchTimeState({ payload: time, options: { reset: true } });
		});
	};

	return { timeState, dispatchTimeState, initialTimeState, resetTimeState };
};

export default useTimeState;
