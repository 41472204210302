import { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Text } from "tap2visit-ui-kit";

import PollsEditContentCard from "../PollsEditContent.card";

import usePollAnswers from "./hooks/usePollAnswers";
import { getAnswerText } from "./utils/getAnswerText";
import { getReceptionText } from "./utils/getReceptionText";

interface IPollsAnswersCount {}

const PollsAnswersCount: FC<IPollsAnswersCount> = (props) => {
	const pollAnswers = usePollAnswers();

	return (
		<PollsEditContentCard style={{ padding: "24px" }}>
			<Flex>
				<TextContainer>
					<Text h={"h4"}>{`${pollAnswers.data?.countAnswers ?? 0} ${getAnswerText(pollAnswers.data?.countAnswers ?? 0)}`}</Text>
					<Text type="base-regular">{`${pollAnswers.data?.countOfParticipants ?? 0} ${getReceptionText(
						pollAnswers.data?.countOfParticipants,
					)}`}</Text>
				</TextContainer>
			</Flex>
		</PollsEditContentCard>
	);
};

export default observer(PollsAnswersCount);

const TextContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: baseline;
`;

const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
