import React, { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { showToast, Upload } from "tap2visit-ui-kit";

import profileUKVisibilityStore from "../profileUKVisibility.store";

interface IPageInfoUpload {
	img?: File;
	uploadFile?: (file: File) => void;
	isDefaultImageEnabled?: boolean;
	loading?: boolean;
}

const ProfileUKImage: FC<IPageInfoUpload> = (props) => {
	const [errorUploadFile, setErrorUploadFile] = React.useState(false);
	const [isErrorSize, setIsErrorSize] = React.useState(false);
	const [img, setImg] = useState(undefined);

	useEffect(() => {
		setImg(props.img);
	}, [props.img]);

	useEffect(() => {
		if (img) {
			const convert = (img?.size / (1024 * 1024)).toFixed(2);
			if (Number(convert.split(".")[0]) > 20) {
				showToast({
					description: "Размер фотографии превышает 20 Mb",
					type: "danger",
				});
				setIsErrorSize(true);
			} else setIsErrorSize(false);
		}
	}, [img]);

	useEffect(() => {
		if (!profileUKVisibilityStore.activeOfficeId) {
			setImg(undefined);
			props?.uploadFile(undefined);
		}
	}, [profileUKVisibilityStore.activeOfficeId, props.img]);

	return (
		<Upload
			type={"preview"}
			accept={"image/png,image/jpeg,image/jpg"}
			width={"341px"}
			height={"190px"}
			files={img ? [img] : undefined}
			loading={props.loading}
			onDrop={(e) => {
				if (!profileUKVisibilityStore.isVisibleOfficeDetail) {
					setErrorUploadFile(false);
					props?.uploadFile(e.dataTransfer.files[0]);
					setImg(e.dataTransfer.files[0]);
				}
			}}
			description="Необходимо загрузить фото входа в УК PNG, JPG  (макс. 20 МБ)"
			disabled={profileUKVisibilityStore.isVisibleOfficeDetail}
			error={errorUploadFile || isErrorSize}
			textError={isErrorSize ? "Размер фотографии превышает 20 Mb" : "Ошибка добавления файла, неверный формат"}
			onChangeFiles={(e) => {
				setErrorUploadFile(false);
				props?.uploadFile(e.target.files[0]);
				setImg(e.target.files[0]);
			}}
			onErrorAddFile={() => {
				setErrorUploadFile(true);
			}}
			onRemove={() => {
				setImg(undefined);
				props?.uploadFile(undefined);
			}}
		/>
	);
};

export default React.memo(observer(ProfileUKImage), (prev, next) => {
	const areEqualsSizeOfFiles = (prev.img?.size ?? 0) === (next.img?.size ?? 0);
	const areEqualsLoading = prev.loading === next.loading;
	return areEqualsSizeOfFiles && areEqualsLoading;
});
