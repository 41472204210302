import { makeAutoObservable } from "mobx";

class AccessRequestRejectModalStore {
	isVisible = false;
	requestId: string | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	showModal = (args: { requestId: string }) => {
		this.isVisible = true;
		this.requestId = args.requestId;
	};

	closeModal = () => {
		this.isVisible = false;
		this.requestId = undefined;
	};
}

export default new AccessRequestRejectModalStore();
