import { FC, useEffect, useState } from "react";
import React from "react";

import { createUserApi, getUserByIdApi, updateUserApi } from "api/api.employee";
import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import UserAvatarAndInitials from "components/UserAvatarAndInitials";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactComponent as EditIcon } from "public/edit-icon-white.svg";
import { useMutation, useQuery } from "react-query";
import AuthStore from "store/Auth.Store";
import styled from "styled-components";
import { Button, Drawer, showToast } from "tap2visit-ui-kit";
import formatPhone from "utils/format/formatPhone";
import getPhoneWithoutMask from "utils/format/getPhoneWithoutMask";
import { getIsVisibleUserFieldsAndTextButton } from "utils/getIsVisibleUserField";
import isEqualsObjectFields from "utils/isEqualsObjectFields";

import useIsCurrentEditedUser from "../hooks/useIsCurrentEditedUser";
import EmployeeStore from "../store/Employee.drawer.store";
import EmployeeDrawerStore from "../store/Employee.drawer.store";
import EmployeeValidationStore from "../store/Employee.validation.store";
import getModalChangeRoleConfirmText from "../utils/getModalChangeRoleConfirmText";

import DrawerUserEdit from "./Drawer.userEdit";
import DrawerUserInfoCollapse from "./Drawer.userInfoCollapse";

const EmployeeDrawer: FC = observer(() => {
	const [openModal, setOpenModal] = useState(false);
	const currentUser = useQuery({
		queryFn: () => getUserByIdApi({ params: { id: EmployeeDrawerStore.selectedUser.id } }),
		queryKey: [QueriesKeys.user, EmployeeDrawerStore.selectedUser.id],
		enabled: !!EmployeeDrawerStore.selectedUser.id,
	});

	const { isMe, isEmployee } = useIsCurrentEditedUser();
	const modalChangeRoleConfirmText = getModalChangeRoleConfirmText(isMe);

	const [isVisibleChangeRoleConfirm, setIsVisibleChangeRoleConfirm] = React.useState(false);

	const userHasBeenChanged =
		EmployeeDrawerStore.selectedUser &&
		currentUser.data &&
		!isEqualsObjectFields(
			{
				...EmployeeDrawerStore.selectedUser,
				mobilePN: getPhoneWithoutMask(EmployeeDrawerStore.selectedUser?.mobilePN ?? ""),
			},
			currentUser.data,
		);

	useEffect(() => {
		if (currentUser.data && EmployeeStore.userId) {
			EmployeeStore.openDrawer({ mode: "visible", user: currentUser.data });
		}
	}, [currentUser]);

	const titleDrawer =
		EmployeeStore.drawerMode === "edit" ? (
			<TitleDrawerWrapper>Карточка сотрудника (Редактирование)</TitleDrawerWrapper>
		) : (
			<TitleDrawerWrapper>Карточка сотрудника</TitleDrawerWrapper>
		);

	const { isVisibleAvatarAndInitialInfo, isVisibleEditFields, textOk } = getIsVisibleUserFieldsAndTextButton(
		EmployeeDrawerStore.drawerMode,
	);

	const createUser = useMutation({
		mutationFn: createUserApi,
	});

	const updateUser = useMutation({
		mutationFn: updateUserApi,
	});

	const updateUserHandler = async () => {
		const { id, isPasswordSet, ...otherUserProps } = EmployeeStore.selectedUser;
		const mobilePN = getPhoneWithoutMask(EmployeeStore.selectedUser.mobilePN);
		setIsVisibleChangeRoleConfirm(false);

		await updateUser.mutateAsync({
			body: {
				comment: otherUserProps.comment,
				email: otherUserProps.email,
				lastName: otherUserProps.lastName,
				middleName: otherUserProps.middleName ? otherUserProps.middleName : null,
				firstName: otherUserProps.firstName,
				mobilePN: mobilePN,
				role: otherUserProps.role,
			},
			path: { id },
		});

		if (isMe && EmployeeDrawerStore.isRoleHasBeenChanged) {
			AuthStore.logout();
		} else {
			EmployeeStore.openDrawer({ mode: "visible", user: EmployeeStore.selectedUser });
			await queryClient.invalidateQueries([QueriesKeys.user, EmployeeDrawerStore.selectedUser.id]);
			await queryClient.invalidateQueries([QueriesKeys.employee]);
		}
	};

	const onOk = async () => {
		const { id, isPasswordSet, ...otherUserProps } = EmployeeStore.selectedUser;
		const isRoleHasBeenChanged = currentUser.data?.role !== EmployeeDrawerStore.selectedUser?.role;

		if (EmployeeStore.drawerMode === "visible") {
			EmployeeStore.openDrawer({ mode: "edit", user: EmployeeStore.selectedUser });
			return;
		}

		const mobilePN = getPhoneWithoutMask(EmployeeStore.selectedUser.mobilePN ?? "");
		const isValidUserFields = EmployeeValidationStore.checkUserValidation({
			...EmployeeStore.selectedUser,
			mobilePN,
		});

		if (!isValidUserFields) {
			showToast({
				description: "Все поля должны быть корректно заполнены",
				type: "danger",
			});
			return;
		}

		if (EmployeeStore.drawerMode === "create") {
			const newUser = await createUser.mutateAsync({
				body: {
					...otherUserProps,
					mobilePN,
				},
			});
			EmployeeStore.openDrawer({
				mode: "visible",
				user: newUser,
			});
			await queryClient.invalidateQueries([QueriesKeys.user, EmployeeDrawerStore.selectedUser.id]);
			await queryClient.invalidateQueries([QueriesKeys.employee]);
			return;
		}

		if (EmployeeStore.drawerMode === "edit") {
			if (EmployeeDrawerStore.isRoleHasBeenChanged) {
				setIsVisibleChangeRoleConfirm(true);
			} else {
				await updateUserHandler();
			}
		}
	};

	const onCloseEditConfirmModal = () => {
		if (EmployeeStore.drawerMode === "create") {
			EmployeeStore.closeDrawer();
		} else {
			EmployeeStore.cancelDrawer();
		}
		setOpenModal(false);
	};

	const selectedUserHasBeenChangedCreateDrawer =
		EmployeeDrawerStore.selectedUser.firstName ||
		EmployeeDrawerStore.selectedUser.lastName ||
		EmployeeDrawerStore.selectedUser.email ||
		EmployeeDrawerStore.selectedUser.mobilePN;

	const onCloseDrawer = () => {
		if (EmployeeStore.drawerMode === "create" && Boolean(selectedUserHasBeenChangedCreateDrawer)) {
			setOpenModal(true);
			return;
		}

		if (EmployeeStore.drawerMode === "edit") {
			if (userHasBeenChanged) {
				setOpenModal(true);
			} else {
				onCloseEditConfirmModal();
			}
		} else {
			EmployeeDrawerStore.closeDrawer();
		}
	};

	return (
		<>
			<Drawer
				title={titleDrawer}
				visible={EmployeeStore.isVisibleDrawer}
				onClose={onCloseDrawer}
				onCancel={onCloseDrawer}
				disableOk={EmployeeDrawerStore.drawerMode === "edit" && !userHasBeenChanged}
				onOk={onOk}
				width="700px"
				showCancel={EmployeeStore.drawerMode === "edit" || EmployeeStore.drawerMode === "create"}
				textOk={textOk}
				showOk={!isEmployee}
				headerContainerStyle={{
					padding: "18px 16px",
				}}
				textCancel="Отменить"
				headerSlot={
					EmployeeStore.drawerMode === "visible" &&
					!isEmployee && (
						<Button icon={EditIcon} onClick={onOk}>
							Редактировать
						</Button>
					)
				}>
				{isVisibleAvatarAndInitialInfo && (
					<UserAvatarAndInitials
						fullName={`${EmployeeDrawerStore.selectedUser.lastName} ${EmployeeDrawerStore.selectedUser.firstName} ${
							EmployeeDrawerStore.selectedUser.middleName ?? ""
						}`}
						role={EmployeeDrawerStore.selectedUser.role}
					/>
				)}

				{EmployeeStore.drawerMode === "visible" && (
					<>
						<Spacer px={16} />
						<DrawerUserInfoCollapse />
					</>
				)}
				{isVisibleEditFields && (
					<>
						<Spacer px={16} />
						<DrawerUserEdit />
					</>
				)}
			</Drawer>
			<BaseModal
				title="Не сохраненные изменения будут потеряны"
				description="Вы внесли изменения и не сохранили их"
				okText={"Продолжить"}
				cancelText="Отменить"
				isVisible={openModal}
				status="warning"
				onCancel={() => setOpenModal(false)}
				onOk={onCloseEditConfirmModal}
				onExit={() => setOpenModal(false)}
			/>
			<BaseModal
				title={modalChangeRoleConfirmText.title}
				description={modalChangeRoleConfirmText.description}
				okText={"Продолжить"}
				cancelText="Отменить"
				isVisible={isVisibleChangeRoleConfirm}
				status="warning"
				onCancel={() => setIsVisibleChangeRoleConfirm(false)}
				onOk={updateUserHandler}
				onExit={() => setIsVisibleChangeRoleConfirm(false)}
			/>
		</>
	);
});

export default EmployeeDrawer;

const TitleDrawerWrapper = styled.p`
	font-size: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
