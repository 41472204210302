import React from "react";

import { Space } from "antd";
import { getAnnouncementsCountApi } from "api/api.gateway";
import TextHeader from "components/TextHeader";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { ReactComponent as Icon } from "public/redButton.svg";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Badge, Button, colors, Text } from "tap2visit-ui-kit";

import AnnouncementsTabStore from "../Announcements.tab.store";
import AnnouncementModeStore from "../store/Announcement.mode.store";

const AnnouncementHeader = () => {
	const navigate = useNavigate();

	const countOfEmergency = useQuery({
		queryFn: () => {
			const statuses: string[] = [AnnouncementsTabStore.selectedTab];

			if (AnnouncementsTabStore.selectedTab === "ACTIVE") {
				statuses.push("NEW");
			}
			return getAnnouncementsCountApi({
				params: {
					types: ["EMERGENCY"],
					statuses,
				},
			});
		},
		queryKey: [QueriesKeys.countOfEmergency, AnnouncementsTabStore.selectedTab],
	});

	const isVisibleCountOfEmergency = countOfEmergency.data?.count > 0;

	return (
		<ExclamationHeaderWrapper>
			<TextHeader>Объявления</TextHeader>
			<Row>
				<Button
					onClick={() => {
						AnnouncementModeStore.setCheckEmergencyButtonClick(false);
						navigate("/announcement/create");
					}}>
					<Text type="small-regular">Создать объявление</Text>
				</Button>
				<Button
					onClick={() => {
						AnnouncementModeStore.setCheckEmergencyButtonClick(true);
						navigate("/announcement/create");
					}}
					typeButton="secondary"
					variant="red"
					icon={IconStyled}>
					<Text type="small-regular">Сообщить о ЧП</Text>
				</Button>
				{isVisibleCountOfEmergency && (
					<SpaceWrapper
						onClick={() => {
							AnnouncementsTabStore.setSortedByTypeStore(AnnouncementsTabStore.sortedByTypeStore.length > 0 ? [] : ["EMERGENCY"]);
						}}>
						<Text type="small-regular" color={colors.iconNeutralDefault}>
							Кол-во ЧП:
						</Text>
						<Badge type="counter" counter={countOfEmergency.data.count} />
					</SpaceWrapper>
				)}
			</Row>
		</ExclamationHeaderWrapper>
	);
};

export default observer(AnnouncementHeader);

const IconStyled = styled(Icon)`
	width: 20px;
	height: 20px;
	margin-top: 3px;
	margin-right: -5px;
`;

const SpaceWrapper = styled(Space)`
	cursor: pointer;
`;
const ExclamationHeaderWrapper = styled(Space)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
`;

const Row = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`;
