import React, { useEffect } from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Space } from "tap2visit-ui-kit";

import { getBuildingsApi } from "../../api/api.gateway";
import PageLoader from "../../components/PageLoader";
import { QueriesKeys } from "../../interfaces/queriesKeys";

import BuildingsHouseCard from "./BuildingsHouseCard";
import BuildingsSlider from "./BuildingsSlider";

const BuildingsHouse = () => {
	const { id } = useParams();

	const buildings = useQuery({
		queryFn: () => getBuildingsApi({ params: { requestStatus: "NEW", announcementStatus: "NEW", complexId: id } }),
		queryKey: [QueriesKeys.advancedBuildings, id],
	});

	useEffect(() => {
		localStorage.setItem("complexId", id);
	}, [id]);

	if (buildings.isFetching) return <PageLoader isLoading={buildings.isFetching} />;
	return (
		<BuildingsHouseWrapper>
			<BuildingsSlider />
			<Space direction="column" align="start">
				<Space align="start">{buildings?.data?.map((v) => <BuildingsHouseCard item={v} />)}</Space>
			</Space>
		</BuildingsHouseWrapper>
	);
};

const BuildingsHouseWrapper = styled.div`
	padding: 16px;
`;

export default BuildingsHouse;
