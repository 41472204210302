import React, { FC, useState } from "react";

import { createDocumentApi, updateDocumentApi } from "api/api.documents";
import { IDocumentCreate } from "interfaces/IDocuments";
import { ReactComponent as EditIcon } from "public/EditCitizen.svg";
import { useMutation } from "react-query";
import { Button, Space, colors } from "tap2visit-ui-kit";

import { updateUserByIdApi } from "../../../api/api.clients";
import CitizenFiles from "../../../components/Citizens/components/CitizenFiles";
import UserEditInput from "../../../components/Citizens/components/UserEdit.input";
import useDownloadedFiles from "../../../components/Citizens/hooks/useDownloadedFiles";
import useNewFileState from "../../../components/Citizens/hooks/useNewFileState";
import useUploadCitizenFiles from "../../../components/Citizens/hooks/useUploadCitizenFiles";
import { citizenCreateStoreInputMask } from "../../../components/Citizens/store/CitizenCreate.store";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import { CitizenRealtyWrapper, CitizenWrapper } from "../../../components/Citizens/style/Citizen.styled";
import {
	CitizenPersonalEdit,
	CitizenPersonalEditFooter,
	CitizenPersonalEditWrapper,
	PersonalInformationWrapper,
} from "../../../components/Citizens/style/Citizen.styled";
import useCitizenDocumentByType from "../hooks/useCitizenDocumentByType";
import useRefreshCitizenAfterUpdate from "../hooks/useRefreshCitizenAfterUpdate";

import {
	CitizenDrawerInformationBlockDescription,
	CitizenDrawerInformationBlockHeader,
	CitizenDrawerTextHeaderBlock,
} from "./CitizenTexts";
import { observer } from "mobx-react-lite";
import CitizenInsuranceNumberValidationStore from "../store/CitizenInsuranceNumberValidationStore";
import { ValidationStatuses } from "modules/validation/Validation.store";
import { cleanString } from "utils/format/cleanString";

export type TCitizenSnilsOnDataChange = (data: { insuranceNumber: string; files: File[] }) => void;

interface ICitizenSnils {
	onlyEditable?: boolean; // ? when we creating a new user
	onDataChange?: TCitizenSnilsOnDataChange; // ? for a new user
}

const CitizenSnils: FC<ICitizenSnils> = (props) => {
	const [edit, setEdit] = useState(!!props.onlyEditable);
	const [insuranceNumber, setInsuranceNumber] = useState("");
	const createDocument = useMutation(createDocumentApi);
	const updateDocument = useMutation(updateDocumentApi);

	const { needToRemoveFileIds, newFiles, setNeedToRemoveFileIds, setNewFiles } = useNewFileState();
	const refreshUser = useRefreshCitizenAfterUpdate({
		setIsEdit: setEdit,
		setNewFiles,
	});

	const citizenDocument = useCitizenDocumentByType({ type: ["SNILS"] });

	React.useEffect(() => {
		if (citizenDocument) {
			setInsuranceNumber(citizenDocument.number);
		}
	}, [citizenDocument]);

	const downloadedFiles = useDownloadedFiles({ fileIds: citizenDocument?.fileIds });
	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
		citizenDocument: citizenDocument,
	});

	const updateUser = useMutation({
		mutationFn: updateUserByIdApi,
	});

	React.useEffect(() => {
		if (props.onDataChange) {
			props.onDataChange({
				insuranceNumber,
				files: newFiles,
			});
		}
	}, [insuranceNumber, newFiles]);

	const changeInsuranceNumber = async () => {
		const newFileIds = await uploadFiles();

		const dataInputs = CitizensDrawerStore.selectedUser;
		const cleanedInsuranceNumber = cleanString(insuranceNumber);

		const isValid = CitizenInsuranceNumberValidationStore.checkValidation({ insuranceNumber: cleanedInsuranceNumber });
		if (!isValid) {
			return;
		}

		const documentBody: IDocumentCreate = {
			type: "SNILS",
			targetId: CitizensDrawerStore.selectedUser.id,
			sourceId: CitizensDrawerStore.selectedUser.id,
			fileIds: newFileIds,
			number: cleanedInsuranceNumber,
		};

		if (citizenDocument) {
			await updateDocument.mutateAsync({
				body: documentBody,
				path: {
					id: citizenDocument.id,
				},
			});
		} else {
			await createDocument.mutateAsync({
				body: documentBody,
			});
		}

		await updateUser.mutateAsync({
			path: { id: dataInputs.id },
			body: {
				contacts: dataInputs.contacts.filter((contact) => contact.value),
				firstName: dataInputs.firstName,
				lastName: dataInputs.lastName,
				middleName: dataInputs.middleName,
			},
		});

		setInsuranceNumber(dataInputs.inn);
		refreshUser();
	};

	const cancelEdit = () => {
		setEdit(false);
		setInsuranceNumber(citizenDocument.number);
	};

	const validationFields = CitizenInsuranceNumberValidationStore.validationData;
	const errorMessage = CitizenInsuranceNumberValidationStore.getMessageIfError("insuranceNumber");

	return (
		<Space width="100%" align="start" direction="column">
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={"СНИЛС"} />
				{!props.onlyEditable && (
					<Button onClick={() => setEdit((status) => !status)} shape="square" typeButton="secondary" icon={EditIcon} />
				)}
			</PersonalInformationWrapper>
			{edit && (
				<CitizenPersonalEditWrapper background={props.onlyEditable ? colors.surfaceNeutralBg2 : undefined}>
					<CitizenPersonalEdit>
						<UserEditInput
							label={"СНИЛС"}
							required
							value={insuranceNumber}
							mask={citizenCreateStoreInputMask.insuranceNumber}
							onChange={(value) => setInsuranceNumber(value)}
							isError={validationFields.insuranceNumber !== ValidationStatuses.VALID}
							message={errorMessage}
							placeholder="000-000-000 00"
						/>

						<CitizenFiles
							fileIds={citizenDocument?.fileIds}
							needToRemoveFileIds={needToRemoveFileIds}
							setNeedToRemoveFileIds={setNeedToRemoveFileIds}
							newFiles={newFiles}
							setNewFiles={setNewFiles}
							title="Изображение"
						/>
					</CitizenPersonalEdit>

					{!props.onlyEditable && (
						<CitizenPersonalEditFooter>
							<Button typeButton="secondary" onClick={cancelEdit}>
								Отменить
							</Button>
							<Button onClick={changeInsuranceNumber}>Сохранить</Button>
						</CitizenPersonalEditFooter>
					)}
				</CitizenPersonalEditWrapper>
			)}
			{!edit && (
				<>
					<Space width="100%" direction="column">
						<CitizenRealtyWrapper>
							<CitizenWrapper>
								<Space align="start" size={1} direction="column">
									<CitizenDrawerInformationBlockHeader text={"СНИЛС"} />
									<CitizenDrawerInformationBlockDescription text={insuranceNumber ? insuranceNumber : "Данные отсутствуют"} />
								</Space>
							</CitizenWrapper>
						</CitizenRealtyWrapper>
					</Space>
					{!!citizenDocument?.fileIds.length && <CitizenFiles disabled fileIds={citizenDocument?.fileIds} title="Изображение" />}
				</>
			)}
		</Space>
	);
};

export default observer(CitizenSnils);
