import React from "react";

import { createPollApi, updatePollApi } from "api/api.votes";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import useRealEstateTreeData from "hooks/useRealEstateTreeData/useRealEstateTreeData";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { toJS } from "mobx";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { showToast } from "tap2visit-ui-kit";

import PollsItemStore from "../store/PollsItem.store";
import PollsSendModalStore from "../store/PollsSendModal.store";

const useSavePoll = () => {
	const createPoll = useMutation(createPollApi);
	const updatePoll = useMutation(updatePollApi);
	const navigate = useNavigate();

	const onSend = async (args?: {
		realEstateIds: {
			complexIds: string[];
			buildingIds: string[];
			entranceIds: string[];
			floorIds: string[];
			buildingObjectIds: string[];
		};
	}) => {
		const realEstateIds = RealEstateTreeStore.bodyBuildingIds;

		const isUpdatePoll = !!PollsItemStore.poll.id;
		const realEstateIDs = args?.realEstateIds ?? {
			complexIds: realEstateIds.complex,
			buildingIds: realEstateIds.buildings,
			entranceIds: realEstateIds.entrances,
			floorIds: realEstateIds.floors,
			buildingObjectIds: realEstateIds.buildingObjects,
		};
		const isTemplate = PollsSendModalStore.isTemplate ?? PollsItemStore.poll.isTemplate;

		if (isUpdatePoll) {
			await updatePoll.mutateAsync({
				body: {
					...PollsItemStore.pollUpdateDto,
					...realEstateIDs,
				},
				path: {
					id: PollsItemStore.poll.id,
				},
			});
		} else {
			await createPoll.mutateAsync({
				body: {
					...PollsItemStore.pollCreateDto,
					...realEstateIDs,
					isTemplate: isTemplate,
				},
			});
		}

		if (isTemplate) {
			queryClient.invalidateQueries([QueriesKeys.pollTemplates]);
		} else {
			queryClient.invalidateQueries([QueriesKeys.polls]);
			if (isUpdatePoll) {
				queryClient.invalidateQueries([QueriesKeys.poll, PollsItemStore.poll.id]);
			}
		}

		PollsSendModalStore.changeStatusModal(false);

		navigate("/polls");

		const createToastMessage = PollsItemStore.poll.type === "POLL" ? "Опрос создан" : "Голосование создано";
		const updateToastMessage = PollsItemStore.poll.type === "POLL" ? "Опрос обновлен" : "Голосование обновлено";

		showToast({
			description: isUpdatePoll ? updateToastMessage : createToastMessage,
			type: "success",
		});
	};

	return onSend;
};

export default useSavePoll;
