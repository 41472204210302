// ! temporary

const getUserRoleByKey = (roleKey: string) => {
	switch (roleKey) {
		case "ADMINISTRATOR":
			return "Главный администратор";
		case "EMPLOYEE":
			return "Сотрудник";
	}
};
export default getUserRoleByKey;
