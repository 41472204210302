import { makeAutoObservable } from "mobx";

class ReceiptsDrawerStore {
	isOpen = false;
	dates: string[] = [];
	isCreationReportInProgress = false;

	constructor() {
		makeAutoObservable(this);
	}

	toggleIsOpenDrawer = () => {
		this.isOpen = !this.isOpen;
	};

	setDates = (dates: string[]) => {
		this.dates = dates;
	};

	setIsCreationReportInProgress = (status: boolean) => {
		this.isCreationReportInProgress = status;
	};

	dispose = () => {
		this.isOpen = false;
		this.dates = [];
		this.isCreationReportInProgress = false;
	};
}

export default new ReceiptsDrawerStore();
