import React, { FC } from "react";

import styled from "styled-components";
import { Loader } from "tap2visit-ui-kit";

interface ITableLoader {}

const TableLoader: FC<ITableLoader> = (props) => (
	<TableLoaderContainer>
		<Loader />
	</TableLoaderContainer>
);

export default TableLoader;

const TableLoaderContainer = styled.div`
	margin-top: 30px;
`;
