import React, { useEffect, useState } from "react";

import Tab from "components/ui/Tab/Tab";
import { observer } from "mobx-react-lite";
import { ReactComponent as PlusSvg } from "public/plus.svg";
import SideBarStore from "store/SideBar.store";
import styled, { css } from "styled-components";
import { Badge, Button, TTabItem, Text, colors } from "tap2visit-ui-kit";

import UKStore from "../../store/UK.Store";

import PageEdit from "./components/PageEdit";
import PageEditInformation from "./components/PageEditInformation";
import PageInfo from "./components/PageInfo";
import ProfileUKSkeleton from "./components/ProfileUK.Skeleton";
import { mainSections } from "./constants";
import useSelectedOffice from "./hooks/useSelectedOffice";
import profileUKVisibilityStore, { TActiveTabType } from "./profileUKVisibility.store";
import DialogAreYouSureCancelEditOfficeStore from "./store/DialogAreYouSureCancelEditOffice.store";
import { showModalProfileEditExistIfThereChanges } from "./utils/showModalProfileEditExistIfThereChanges";

const VERTICAL_TABS_MAX_WIDTH = "252px";

const ProfileUKPage = () => {
	const [openRequisites, setOpenRequisites] = useState(
		localStorage.getItem("is_shown_requisite") ? localStorage.getItem("is_shown_requisite") : true,
	);

	const { offices, selectedActiveOffice } = useSelectedOffice();
	const isOfficesExists = !!offices.data?.length;

	useEffect(() => {
		offices.refetch();
	}, [UKStore.getUK().managingOrganizationId]);

	useEffect(() => {
		if (!localStorage.getItem("is_shown_requisite") && localStorage.getItem("is_shown_requisite") === "false") {
			localStorage.setItem("is_shown_requisite", String(true));
		}
	}, []);

	useEffect(() => {
		setOpenRequisites(localStorage.getItem("is_shown_requisite") === "true");
	}, [localStorage.getItem("is_shown_requisite")]);

	React.useEffect(() => {
		if (profileUKVisibilityStore.isEditModeEnabled) {
			profileUKVisibilityStore.editOffice(selectedActiveOffice);
		}
	}, [selectedActiveOffice]);

	React.useEffect(() => {
		if (offices.data && !profileUKVisibilityStore.activeOfficeId) {
			profileUKVisibilityStore.setActiveOfficeId(offices.data?.[0]?.id);
			profileUKVisibilityStore.setIsThereAtLeastOneOffice((offices.data?.length ?? 0) > 0);
		}
	}, [offices.data]);

	const officesTabsItems: TTabItem[] = offices.data?.map((office) => ({
		children: (
			<TabOfficeNameContainer>
				<TabText type="large-medium">{office.name}</TabText>
				{profileUKVisibilityStore.activeOfficeId === office.id && (
					<Badge size="small" type="status" status={office.isShownToUsers ? "green" : "grey"}>
						<TabTextStatus type="small-regular">{office.isShownToUsers ? "Отображается" : "Не отображается"}</TabTextStatus>
					</Badge>
				)}
			</TabOfficeNameContainer>
		),
		key: office.id,
	}));

	const onAddOffice = () => {
		if (profileUKVisibilityStore.isEditModeEnabled) {
			DialogAreYouSureCancelEditOfficeStore.setVisibleDialogWithCallbackAfterExiting(profileUKVisibilityStore.createNewOffice);
		} else {
			profileUKVisibilityStore.createNewOffice();
		}
	};

	const onChangeTopOfficesTab = (key: string) => {
		if (profileUKVisibilityStore.isEditModeEnabled) {
			showModalProfileEditExistIfThereChanges({
				onContinue: () => {
					onOfficesInfoTabClick(key);
					profileUKVisibilityStore.setIsEditModeEnabled(false);
				},
			});
		} else {
			onOfficesInfoTabClick(key);
		}
	};

	const onChangeOfficeTab = (key: string) => {
		if (profileUKVisibilityStore.isEditModeEnabled) {
			showModalProfileEditExistIfThereChanges({
				onContinue: () => {
					changeOfficeTabClick(key);
					profileUKVisibilityStore.setIsEditModeEnabled(false);
				},
			});
		} else {
			changeOfficeTabClick(key);
		}
	};

	const onOfficesInfoTabClick = (key: string) => {
		profileUKVisibilityStore.changeCurrentActiveTab(key as TActiveTabType);
		profileUKVisibilityStore.cancelEdit();
	};

	const changeOfficeTabClick = (key: string) => {
		profileUKVisibilityStore.setActiveOfficeId(key);
	};

	return (
		<>
			<Page>
				<TabsWrapper>
					<Tab
						style={styleTab}
						withLine={true}
						paddingItem={"12px 0"}
						items={mainSections ?? []}
						direction={"horizontal"}
						activeKey={profileUKVisibilityStore.currentActiveTab}
						onTabClick={onChangeTopOfficesTab}
					/>

					{profileUKVisibilityStore.currentActiveTab === "offices" && profileUKVisibilityStore.activeOfficeId && (
						<Button onClick={onAddOffice} icon={PlusSvg} typeButton="secondary">
							Добавить офис
						</Button>
					)}
				</TabsWrapper>
				{profileUKVisibilityStore.isVisibleInfoOffice ? (
					<PageEditInformation />
				) : (
					<ContentPageInfoContainer>
						{offices.isFetching && !isOfficesExists && (
							<LoaderContainer id="container">
								<ProfileUKSkeleton />
							</LoaderContainer>
						)}

						{(!offices.isFetching || isOfficesExists) && (
							<>
								{!profileUKVisibilityStore.isVisibleInfoOffice && (offices.data?.length ?? 0) > 0 && (
									<TabsWrapperVertical>
										<Tab
											withLine={true}
											paddingItem={"12px 27px 12px 12px"}
											items={officesTabsItems ?? []}
											direction={"vertical"}
											width={VERTICAL_TABS_MAX_WIDTH}
											activeKey={profileUKVisibilityStore.activeOfficeId}
											onTabClick={onChangeOfficeTab}
										/>
									</TabsWrapperVertical>
								)}
								{profileUKVisibilityStore.isVisibleOfficeDetail && selectedActiveOffice && (
									<OfficeContainer sideBarWidth={SideBarStore.sideBarWidth}>
										<PageInfo office={selectedActiveOffice} />
									</OfficeContainer>
								)}
								{profileUKVisibilityStore.isVisiblePageEdit && (
									<OfficeContainer sideBarWidth={SideBarStore.sideBarWidth}>
										<PageEdit />
									</OfficeContainer>
								)}
							</>
						)}
					</ContentPageInfoContainer>
				)}
			</Page>
		</>
	);
};

export default observer(ProfileUKPage);

const styleTab = {
	borderBottom: "none",
	marginLeft: "10px",
};
const LoaderContainer = styled.div`
	height: 100px;
	width: 100%;
	display: flex;
	padding: 0 24px;
`;

const TabOfficeNameContainer = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

const TabTextStatus = styled(Text)`
	white-space: nowrap;
`;

const PAGE_PADDINGS = "16px";

const Page = styled.div`
	padding: ${PAGE_PADDINGS};
	min-height: 100vh;
	background-color: ${colors.surfaceNeutralBg2};
	width: 100%;
	> :first-child {
		width: 100%;
		gap: 8px;
		padding-left: 24px;
		> :last-child {
			bottom: 0;
			left: unset;
		}
	}
`;

const TabsWrapper = styled.div`
	margin-bottom: 24px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	border-bottom: 0.5px solid #12121229;
`;
const TabsWrapperVertical = styled.div`
	padding: 8px 24px 24px 8px;
	flex-wrap: nowrap;
	width: ${VERTICAL_TABS_MAX_WIDTH};
`;

const ContentPageInfoContainer = styled.div`
	display: flex;
	width: 100%;
	overflow: hidden;
	min-height: 100vh;
`;

const OfficeContainer = styled.div<{ sideBarWidth: string }>`
	padding-left: 32px;
	width: 100%;
`;

const TabText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;

	${() => css`
		max-width: calc(${VERTICAL_TABS_MAX_WIDTH} / 2 - 30px); // ? Text takes up half the space
	`}

	overflow: hidden;
	text-overflow: ellipsis;
`;
