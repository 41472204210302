import React, { useState } from "react";

import { Spacer } from "components/layout/Spacer";
import TextHeader from "components/TextHeader";
import Input from "components/ui/Input/Input";
import InputPassword from "components/ui/Input/InputPassword";
import { ReactComponent as CloseImage } from "public/eye_off.svg";
import { ReactComponent as OpenImage } from "public/eye_on.svg";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Button, FormItem } from "tap2visit-ui-kit";

import { loginToAppApi, sendCodeOnEmailApi } from "../../../api/api.auth";
import AuthStore from "../../../store/Auth.Store";
import { intl } from "../../../utils/translate";
import { TAuthProps } from "../Authorization";

import { ButtonContainer } from "./FirstStageAuth";

const SecondStageAuth = ({ email, nextStage }: TAuthProps) => {
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [state, setState] = useState<"error" | "default">("default");
	const sendCodeOnEmail = useMutation(sendCodeOnEmailApi);

	const sendCode = () => {
		sendCodeOnEmail.mutateAsync({ email });
	};

	const loginToApp = useMutation(loginToAppApi, {
		onSuccess: (res) => {
			AuthStore.saveTokens(res);
		},
	});

	const loginToAccount = () => {
		if (!password) setState("error");
		if (password) {
			setState("default");
			loginToApp.mutateAsync({ email, password }).then((data) => {
				AuthStore.saveTokens(data);
				nextStage(6);
			});
		}
	};

	const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === "Enter") {
			loginToAccount();
		}
	};

	return (
		<Wrapper>
			<TextHeader>
				<FormattedMessage id="entrance" />
			</TextHeader>
			<FormItem label={intl.getMessage("email")}>
				<Input sizeInput={"large"} value={email} disabled={true} />
			</FormItem>
			<FormItem required state={state} label={intl.getMessage("password")} message={state === "error" ? "Неверный пароль" : ""}>
				<>
					<InputPassword
						onKeyDown={onEnter}
						sizeInput={"large"}
						state={state}
						value={password}
						onChangeValue={(value) => {
							setPassword(value);
							setState("default");
						}}
					/>
					<div style={{ position: "relative" }}>
						<ButtonLinkContainer>
							<ButtonStyled
								size="small"
								typeButton="link"
								onClick={() => {
									sendCode();
									nextStage(4);
								}}>
								<FormattedMessage id="forgotYourPassword" />
							</ButtonStyled>
						</ButtonLinkContainer>
					</div>
				</>
			</FormItem>

			<ButtonItemContainer>
				<Button size="large" typeButton="primary" onClick={loginToAccount}>
					<FormattedMessage id="further" />
				</Button>
			</ButtonItemContainer>
		</Wrapper>
	);
};

export default SecondStageAuth;

const ButtonStyled = styled(Button)`
	font-size: 12px;
`;
const Wrapper = styled.div`
	display: grid;
	gap: 24px;

	input {
		width: 100%;
	}
`;

export const ButtonLinkContainer = styled.div`
	position: absolute;
	bottom: -25px;
	right: -5px;
	@media (max-width: 1560px) {
		bottom: -35px;
	}
`;

const ButtonItemContainer = styled(ButtonContainer)`
	margin-top: 24px;
`;
