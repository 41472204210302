import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as TrashIcon } from "public/Trash.svg";
import { useMutation } from "react-query";
import styled from "styled-components";

import { createContactApi, deleteClientContactApi } from "../../../api/api.clients";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";

import CitizenContact from "./CitizenContact/CitizenContact";

const CitizenPhone = () => {
	const citizenPhoneMap = CitizensDrawerStore.selectedUser.contacts?.filter((v) => !v.isDefault && v.type === "PHONE");

	const createClientContact = useMutation(createContactApi);
	const deleteClientContact = useMutation(deleteClientContactApi);

	const onChange = async (phone: string, description: string) => {
		const dataInputs = CitizensDrawerStore.selectedUser;
		dataInputs.contacts.push({ description, isDefault: false, type: "PHONE", value: phone });
		await createClientContact.mutateAsync({
			body: {
				clientId: dataInputs.id,
				description: description,
				isDefault: false,
				type: "PHONE",
				value: phone,
			},
		});

		queryClient.invalidateQueries([QueriesKeys.clients]);
	};

	const deletePhone = async (id: string) => {
		await deleteClientContact.mutateAsync({ path: { id } });

		queryClient.invalidateQueries([QueriesKeys.clients]);
	};

	return (
		<CitizenContact
			title={"Номера телефонов"}
			contactsCollection={citizenPhoneMap}
			onSave={onChange}
			onDeleteContact={deletePhone}
			modalTitle={"Добавить номер телефона"}
			modalTitlePlaceholder="+7 000 000-00-00"
			modalTitleLabel="Телефон"
			modalDescriptionPlaceholder="Например: телефон собственника"
			modalTitleMask="+7 (999) 999 99 99"
			buttonText={"Добавить номер телефона"}
			type="PHONE"
		/>
	);
};

export default CitizenPhone;

const TrashIconContainer = styled(TrashIcon)`
	cursor: pointer;
`;
