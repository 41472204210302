import { makeAutoObservable } from "mobx";

export type TPollsEditTabsVariants = "questions" | "answers";

class PollsItemTabsStore {
	activeTab: TPollsEditTabsVariants = "questions";

	constructor() {
		makeAutoObservable(this);
	}

	setActiveTab = (tabKey: TPollsEditTabsVariants) => {
		this.activeTab = tabKey;
	};

	dispose = () => {
		this.activeTab = "questions";
	};
}

export default new PollsItemTabsStore();
