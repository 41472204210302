import { makeAutoObservable } from "mobx";

export type TPeriodTypes = "week" | "month" | "year" | "custom";

class ReceiptsPeriodStore {
	selectedPeriodType: TPeriodTypes = "week";

	constructor() {
		makeAutoObservable(this);
	}

	setSelectedPeriodType = (type: TPeriodTypes) => {
		this.selectedPeriodType = type;
	};

	dispose = () => {
		this.selectedPeriodType = "week";
	};
}

export default new ReceiptsPeriodStore();
