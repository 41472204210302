import { FC } from "react";
import Skeleton from "react-loading-skeleton";

interface IAlertSkeleton {}

const AlertSkeleton: FC<IAlertSkeleton> = (props) => {
	return <Skeleton height={63} />;
};

export default AlertSkeleton;
