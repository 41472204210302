import apiClient from "api/api.client";
import { IRequestUser } from "interfaces/IRequest";

export type TCancelRequestApi = (args: { path: { requestId: string }; body: { comment: string } }) => Promise<void>;

export const cancelRequestApi: TCancelRequestApi = async (args) => {
	try {
		const res = await apiClient.post(`requests/v1/requests/${args.path.requestId}/cancel`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TApproveRequestApi = (args: { body: { requestId: string; baseClientId: string } }) => Promise<IRequestUser>;

export const approveRequestApi: TApproveRequestApi = async (args) => {
	try {
		const res = await apiClient.post(`api-gateway/v1/requests/approve`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TRestoreRequestApi = (args: { path: { requestId: string } }) => Promise<IRequestUser>;

export const restoreRequestApi: TRestoreRequestApi = async (args) => {
	try {
		const res = await apiClient.post(`requests/v1/requests/${args.path.requestId}/restore`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
