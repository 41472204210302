import Tab from "components/ui/Tab/Tab";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import AnnouncementsTabStore, { TTabOptions } from "../Announcements.tab.store";

import { mainSectionsHeader } from "./constants";

const AnnouncementTab = () => (
	<ExclamationTabWrapper>
		<Tab
			activeKey={AnnouncementsTabStore.selectedTab}
			onTabClick={(key) => AnnouncementsTabStore.setSelectedTab(key as TTabOptions)}
			withLine={true}
			paddingItem={"12px 0"}
			style={{ borderBottom: "none" }}
			items={mainSectionsHeader ?? []}
			direction={"horizontal"}
		/>
	</ExclamationTabWrapper>
);

const ExclamationTabWrapper = styled.div`
	margin: 20px;
`;
export default observer(AnnouncementTab);
