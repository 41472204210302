export type TInputType =
	| "seriesBuilding"
	| "numberBuilding"
	| "departmentCodeBuilding"
	| "dateOfIssueBuilding"
	| "expirationDateBuilding"
	| "shareBuilding";

export type TCitizenBuildingDocumentType = "EGRN" | "CERTIFICATE_OF_STATE_REGISTRATION_OF_RIGHT";

export default (inputFieldType: TInputType, typeOfDocument: TCitizenBuildingDocumentType) => {
	if (typeOfDocument === "EGRN") {
		if (inputFieldType === "numberBuilding") {
			return "99/9999/99999999";
		}
	}
	if (typeOfDocument === "CERTIFICATE_OF_STATE_REGISTRATION_OF_RIGHT") {
		if (inputFieldType === "seriesBuilding") {
			return "**-**";
		}
		if (inputFieldType === "numberBuilding") {
			return "999999";
		}
	}

	if (inputFieldType === "shareBuilding") {
		return "9/9";
	}
};
