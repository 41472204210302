import React, { FC } from "react";

import TextHeader from "components/TextHeader";
import { ReactComponent as ExcludeSvg } from "public/exclude.svg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "tap2visit-ui-kit";

interface IPollsHeader {}

const PollsHeader: FC<IPollsHeader> = (props) => {
	const navigate = useNavigate();

	return (
		<Container>
			<TextHeader>Опросы и голосования</TextHeader>
			<Button onClick={() => navigate("/polls/create")} size="medium" typeButton="primary" icon={ExcludeSvg}>
				Создать опрос/голование
			</Button>
		</Container>
	);
};

export default PollsHeader;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
