export enum QueriesKeys {
	"checkCodeFromEmail" = "checkCodeFromEmail",
	"employee" = "employee",
	"clients" = "clients",
	"clientsAll" = "clientsAll",
	"complexes" = "complexes",
	"buildings" = "buildings",
	"buildingsBase" = "buildingsBase",
	"entrances" = "entrances",
	"floors" = "floors",
	"buildingObjects" = "buildingObjects",
	"buildingObjectsPage" = "buildingObjectsPage",
	"aliases" = "aliases",
	"checkAuth" = "checkAuth",
	"offices" = "offices",
	"officeAddress" = "officeAddress",
	"user" = "user",
	"requests" = "requests",
	"roles" = "roles",
	"announcements" = "announcements",
	"announcement" = "announcement",
	"countOfEmergency" = "countOfEmergency",
	"idOrganization" = "idOrganization",
	"downloadFile" = "downloadFile",
	"comment" = "comment",
	"realEstateComments" = "realEstateComments",
	"citizenBuildingObjectsIds" = "citizenBuildingObjectsIds",
	"citizenBuildingObjects" = "citizenBuildingObjects",
	"listAddresses" = "listAddresses",
	"buildingObjectClients" = "buildingObjectClients",
	"buildingObjectClientsDetail" = "buildingObjectClients",
	"clientsDocuments" = "clientsDocuments",
	"advancedComplexes" = "advancedComplexes",
	"advancedEntrances" = "advancedEntrances",
	"advancedBuildings" = "advancedBuildings",
	"advancedFloors" = "advancedFloors",

	"polls" = "polls",
	"poll" = "poll",
	"pollAnswers" = "pollAnswers",
	"pollTemplates" = "pollTemplates",

	"payments" = "payments",
	"paymentsStatistics" = "paymentsStatistics",
}
