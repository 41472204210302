import { isVisibleFunctionality } from "constants/constants.common";

import React, { FC } from "react";

import CitizensHeader from "pages/citizens/Citizens.header";
import EmployeeHeader from "pages/employee/Employee.header";
import PollsDetailHeader from "pages/polls/components/PollsDetail.header";
import PollsHeader from "pages/polls/Polls/Polls.header";
import PollsViewHeader from "pages/polls/PollsView/PollsView.header";
import ReceiptsHeader from "pages/receipts/components/Receipts.header";
import { СountersHeader } from "pages/сounters/СountersHeader";

import AccessRequestHeader from "../pages/accessRequest/AccessRequest.header";
import AnnouncementCreateHeader from "../pages/announcemets/components/AnnouncementCreateHeader";
import ExclamationHeader from "../pages/announcemets/components/AnnouncementHeader";
import BuildingsHeader from "../pages/buildings/Buildings.header";
import BuildingsEntranceHeader from "../pages/buildings/BuildingsEntrance.header";
import BuildingsHouseHeader from "../pages/buildings/BuildingsHouse.header";
import ProfileHeader from "../pages/profileUK/components/ProfileHeader";

interface IRoutesHeader {}

const RoutesHeader: FC<IRoutesHeader> = (props) => {
	if (location.pathname.startsWith("/announcement/edit")) {
		return <AnnouncementCreateHeader mode="edit" />;
	}

	if (location.pathname.startsWith("/polls/edit") || location.pathname.startsWith("/polls/create")) {
		if (isVisibleFunctionality) {
			return <PollsDetailHeader />;
		}
	}

	if (location.pathname.startsWith("/polls/view")) {
		if (isVisibleFunctionality) {
			return <PollsViewHeader />;
		}
	}

	switch (location.pathname) {
		case "/buildings":
			if (isVisibleFunctionality) {
				return <BuildingsHeader />;
			}
			break;
		case "/employee":
			return <EmployeeHeader />;
			break;
		case "/profile":
		case "/profile/edit":
			if (isVisibleFunctionality) {
				return <ProfileHeader />;
			}
			break;
		case "/citizens":
			return <CitizensHeader />;
		case "/access-request":
			if (isVisibleFunctionality) {
				return <AccessRequestHeader />;
			}
			break;
		case "/announcement":
			if (isVisibleFunctionality) {
				return <ExclamationHeader />;
			}
			break;
		case "/announcement/create":
			if (isVisibleFunctionality) {
				return <AnnouncementCreateHeader mode="create" />;
			}
			break;
		case "/polls":
			if (isVisibleFunctionality) {
				return <PollsHeader />;
			}
			break;
		case "/receipts":
			return <ReceiptsHeader />;
		case "/counters":
			return <СountersHeader />;
	}

	if (isVisibleFunctionality) {
		if (location.pathname.includes("/buildings/house")) return <BuildingsHouseHeader />;
		if (location.pathname.includes("/buildings/entrance")) return <BuildingsEntranceHeader />;
		if (location.pathname.includes("/buildings/floor")) return <BuildingsEntranceHeader />;
	}

	return <></>;
};

export default RoutesHeader;
