import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import { createPortal } from "react-dom";
import { Modal } from "tap2visit-ui-kit";

import DialogAreYouSureCancelEditOfficeAddressStore from "../store/DialogAreYouSureCancelEditOfficeAddress.store";

interface IPageInfoDialogAreYouSureCancelEditOffice {
	onContinue?: () => void;
}

const PageInfoDialogAreYouSureCancelEditOffice: FC<IPageInfoDialogAreYouSureCancelEditOffice> = (props) => {
	const onCancel = () => {
		DialogAreYouSureCancelEditOfficeAddressStore.setIsVisible(false);
		DialogAreYouSureCancelEditOfficeAddressStore.setCallbackAfterExitEditMode(undefined);
	};

	const onContinue = async () => {
		DialogAreYouSureCancelEditOfficeAddressStore.setIsVisible(false);
		props.onContinue?.();
		DialogAreYouSureCancelEditOfficeAddressStore.callbackAfterExiting?.();
		DialogAreYouSureCancelEditOfficeAddressStore.setCallbackAfterExitEditMode(undefined);
	};

	return (
		<>
			{DialogAreYouSureCancelEditOfficeAddressStore.isVisible &&
				createPortal(
					<Modal
						okText="Продолжить"
						onCancel={onCancel}
						onOk={onContinue}
						onExit={onCancel}
						cancelText="Отменить"
						status="delete"
						title="Закрыть редактор?">
						Не сохраненные изменения будут потеряны
					</Modal>,
					document.getElementById("modal"),
				)}
		</>
	);
};

export default observer(PageInfoDialogAreYouSureCancelEditOffice);
