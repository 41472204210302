import { isVisibleFunctionality } from "constants/constants.common";

import React from "react";

import { observer } from "mobx-react-lite";
import Citizens from "pages/citizens/Citizens";
import Employee from "pages/employee/Employee";
import Polls from "pages/polls/Polls/Polls";
import PollsEdit from "pages/polls/PollsEdit/PollsEdit";
import PollsView from "pages/polls/PollsView/PollsView";
import Receipts from "pages/receipts/Receipts";
import { Counters } from "pages/сounters/Сounters";
import { ReactComponent as Arrow } from "public/arrow_right.svg";
import { useQuery } from "react-query";
import { Route } from "react-router-dom";
import { colors } from "tap2visit-ui-kit";

import { getProfiles } from "../api/office/api.office";
import { QueriesKeys } from "../interfaces/queriesKeys";
import AccessRequest from "../pages/accessRequest/AccessRequest";
import Announcement from "../pages/announcemets/Announcement";
import AnnouncementChange from "../pages/announcemets/AnnouncementChange";
import AuthorizationPage from "../pages/authorization/Authorization";
import Buildings from "../pages/buildings/Buildings";
import BuildingsEntrance from "../pages/buildings/BuildingsEntrance";
import BuildingsFloor from "../pages/buildings/BuildingsFloor";
import BuildingsHouse from "../pages/buildings/BuildingsHouse";
import ProfileUKPage from "../pages/profileUK/ProfileUK";
import AuthStore from "../store/Auth.Store";
import UKStore from "../store/UK.Store";

import ExtendedRoutes from "./extendedRoutes";
import WithNavbar from "./withSideBar";

const MainRouter = observer(() => {
	useQuery({
		queryFn: () => getProfiles(),
		enabled: AuthStore.checkAuth(),
		queryKey: [QueriesKeys.checkAuth],
		onSuccess: (res) => {
			const array = res.map((item) => ({
				title: item.name,
				slot: <Arrow />,
				id: item.id,
				managingOrganizationId: item.managingOrganizationId,
				leftIcon: {
					firstAltLetter: item.name[0],
					secondAltLetter: "",
					type: "avatar",
				},
			}));
			UKStore.setCompanies(array);
		},
	});

	return (
		<div style={{ background: colors.surfaceNeutralBg2, minHeight: "100vh" }}>
			<ExtendedRoutes>
				<Route Component={AuthorizationPage} path="/login" />
			</ExtendedRoutes>
			<WithNavbar>
				<>
					{isVisibleFunctionality && <Route element={<ProfileUKPage />} path="profile" />}
					<Route path="employee" element={<Employee />} />
					{isVisibleFunctionality && (
						<>
							<Route path="citizens" element={<Citizens />} />
							<Route path="access-request" element={<AccessRequest />} />
							<Route path="buildings">
								<Route index element={<Buildings />} />
								<Route path="house/:id" element={<BuildingsHouse />} />
								<Route path="entrance/:id" element={<BuildingsEntrance />} />
								<Route path="floor/:id" element={<BuildingsFloor />} />
							</Route>
							<Route path="announcement">
								<Route index element={<Announcement />} />
								<Route path="create" element={<AnnouncementChange />} />
								<Route path="create/:id" element={<AnnouncementChange />} />
								<Route path="edit/:id" element={<AnnouncementChange />} />
							</Route>
							<Route path="polls">
								<Route index element={<Polls />} />
								<Route path="edit/:id" element={<PollsEdit />} />
								<Route path="view/:id" element={<PollsView />} />
								<Route path="create" element={<PollsEdit />} />
							</Route>
						</>
					)}

					<Route path="receipts">
						<Route index element={<Receipts />} />
					</Route>
					<Route path="counters">
						<Route index element={<Counters />} />
					</Route>
				</>
			</WithNavbar>
		</div>
	);
});

export default MainRouter;
