import { IClient } from "interfaces/IClient";
import { makeAutoObservable } from "mobx";

class ModalConfirmEditCitizenStore {
	isVisible = false;
	clickedUser: IClient = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	visible = (user: IClient) => {
		this.isVisible = true;
		this.clickedUser = user;
	};

	close = () => {
		this.clickedUser = undefined;
		this.isVisible = false;
	};
}

export default new ModalConfirmEditCitizenStore();
