import React, { useState } from "react";

import { Space } from "antd";
import { updateBuildingObjectApi } from "api/api.realEstate";
import { Spacer } from "components/layout/Spacer";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as EditIcon } from "public/EditCitizen.svg";
import { useMutation } from "react-query";
import { Alert, Button } from "tap2visit-ui-kit";

import UserEditInput from "../../../components/Citizens/components/UserEdit.input";
import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";
import {
	CitizenPersonalEdit,
	CitizenPersonalEditFooter,
	CitizenPersonalEditWrapper,
	CitizenRealtyDivider,
	CitizenRealtySpace,
	CitizenRealtyWrapper,
	PersonalInformationWrapper,
} from "../../../components/Citizens/style/Citizen.styled";
import { getBuildingObjectType, getTextLivingArea } from "../../../utils/getBuildingObjectType";

import {
	CitizenDrawerInformationBlockDescription,
	CitizenDrawerInformationBlockHeader,
	CitizenDrawerTextHeaderBlock,
} from "./CitizenTexts";

const CitizenRealtyInformation = () => {
	const [edit, setEdit] = useState(false);

	const [totalArea, setTotalArea] = React.useState(CitizenBuildingObjectStore.selectedBuilding?.totalArea);
	const [livingArea, setLivingArea] = React.useState(CitizenBuildingObjectStore.selectedBuilding?.livingArea);
	const [terraceArea, setTerraceAre] = React.useState(CitizenBuildingObjectStore.selectedBuilding?.terraceArea);

	const updateBuildingObject = useMutation(updateBuildingObjectApi);

	const citizenPersonalInformation = [
		{
			label: "Адрес помещения",
			as: "textarea",
			required: true,
			value: CitizenBuildingObjectStore.selectedBuildingAddress,
			disabled: true,
		},
		{
			label: "Количество комнат",
			as: "input",
			required: true,
			value: CitizenBuildingObjectStore.selectedBuilding?.countOfRooms,
			message: "При изменении предоставьте подтверждающий документ",
			disabled: true,
		},
		{
			label: "Этаж",
			as: "input",
			required: true,
			value: CitizenBuildingObjectStore.selectedBuilding?.floorNumber,
			disabled: true,
		},
		{
			label: "Общая площадь, м²",
			as: "input",
			value: totalArea,
			message: "При изменении предоставьте подтверждающий документ",
			onChange: setTotalArea,
		},
		{
			label: "Жилая площадь/площадь БТИ, м² т",
			as: "input",
			required: true,
			value: livingArea,
			message: "При изменении предоставьте подтверждающий документ",
			onChange: setLivingArea,
		},
		{
			label: "Балкон, м²",
			as: "input",
			required: true,
			value: terraceArea,
			onChange: setTerraceAre,
		},
		{
			label: "Тип помещения",
			as: "input",
			value: getTextLivingArea(CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace),
			message: "При изменении предоставьте подтверждающий документ",
			disabled: true,
		},
		{
			label: "Тип недвижимости",
			as: "input",
			required: true,
			value: getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType),
			disabled: true,
		},
	];

	const onSave = async () => {
		await updateBuildingObject.mutateAsync({
			body: {
				flatType: CitizenBuildingObjectStore.selectedBuilding.flatType,
				livingArea: livingArea,
				terraceArea: terraceArea,
				totalArea: totalArea,
			},
			path: {
				id: CitizenBuildingObjectStore.selectedBuilding.id,
			},
		});
		setEdit(false);
		queryClient.invalidateQueries([QueriesKeys.citizenBuildingObjects]);
	};

	return (
		<>
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={"Информация о квартире"} />
				{/* TEMPORARY HIDDEN */}
				{/* <Button onClick={() => setEdit((status) => !status)} shape="square" typeButton="secondary" icon={EditIcon} /> */}
			</PersonalInformationWrapper>
			{edit ? (
				<>
					<Spacer px={8} />

					<CitizenPersonalEditWrapper>
						<CitizenPersonalEdit>
							<Alert
								visible
								withIcon
								closeIcon={false}
								variant="warning"
								title="При изменении типа помещения, общей площади, жилой площади предоставьте подтверждающие документы!"
							/>

							{/* // TODO 😔 */}
							{citizenPersonalInformation.map((v: any) => (
								<UserEditInput
									label={v.label}
									required={v.required}
									value={v.value}
									as={v.as}
									onChange={v.onChange}
									disabled={v.disabled}
									message={v.message}
								/>
							))}
						</CitizenPersonalEdit>

						<CitizenPersonalEditFooter>
							<Button typeButton="secondary" onClick={() => setEdit(false)}>
								Отменить
							</Button>
							<Button onClick={onSave}>Сохранить</Button>
						</CitizenPersonalEditFooter>
					</CitizenPersonalEditWrapper>
				</>
			) : (
				<CitizenRealtySpace direction="vertical">
					<CitizenRealtyWrapper>
						{citizenPersonalInformation.map((v) => (
							<>
								<Space size={1} direction="vertical" style={{ padding: "12px" }}>
									<CitizenDrawerInformationBlockHeader text={v.label} />
									<CitizenDrawerInformationBlockDescription text={v.value} />
								</Space>
								<CitizenRealtyDivider />
							</>
						))}
					</CitizenRealtyWrapper>
				</CitizenRealtySpace>
			)}
		</>
	);
};

export default CitizenRealtyInformation;
