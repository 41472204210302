import React, { useEffect, useState } from "react";

import TextHeader from "components/TextHeader";
import Input from "components/ui/Input/Input";
import InputPassword from "components/ui/Input/InputPassword";
import { QueriesKeys } from "interfaces/queriesKeys";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Button, FormItem, Text } from "tap2visit-ui-kit";
import { intl } from "utils/translate";

import { checkCodeFromEmailApi, sendCodeOnEmailApi } from "../../../api/api.auth";
import { TAuthProps } from "../Authorization";

import { ButtonLinkContainer } from "./SecondStageAuth";

const RegistrationStage = ({ email, nextStage, setCode }: TAuthProps) => {
	const [codeLocal, setCodeLocal] = useState("");
	const [timer, setTimer] = useState(59);
	const [isErrorCode, setIsErrorCode] = useState(false);
	const code = codeLocal.replace("-", "");

	useQuery({
		queryFn: () => checkCodeFromEmailApi({ email, code: codeLocal.replace("-", "") }),
		queryKey: [QueriesKeys.checkCodeFromEmail, code],
		enabled: code.length === 4,
		onSuccess: (res) => {
			if (res.value) {
				setCode(codeLocal);
				nextStage(3);
			} else {
				setIsErrorCode(true);
			}
		},
	});

	const sendCodeOnEmail = useMutation(sendCodeOnEmailApi);

	useEffect(() => {
		if (timer === 0) return;
		const timerID = setInterval(() => {
			setTimer(timer - 1);
		}, 1000);

		return () => clearInterval(timerID);
	}, [timer]);

	const resendCode = async () => {
		sendCodeOnEmail.mutateAsync({ email });
		setTimer(59);
		setCodeLocal("");
		setCode("");
		setIsErrorCode(false);
	};

	const sendingCode = (value: string) => {
		setIsErrorCode(false);
		setCodeLocal(value);
	};

	return (
		<>
			<TextHeader>Войти</TextHeader>
			<FormItem label={intl.getMessage("email")}>
				<Input sizeInput={"large"} value={email} disabled={true} />
			</FormItem>
			<FormItem
				message={isErrorCode ? "Код некорректен" : ""}
				state={isErrorCode ? "error" : "default"}
				label={intl.getMessage("codeFromEmail")}
				labelTextStyle={{ fontSize: "12px" }}>
				<>
					<InputPassword
						sizeInput={"large"}
						placeholder={"00-00"}
						value={codeLocal}
						onChangeValue={sendingCode}
						mask="99-99"
						maskChair=""
					/>
					<div style={{ position: "relative" }}>
						<ButtonContainer isErrorState={isErrorCode}>
							<Button size="small" typeButton="link" disabled={!!timer} onClick={resendCode}>
								{`${intl.getMessage("codeRequestAgain")} ${timer ? `через ${timer} сек.` : ""}`}
							</Button>
						</ButtonContainer>
					</div>
				</>
			</FormItem>
		</>
	);
};

export default RegistrationStage;

const ButtonContainer = styled.div<{ isErrorState: boolean }>`
	position: absolute;
	right: 0;
	top: ${(props) => (props.isErrorState ? "14px" : "0")};

	@media (max-width: 1640px) {
		top: ${(props) => (props.isErrorState ? "14px" : "0")};
	}
`;
