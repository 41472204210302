import { IBuildingObject } from "interfaces/IBuilding";
import { makeAutoObservable } from "mobx";

class CitizenBuildingObjectStore {
	selectedBuilding: IBuildingObject | undefined = undefined;
	selectedBuildingAddress: string | undefined = undefined;
	isOpenBuildingDrawer = false;
	preselectedRole: "TRUSTED_PERSON" | "TENANT" = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	openDrawer = (buildingObject: IBuildingObject, address: string) => {
		this.isOpenBuildingDrawer = true;
		this.selectedBuilding = buildingObject;
		this.selectedBuildingAddress = address;
	};

	closeDrawer = () => {
		this.isOpenBuildingDrawer = false;
		this.selectedBuilding = undefined;
		this.selectedBuildingAddress = undefined;
	};

	setPreselectedRole = (role?: "TRUSTED_PERSON" | "TENANT") => {
		this.preselectedRole = role;
	};
}

export default new CitizenBuildingObjectStore();
