import React from "react";

import { Space } from "antd";
import { observer } from "mobx-react-lite";
import { Avatar, Text } from "tap2visit-ui-kit";
import last from "utils/last";

import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";

const CitizenHeaderInformation = () => (
	<Space size={20}>
		<Avatar text={"502"} size="64" />
		<Text h="h3">{CitizenBuildingObjectStore.selectedBuildingAddress} </Text>
	</Space>
);

export default observer(CitizenHeaderInformation);
