import React, { FC, useState } from "react";

import { ReactComponent as BarrierIcon } from "public/BarrierIcon.svg";
import { ReactComponent as BulletedList } from "public/BulletedList.svg";
import { ReactComponent as ChatIcon } from "public/Chat.svg";
import { ReactComponent as StopWatch } from "public/StopWatch.svg";
import { ReactComponent as UserDeleteIcon } from "public/UserDeleteIcon.svg";
import { ReactComponent as VideoCameraIcon } from "public/VideoCameraIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { BoldPlaydown } from "t2v-icons";
import { Button, DropDown, IDropDownCurrentItem, Space, colors, getUid } from "tap2visit-ui-kit";

import { IFloors, IFloorsBuildingObjects } from "../../interfaces/IComplexes";

import BuildingsStore from "./Buildings.store";
import { getFlatType } from "./utils/getFlatType";

interface IBuildingsFloorCard {
	item: IFloors;
}

const DROPDOWN_BUTTON_ID = getUid("dropdown_floor_button");

const BuildingsFloorCard: FC<IBuildingsFloorCard> = ({ item }) => {
	const [open, setOpen] = useState(false);
	const [openBuildingObjects, setOpenBuildingObjects] = useState(false);
	const [itemId, setItemId] = useState("");
	const navigate = useNavigate();
	const { id } = useParams();
	const dropdownItems = [
		{
			meta: {
				id: "1",
			},
			icon: ChatIcon,
			text: "Дать объявление",
		},
		{
			meta: {
				id: "2",
			},
			icon: BulletedList,
			text: "Список жителей",
		},
		{
			meta: {
				id: "3",
			},
			icon: UserDeleteIcon,
			text: "Список должников",
		},
		{
			meta: {
				id: "4",
			},
			icon: StopWatch,
			text: "Общедомовые счетчики",
		},
		{
			meta: {
				id: "5",
			},
			icon: VideoCameraIcon,
			text: "Камеры",
		},
		{
			meta: {
				id: "6",
			},
			icon: BarrierIcon,
			text: "Шлагбаумы",
		},
	];

	const onClickDropdown = (itemDropdown: IDropDownCurrentItem) => {
		if (itemDropdown.meta.id === "1") {
			navigate("/announcement/create", {
				state: { selectedId: BuildingsStore.selectedId, buildingId: BuildingsStore.entranceId, entranceId: id, floorId: item.id },
			});
		}
		if (itemDropdown.meta.id === "2") {
			navigate("/citizens", {
				state: { selectedId: BuildingsStore.selectedId, buildingId: BuildingsStore.entranceId, entranceId: id, floorId: item.id },
			});
		}
	};

	const dropdownBuildingObjectsItems = (item: IFloorsBuildingObjects) => {
		if (item.flatType)
			return [
				{
					meta: {
						id: "1",
					},
					text: `${getFlatType.get(item.flatType)} ${item.livingArea ? `${item.livingArea} м²` : ""}`,
				},
				{
					meta: {
						id: "1",
					},
					text: (
						<DropDownText>
							На счету: <span>{item.debt}₽</span>
						</DropDownText>
					),
				},
			];
		return [
			{
				meta: {
					id: "1",
				},
				text: (
					<DropDownText>
						На счету: <span>{item.debt}₽</span>
					</DropDownText>
				),
			},
		];
	};

	return (
		<BuildingsFloorCardWrapper>
			<Space align="start">
				<ButtonStyled id={DROPDOWN_BUTTON_ID} onClick={() => setOpen(!open)} typeButton="ghost" shape="square">
					<Space size={2}>
						{String(item.number)}
						<BoldPlaydownStyled />
					</Space>
				</ButtonStyled>
				<DropDown
					style={{ top: "60px" }}
					onClick={(itemDropdown) => onClickDropdown(itemDropdown)}
					onClickOutside={() => setOpen(false)}
					isVisible={open}
					items={dropdownItems}
					outerButtonDropdownId={DROPDOWN_BUTTON_ID}
				/>
				<Space wrap="wrap">
					{item.buildingObjects.map((v) => (
						<div style={{ position: "relative" }}>
							<DropDown
								style={{ top: "60px", width: "125px" }}
								dropDownItemStyle={{ minWidth: "115px" }}
								onClickOutside={() => {
									setOpenBuildingObjects(false);
								}}
								isVisible={openBuildingObjects && itemId === v.id}
								items={dropdownBuildingObjectsItems(v)}
							/>
							{!!v.emergencyAnnouncementCount && <EmergencyAnnouncementCount />}
							<ButtonStyledBuildingObjects
								onClick={() => {
									setOpenBuildingObjects(!open);
									setItemId(v.id);
								}}
								isOwnerExists={v.ownerExists}
								isDebt={Boolean(v.debt)}
								typeButton="secondary"
								shape="square">
								{v.number}
							</ButtonStyledBuildingObjects>
						</div>
					))}
				</Space>
			</Space>
		</BuildingsFloorCardWrapper>
	);
};
const BoldPlaydownStyled = styled(BoldPlaydown)`
	fill: ${colors.iconNeutralDefault}!important;
	font-size: 10px;
`;
const EmergencyAnnouncementCount = styled.div`
	width: 10px;
	height: 10px;
	background: #ca504a;
	border-radius: 100%;
	position: absolute;
	right: -2px;
	top: -3px;
`;
const DropDownText = styled.p`
	color: #12121299;
	span {
		color: #3ea278;
	}
`;
const ButtonStyledBuildingObjects = styled(Button)<{ isDebt?: boolean; isOwnerExists: boolean }>`
	width: 54px;
	height: 54px;
	border: ${({ isOwnerExists }) => {
		if (isOwnerExists) return "none";
	}};
	background: ${({ isDebt, isOwnerExists }) => {
		if (!isOwnerExists) return "transparent";
		if (isDebt) return colors.strokeIconFrameDanger;
		return colors.strokeIconFrameDefault2;
	}};
	&:hover {
		background: ${({ isDebt, isOwnerExists }) => {
			if (!isOwnerExists) return "transparent";
			if (isDebt) return colors.textDangerHover;
			return colors.iconSuccessHover;
		}};
		color: ${({ isOwnerExists }) => {
			if (isOwnerExists) return "black";
		}};
		border: ${({ isOwnerExists }) => {
			if (isOwnerExists) return "none";
		}};
	}
	&:active {
		background: ${({ isDebt, isOwnerExists }) => {
			if (!isOwnerExists) return "transparent";
			if (isDebt) return colors.textDangerPressed;
			return colors.textSuccessPressed;
		}};
		color: ${({ isOwnerExists }) => {
			if (isOwnerExists) return "white";
		}};
		border: ${({ isOwnerExists }) => {
			if (isOwnerExists) return "none";
		}};
	}
`;
const BuildingsFloorCardWrapper = styled.div`
	position: relative;
`;
const ButtonStyled = styled(Button)`
	width: 54px;
	height: 54px;
	color: ${colors.textNeutralPrimary};
	&:hover {
		background: ${colors.surfaceNeutralBg4}!important;
		color: ${colors.textNeutralPrimary}!important;
	}
	&:active {
		background: ${colors.surfaceNeutralBg12}!important;
		color: ${colors.textNeutralPrimary}!important;
	}
`;
export default BuildingsFloorCard;
