export default (isMe: boolean) => {
	const text = {
		title: "",
		description: "",
	};

	text.title = isMe ? "Вы уверены, что хотите изменить собственную роль?" : " Вы уверены, что хотите изменить роль?";
	text.description = isMe
		? "При изменении собственной роли необходимо пройти повторный процесс авторизации"
		: "При изменении роли пользователю необходимо пройти повторный процесс авторизации";

	return text;
};
