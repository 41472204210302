import { TRoleTypes } from "interfaces/IRole";

export default (role: TRoleTypes) => {
	switch (role) {
		case "INTEREST_HOLDER":
			return "Держатель процентной ставки";
		case "OWNER":
			return "Собственник";
		case "PAYER":
			return "Плательщик";
		case "TENANT":
			return "Арендатор";
		case "TRUSTED_PERSON":
			return "Доверенное лицо";
		default:
			return "Неизвестная роль";
	}
};
