import dayjs from "dayjs";
import isToday from "utils/isToday";
import isYesterday from "utils/isYesterday";

export default (date: string) => {
	const dateOfComment = dayjs(date);
	const time = dateOfComment.format("HH:MM:ss");

	if (isToday(date)) {
		return `Сегодня, ${time}`;
	} else if (isYesterday(date)) {
		return "Вчера";
	}

	return dayjs(date).format("DD MMM");
};
