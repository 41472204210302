export default (arr1: string[], arr2: string[]) => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	const stringArr1 = arr1.sort().join();
	const stringArr2 = arr2.sort().join();

	return stringArr1 === stringArr2;
};
