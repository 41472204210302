const isLessThen = (str: string, maxLength: number) => (str?.length ?? 0) <= maxLength;
const isNotEmpty = (str: string) => !!str?.length;
const isEmpty = (str: string) => (str?.length ?? 0) === 0;
const isEquals = (str: string, length: number) => str.length === length;
const isMaskCorrect = (args: { value?: string; mask: string }) => isNotEmpty(args.value) && args.value?.indexOf("_") === -1;

export const validation = {
	isLessThen,
	isNotEmpty,
	isEquals,
	isMaskCorrect,
	isEmpty,
};
