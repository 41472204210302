import { useState } from "react";

import { Space } from "antd";
import Collapse from "components/Collapse/Collapse";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { colors, Drawer } from "tap2visit-ui-kit";

import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";

import CitizenBuildingObjectTruestedClientsInfo from "./CitizenBuildingObjectTruestedClientsInfo";
import CitizenCommentsCollapse from "./CitizenComments.collapse";
import CitizenConfidantModal from "./CitizenConfidantModal";
import CitizenDocumentOwners from "./CitizenDocumentOwners";
import CitizenHeaderInformation from "./CitizenHeaderInformation";
import CitizenOwnersInformation from "./CitizenOwnersInformation";
import CitizenRealtyInformation from "./CitizenRealtyInformation";

interface ICitizenRealtyDrawer {
	open: boolean;
	setOpen: (v: boolean) => void;
}
const CitizenRealtyDrawer = ({ open, setOpen }: ICitizenRealtyDrawer) => {
	const [openCitizenConfidantModal, setOpenCitizenConfidantModal] = useState(false);

	const onClickAddTrustedPerson = () => {
		setOpenCitizenConfidantModal(true);
		CitizenBuildingObjectStore.setPreselectedRole("TRUSTED_PERSON");
	};

	const onClickAddTenant = () => {
		setOpenCitizenConfidantModal(true);
		CitizenBuildingObjectStore.setPreselectedRole("TENANT");
	};

	return (
		<Drawer
			backgroundBody={colors.surfaceNeutralBg4}
			title="Квартира"
			showOk={false}
			showCancel={false}
			width="700px"
			onClose={CitizenBuildingObjectStore.closeDrawer}
			visible={CitizenBuildingObjectStore.isOpenBuildingDrawer}>
			<CitizenConfidantModal open={openCitizenConfidantModal} setOpen={setOpenCitizenConfidantModal} />
			<Space size={30} direction="vertical">
				<CitizenHeaderInformation />

				<CitizenCommentsCollapse type="real-estate" />

				<CitizenOwnersInformation />

				<CitizenDocumentOwners />

				{/* TEMPORARY HIDDEN */}
				{/* <CitizenBuildingObjectTruestedClientsInfo
					title="Доверенные лица собственников"
					roleType="TRUSTED_PERSON"
					setOpenDrawerAddTrustedPerson={onClickAddTrustedPerson}
					buttonText="Добавить доверенное лицо"
				/>

				<CitizenBuildingObjectTruestedClientsInfo
					title="Арендаторы"
					roleType="TENANT"
					setOpenDrawerAddTrustedPerson={onClickAddTenant}
					buttonText="Добавить арендатора"
				/> */}

				<CitizenRealtyInformation />
			</Space>
		</Drawer>
	);
};

const CollapseWrapperComment = styled(Collapse)`
	background: white;
	.ant-collapse-content-box {
		background: ${colors.surfaceNeutralBg2};
	}
`;

const SpaceWrapper = styled(Space)`
	width: 100%;
`;

export default observer(CitizenRealtyDrawer);
