import React, { FC } from "react";

import Collapse from "components/Collapse/Collapse";
import { observer } from "mobx-react-lite";
import { Text, TinyTable } from "tap2visit-ui-kit";
import getRoleTranslate from "utils/getRoleTranslate";

import DrawerStore from "./Drawer.store";
import { formatData } from "./Drawer.utils";

interface IDrawerCollapse {}

const DrawerCollapse: FC<IDrawerCollapse> = (props) => {
	const currentRoles = DrawerStore.selectedAnnouncement.roleNames.map((role) => [getRoleTranslate(role)]);

	const addresses = [DrawerStore.selectedAnnouncement.complexAddresses.map((address) => address)];

	const generalInformation = [
		["Тип", "Объявление"],
		["Отправитель", DrawerStore.selectedAnnouncement.fullName],
		["Дата создания", formatData(DrawerStore.selectedAnnouncement.createDateTime)],
		["Начало публикации", formatData(DrawerStore.selectedAnnouncement.startDateTime)],
		["Конец публикации", formatData(DrawerStore.selectedAnnouncement.endDateTime)],
	];

	const collapseItems = [
		{
			key: "1",
			label: <Text type={"base-medium"}>Отправлено ролям</Text>,
			children: <TinyTable containerWidth="100%" rows={currentRoles} />,
		},
		{
			key: "2",
			label: <Text type={"base-medium"}>Адресаты</Text>,
			children: <TinyTable containerWidth="100%" rows={addresses} />,
		},
		{
			key: "3",
			label: <Text type={"base-medium"}>Общая информация</Text>,
			children: <TinyTable containerWidth="100%" rows={generalInformation} />,
		},
	];

	return <Collapse items={collapseItems} />;
};

export default observer(DrawerCollapse);
