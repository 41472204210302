export const LIGHT_THEME = {
	absolute_100: "#FFF",
	absolute_200: "#EEF0F3",
	absolute_300: "#DDDFE2",
	absolute_400: "#BABEC4",
	absolute_500: "#92969C",
	absolute_600: "#838890",
	absolute_700: "#6C727B",
	absolute_800: "#42474F",
	absolute_900: "#24272C",
	absolute_1000: "#000",

	yellow_100: "#FFFCF3",
	yellow_200: "#FFF1C2",
	yellow_300: "#FFDA58",
	yellow_400: "#FFC600",
	yellow_500: "#E0AE00",

	green_100: "#EEFFF2",
	green_200: "#DBFFE4",
	green_300: "#93E6A8",
	green_400: "#26B94B",
	green_500: "#008F24",

	red_100: "#FFF5F7",
	red_200: "#FFE0E2",
	red_300: "#FFAFAB",
	red_400: "#FF3535",
	red_500: "#C70000",
	red_600: "#9E0000",

	blue_100: "#F7FAFF",
	blue_200: "#DFEAFF",
	blue_300: "#B8D0FF",
	blue_400: "#357AFF",
	blue_500: "#0049D7",
	blue_600: "#0039A6",

	focus: "#F845FC",
	background: "#F9FBFF",
};
