import { makeAutoObservable } from "mobx";

class AnnouncementModeStore {
	checkEmergencyButtonClick = false;
	isOpenCreateTemplateDrawer = false;

	constructor() {
		makeAutoObservable(this);
	}

	setCheckEmergencyButtonClick = (data: boolean) => {
		this.checkEmergencyButtonClick = data;
	};

	setIsOpenCreateTemplateDrawer = (status: boolean) => {
		this.isOpenCreateTemplateDrawer = status;
	};

	dispose = () => {
		this.checkEmergencyButtonClick = false;
		this.isOpenCreateTemplateDrawer = false;
	};
}

export default new AnnouncementModeStore();
