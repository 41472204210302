import {
	IVoteAnswersDto,
	IVoteCreateDto,
	IVoteDto,
	IVotePage,
	IVoteTemplatePage,
	IVoteTemplatePageParams,
	IVoteUpdateDto,
	TVoteStatuses,
	TVoteType,
} from "interfaces/IVote";
import { isMockEnabled } from "mocks/mocks.constants";
import { pollAnswers } from "mocks/mocks.votes";
import { joinArrParams } from "utils/joinParams";

import apiClient from "./api.client";

export type TGetListOfVoterApi = (args: {
	params: {
		template: boolean;
		types: TVoteType[];
		statuses: TVoteStatuses[];
		textSearch?: string;
		createdBySearch?: string;
		createDateTimeEnd?: string;
		page: number;
		size: number;
		sort?: string[];
	};
}) => Promise<IVotePage>;

export const getListOfVotesApi: TGetListOfVoterApi = async (args) => {
	try {
		if (isMockEnabled) {
			// TODO create a mock data
			return;
		}
		const res = await apiClient.get("/votes/v1/votes", {
			params: {
				...args.params,
				types: joinArrParams(args?.params.types),
				statuses: joinArrParams(args?.params?.statuses),
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreatePollApi = (args: { body: IVoteCreateDto }) => Promise<IVoteDto>;

export const createPollApi: TCreatePollApi = async (args) => {
	try {
		const res = await apiClient.post("/votes/v1/votes", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetPollByIdApi = (args: { path: { id: string } }) => Promise<IVoteDto>;

export const getPollByIdApi: TGetPollByIdApi = async (args) => {
	try {
		const res = await apiClient.get(`/votes/v1/votes/${args.path.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUpdatePollApi = (args: { path: { id: string }; body: IVoteUpdateDto }) => Promise<IVoteDto>;

export const updatePollApi: TUpdatePollApi = async (args) => {
	try {
		const res = await apiClient.patch(`/votes/v1/votes/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TArchivePollApi = (args: { body: { pollId: string } }) => Promise<void>;

export const archivePollApi: TArchivePollApi = async (args) => {
	try {
		const res = await apiClient.post(`/votes/v1/votes/${args.body.pollId}/archive`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeletePollApi = (args: { body: { pollId: string } }) => Promise<void>;

export const deletePollApi: TDeletePollApi = async (args) => {
	try {
		const res = await apiClient.delete(`/votes/v1/votes/${args.body.pollId}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetPollAnswersApi = (args: { path: { pollId: string } }) => Promise<IVoteAnswersDto>;

export const getPollAnswersApi: TGetPollAnswersApi = async (args) => {
	try {
		if (isMockEnabled) {
			return pollAnswers;
		}

		const res = await apiClient.get(`/votes/v1/votes/${args.path.pollId}/answers`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetVotesTempatesPageApi = (args: { params: IVoteTemplatePageParams }) => Promise<IVoteTemplatePage>;

export const getVotesTempatesPageApi: TGetVotesTempatesPageApi = async (args) => {
	try {
		const { textSearch, sort, types, ...otherParams } = args.params;
		const params: Record<string, any> = { ...otherParams };
		let path = "/votes/v1/votes/templates/page";
		let sortParams = "?";

		if (textSearch) {
			params.textSearch = textSearch;
		}

		if (types?.length) {
			params.types = types.join(",");
		}

		if (sort?.length) {
			sort.forEach((sort, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${sort.key},${sort.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
