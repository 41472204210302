import React from "react";

import { getPollsPageApi } from "api/api.gateway";
import { archivePollApi, deletePollApi, getVotesTempatesPageApi } from "api/api.votes";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import dayjs from "dayjs";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import { TSortingDirection } from "interfaces/ISorting";
import { TVoteStatuses, TVoteType } from "interfaces/IVote";
import { QueriesKeys } from "interfaces/queriesKeys";
import PollsConfirmStore from "pages/polls/Polls/store/Polls.confirm.store";
import PollsEditTabsStore from "pages/polls/store/PollsItem.tabs.store";
import { ReactComponent as CopySvg } from "public/copy.svg";
import { ReactComponent as DiagramSvg } from "public/diagram.svg";
import { ReactComponent as StopSvg } from "public/stop.svg";
import { ReactComponent as TrashSvg } from "public/Trash.svg";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Text, TTableColumn } from "tap2visit-ui-kit";

import PollsTabsStore from "../../../store/Polls.tabs.store";

import getPollText from "./utils/getPollText";

const useTableData = () => {
	const paginationData = useTablePaginationLogic();

	const navigate = useNavigate();
	const tableFilterStore = useTableFilterContext();
	const [selectedTypes, setSelectedTypes] = React.useState<TVoteType[]>([]);
	const [typeSortingDirection, setTypeSortingDirection] = React.useState<TSortingDirection>();
	const [nameSortDirection, setNameSortDirection] = React.useState<TSortingDirection>();

	const [creatorNameSearch, setCreatorNameSearch] = React.useState("");
	const [nameSearch, setNameSearch] = React.useState("");

	const [templateNameSearch, setTemplateNameSearch] = React.useState("");
	const [selectedTemplateTypes, setSelectedTemplateTypes] = React.useState<TVoteType[]>([]);
	const [templateTypeSortingDirection, setTemplateTypeSortingDirection] = React.useState<TSortingDirection>();
	const [templateNameSortDirection, setTemplateNameSortDirection] = React.useState<TSortingDirection>();

	const pollsTemplates = useQuery({
		queryFn: () => {
			let types: TVoteType[] = ["POLL", "VOTE"];
			const sort = [];

			if (selectedTemplateTypes.length === 1) {
				types = selectedTemplateTypes;
			}

			if (templateTypeSortingDirection) {
				sort.push({ key: "type", direction: templateTypeSortingDirection });
			}

			if (templateNameSortDirection) {
				sort.push({ key: "name", direction: templateNameSortDirection });
			}

			return getVotesTempatesPageApi({
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,

					types: types,
					textSearch: templateNameSearch,

					sort,
				},
			});
		},
		queryKey: [
			QueriesKeys.pollTemplates,
			templateNameSearch,
			selectedTemplateTypes,
			templateTypeSortingDirection,
			templateNameSortDirection,

			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
		enabled: PollsTabsStore.activeTab === "template",
	});

	const polls = useQuery({
		queryFn: () => {
			const statuses: TVoteStatuses[] = [];
			let types: TVoteType[] = ["POLL", "VOTE"];
			const sort = [];

			if (PollsTabsStore.activeTab === "active") {
				if (!PollsTabsStore.onlyActiveStatus) {
					statuses.push("NEW");
				}
				statuses.push("ACTIVE");
			}

			if (PollsTabsStore.activeTab === "archive") {
				statuses.push("ARCHIVE");
			}

			if (selectedTypes.length === 1) {
				types = selectedTypes;
			}

			if (typeSortingDirection) {
				sort.push({ key: "type", direction: typeSortingDirection });
			}

			if (nameSortDirection) {
				sort.push({ key: "name", direction: nameSortDirection });
			}

			return getPollsPageApi({
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,
					types: types,
					statuses: statuses,
					sort,
					nameSearch: nameSearch,
					fullName: creatorNameSearch,

					complexIds: tableFilterStore.selectedComplexIds,
					buildingIds: tableFilterStore.selectedBuildingsIds,
					entranceIds: tableFilterStore.selectedEntrancesIds,
					floorIds: tableFilterStore.selectedFloorsIds,
					buildingObjectIds: tableFilterStore.selectedBuildingObjectIds,
				},
			});
		},
		queryKey: [
			QueriesKeys.polls,
			PollsTabsStore.activeTab,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			tableFilterStore.selectedComplexIds,
			tableFilterStore.selectedBuildingsIds,
			tableFilterStore.selectedEntrancesIds,
			tableFilterStore.selectedComplexIds,
			tableFilterStore.selectedBuildingObjectIds,

			PollsTabsStore.onlyActiveStatus,
			selectedTypes,
			typeSortingDirection,
			nameSearch,
			creatorNameSearch,
		],
		enabled: PollsTabsStore.activeTab !== "template",
	});

	React.useEffect(() => {
		paginationData.setClientsCurrentPage(1);
		paginationData.setClientsTotal(1);
		paginationData.setClientsSize(20);
	}, [PollsTabsStore.activeTab]);

	const activeOrArchiveColumns: TTableColumn[] = [
		{
			dataIndex: "type",
			title: "Тип",
			key: "type",
			filters: [
				{ text: "Опрос", key: "POLL" as TVoteType },
				{ text: "Голосование", key: "VOTE" as TVoteType },
			],
			onChangeFilter: (filters) => {
				const selectedTypes = filters.map((filter) => filter.key) as TVoteType[];
				setSelectedTypes(selectedTypes);
			},
			onSortedBy: setTypeSortingDirection,
			style: {
				minWidth: "120px",
			},
		},
		{
			dataIndex: "name",
			title: "Название",
			key: "name",
			onSearch: setNameSearch,
			onSortedBy: setNameSortDirection,
			style: {
				minWidth: "200px",
			},
		},
		{
			dataIndex: "address",
			title: "Адресаты",
			key: "address",
			width: "400px",
			style: {
				minWidth: "200px",
			},
		},
		{
			dataIndex: "dateOfCreation",
			title: "Дата создания",
			key: "dateOfCreation",

			style: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "startDateTime",
			title: "Начало",
			key: "startDateTime",

			style: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "endDateTime",
			title: "Окончание",
			key: "endDateTime",

			style: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "sender",
			title: "Отправитель",
			key: "sender",
			onSearch: setCreatorNameSearch,
			style: {
				minWidth: "200px",
			},
		},
		{
			dataIndex: "actions",
			title: "Действия",
			key: "actions",
			style: {
				minWidth: "150px",
			},
		},
	];

	const templateColumns = [
		{
			dataIndex: "type",
			title: "Тип",
			key: "type",
			filters: [
				{ text: "Опрос", key: "POLL" as TVoteType },
				{ text: "Голосование", key: "VOTE" as TVoteType },
			],
			onChangeFilter: (filters) => {
				const selectedTypes = filters.map((filter) => filter.key) as TVoteType[];
				setSelectedTemplateTypes(selectedTypes);
			},
			onSortedBy: setTemplateTypeSortingDirection,
			style: {
				minWidth: "100px",
			},
		},
		{
			dataIndex: "name",
			title: "Название",
			key: "name",
			style: {
				minWidth: "700px",
			},
			onSearch: setTemplateNameSearch,
			onSortedBy: setTemplateNameSortDirection,
		},
		{
			dataIndex: "actions",
			title: "Действия",
			key: "actions",
			style: {
				width: "280px",
			},
		},
	];

	const archivePoll = useMutation(archivePollApi);
	const deletePoll = useMutation(deletePollApi);

	React.useEffect(() => {
		if (polls.data) {
			paginationData.setClientsTotal(polls.data.totalPages);
		}
	}, [polls.data?.page]);

	const onAnswerOpen = (pollId: string) => {
		PollsEditTabsStore.setActiveTab("answers");
		navigate(`/polls/edit/${pollId}`);
	};

	const onArchivePoll = (poll: { id: string; name: string }) => {
		PollsConfirmStore.openConfirmArchiveModal({
			callback: async () => {
				await archivePoll.mutateAsync({ body: { pollId: poll.id } });
				await polls.refetch();
			},
			name: poll.name,
		});
	};

	const onDeletePoll = (poll: { id: string; name: string }) => {
		PollsConfirmStore.openConfirmDeleteModal({
			callback: async () => {
				await deletePoll.mutateAsync({ body: { pollId: poll.id } });
				await polls.refetch();
			},
			name: poll.name,
		});
	};

	const pollsTableData =
		polls.data?.content?.map((poll) => ({
			type: getPollText(poll.type),
			name: poll.name,
			address:
				poll.complexAddresses?.join(",") ||
				poll.buildingAddresses?.join(",") ||
				poll.entranceAddresses?.join(",") ||
				poll.floorAddresses?.join("") ||
				poll.buildingObjectAddresses?.join("") ||
				"",
			dateOfCreation: dayjs(poll.createDateTime).format("DD.MM.YYYY HH:MM"),
			startDateTime: dayjs(poll.startDateTime).format("DD.MM.YYYY HH:MM"),
			endDateTime: dayjs(poll.endDateTime).format("DD.MM.YYYY HH:MM"),
			meta: { status: poll.status },

			isPlanned: poll.isPlanned ? "Да" : "Нет",
			sender: poll.fullName,
			actions: (
				<Button
					groupIcons={[CopySvg, DiagramSvg, StopSvg, TrashSvg]}
					groupIconsHandler={[
						() => navigate(`/polls/create?from=${poll.id}`),
						() => onAnswerOpen(poll.id),
						() => onArchivePoll({ id: poll.id, name: poll.name }),
						() => onDeletePoll({ id: poll.id, name: poll.name }),
					]}
					size="medium"
					isGroupButton
					typeButton="ghost"
					onClick={(e) => {
						e.stopPropagation();
					}}
					groupButtonsContainerStyle={{ display: "flex", gap: "12px" }}
				/>
			),
			id: poll.id,
		})) ?? [];

	const pollsTemplateData =
		pollsTemplates.data?.content?.map((poll) => ({
			id: poll.id,
			type: getPollText(poll.type),
			name: poll.name,
			meta: { status: poll.status, isTemplate: true },
			actions: (
				<FlexContainer>
					<Text
						color={"#5E7BB5"}
						type="table-cell-m-regular"
						onClick={(e) => {
							e.stopPropagation();
							navigate(`/polls/create?fromTemplate=${poll.id}`);
						}}>
						Создать из шаблона
					</Text>
					<Text
						color={"#5E7BB5"}
						type="table-cell-m-regular"
						onClick={(e) => {
							e.stopPropagation();
							navigate(`/polls/edit/${poll.id}`);
						}}>
						Редактировать
					</Text>
				</FlexContainer>
			),
		})) ?? [];

	const data = PollsTabsStore.activeTab === "template" ? pollsTemplateData : pollsTableData;
	const column = PollsTabsStore.activeTab === "template" ? templateColumns : activeOrArchiveColumns;
	const isFetching = polls.isFetching || pollsTemplates.isFetching;

	return { pollData: data, pollColumn: column, pagination: paginationData, isFetching };
};

export default useTableData;

const Center = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const FlexContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
