import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import { Text } from "tap2visit-ui-kit";

interface IChartEmpty {}

const ChartEmpty: FC<IChartEmpty> = (props) => (
	<>
		<Spacer px={16} />
		<Text type="base-regular">На данный вопрос, еще не было ответов</Text>
	</>
);

export default ChartEmpty;
