import { isVisibleFunctionality } from "constants/constants.common";
import { HOME_PAGE } from "constants/constants.paths";

import { FC, useEffect, useState } from "react";

import { Menu as AMenu, MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import AnnouncementAreYouSureDialogStore from "pages/announcemets/store/Announcement.areYouSureDialog.store";
import useAvatarLetters from "pages/profileUK/hooks/useAvatarLetters";
import useLogoUK from "pages/profileUK/hooks/useLogoUK";
import profileUKVisibilityStore from "pages/profileUK/profileUKVisibility.store";
import DialogAreYouSureCancelEditOfficeStore from "pages/profileUK/store/DialogAreYouSureCancelEditOffice.store";
import { ReactComponent as Back } from "public/arrow_left_block.svg";
import { ReactComponent as Up } from "public/arrow_right_block.svg";
import { ReactComponent as Help } from "public/help.svg";
import { ReactComponent as AnnouncemetPoint } from "public/menu/announcement_point.svg";
import { ReactComponent as Build } from "public/menu/build.svg";
import { ReactComponent as Counter } from "public/menu/counter.svg";
import { ReactComponent as File } from "public/menu/file.svg";
import { ReactComponent as Home } from "public/menu/home.svg";
import { ReactComponent as List } from "public/menu/list.svg";
import { ReactComponent as ManyDots } from "public/menu/many_dots.svg";
import { ReactComponent as Staff } from "public/menu/staf.svg";
import { ReactComponent as User } from "public/menu/user.svg";
import { ReactComponent as PollSvg } from "public/poll.svg";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Text } from "tap2visit-ui-kit";
import packageJson from "../../../package.json";

import { TemplateBasicOrganization } from "../../pages/profileUK/components/TemplateBasicOrganization";
import { showModalProfileEditExistIfThereChanges } from "../../pages/profileUK/utils/showModalProfileEditExistIfThereChanges";
import UKStore from "../../store/UK.Store";

import MenuProfile from "./components/Menu.profile";
import { RowItem } from "./styles";

type TMenuItem = Required<MenuProps>["items"][number];

const menuItems: (TMenuItem | boolean)[] = [
	isVisibleFunctionality && {
		label: "Жилые комплексы",
		key: "buildings",
		icon: <Build />,
	},
	isVisibleFunctionality && {
		label: "Профиль УК",
		key: "profile",
		icon: <Home />,
	},
	{
		label: "Квитанции",
		key: "receipts",
		icon: <File />,
	},
	{
		label: "Счетчики",
		key: "counters",
		icon: <Counter />,
	},
	isVisibleFunctionality && {
		label: "Жители",
		key: "citizens",
		icon: <List />,
	},
	isVisibleFunctionality && {
		label: "Запрос на доступ",
		key: "access-request",
		icon: <User />,
	},
	// {
	// 	label: "Сообщение от жителя",
	// 	key: "sub7",
	// 	icon: <Chat />,
	// },
	// {
	// 	label: "Должники",
	// 	key: "sub8",
	// 	icon: <User_X />,
	// },
	isVisibleFunctionality && {
		label: "Объявления",
		key: "announcement",
		icon: <AnnouncemetPoint />,
	},
	{
		label: "Сотрудники",
		key: "employee",
		icon: <Staff />,
	},
	isVisibleFunctionality && {
		label: "Опросы и голосование",
		key: "polls",
		icon: <PollSvg />,
	},
	// {
	// 	label: "Камеры",
	// 	key: "sub11",
	// 	icon: <Video />,
	// },
	// {
	// 	label: "Шлагбаум",
	// 	key: "sub12",
	// 	icon: <Barrier />,
	// },
].filter((item) => typeof item !== "boolean");

interface IMenu {
	setSideBarWidth: React.Dispatch<React.SetStateAction<string>>;
}

const Menu: FC<IMenu> = (props) => {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const [collapsed, setCollapsed] = useState(false);
	const location = useLocation().pathname;
	const { logoUKLink } = useLogoUK();
	const avatarLetters = useAvatarLetters();

	useEffect(() => {
		props.setSideBarWidth(collapsed ? "64px" : "255px");
	}, [collapsed]);

	const clickMenuItem = (item: any) => {
		const navigateToScreen = () => {
			navigate(item.key);
		};

		if (profileUKVisibilityStore.isEditModeEnabled) {
			showModalProfileEditExistIfThereChanges({
				onContinue: () => {
					navigateToScreen();
					profileUKVisibilityStore.setIsEditModeEnabled(false);
				},
			});
			return;
		}

		if (location === "/announcement/create" || location.indexOf("/announcement/edit") !== -1) {
			AnnouncementAreYouSureDialogStore.setVisibleDialogWithCallbackAfterExiting(navigateToScreen);
			return;
		}

		navigateToScreen();
	};

	const setOpenChoiceAnotherUK = () => {
		const openChoiceUK = () => setOpen(true);

		if (profileUKVisibilityStore.isEditModeEnabled) {
			DialogAreYouSureCancelEditOfficeStore.setVisibleDialogWithCallbackAfterExiting(() => setOpen(true));
		}

		if (location === "/announcement/create" || location.indexOf("/announcement/edit") !== -1) {
			AnnouncementAreYouSureDialogStore.setVisibleDialogWithCallbackAfterExiting(openChoiceUK);
			return;
		}

		openChoiceUK();
	};

	return (
		<>
			{open && <TemplateBasicOrganization setOpen={setOpen} />}
			<AMenu selectedKeys={[location.split("/")[1]]} mode="inline" inlineCollapsed={collapsed}>
				<RowItem key={"LOGO"} onClick={() => navigate(HOME_PAGE)} collapsed={collapsed}>
					<Avatar size={"32"} img={logoUKLink} firstName={avatarLetters.firstLetter} lastName={avatarLetters.secondLetter} />
					<Text type={"base-medium"}>{(UKStore.getUK().name as unknown as string) || ""}</Text>
				</RowItem>
				<ChangeUK onClick={setOpenChoiceAnotherUK}>
					<RowItem key={"UK_select"} collapsed={collapsed}>
						<ManyDots />
						<Text type="base-medium">Выбрать другую УК</Text>
					</RowItem>
				</ChangeUK>
				{menuItems.length &&
					menuItems.map((item: any) => (
						// @ts-ignore TODO
						<AMenu.Item
							key={item!.key}
							onClick={() => {
								clickMenuItem(item);
							}}
							// @ts-ignore TODO
							collapsed={collapsed}>
							{item.icon}
							<Text type={"base-medium"}>{item!.label}</Text>
						</AMenu.Item>
					))}
				<BottomContainer>
					{isVisibleFunctionality && (
						<RowItem key={"subBottom1"} collapsed={collapsed}>
							<Help />
							<Text type={"base-medium"} color={"#4364A6"}>
								Нужна помощь
							</Text>
						</RowItem>
					)}

					<MenuProfile collapsed={collapsed} />
					<RowItem
						key={"subBottom3"}
						collapsed={collapsed}
						onClick={() => setCollapsed((init) => !init)}
						style={{ justifyContent: "space-between" }}>
						{!collapsed ? <Back /> : <Up />}
						<Text type={"small-regular"} color={"#12121299"}>
							Версия {packageJson.version}
						</Text>
					</RowItem>
				</BottomContainer>
			</AMenu>
		</>
	);
};

export default observer(Menu);

const ChangeUK = styled.div`
	border-bottom: 0.5px solid #12121229;
	padding-bottom: 8px;
`;

const BottomContainer = styled.div`
	position: absolute;
	bottom: 0;
	background: white;
`;
