import React, { useState } from "react";

import { queryClient } from "index";
import { ReactComponent as EditIcon } from "public/EditCitizen.svg";
import { useMutation } from "react-query";
import { Button, Space } from "tap2visit-ui-kit";

import { updateUserByIdApi } from "../../../api/api.clients";
import UserEditInput from "../../../components/Citizens/components/UserEdit.input";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import {
	CitizenPersonalEdit,
	CitizenPersonalEditFooter,
	CitizenPersonalEditWrapper,
	CitizenRealtyDivider,
	CitizenRealtySubTitle,
	CitizenRealtyTitle,
	CitizenRealtyWrapper,
	CitizenWrapper,
	PersonalInformationWrapper,
} from "../../../components/Citizens/style/Citizen.styled";
import { QueriesKeys } from "../../../interfaces/queriesKeys";

import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";

const CitizenPersonalInformation = () => {
	const [edit, setEdit] = useState(false);
	const [lastName, setLastName] = useState(CitizensDrawerStore.selectedUser.lastName);
	const [firstName, setFirstName] = useState(CitizensDrawerStore.selectedUser.firstName);
	const [middleName, setMiddleName] = useState(CitizensDrawerStore.selectedUser.middleName);
	const [residentialAddress, setResidentialAddress] = React.useState(CitizensDrawerStore.selectedUser.residentialAddress);

	const updateUser = useMutation({
		mutationFn: updateUserByIdApi,
	});
	const changePersonalInformation = async () => {
		const dataInputs = CitizensDrawerStore.selectedUser;

		const updatedUser = await updateUser.mutateAsync({
			path: { id: dataInputs.id },
			body: {
				contacts: dataInputs.contacts.filter((contact) => contact.value),
				firstName,
				lastName,
				inn: dataInputs.inn,
				insuranceNumber: dataInputs.insuranceNumber,
				middleName: middleName ? middleName : null,
				residentialAddress: residentialAddress ? residentialAddress : null,
			},
		});

		CitizensDrawerStore.openDrawer({ mode: "visible", user: updatedUser });

		queryClient.invalidateQueries([QueriesKeys.clients]);
		queryClient.invalidateQueries([QueriesKeys.user, CitizensDrawerStore.selectedUser.id]);
		setEdit(false);
	};
	return (
		<Space width="100%" direction="column">
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={"Персональная информация"} />
				<Button shape="square" typeButton="secondary" onClick={() => setEdit((status) => !status)} icon={EditIcon} />
			</PersonalInformationWrapper>
			{edit && (
				<CitizenPersonalEditWrapper>
					<CitizenPersonalEdit>
						<UserEditInput label={"Фамилия"} required value={lastName} onChange={(value) => setLastName(value)} />
						<UserEditInput label={"Имя"} required value={firstName} onChange={(value) => setFirstName(value)} />
						<UserEditInput label={"Отчество"} value={middleName} onChange={(value) => setMiddleName(value)} />
						<UserEditInput label={"Адрес проживания"} as="textarea" value={residentialAddress} onChange={setResidentialAddress} />
						<p>Введите адрес фактического проживания, адрес по прописке указывается в разделе «Паспорт»</p>
					</CitizenPersonalEdit>

					<CitizenPersonalEditFooter>
						<Button typeButton="secondary" onClick={() => setEdit(false)}>
							Отменить
						</Button>
						<Button disabled={!lastName || !firstName} onClick={changePersonalInformation}>
							Сохранить
						</Button>
					</CitizenPersonalEditFooter>
				</CitizenPersonalEditWrapper>
			)}
			{!edit && (
				<Space width="100%" direction="column">
					<CitizenRealtyWrapper>
						<CitizenWrapper>
							<Space align="start" size={1} direction="column">
								<CitizenRealtyTitle>ФИО</CitizenRealtyTitle>
								<CitizenRealtySubTitle>
									{CitizensDrawerStore.selectedUser.lastName} {CitizensDrawerStore.selectedUser.firstName}{" "}
									{CitizensDrawerStore.selectedUser.middleName}
								</CitizenRealtySubTitle>
							</Space>
						</CitizenWrapper>
						<CitizenRealtyDivider />
						<CitizenWrapper>
							<Space align="start" size={1} direction="column">
								<CitizenRealtyTitle>Адрес проживания</CitizenRealtyTitle>
								<CitizenRealtySubTitle>{CitizensDrawerStore.selectedUser.residentialAddress ?? "Адрес отсутствует"}</CitizenRealtySubTitle>
							</Space>
						</CitizenWrapper>
					</CitizenRealtyWrapper>
				</Space>
			)}
		</Space>
	);
};

export default CitizenPersonalInformation;
