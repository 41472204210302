import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import styled from "styled-components";

import PollsEditCharts from "./PollAnswers.charts";
import PollsAnswersCount from "./PollAnswers.count";

interface IPollAnswers {}

const PollAnswers: FC<IPollAnswers> = (props) => (
	<Container>
		<PollsAnswersCount />
		<PollsEditCharts />
	</Container>
);

export default PollAnswers;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 24px;
`;
