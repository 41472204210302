import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Segmented, TSegmentedArray, TSegmentedItem, Text, colors } from "tap2visit-ui-kit";

import ReceiptsDrawerStore from "../../store/ReceiptsDrawer.store";
import Label from "../components/Label";
import ReceiptsPeriodStore, { TPeriodTypes } from "../store/ReceiptsPeriod.store";

interface IPeriod {}

const Period: FC<IPeriod> = (props) => {
	const onClickSegmented = (item: TSegmentedItem) => {
		ReceiptsPeriodStore.setSelectedPeriodType(item.key as TPeriodTypes);
		ReceiptsDrawerStore.setDates([]);
	};

	const segmentedItems: TSegmentedArray = [
		{
			title: "Неделя",
			key: "week" as TPeriodTypes,
			disabled: ReceiptsDrawerStore.isCreationReportInProgress,
		},
		{
			title: "Месяц",
			key: "month" as TPeriodTypes,
			disabled: ReceiptsDrawerStore.isCreationReportInProgress,
		},
		{
			title: "Год",
			key: "year" as TPeriodTypes,
			disabled: ReceiptsDrawerStore.isCreationReportInProgress,
		},
		{
			title: "Задать период",
			key: "custom" as TPeriodTypes,
			disabled: true,
		},
	];

	return (
		<>
			<Label text="Период формирования отчета" />
			<Spacer px={4} />
			<SegmentedContainer>
				<Segmented onClick={onClickSegmented} items={segmentedItems} direction={"horizontal"} size="m" />
			</SegmentedContainer>
		</>
	);
};

export default observer(Period);

const SegmentedContainer = styled.div`
	width: 295px;
`;
