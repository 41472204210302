import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import { observer } from "mobx-react-lite";

import PollsTabs from "./components/Polls.tabs";
import PollsTable from "./components/tables/Polls.table";
import { PollsMainPaddingContent } from "./Polls.styled";
import PollsConfirmStore from "./store/Polls.confirm.store";
import PollsTabsStore from "./store/Polls.tabs.store";

interface IPolls {}

const TableFilterInstanceStore = new BuildingFilterStore();

const Polls: FC<IPolls> = (props) => (
	<TableFilterContext.Provider value={TableFilterInstanceStore}>
		<PollsMainPaddingContent>
			<PollsTabs />
		</PollsMainPaddingContent>

		<PollsMainPaddingContent>
			<TableFilter
				customSwitchOptions={{
					callback: PollsTabsStore.toggleOnlyActiveStatus,
					label: "Статус",
					isActive: PollsTabsStore.onlyActiveStatus,
					switchLabel: "Только активные",
					isVisible: PollsTabsStore.activeTab === "active",
				}}
			/>
		</PollsMainPaddingContent>

		<Spacer px={16} />
		<PollsTable />

		<BaseModal
			title={PollsConfirmStore.isVisibleConfirmArchiveModal ? "Уверены, что хотите остановить опрос?" : "Уверены, что хотите удалить?"}
			description={PollsConfirmStore.actionPollName}
			okText={"Да"}
			cancelText="Нет"
			isVisible={PollsConfirmStore.isVisibleConfirmArchiveModal || PollsConfirmStore.isVisibleConfirmDeleteModal}
			status="delete"
			onCancel={PollsConfirmStore.cancel}
			onOk={PollsConfirmStore.confirm}
			onExit={PollsConfirmStore.cancel}
		/>
	</TableFilterContext.Provider>
);

export default observer(Polls);
