import styled from "styled-components";
import { colors } from "tap2visit-ui-kit";

export const OuterContainer = styled.div`
	border: 0.5px solid ${colors.strokeLine};
	border-radius: 12px;
	width: 100%;

	position: relative;

	background-color: ${colors.surfaceNeutralBgWhite};
`;

export const Container = styled.div`
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 32px;
`;

export const ContentContainer = styled.div`
	width: 50%;
`;

export const PaddingContainer = styled.div`
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 32px;
`;
