import React, { FC } from "react";

import { IInput, Input } from "tap2visit-ui-kit";

interface IInputPassword extends Omit<IInput, "slotRight" | "type" | "inputRef"> {
	onChangeValue?: (value: string) => void;
}

const InputPassword: FC<IInputPassword> = (props) => (
	<Input.Password {...props} onChange={(e) => (props.onChange ? props.onChange(e) : props.onChangeValue?.(e.target.value))} />
);

export default InputPassword;
