import React, { useEffect, useState } from "react";

import Tab from "components/ui/Tab/Tab";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { getEntrancesApi } from "../../api/api.gateway";
import { QueriesKeys } from "../../interfaces/queriesKeys";

import BuildingsStore from "./Buildings.store";

const BuildingsFloorTab = () => {
	const navigate = useNavigate();

	const [activeKey, setActiveKey] = useState("");
	const { id } = useParams();
	const [tab, setTab] = useState([]);
	const entrances = useQuery({
		queryFn: () => getEntrancesApi({ params: { requestStatus: "NEW", announcementStatus: "NEW", buildingId: BuildingsStore.entranceId } }),
		queryKey: [QueriesKeys.advancedEntrances, BuildingsStore.entranceId],
	});

	useEffect(() => {
		if (entrances.data) {
			setTab(
				entrances.data.map((v) => ({
					key: v.id,
					label: `Подъезд ${v.number}`,
				})),
			);
		}
	}, [entrances.data]);

	useEffect(() => {
		if (id) setActiveKey(id);
	}, [id]);

	if (!id && !tab) return;
	return (
		<TabsWrapper>
			<Tab
				activeKey={activeKey}
				withLine={true}
				paddingItem={"12px 0"}
				items={tab ?? []}
				direction={"horizontal"}
				onTabClick={(key) => {
					setActiveKey(key);
					navigate(`/buildings/floor/${key}`);
				}}
			/>
		</TabsWrapper>
	);
};

const TabsWrapper = styled.div`
	padding: 8px 0 24px 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;
export default BuildingsFloorTab;
