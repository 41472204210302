import { IDocument, IDocumentCreate } from "interfaces/IDocuments";

import apiClient from "./api.client";

// TODO Need to split methinds if args in params and args in path
export type TGetDocumentsByIdApi = (args: {
	path?: { id: string };
	params?: { targetId?: string; sourceId?: string };
}) => Promise<IDocument[]>;

export const getDocumentsByIdApi: TGetDocumentsByIdApi = async (args) => {
	try {
		const params: Record<string, string> = {};
		let path = "documents/v1/documents";

		if (args.params?.targetId) {
			params.targetId = args.params.targetId;
		}

		if (args.params?.sourceId) {
			params.sourceId = args.params.sourceId;
		}

		if (args.path?.id) {
			path += args.path.id;
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// export type TGetDocumentsBySourceIdApi = (args: { path?: { id: string }; params?: { targetId: string } }) => Promise<IDocument[]>;

// export const getDocumentsByTargetIdApi: TGetDocumentsByTargetIdApi = async (args) => {
// 	try {
// 		const params: Record<string, string> = {};
// 		let path = "documents/v1/documents";

// 		if (args.params?.targetId) {
// 			params.targetId = args.params.targetId;
// 		}

// 		if (args.path?.id) {
// 			path += args.path.id;
// 		}

// 		const res = await apiClient.get(path, {
// 			params,
// 		});

// 		return res.data;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

export type TCreateDocumentApi = (args: { body: IDocumentCreate }) => Promise<IDocument>;

export const createDocumentApi: TCreateDocumentApi = async (args) => {
	try {
		const res = await apiClient.post("documents/v1/documents", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUpdateDocumentApi = (args: { body: IDocumentCreate; path: { id: string } }) => Promise<IDocument>;

export const updateDocumentApi: TUpdateDocumentApi = async (args) => {
	try {
		const res = await apiClient.patch(`documents/v1/documents/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateDocumentListApi = (args: { body: IDocumentCreate[] }) => Promise<IDocument[]>;

export const createDocumentListApi: TCreateDocumentListApi = async (args) => {
	try {
		const res = await apiClient.post("documents/v1/documents/list", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
