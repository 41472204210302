import React from "react";

import { getUserByIdApi } from "api/api.clients";
import { IClient } from "interfaces/IClient";
import { IRequestUser } from "interfaces/IRequest";
import { IRole } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import { toJS } from "mobx";
import { useQuery } from "react-query";
import getRoleTranslate from "utils/getRoleTranslate";

import AccessRequestCanApproveRequestStore from "../store/AccessRequestCanApproveRequest.store";

import useUserRole from "./useUserRole";

enum LoginMatchStatuses {
	"LOGIN_NOT_MATCH" = "LOGIN_NOT_MATCH",
	"LOGIN_MATCH" = "LOGIN_MATCH",
	"FOUND_IN_CONTACTS" = "FOUNDED",
	"NOT_FOUND_IN_CONTACTS" = "NOT_FOUND",
	"NOT_COMPARED" = "NOT_COMPARED",
}

export type TUseMatchUsersReturn = {
	rolesMatch: boolean;
	firstNameMatch: boolean;
	lastNameMatch: boolean;
	middleNameMatch: boolean;
	innMatch: boolean;
	emailMatch: boolean;
	loginMatchStatus: keyof typeof LoginMatchStatuses;
};

const useMatchUsers = (args: { requestUser: IRequestUser; compareUser: IClient; compareUserRoles: IRole[] }): TUseMatchUsersReturn => {
	const [rolesMatch, setRolesMatch] = React.useState(false);
	const [firstNameMatch, setFirstNameMatch] = React.useState(false);
	const [lastNameMatch, setLastNameMatch] = React.useState(false);
	const [middleNameMatch, setMiddleNameMatch] = React.useState(true);
	const [innMatch, setInnMatch] = React.useState(true);
	const [emailMatch, setEmailMatch] = React.useState(true);
	const [loginMatchStatus, setLoginMatchStatus] = React.useState<keyof typeof LoginMatchStatuses>("NOT_COMPARED");

	const dispose = () => {
		setRolesMatch(false);
		setFirstNameMatch(false);
		setLastNameMatch(false);
		setMiddleNameMatch(false);
		setInnMatch(false);
		setEmailMatch(false);
	};

	React.useEffect(
		() => () => {
			dispose();
			AccessRequestCanApproveRequestStore.dispose();
		},
		[args.compareUser],
	);

	const compareUserEmails = args.compareUser?.contacts?.filter((contact) => contact.type === "EMAIL");
	const requestUserEmailValues = args.requestUser.client.emails?.map((v) => v.value);
	const compareUserEmailValues = compareUserEmails?.map((email) => email.value);

	const requestUserRolesString = useUserRole({ clientId: args.requestUser.clientId, roleId: args.requestUser.roleId });
	const currentBuildingUserRoles = args.compareUserRoles?.map((role) => getRoleTranslate(role.name))?.join(",");

	React.useEffect(() => {
		if (
			rolesMatch &&
			firstNameMatch &&
			lastNameMatch &&
			middleNameMatch &&
			(loginMatchStatus === "FOUND_IN_CONTACTS" || loginMatchStatus === "LOGIN_MATCH" || loginMatchStatus === "NOT_FOUND_IN_CONTACTS")
		) {
			AccessRequestCanApproveRequestStore.setCanApproveRequest();
			AccessRequestCanApproveRequestStore.setIsNeedToShowConfirmApproveModal(loginMatchStatus === "NOT_FOUND_IN_CONTACTS");
		}
	}, [rolesMatch, firstNameMatch, lastNameMatch, middleNameMatch, innMatch, loginMatchStatus]);

	React.useEffect(() => {
		if (!args.compareUser) {
			setRolesMatch(false);
			return;
		}

		if (requestUserRolesString && currentBuildingUserRoles) {
			setRolesMatch(currentBuildingUserRoles.indexOf(requestUserRolesString) !== -1);
		}
	}, [requestUserRolesString, currentBuildingUserRoles]);

	React.useEffect(() => {
		if (!args.compareUser) {
			setFirstNameMatch(false);
			return;
		}

		if (args.requestUser?.client?.firstName && args.compareUser?.firstName) {
			setFirstNameMatch(args.requestUser.client.firstName === args.compareUser.firstName);
		}
	}, [args.requestUser, args.compareUser]);

	React.useEffect(() => {
		if (!args.compareUser) {
			setLastNameMatch(false);
			return;
		}

		if (args.requestUser?.client?.lastName && args.compareUser?.lastName) {
			setLastNameMatch(args.requestUser.client.lastName === args.compareUser.lastName);
		}
	}, [args.requestUser, args.compareUser]);

	React.useEffect(() => {
		if (!args.compareUser) {
			setMiddleNameMatch(false);
			return;
		}

		if (!args.requestUser?.client?.middleName && !args.compareUser?.middleName) {
			return;
		}

		setMiddleNameMatch(args.requestUser.client.middleName === args.compareUser.middleName);
	}, [args.requestUser, args?.compareUser]);

	React.useEffect(() => {
		if (!args.compareUser) {
			setInnMatch(false);
			return;
		}

		if (args.compareUser && args.requestUser) {
			setInnMatch(args.compareUser.inn === args.requestUser.client.inn);
		}
	}, [args.requestUser, args.compareUser]);

	React.useEffect(() => {
		if (!args.compareUser) {
			setInnMatch(false);
			return;
		}

		setEmailMatch(requestUserEmailValues.every((email) => compareUserEmailValues.includes(email)));
	}, [args.requestUser, args.compareUser]);

	React.useEffect(() => {
		if (!args.requestUser) {
			setLoginMatchStatus("NOT_COMPARED");
			return;
		}

		const loginRequestUserPhone = args.requestUser?.client?.phones?.find((phone) => phone.isDefault);
		const compareUserLogin = args.compareUser?.contacts?.find((contact) => contact.isDefault && contact.type === "PHONE");

		if (compareUserLogin) {
			if (compareUserLogin.value !== loginRequestUserPhone.value) {
				setLoginMatchStatus("LOGIN_NOT_MATCH");
			} else {
				setLoginMatchStatus("LOGIN_MATCH");
			}
			return;
		}

		const compareUserPhones = args.compareUser?.contacts?.filter((contact) => !contact.isDefault && contact.type === "PHONE");
		const requestLoginExistInCompareContacts = compareUserPhones?.find((phone) => phone.value === loginRequestUserPhone.value);

		if (requestLoginExistInCompareContacts) {
			setLoginMatchStatus("FOUND_IN_CONTACTS");
		} else {
			setLoginMatchStatus("NOT_FOUND_IN_CONTACTS");
		}
	}, [args.requestUser, args.compareUser]);

	return {
		rolesMatch,
		firstNameMatch,
		lastNameMatch,
		middleNameMatch,
		innMatch,
		emailMatch,
		loginMatchStatus,
	};
};

export default useMatchUsers;
