import apiClient from "./api.client";

export type TSwitchProfileUKApi = (args: { path: { profileId: string } }) => Promise<void>;

export const switchProfileUKApi: TSwitchProfileUKApi = async (args) => {
	try {
		const res = await apiClient.post(`eaa/v1/users/profile/${args.path.profileId}/switch`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
