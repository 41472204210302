import { DayOfWeek } from "interfaces/IOffice";

export const week: Record<DayOfWeek, string> = {
	MONDAY: "Понедельник",
	TUESDAY: "Вторник",
	WEDNESDAY: "Среда",
	THURSDAY: "Четверг",
	FRIDAY: "Пятница",
	SATURDAY: "Суббота",
	SUNDAY: "Воскресенье",
};

export const mainSections = [
	{
		key: "offices" as string,
		label: "Офисы УК" as string,
	},
	{
		key: "information" as string,
		label: "Сведения об УК" as string,
	},
];
