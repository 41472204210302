import React, { FC } from "react";

import DatePicker from "components/DatePicker";
import { Divider } from "components/DrawerFormFields/DrawerFormFields";
import { Spacer } from "components/layout/Spacer";
import dayjs, { Dayjs } from "dayjs";
import { TTimeLifeType } from "interfaces/IVote";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";
import { FormItem, InputNumber, Select, TContextMenuItem, Text, TimePicker, colors } from "tap2visit-ui-kit";

import PollsEditStore from "../store/PollsItem.store";

interface IPollsPeriodOfTime {}

type TSelectRadioType = "periodOfTime" | "planned";

const PollsPeriodOfTime: FC<IPollsPeriodOfTime> = (props) => {
	const [selectedRadio, setSelectedRadio] = React.useState<TSelectRadioType>(
		PollsEditStore.poll.startDateTime?.length ? "planned" : "periodOfTime",
	);

	const onChangeSelectRadio = (type: TSelectRadioType) => {
		setSelectedRadio(type);
		if (type === "periodOfTime") {
			PollsEditStore.changeTimeLife({
				timeLifeType: "DAY",
				timeLifeValue: 0,
			});
		}

		if (type === "planned") {
			PollsEditStore.changeStartEndTimePoll({
				type: "startDateTime",
				typeOfTime: "day",
				value: dayjs(),
			});

			PollsEditStore.changeStartEndTimePoll({
				type: "endDateTime",
				typeOfTime: "day",
				value: dayjs().add(1, "day"),
			});
		}
	};

	const [isOpenSelectDayOfPeriod, setIsOpenSelectDayOfPeriod] = React.useState(false);

	const [listOfPeriodOfTime, setListOfPeriodOfTime] = React.useState<TContextMenuItem[]>([
		{
			focused: PollsEditStore.poll.timeLifeType === "DAY",
			text: "Сутки",
			key: "DAY" as TTimeLifeType,
		},
		{
			focused: PollsEditStore.poll.timeLifeType === "WEEK",
			text: "Неделя",
			key: "WEEK" as TTimeLifeType,
		},
		{
			focused: PollsEditStore.poll.timeLifeType === "MONTH",
			text: "Месяц",
			key: "MONTH" as TTimeLifeType,
		},
		{
			focused: PollsEditStore.poll.timeLifeType === "YEAR",
			text: "Год",
			key: "YEAR" as TTimeLifeType,
		},
	]);

	const onClickSelectItem = (item: TContextMenuItem) => {
		setListOfPeriodOfTime((list) =>
			list.map((currItem) => {
				if (currItem.key === item.key) {
					PollsEditStore.changeTimeLife({
						timeLifeType: currItem.key as TTimeLifeType,
					});

					return {
						...currItem,
						focused: true,
					};
				}
				return {
					...currItem,
					focused: false,
				};
			}),
		);
	};

	const onChange = (value: number) => {
		PollsEditStore.changeTimeLife({
			timeLifeValue: value,
		});
	};

	const onChangeStartDay = (value: dayjs.Dayjs) => {
		PollsEditStore.changeStartEndTimePoll({
			type: "startDateTime",
			typeOfTime: "day",
			value,
		});
	};

	const onChangeEndDay = (value: dayjs.Dayjs) => {
		PollsEditStore.changeStartEndTimePoll({
			type: "endDateTime",
			typeOfTime: "day",
			value,
		});
	};
	const onChangeStartTime = (value: dayjs.Dayjs) => {
		PollsEditStore.changeStartEndTimePoll({
			type: "startDateTime",
			typeOfTime: "time",
			value,
		});
	};

	const onChangeEndTime = (value: dayjs.Dayjs) => {
		PollsEditStore.changeStartEndTimePoll({
			type: "endDateTime",
			typeOfTime: "time",
			value,
		});
	};

	return (
		<>
			<FormItem label="Время жизни опроса">
				<Container>
					<CustomText
						onClick={() => onChangeSelectRadio("periodOfTime")}
						selected={selectedRadio === "periodOfTime"}
						type="base-medium"
						style={{ flexGrow: 1 }}>
						В течение отрезка времени
					</CustomText>

					<Divider horizontal />

					<CustomText
						style={{ flexShrink: 1 }}
						onClick={() => onChangeSelectRadio("planned")}
						selected={selectedRadio === "planned"}
						type="base-medium">
						Запланировано
					</CustomText>
				</Container>
			</FormItem>

			<Spacer px={16} />

			{selectedRadio === "periodOfTime" && (
				<>
					<Row>
						{/* <InputNumber sizeInput={"medium"} value={Number(PollsEditStore.poll.timeLifeValue)} onChange={onChangeTimeLifeValue} /> */}
						<InputNumber sizeInput={"medium"} value={Number(PollsEditStore.poll.timeLifeValue)} onChangeValue={onChange} />
						<Select
							style={{ width: "140px" }}
							items={listOfPeriodOfTime}
							isOpened={isOpenSelectDayOfPeriod}
							onClickSelect={() => setIsOpenSelectDayOfPeriod(true)}
							onClickSelectItem={onClickSelectItem}
							onClose={() => setIsOpenSelectDayOfPeriod(false)}
							size="m"
						/>
					</Row>
				</>
			)}

			{selectedRadio === "planned" && (
				<>
					<CalendarRow>
						<FormItem label="Haчало">
							<DatePicker
								currentDate={PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : dayjs()}
								// TODO Need to handle null
								onChangeDate={(date) => onChangeStartDay(dayjs(date[0]))}
							/>
						</FormItem>
						<TimePicker
							initialTime={PollsEditStore.poll.startDateTime ? dayjs(PollsEditStore.poll.startDateTime) : dayjs()}
							onChange={onChangeStartTime}
						/>
					</CalendarRow>

					<Spacer px={8} />

					<CalendarRow>
						<FormItem label="Окончание">
							<DatePicker
								currentDate={PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime) : dayjs()}
								// TODO Need to handle null
								onChangeDate={(date) => onChangeEndDay(dayjs(date[0]))}
							/>
						</FormItem>
						<TimePicker
							initialTime={PollsEditStore.poll.endDateTime ? dayjs(PollsEditStore.poll.endDateTime) : dayjs()}
							onChange={onChangeEndTime}
						/>
					</CalendarRow>
				</>
			)}
		</>
	);
};

export default observer(PollsPeriodOfTime);

const Container = styled.div`
	display: flex;
	border: 0.5px solid ${colors.strokeDivider};
	border-radius: 8px;
	width: 350px;
	cursor: pointer;
`;

const CustomText = styled(Text)<{ selected: boolean }>`
	padding: 5px 12px;
	${(props) => {
		if (props.selected) {
			return css`
				background: ${colors.surfaceBrandTransparent};
				color: ${colors.textBrandDefault};
			`;
		}
	}}
`;

const Row = styled.div`
	display: flex;
	gap: 16px;
`;

const CalendarRow = styled.div`
	display: flex;
	gap: 4px;
	align-items: end;
`;
