import React from "react";

import { uploadFilesApi, deleteFileApi } from "api/api.storage";
import { IDocument } from "interfaces/IDocuments";
import { useMutation } from "react-query";

type TUseCreateUpdateDocument = {
	needToRemoveFileIds: string[];
	newFiles: File[];
	citizenDocument?: IDocument;
};

const useUploadCitizenFiles = (args: TUseCreateUpdateDocument) => {
	const uploadFilesMutation = useMutation(uploadFilesApi);
	const deleteFile = useMutation(deleteFileApi);
	const filesHasBeenAlreadyDeleted = React.useRef<string[]>([]);

	const uploadFiles = async () => {
		for (const fileId of args.needToRemoveFileIds) {
			if (filesHasBeenAlreadyDeleted.current.includes(fileId)) {
				continue;
			}
			filesHasBeenAlreadyDeleted.current.push(fileId);
			deleteFile.mutateAsync(fileId); // ? No needed to wait
		}

		const uploadedFiles = await uploadFilesMutation.mutateAsync({ files: args.newFiles, bucket: "DOCUMENTS" });
		const fileIds = [
			...uploadedFiles.map((file) => file.id),
			...(args.citizenDocument?.fileIds?.filter((fileId) => !args.needToRemoveFileIds.includes(fileId)) ?? []),
		];

		return fileIds;
	};

	return uploadFiles;
};

export default useUploadCitizenFiles;
