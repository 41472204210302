import { emailRegExp } from "constants/constants.regexp";

import { IUser } from "interfaces/User/IUser";
import { makeAutoObservable, toJS } from "mobx";
import { validation } from "modules/validation/utils/validation";

type TUserValidationField = keyof Pick<IUser, "firstName" | "lastName" | "mobilePN" | "email" | "comment" | "middleName">;

const initialUserValidation: Record<TUserValidationField, boolean> = {
	firstName: true,
	lastName: true,
	email: true,
	mobilePN: true,
	comment: true,
	middleName: true,
};

class EmployeeValidationStore {
	user = initialUserValidation;

	constructor() {
		makeAutoObservable(this);
	}

	checkUserValidation = (user: IUser) => {
		const { isLessThen, isNotEmpty, isEquals } = validation;

		Object.entries(user).forEach((field) => {
			const [key, value] = field;
			if (key === "firstName" || key === "lastName") {
				this.user[key] = isNotEmpty(value) && isLessThen(value, 50);
				return;
			}

			if (key === "middleName") {
				this.user[key] = isLessThen(value, 50);
			}

			if (key === "email") {
				this.user[key] = isNotEmpty(value) && value.match(emailRegExp);
				return;
			}

			if (key === "mobilePN") {
				this.user[key] = isNotEmpty(value) && isEquals(value, 12);
			}

			if (key === "comment") {
				this.user[key] = validation.isLessThen(value, 255);
			}
		});

		const isValid = Object.values(this.user).every((value) => value);

		return isValid;
	};

	validUserField = (key: TUserValidationField) => {
		this.user[key] = true;
	};

	dispose = () => {
		this.user = initialUserValidation;
	};
}

export default new EmployeeValidationStore();
