import { Space } from "antd";
import { Spacer } from "components/layout/Spacer";
import { RoleTypes } from "interfaces/IRole";
import { ReactComponent as MessagesPlus } from "public/MessagesPlus.svg";
import styled from "styled-components";
import { Avatar, Button } from "tap2visit-ui-kit";

import {
	CitizenRealtyDivider,
	CitizenRealtyWrapper,
	CitizenRealtyWrapperFlexDrawer,
	CitizenRealtyWrapperSpace,
} from "../../../components/Citizens/style/Citizen.styled";
import useBuildingClientsByRole from "../hooks/useBuildingClientsByRole";

import { CitizenDrawerTextHeaderBlock, CitizenDrawerBlockItemHeader, CitizenDrawerBlockItemDescription } from "./CitizenTexts";

const CitizenOwnersInformation = () => {
	const buildingObjectClients = useBuildingClientsByRole({ role: RoleTypes.OWNER });

	return (
		<>
			<CitizenDrawerTextHeaderBlock text="Собственники" />
			<Spacer px={12} />
			<Container>
				{buildingObjectClients.data?.map((i, idx) => {
					const phone = i.contacts?.find((contact) => contact.type === "PHONE")?.value;
					const isFirstElement = idx === 0;

					return (
						<>
							{!isFirstElement && <CitizenRealtyDivider />}

							<CitizenRealtyFlexRent>
								<CitizenRealtyWrapperFlexDrawer>
									<Avatar size="32" firstName={i.lastName?.[0]} lastName={i.firstName?.[0]} />
									<CitizenRealtyWrapperSpace size={1} direction="vertical">
										<CitizenDrawerBlockItemHeader text={i.fullName} />
										<CitizenDrawerBlockItemDescription text={phone} />
									</CitizenRealtyWrapperSpace>
								</CitizenRealtyWrapperFlexDrawer>
								{/* TEMPORARY HIDDEN */}
								{/* <Button icon={MessagesPlus} shape="square" size="medium" typeButton="secondary" /> */}
							</CitizenRealtyFlexRent>
						</>
					);
				})}
			</Container>
		</>
	);
};
const CitizenRealtyFlexRent = styled(CitizenRealtyWrapper)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
	border-radius: 0;
`;
const SpaceWrapper = styled(Space)`
	width: 100%;
`;

const Container = styled.div`
	border-radius: 10px;
	overflow: hidden;
`;

export default CitizenOwnersInformation;
