import { IAnnouncement } from "interfaces/IAnnouncements";
import { makeAutoObservable } from "mobx";

const tmpAnnouncement: IAnnouncement = {
	id: "7db3e46a-31f3-4a66-b666-faabf7d7e8b4",
	status: "ACTIVE",
	type: "ORDINARY",
	roleNames: ["INTEREST_HOLDER"],
	text: "string",
	startDateTime: "2024-01-26T08:20:11.838Z",
	endDateTime: "2024-01-27T08:20:11.838Z",
	complexAddresses: [],
	buildingAddresses: [],
	entranceAddresses: [],
	floorAddresses: [],
	buildingObjectAddresses: [],
	fullName: "Ларионов К. ",
	organizationId: "b6a408d4-62af-4aa5-b3be-d380a5b6b12c",
	createDateTime: "2024-01-26T08:55:17.531Z",
	buildingIds: [],
	buildingObjectIds: [],
	complexIds: [],
	entranceIds: [],
	floorIds: [],
};

class DrawerStore {
	isOpenDrawer = false;
	selectedAnnouncement: IAnnouncement = tmpAnnouncement;

	constructor() {
		makeAutoObservable(this);
	}

	openDrawer = (selectedAnnouncement: IAnnouncement) => {
		this.isOpenDrawer = true;
		this.selectedAnnouncement = selectedAnnouncement;
	};

	closeDrawer = () => {
		this.isOpenDrawer = false;
	};
}

export default new DrawerStore();
