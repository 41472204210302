import React from "react";

import { IRole } from "interfaces/IRole";
import { TinyTable } from "tap2visit-ui-kit";
import getRoleTranslate from "utils/getRoleTranslate";

import { IClient } from "../../../interfaces/IClient";

const AccessRequestCollapseTable = ({ item }: { item: IClient & { role: IRole[] } }) => {
	const tableColumns = [
		["Роль", getRoleTranslate(item.role?.[0]?.name as any) ?? "Данные отсутствуют"],
		["Фамилия", item.lastName ?? "Данные отсутствуют"],
		["Имя", item.firstName ?? "Данные отсутствуют"],
		["Отчество", item.middleName ?? "Данные отсутствуют"],
		["ИНН", item.inn ?? "Данные отсутствуют"],
		["Логин приложения", item.contacts.find((v) => v.type === "PHONE")?.value || "Данные отсутствуют"],
		["Телефон для связи", item.contacts.find((v) => v.type === "PHONE")?.value || "Данные отсутствуют"],
		["Эл. почта", item.contacts.find((v) => v.type === "EMAIL")?.value || "Данные отсутствуют"],
	];

	return <TinyTable containerWidth={"1050px"} rows={tableColumns} />;
};

export default AccessRequestCollapseTable;
