import { makeAutoObservable } from "mobx";
import { IItemList } from "tap2visit-ui-kit";

interface IUK {
	name?: string;
	id?: string;
	managingOrganizationId: string;
}

interface IUKField {
	label: string;
	value: string;
}

const initialState: IUK = {
	id: "0",
	name: "",
	managingOrganizationId: "",
};

class UKStore {
	UK = initialState;
	companies: IItemList[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	getUK = () => {
		let uk = this.UK;
		if (uk.name) {
			return uk;
		}
		uk = JSON.parse(localStorage.getItem("uk") ?? "{}") as IUK;
		if (uk?.name) {
			return uk;
		}

		return initialState;
	};

	setYK = (current) => {
		this.UK = { name: current.title, managingOrganizationId: current.managingOrganizationId, id: current.id };
		localStorage.setItem(
			"uk",
			JSON.stringify({ id: current.id, name: current.title, managingOrganizationId: current.managingOrganizationId }),
		);
		return this.UK;
	};
	setCompanies = (value: IItemList[]) => {
		this.companies = value;
	};

	getCompanies = () => this.companies;

	dispose = () => {
		this.UK = initialState;
		this.companies = [];
	};
}

export default new UKStore();
