import styled, { css } from "styled-components";

export const RowItem = styled.div<{ collapsed: boolean }>`
	text-align: start;
	padding-left: 12px;
	font-family: "Inter", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	color: #121212e5;
	margin: 8px 12px 0 12px;
	width: calc(100% - 24px);
	border-radius: 8px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	height: 40px;
	text-overflow: ellipsis;

	& > p {
		margin-left: 12px;
	}

	&:hover {
		background: #1212120a;
	}

	&:active {
		background: #1212121f;
	}

	${({ collapsed }) => {
		if (collapsed) {
			return css`
				padding-left: unset;
				justify-content: center !important;

				& > p {
					display: none;
				}
			`;
		}
	}}
`;
