import { IClientContact } from "interfaces/IClient";
import { IRequestClientContact } from "interfaces/IRequest";
import { cloneDeep } from "utils/cloneDeep";

/**
 * @description it is necessary to fill in the missing contacts as a result of the function to level the arrays of numbers
 */
const requestPhoneTemplate: IRequestClientContact = { id: null, value: "", isDefault: false };
const comparePhoneTemplate: IClientContact = {
	id: null,
	value: "",
	isDefault: false,
	type: "PHONE",
	description: "",
};

/**
 * @description get contacts where request contact index is equals compare contact index
 */
export const getSortedContacts = (args: { requestContacts: IRequestClientContact[]; compareContacts: IClientContact[] }) => {
	const contacts: { requestContacts: IRequestClientContact[]; compareContacts: IClientContact[] } = {
		requestContacts: [],
		compareContacts: [],
	};

	if (!args.requestContacts || !args.compareContacts) {
		contacts.requestContacts = args.requestContacts ?? [];
		contacts.compareContacts = args.compareContacts ?? [];
		return contacts;
	}

	const requestPhones = cloneDeep(args.requestContacts);
	const comparePhones = cloneDeep(args.compareContacts);

	for (let i = 0; i <= requestPhones.length - 1; i++) {
		const requestPhone = requestPhones[i];
		const comparePhoneIdx = comparePhones.findIndex((contact) => contact.value === requestPhone.value);

		if (comparePhoneIdx !== -1) {
			contacts.requestContacts.push(cloneDeep(requestPhone));
			contacts.compareContacts.push(cloneDeep(comparePhones[comparePhoneIdx]));

			requestPhones[i].value = undefined;
			comparePhones[comparePhoneIdx].value = undefined;
		}
	}

	const remainingRequestsPhones = requestPhones.filter((contact) => contact.value);
	const remainingComparePhones = comparePhones.filter((contact) => contact.value);

	contacts.requestContacts = [...contacts.requestContacts, ...remainingRequestsPhones];
	contacts.compareContacts = [...contacts.compareContacts, ...remainingComparePhones];

	// #region adding missing contacts to equalize length of contact's arrays
	const compareLengthArray = contacts.requestContacts.length - contacts.compareContacts.length;

	if (compareLengthArray < 0) {
		const missingPhoneElementsArray = new Array(Math.abs(compareLengthArray)).fill(requestPhoneTemplate);
		contacts.requestContacts = [...contacts.requestContacts, ...missingPhoneElementsArray];
	}

	if (compareLengthArray > 0) {
		const missingPhoneElementsArray = new Array(Math.abs(compareLengthArray)).fill(comparePhoneTemplate);
		contacts.compareContacts = [...contacts.compareContacts, ...missingPhoneElementsArray];
	}
	// #endregion

	return contacts;
};
