import React from "react";

import TextHeader from "components/TextHeader";
import { FormattedMessage } from "react-intl";
import { Text } from "tap2visit-ui-kit";

import ChoiceYKList from "./ChoiceUKList";

const ChoiceYK = () => (
	<>
		<TextHeader>
			<FormattedMessage id="selectYK" />
		</TextHeader>
		<ChoiceYKList />
	</>
);

export default ChoiceYK;
