import { useEffect, useState } from "react";

import {
	createRealEstateCommentApi,
	deleteRealEstateCommentApi,
	getRealEstateCommentsApi,
	updateRealEstateCommentApi,
} from "api/api.realEstate";
import { IComments } from "interfaces/IClient";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Avatar, Space, Button, Text, TextArea, Loader, colors } from "tap2visit-ui-kit";

import { createCommentApi, deleteCommentByIdApi, getComments, updateCommentByIdApi } from "../../../api/api.clients";
import { getUserApi, getUserByIdApi } from "../../../api/api.employee";
import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import getCommentsDate from "../utils/getCommentsDate";

type TCommentState = {
	firstName: string;
	lastName: string;
} & IComments;

export type TCommentsType = "citizen" | "real-estate";

interface ICitizenComments {
	type: TCommentsType;
}

const CitizenComments: React.FC<ICitizenComments> = (props) => {
	const [commentsAll, setCommentsAll] = useState<TCommentState[]>([]);
	const [comments, setComments] = useState<TCommentState[]>([]);
	const [comment, setComment] = useState("");
	const [newComment, setNewComment] = useState("");
	const [edit, setEdit] = useState(false);
	const [currentComment, setCurrentComment] = useState("");
	const [clickMoreButton, setClickMoreButton] = useState(false);

	const authenticatedUser = useQuery({
		queryFn: () => getUserApi(),
	});

	const { data, refetch, isFetching } = useQuery({
		queryFn: () => getComments({ params: { clientId: CitizensDrawerStore.selectedUser.id } }),
		queryKey: [CitizensDrawerStore.selectedUser, QueriesKeys.comment],
		enabled: props.type === "citizen",
	});

	const realEstateComments = useQuery({
		queryFn: () => getRealEstateCommentsApi({ params: { buildingObjectId: CitizenBuildingObjectStore.selectedBuilding.id } }),
		queryKey: [CitizenBuildingObjectStore.selectedBuilding?.id, QueriesKeys.realEstateComments],
		enabled: props.type === "real-estate",
	});

	const createComment = useMutation(createCommentApi);
	const deleteComment = useMutation(deleteCommentByIdApi);
	const updateComment = useMutation(updateCommentByIdApi);

	const createRealEstateComment = useMutation(createRealEstateCommentApi);
	const deleteRealEstateComment = useMutation(deleteRealEstateCommentApi);
	const updateRealEstateComment = useMutation(updateRealEstateCommentApi);

	useEffect(() => {
		(async () => {
			const currentData = props.type === "citizen" ? data : realEstateComments.data;
			if (currentData) {
				const uniqueCreatedBy = Array.from(new Set(currentData.map((item) => item.createdBy)));
				const informationUserMap = await Promise.all(uniqueCreatedBy.map(async (v) => await getUserByIdApi({ params: { id: v } }))); // TODO Need to use react query

				const allowComments = currentData.map((v) => ({
					...v,
					// eslint-disable-next-line max-nested-callbacks
					firstName: informationUserMap.find((val) => val.id === v.createdBy).firstName,
					// eslint-disable-next-line max-nested-callbacks
					lastName: informationUserMap.find((val) => val.id === v.createdBy).lastName,
				}));

				setCommentsAll(allowComments);
			}
		})();
	}, [data, realEstateComments.data]);

	useEffect(() => {
		if (commentsAll.length > 3 && !clickMoreButton) {
			setComments(commentsAll.slice(0, 2));
		} else setComments(commentsAll);
	}, [commentsAll]);

	const sentComment = async () => {
		if (props.type === "citizen") {
			createComment.mutateAsync({ body: { comment, clientId: CitizensDrawerStore.selectedUser.id } }).then((res) => {
				refetch();
				setComment("");
			});
		}

		if (props.type === "real-estate") {
			await createRealEstateComment.mutateAsync({ body: { comment, buildingObjectId: CitizenBuildingObjectStore.selectedBuilding.id } });

			realEstateComments.refetch();
			refetch();
			setComment("");
		}
	};

	const openEditInput = (comment: TCommentState) => {
		setCurrentComment(comment.id);
		setNewComment(comment.comment);
		setEdit(true);
	};

	const deleteCommentFunc = async (id: string) => {
		if (props.type === "citizen") {
			await deleteComment.mutateAsync({ path: { id } });

			await refetch();
		}

		if (props.type === "real-estate") {
			await deleteRealEstateComment.mutateAsync({ path: { id } });

			await realEstateComments.refetch();
		}
	};

	const changeComment = async () => {
		if (props.type === "citizen") {
			await updateComment.mutateAsync({ path: { id: currentComment }, body: { comment: newComment } });
			await refetch();
			setEdit(false);
			setCurrentComment("");
		}

		if (props.type === "real-estate") {
			await updateRealEstateComment.mutateAsync({ path: { id: currentComment }, body: { comment: newComment } });
			await realEstateComments.refetch();
			setEdit(false);
			setCurrentComment("");
		}
	};

	const cancelEdit = () => {
		setCurrentComment(undefined);
		setNewComment("");
		setEdit(false);
	};

	const showMoreComment = () => {
		setComments(commentsAll);
		setClickMoreButton(true);
	};
	return (
		<Container>
			<Space direction="column" size={10}>
				{!isFetching &&
					comments?.map((v) => {
						const commentEditedText = v.edited ? "(отредактировано)" : "";

						return (
							<Space width="100%" align="start" justify="start">
								<Avatar firstName={v.lastName} lastName={v.firstName} size={"40"} />
								<Space align="start" size={0} direction="column">
									<HeaderCommentEditContainer>
										<Text type="large-medium">
											{v.firstName} {v.lastName}
										</Text>
										{edit && currentComment === v.id && <Text type="base-regular">редактирование комментария</Text>}
									</HeaderCommentEditContainer>
									{edit && currentComment === v.id && (
										<>
											<EditCommentContainer>
												<TextAreaStyled maxLength={255} value={newComment} onChangeValue={(value) => setNewComment(value)} />
											</EditCommentContainer>
											<Space justify="flex-end" width="100%">
												<Button onClick={cancelEdit} typeButton="text">
													Отменить
												</Button>
												<Button onClick={changeComment} typeButton="secondary">
													Сохранить
												</Button>
											</Space>
										</>
									)}
									{currentComment !== v.id && !v.deleted && (
										<Text type="base-regular" style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
											{v.comment}
										</Text>
									)}

									{currentComment !== v.id && v.deleted && (
										<Text
											type="base-regular"
											color={colors.textNeutralPlaceholder}
											style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
											Комментарий удален
										</Text>
									)}
									{!edit && (
										<CommentActionsContainer>
											<DateTextWrapper type="base-regular" color={colors.textNeutralSecondary}>{`${getCommentsDate(
												v.modifiedAt,
												// v.modifiedAt,
											)} ${commentEditedText}`}</DateTextWrapper>
											{!v.deleted && (
												<>
													<Button onClick={() => openEditInput(v)} typeButton="link">
														Редактировать
													</Button>
													<Button onClick={() => deleteCommentFunc(v.id)} variant="red" typeButton="link">
														Удалить
													</Button>
												</>
											)}
										</CommentActionsContainer>
									)}
								</Space>
							</Space>
						);
					})}
				{commentsAll.length > 3 && !clickMoreButton && (
					<MoreCommentsTitle
						onClick={showMoreComment}
						typeButton="link">{`Показать еще ${commentsAll.length} комментариев`}</MoreCommentsTitle>
				)}
				{isFetching && (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				)}
				{!isFetching && (
					<>
						<CommentsTextAreaWrapper>
							<Avatar firstName={authenticatedUser.data.lastName} lastName={authenticatedUser.data.firstName} size={"40"} />
							<TextAreaWrapper>
								<TextAreaStyled maxLength={255} value={comment} onChangeValue={(value) => setComment(value)} />
							</TextAreaWrapper>
						</CommentsTextAreaWrapper>
						<ButtonWrapper onClick={sentComment}>Отправить</ButtonWrapper>
					</>
				)}
			</Space>
		</Container>
	);
};

export default CitizenComments;

const MoreCommentsTitle = styled(Button)`
	margin-right: auto;
`;

const ButtonWrapper = styled(Button)`
	margin-left: auto;
`;
const TextAreaStyled = styled(TextArea)`
	width: 100%;
	background: white;
	resize: none;

	word-wrap: break-word;
	word-break: break-all;
`;
const DateTextWrapper = styled(Text)`
	// margin-right: 10px;
`;
const CommentsTextAreaWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
`;
const TextAreaWrapper = styled.div`
	width: 100%;
	margin-left: 10px;
`;

const LoaderContainer = styled.div`
	padding-top: 10px;
	padding-bottom: 10px;
`;

const EditCommentContainer = styled.div`
	width: 100%;
	min-width: 550px;
`;

const HeaderCommentEditContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;
const CommentText = styled(Text)`
	word-wrap: break-word;
	word-break: break-word;
	white-space: normal;
`;

const CommentContainer = styled.div`
	word-wrap: break-word;
	word-break: break-word;
	white-space: normal;
`;

const Container = styled.div``;

const CommentActionsContainer = styled.div`
	display: flex;
	align-items: center;
`;
