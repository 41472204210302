import React, { FC } from "react";

import { Skeleton } from "antd";
import { Spacer } from "components/layout/Spacer";
import styled from "styled-components";

interface IProfileUKSkeleton {}
const ProfileUKSkeleton: FC<IProfileUKSkeleton> = (props) => {
	const contentContainerWidth = `${window.innerWidth - 225}px`;
	return (
		<Wrapper>
			<Container>
				<TabsContainerSkeleton>
					<TextSkeletonTabContainer>
						<SkeletonText active style={{ height: "24px", width: "80%", marginTop: "12px" }} />

						<Spacer px={12} />

						<SkeletonText active style={{ height: "24px", width: "80%" }} />
					</TextSkeletonTabContainer>
					<TabLine active style={{ height: "48px", width: "2px", minWidth: "auto" }} />
				</TabsContainerSkeleton>

				<ContentContainer width={contentContainerWidth}>
					<SpaceBetween>
						<OfficeNameContainer>
							<Skeleton.Button active style={{ width: "170px", height: "24px", display: "block" }} />
							<Skeleton.Button shape="round" active style={{ width: "190px", height: "28px", display: "block" }} />
						</OfficeNameContainer>

						<EditButtonsContainer>
							<Skeleton.Button active style={{ width: "144px", height: "24px" }} />
							<Skeleton.Button active style={{ width: "32px", height: "32px", display: "block", minWidth: "auto" }} />
						</EditButtonsContainer>
					</SpaceBetween>

					<Spacer px={16} />

					<InfoContainer>
						<Skeleton.Button active style={{ width: "100%", height: "190px", display: "block" }} />
						<Skeleton.Button active style={{ width: "100%", height: "190px", display: "block" }} />
					</InfoContainer>

					<Spacer px={24} />

					<SpaceBetween>
						<Skeleton.Button active style={{ width: "250px", height: "24px" }} />

						<Skeleton.Button active style={{ width: "32px", height: "32px", display: "block", minWidth: "auto" }} />
					</SpaceBetween>

					<Spacer px={16} />

					<TableContainer>
						<Skeleton.Button active style={{ width: "100%", height: "80px" }} />
					</TableContainer>

					<Spacer px={24} />

					<SpaceBetween>
						<Skeleton.Button active style={{ width: "250px", height: "24px" }} />

						<Skeleton.Button active style={{ width: "32px", height: "32px", display: "block", minWidth: "auto" }} />
					</SpaceBetween>

					<Spacer px={16} />

					<TableContainer>
						<Skeleton.Button active style={{ width: "100%", height: "150px" }} />
					</TableContainer>
				</ContentContainer>
			</Container>
		</Wrapper>
	);
};

export default ProfileUKSkeleton;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

const Container = styled.div`
	display: flex;
	width: 100%;
`;

const TabsContainerSkeleton = styled.div`
	display: flex;
	width: 225px;
	justify-content: space-between;
`;

const TextSkeletonTabContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
`;

const SkeletonText = styled(Skeleton.Button)``;

const TabLine = styled(Skeleton.Button)``;

const ContentContainer = styled.div<{ width: string }>`
	padding-left: 24px;
	width: calc(100% - 225px);
	display: flex;
	flex-direction: column;
`;

const InfoContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;

	> .ant-skeleton-element:first-child {
		width: 30%;
	}

	> .ant-skeleton-element:last-child {
		width: 70%;
	}
`;

const OfficeNameContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const SpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const EditButtonsContainer = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
