export interface IOffice {
	id: string;
	name: string;
	description: string;
	addressId: string;
	avatarId: string;
	isArchived: boolean;
	isShownToUsers: boolean;
	timetables: IWorkingTime[];
	emails: IEmail[];
	phones: IPhone[];
	executives: IExecutiveDto[];
}

export interface INewEditableOffice {
	officeName: string;
	additionalInformation: string;
	phones: string[];
	positions: string[];
	emails: string[];
	timeState: IWorkingTimeCreate[];
}
export interface IOfficeCreateOrUpdate {
	name?: string;
	description?: string;
	addressId?: string;
	avatarId?: string;
	isArchived?: boolean;
	isShownToUsers?: boolean;
	timetables?: IWorkingTimeCreate[];
	emails?: Partial<IEmail>[];
	phones?: Partial<IPhone>[];
	executives?: Partial<IExecutiveDto>[];
	managingOrganizationId: string;
}

export enum DayOfWeek {
	MONDAY = "MONDAY",
	TUESDAY = "TUESDAY",
	WEDNESDAY = "WEDNESDAY",
	THURSDAY = "THURSDAY",
	FRIDAY = "FRIDAY",
	SATURDAY = "SATURDAY",
	SUNDAY = "SUNDAY",
}

interface ITimePeriod {
	startTime: string;
	endTime: string;
}

export interface IWorkingTimeReceptionHours {
	timePeriod: ITimePeriod;
	description: string;
}

export interface IWorkingTimeCreate {
	dayOfWeek: DayOfWeek;
	workingTimePeriod?: ITimePeriod;
	breakTimePeriod?: ITimePeriod;
	isWeekend?: boolean;
	receptionHours?: IWorkingTimeReceptionHours[];
	id?: string;
}

export interface IWorkingTime {
	id: string;
	dayOfWeek: DayOfWeek;
	workingTimePeriod: ITimePeriod;
	breakTimePeriod: ITimePeriod;
	isWeekend: boolean;
	receptionHours: {
		id: string;
		timePeriod: ITimePeriod;
		description: string;
	}[];
}

export interface IPhone {
	id: string;
	number: string;
	description: string;
	isDefault: boolean;
	isShownToUsers: boolean;
}

export interface IEmail {
	id: string;
	address: string;
	description: string;
	isDefault: boolean;
	isShownToUsers: boolean;
}

export interface IExecutiveDto {
	id: string;
	position: string;
	fio: string;
	isShownToUsers: boolean;
}
