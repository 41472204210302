import { FC } from "react";

import TextHeader from "components/TextHeader";
import { ReactComponent as AddPersonSvg } from "public/add_person.svg";
import { Button, Text } from "tap2visit-ui-kit";

import CitizensDrawerStore from "../../components/Citizens/store/Citizens.drawer.store";

const CitizensHeader: FC = () => (
	<>
		<TextHeader>Список жителей</TextHeader>
		{/* Temporary hidden */}
		{/* <Button onClick={() => CitizensDrawerStore.openDrawer({ mode: "create" })} icon={AddPersonSvg}>
			Создать жителя
		</Button> */}
	</>
);

export default CitizensHeader;
