import React, { FC } from "react";

import { getRolesApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { FormItem, Chip } from "tap2visit-ui-kit";
import getRoleTranslate from "utils/getRoleTranslate";

import PollsEditStore from "../store/PollsItem.store";

interface IPollsEditContentRoles {}

type TRoleItem = {
	selected: boolean;
	key: string;
	id: string;
	text: string;
};

interface IPollsRoles {
	editable?: boolean;
}

const PollsRoles: FC<IPollsRoles> = (props) => {
	const [items, setItems] = React.useState<TRoleItem[]>([]);

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});

	React.useEffect(() => {
		const initialSelectedRolesIds = new Set(PollsEditStore.poll.roleIds);

		setItems(() =>
			(roles.data ?? []).map((role) => ({
				key: role.name,
				id: role.id,
				selected: initialSelectedRolesIds.has(role.id),
				text: getRoleTranslate(role.name),
			})),
		);
	}, [roles.data]);

	const onChipClick = (role: TRoleItem) => {
		PollsEditStore.changeRoles({
			roleId: role.id,
		});

		setItems((items) =>
			items.map((item) => {
				if (item.key === role.key) {
					return {
						...item,
						selected: !item.selected,
					};
				}
				return item;
			}),
		);
	};

	return (
		<FormItem label="Роли">
			<Row>
				<>
					{items.map((item) => (
						<Chip
							key={item.key}
							onClick={() => onChipClick(item)}
							selected={item.selected}
							title={item.text}
							size={"m"}
							disabled={!props.editable}
						/>
					))}
				</>
			</Row>
		</FormItem>
	);
};

export default observer(PollsRoles);

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	row-gap: 8px;
`;
