import React from "react";

import { Space } from "antd";
import { updateDocumentApi } from "api/api.documents";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import styled from "styled-components";

import CitizenFiles from "../../../components/Citizens/components/CitizenFiles";
import useNewFileState from "../../../components/Citizens/hooks/useNewFileState";
import useUploadCitizenFiles from "../../../components/Citizens/hooks/useUploadCitizenFiles";
import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";
import useCitizenDocumentByType from "../hooks/useCitizenDocumentByType";

import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";

const CitizenDocumentOwners = () => {
	const { needToRemoveFileIds, newFiles, setNeedToRemoveFileIds, setNewFiles } = useNewFileState();
	const isMounted = React.useRef(false);

	const updateDocument = useMutation(updateDocumentApi);
	const buildingId = CitizenBuildingObjectStore.selectedBuilding?.id;

	const buildingDocument = useCitizenDocumentByType({
		type: ["EGRN", "CERTIFICATE_OF_STATE_REGISTRATION_OF_RIGHT"],
		targetId: CitizenBuildingObjectStore.selectedBuilding?.id,
		deps: !!CitizenBuildingObjectStore.selectedBuilding?.id,
	});

	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
		citizenDocument: buildingDocument,
	});

	React.useEffect(() => {
		(async () => {
			if (isMounted.current) {
				const newFileIds = await uploadFiles();
				const { ...otherBuildingDocument } = buildingDocument;
				await updateDocument.mutateAsync({
					body: {
						...otherBuildingDocument,
						fileIds: newFileIds,
					},
					path: {
						id: buildingDocument.id,
					},
				});
			}
			isMounted.current = true;
		})();
	}, [needToRemoveFileIds, newFiles]);

	React.useEffect(
		() => () => {
			if (newFiles.length || needToRemoveFileIds.length) {
				queryClient.invalidateQueries([QueriesKeys.clientsDocuments, buildingId]);
			}
		},
		[newFiles, needToRemoveFileIds],
	);

	return (
		<SpaceWrapper direction="vertical">
			<CitizenDrawerTextHeaderBlock text="Документы о праве собственности" />
			<CitizenFiles
				background="transparent"
				newFiles={newFiles}
				fileIds={buildingDocument?.fileIds ?? []}
				needToRemoveFileIds={needToRemoveFileIds}
				setNeedToRemoveFileIds={setNeedToRemoveFileIds}
				setNewFiles={(files) => {
					setNewFiles(files);
				}}
				disabled={false}
			/>
		</SpaceWrapper>
	);
};
const SpaceWrapper = styled(Space)`
	width: 100%;
`;
export default CitizenDocumentOwners;
