import React, { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import Tab from "components/ui/Tab/Tab";
import { observer } from "mobx-react-lite";
import { ReactComponent as ArrowLeftSvg } from "public/arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { DEFAULT_PAGE_HEADER_PADDING } from "routes/withSideBar";
import styled from "styled-components";
import { Badge, Button, Text, colors } from "tap2visit-ui-kit";

import usePollAnswers from "../components/PollAnswers/hooks/usePollAnswers";
import PollsEditStore from "../store/PollsItem.store";
import PollsEditTabsStore, { TPollsEditTabsVariants } from "../store/PollsItem.tabs.store";

interface IPollsHeader {}

const tabs: { key: TPollsEditTabsVariants; label: string }[] = [
	{
		key: "questions",
		label: "Вопросы",
	},
	{
		key: "answers",
		label: "Ответы",
	},
];

const PollsHeader: FC<IPollsHeader> = (props) => {
	const navigate = useNavigate();
	const pollAnswers = usePollAnswers();

	React.useEffect(() => {
		const topHeaderContainer = document.getElementById("top-page-header");
		if (topHeaderContainer) {
			topHeaderContainer.style.padding = "14px 16px 0 16px";
		}

		return () => {
			if (topHeaderContainer) {
				topHeaderContainer.style.padding = DEFAULT_PAGE_HEADER_PADDING;
			}
		};
	}, []);

	return (
		<Container>
			<HeaderTopContainer>
				<BackLink onClick={() => navigate("/polls", { replace: true })}>
					<Button shape="square" size="medium" icon={ArrowLeftSvg} typeButton="text" />
					<Text h="h4">{PollsEditStore.poll?.name}</Text>
				</BackLink>
			</HeaderTopContainer>

			<>
				<Spacer px={4} />
				<TabsWrapper>
					<TabsContainer>
						<Tab
							activeKey={PollsEditTabsStore.activeTab}
							items={tabs}
							paddingItem={"12px"}
							onTabClick={(key: TPollsEditTabsVariants) => PollsEditTabsStore.setActiveTab(key)}
						/>
					</TabsContainer>
					<Badge
						showZero
						background={colors.surfaceNeutralBg4}
						color={colors.textNeutralSecondary}
						type={"counter"}
						counter={pollAnswers.data?.countAnswers ?? 0}
						status="grey"
					/>
				</TabsWrapper>
			</>
		</Container>
	);
};

export default observer(PollsHeader);

const HeaderTopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const GroupButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const BackLink = styled(GroupButtons)`
	cursor: pointer;
`;

const TabsContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const TabsWrapper = styled.div`
	display: flex;
	align-items: center;
`;
