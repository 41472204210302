import axios from "axios";
import AuthStore from "store/Auth.Store";

import { ErrorsCode } from "../interfaces/errorsCode";
import { ITokens } from "../interfaces/IToken";

import { updateTokensApi } from "./api.auth";
import showApiErrorIfExist from "./utils/showApiErrorIfExist";

let deleyedRequests: (() => Promise<void>)[] = [];

const instance = axios.create({
	baseURL: `https://${window.location.host}/`,
});

let updateTokenInProgress = false;
instance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		console.warn(error.response);

		if (error.response.status === ErrorsCode.Unauthorized && originalRequest && !originalRequest._retry) {
			if (updateTokenInProgress) {
				return new Promise((resolve) => {
					deleyedRequests.push(async () => {
						const { access_token } = await AuthStore.getTokens();
						originalRequest.headers.Authorization = `Bearer ${access_token}`;
						originalRequest._retry = true;
						const res = await instance(originalRequest);

						resolve(res);
					});
				});
			}
			updateTokenInProgress = true;
			originalRequest._retry = true;
			let newTokens: ITokens;

			try {
				newTokens = await updateTokensApi();
				AuthStore.saveTokens(newTokens);
			} catch (error) {
				if (error.response.status === ErrorsCode.Unauthorized) {
					AuthStore.logout();
					AuthStore.login();
				}
				updateTokenInProgress = false;
				return Promise.reject(error);
			}

			originalRequest.headers["Authorization"] = `Bearer ${newTokens.accessToken}`;
			updateTokenInProgress = false;
			await Promise.all(deleyedRequests.map((req) => req()));
			deleyedRequests = [];

			return instance(originalRequest);
		}

		showApiErrorIfExist(error);
		return Promise.reject(error);
	},
);

instance.interceptors.request.use(
	(config) => {
		const isBasicAuth = (config.headers.Authorization as string)?.startsWith("Basic");
		const accessToken = AuthStore.getTokens().access_token;

		if (accessToken != null && !isBasicAuth) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		return config;
	},
	(error) => Promise.reject(error),
);

export default instance;
