import React, { useEffect, useState } from "react";

import { Divider, Space } from "antd";
import Input from "components/ui/Input/Input";
import { queryClient } from "index";
import { observer } from "mobx-react-lite";
import { ReactComponent as DeleteIcon } from "public/deleteDropdown.svg";
import { ReactComponent as EditIcon } from "public/edit-icon.svg";
import { ReactComponent as Messages } from "public/messages.svg";
import { ReactComponent as MessagesEmergency } from "public/MessagesEmergency.svg";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Button, colors, Drawer, Text } from "tap2visit-ui-kit";

import { deleteAnnouncementsTemplateApi, getAnnouncementsTemplatesListApi } from "../../../api/api.gateway";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import AnnouncementsCurrentItemStore from "../store/Announcements.currentItem.store";
import AnnouncementsViewDataStore from "../store/Announcements.viewData.store";

const buttonsGroupArr: { title: string; active: boolean; key: "ORDINARY" | "EMERGENCY" | null }[] = [
	{
		title: "Все объявления",
		active: true,
		key: null,
	},
	{
		title: "Обычные объявления",
		active: false,
		key: "ORDINARY",
	},
	{
		title: "ЧП",
		active: false,
		key: "EMERGENCY",
	},
];
const TemplatesDrawer = ({ isOpenTemplateDrawer, onClose }: { isOpenTemplateDrawer: boolean; onClose: () => void }) => {
	const [buttonsGroup, setButtonsGroup] = useState(buttonsGroupArr);
	const [text, setText] = useState("");

	const { data: templates, refetch } = useQuery({
		queryFn: () => getAnnouncementsTemplatesListApi({ query: text, type: buttonsGroup.find((v) => v.active).key }),
		queryKey: [QueriesKeys.announcements],
	});

	const deleteAnnouncementsTemplate = useMutation(deleteAnnouncementsTemplateApi);

	useEffect(() => {
		refetch();
	}, [buttonsGroup, isOpenTemplateDrawer, text]);

	const deleteTemplate = async (id: string) => {
		await deleteAnnouncementsTemplate.mutateAsync(id);
		await queryClient.invalidateQueries([QueriesKeys.announcements]);
	};

	if (!templates) return;
	return (
		<Drawer
			visible={isOpenTemplateDrawer}
			title={<Text h="h4">Выбрать шаблон</Text>}
			onClose={() => {
				setText("");
				onClose();
			}}
			width="700px"
			headerSlot={<></>}>
			<TemplatesWrapperButton direction="vertical" size={20}>
				<Space>
					{buttonsGroup.map((v) => (
						<Button
							typeButton={v.active ? "primary" : "secondary"}
							onClick={() =>
								setButtonsGroup(
									buttonsGroup.map((val) => ({
										...val,
										active: v.key === val.key,
									})),
								)
							}>
							{v.title}
						</Button>
					))}
				</Space>
				<InputWrapper value={text} onChange={(e) => setText(e.target.value)} sizeInput="medium" />
			</TemplatesWrapperButton>
			<TemplatesWrapper>
				{templates?.map((v) => (
					<>
						<TemplatesWrapperContent isEmergency={v.type === "EMERGENCY"} size={5}>
							<Space>
								{v.type === "EMERGENCY" ? <MessagesEmergency /> : <Messages />}
								<TemplatesWrapperText size={5} direction="vertical">
									<Title>{v.name}</Title>
									<Subtitle>{v.text}</Subtitle>
								</TemplatesWrapperText>
							</Space>
							<Space>
								<Button
									onClick={() => {
										onClose();
										AnnouncementsCurrentItemStore.setTemplateItem(v);
										AnnouncementsCurrentItemStore.setCurrentItem("fillFromTemplate");
										AnnouncementsViewDataStore.setTypeAnnouncements(
											AnnouncementsViewDataStore.typeAnnouncements.map((val) => ({
												...val,
												focused: val.key === v.type,
											})),
										);
									}}>
									Выбрать шаблон
								</Button>
								<EditIconWrapper
									onClick={() => {
										onClose();
										AnnouncementsCurrentItemStore.setTemplateItem(v);
										AnnouncementsCurrentItemStore.setCurrentItem("editTemplate");
									}}
								/>
								<DeleteIconWrapper onClick={() => deleteTemplate(v.id)} />
							</Space>
						</TemplatesWrapperContent>
						<DividerWrapper />
					</>
				))}
			</TemplatesWrapper>
		</Drawer>
	);
};

const Title = styled.p`
	font-size: 14px;
	font-weight: 500;
`;
const Subtitle = styled.p`
	font-size: 12px;
	font-weight: 450;
	color: #12121299;
`;
const DividerWrapper = styled(Divider)`
	margin: 0;
`;

const InputWrapper = styled(Input)`
	width: 100%;
`;

const TemplatesWrapperText = styled(Space)`
	width: 350px;
`;

const TemplatesWrapperContent = styled(Space)<{ isEmergency: boolean }>`
	width: 100%;
	justify-content: space-between;
	padding: 8px 14px;
	background: ${(props) => (props.isEmergency ? colors.surfaceDangerTransparent : "transparent")};
`;

const TemplatesWrapper = styled.div`
	width: 100%;
`;

const TemplatesWrapperButton = styled(Space)`
	width: 100%;
	margin-bottom: 20px;
`;
const EditIconWrapper = styled(EditIcon)`
	cursor: pointer;
	fill: #12121299;
`;
const DeleteIconWrapper = styled(DeleteIcon)`
	cursor: pointer;
`;

export default observer(TemplatesDrawer);
