import React, { FC } from "react";

import { deleteOfficeApi } from "api/office/api.office";
import { ReactComponent as EyeOffSvg } from "public/eye_off.svg";
import { ReactComponent as EyeOnSvg } from "public/eye_on.svg";
import { ReactComponent as TrashSvg } from "public/Trash.svg";
import { ReactComponent as MenuSvg } from "public/verticale_dot.svg";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Button, colors, DropDown, IDropDownCurrentItem, Text } from "tap2visit-ui-kit";

import useCreateAndUpdateMutations from "../hooks/useCreateAndUpdateOfficeMutations";
import useSelectedOffice from "../hooks/useSelectedOffice";
import profileUKVisibilityStore from "../profileUKVisibility.store";

import PageEditDialogAreYouSureDeleteOffice from "./PageEdit.DialogAreYouSureDeleteOffice";

interface IPageInfoMenu {}

const PageInfoMenu: FC<IPageInfoMenu> = (props) => {
	const [isVisibleDeleteOfficeModal, setIsVisibleDeleteOfficeModal] = React.useState(false);

	const [isVisibleDropDown, setIsVisibleDropDown] = React.useState(false);

	const [isDisabledShowUserAction, setIsDisabledShowUserAction] = React.useState(false);
	const [isDisabledDeleteOffice, setIsDisabledDeleteOffice] = React.useState(false);
	// ! Need in future
	// const [isDisabledCloseOffice, setIsDisabledCloseOffice] = React.useState(false);

	const { selectedActiveOffice, offices } = useSelectedOffice();
	const { updateOffice } = useCreateAndUpdateMutations({ state: selectedActiveOffice });

	const deleteOffice = useMutation({
		mutationFn: deleteOfficeApi,
	});

	const isThereOnlyOneOffice = offices.data?.length === 1;

	const dropdownItems: IDropDownCurrentItem[] = [
		{
			text: selectedActiveOffice.isShownToUsers ? "Скрыть в приложении" : "Показать в приложении",
			icon: selectedActiveOffice.isShownToUsers ? EyeOffSvg : EyeOnSvg,
			meta: { action: "showUsers" },
			disabled: isDisabledShowUserAction,
		},
		{
			text: (
				<DeleteText type="base-regular" disabled={isThereOnlyOneOffice}>
					Удалить офис
				</DeleteText>
			),
			icon: () => <TrashSvg fill={isThereOnlyOneOffice ? colors.iconNeutralDisabled : colors.iconDangerDefault} />,
			meta: { action: "deleteOffice" },
			disabled: isDisabledDeleteOffice || offices.data?.length === 1,
		},
		// ! Need in future
		// {
		// 	text: "Закрыть офис (отправить в архив)",
		// 	icon: LockSvg,
		// 	meta: { action: "archiveOffice" },
		// 	disabled: isDisabledCloseOffice,
		// },
	];

	const onClickButton = () => setIsVisibleDropDown((status) => !status);
	const onClickOutsideDropDown = () =>
		setTimeout(() => {
			setIsVisibleDropDown(false);
			// TODO (when clicked on button control dropdown is reopening)
		}, 100);

	const deleteOfficeHandler = async () => {
		setIsDisabledDeleteOffice(true);
		await deleteOffice.mutateAsync({ path: { id: selectedActiveOffice.id } });
		profileUKVisibilityStore.setActiveOfficeId(null);
		setIsDisabledDeleteOffice(false);
		offices.refetch();
	};

	const onCancelDelete = () => {
		setIsVisibleDeleteOfficeModal(false);
	};

	const onClick = async (item: IDropDownCurrentItem) => {
		switch (item.meta.action) {
			case "showUsers":
				setIsDisabledShowUserAction(true);
				await updateOffice.mutateAsync({ isShownToUsers: !selectedActiveOffice.isShownToUsers });
				setIsDisabledShowUserAction(false);
				break;
			case "deleteOffice":
				setIsVisibleDeleteOfficeModal(true);
				break;
		}
	};

	return (
		<>
			<ButtonContainer>
				<Button onClick={onClickButton} shape="square" icon={MenuSvg} typeButton="secondary" />
				<DropDown
					onClick={onClick}
					onClickOutside={onClickOutsideDropDown}
					as={"context menu"}
					isVisible={isVisibleDropDown}
					position="bottom-middle-right"
					items={dropdownItems}
				/>
			</ButtonContainer>
			<PageEditDialogAreYouSureDeleteOffice
				isVisible={isVisibleDeleteOfficeModal}
				onDelete={deleteOfficeHandler}
				onCancel={onCancelDelete}
			/>
		</>
	);
};

export default PageInfoMenu;

const ButtonContainer = styled.div`
	position: relative;
`;

const DeleteText = styled(Text)<{ disabled: boolean }>`
	color: ${(props) => {
		if (props.disabled) {
			return colors.textNeutralDisabled;
		}
		return colors.textDangerDefault;
	}};
`;
