import dayjs from "dayjs";

export default (args: { data: string; from: string; to: string }) => {
	const blob = new Blob([args.data], { type: "application/vnd.ms-excel" });
	const url = window.URL.createObjectURL(blob);
	const from = dayjs(args.from).format("DD.MM.YYYY");
	const to = dayjs(args.to).format("DD.MM.YYYY");
	const fileSize = (blob.size / 1024).toFixed(2);
	const fileString = `${fileSize} KB`;

	return { url, from, to, size: fileString };
};
