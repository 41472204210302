import dayjs from "dayjs";
import { IAnnouncement } from "interfaces/IAnnouncements";

import AnnouncementsTabStore from "../Announcements.tab.store";

export default (announcement: IAnnouncement) => {
	const mightActions = {
		delete: false,
		archive: false,
		repeat: false,
		edit: true,
	};

	if (AnnouncementsTabStore.selectedTab === "ACTIVE") {
		const todayIsBeforeStartDate = dayjs().isBefore(dayjs(announcement.startDateTime));

		mightActions.delete = todayIsBeforeStartDate;
		mightActions.archive = !todayIsBeforeStartDate;
	} else {
		mightActions.repeat = true;
		mightActions.edit = false;
	}

	return mightActions;
};
