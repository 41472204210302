import React, { useEffect } from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Space } from "tap2visit-ui-kit";

import { getFloorsApi } from "../../api/api.gateway";
import PageLoader from "../../components/PageLoader";
import { queryClient } from "../../index";
import { QueriesKeys } from "../../interfaces/queriesKeys";

import BuildingsFloorCard from "./BuildingsFloorCard";
import BuildingsFloorTab from "./BuildingsFloorTab";
import BuildingsFooter from "./BuildingsFooter";
import BuildingsSliderFloor from "./BuildingsSliderFloor";

const BuildingsFloor = () => {
	const { id } = useParams();
	const floors = useQuery({
		queryFn: () => getFloorsApi({ params: { announcementStatus: "NEW", entranceId: id } }),
		queryKey: [QueriesKeys.advancedFloors, id],
	});

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.advancedFloors]);
	}, [id]);

	if (floors.isFetching) return <PageLoader isLoading={floors.isFetching} />;
	return (
		<BuildingsFloorWrapper>
			<BuildingsSliderFloor />
			<BuildingsFloorTab />
			<Space direction="column" align="start">
				{floors.data?.map((v) => <BuildingsFloorCard item={v} />)}
			</Space>
			<BuildingsFooter />
		</BuildingsFloorWrapper>
	);
};

const BuildingsFloorWrapper = styled.div`
	padding: 16px;
	background: #12121205;
`;
export default BuildingsFloor;
