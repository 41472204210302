import { FC, useEffect, useState } from "react";

import { getAddressById } from "api/api.aliases";
import { Spacer } from "components/layout/Spacer";
import TextHeader from "components/TextHeader";
import { IOffice } from "interfaces/IOffice";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as Eye_off } from "public/eye_off.svg";
import { ReactComponent as Eye_on } from "public/eye_on.svg";
import { ReactComponent as Pencil } from "public/pencil.svg";
import { useQuery } from "react-query";
import styled, { css } from "styled-components";
import { Badge, Button, colors, TinyTable, Text } from "tap2visit-ui-kit";

import { week } from "../constants";
import useOfficeLogo from "../hooks/useOfficeLogo";
import profileUKVisibilityStore from "../profileUKVisibility.store";

import PageInfoMenu from "./PageInfo.menu";
import { getTimeWithoutSeconds } from "./PageInfo.utils";
import ProfileUKImage from "./ProfileUKImage";
import RenderByCondition from "./RenderByCondition";

interface IPageInfo {
	office: IOffice;
}

const StatusBadge: FC<{ isShownToUsers: boolean }> = (props) => (
	<Badge
		type="status"
		status={props.isShownToUsers ? "green" : "grey"}
		text={props.isShownToUsers ? "Отображается у пользователей" : "Не отображается у пользователей"}
	/>
);

const PageInfo: FC<IPageInfo> = (props) => {
	const [isOpenStaff, setIsOpenStaff] = useState(localStorage.getItem("staff_is_shown") ? localStorage.getItem("staff_is_shown") : true);
	const [isOpenContacts, setIsOpenContacts] = useState(
		localStorage.getItem("contacts_is_shown") ? localStorage.getItem("contacts_is_shown") : true,
	);
	const [isOpenHours, setIsOpenHours] = useState(localStorage.getItem("hours_is_shown") ? localStorage.getItem("hours_is_shown") : true);

	const address = useQuery({
		queryFn: () => getAddressById({ params: { id: props.office.addressId } }),
		queryKey: [QueriesKeys.officeAddress, props.office.id, props.office.addressId],
		enabled: !!props.office.addressId,
	});

	useEffect(() => {
		if (!localStorage.getItem("is_shown_requisite") && localStorage.getItem("is_shown_requisite") === "false") {
			localStorage.setItem("is_shown_requisite", String(true));
		}

		if (!localStorage.getItem("staff_is_shown") && localStorage.getItem("staff_is_shown") === "false") {
			localStorage.setItem("staff_is_shown", String(true));
		}

		if (!localStorage.getItem("contacts_is_shown") && localStorage.getItem("contacts_is_shown") === "false") {
			localStorage.setItem("contacts_is_shown", String(true));
		}

		if (!localStorage.getItem("hours_is_shown") && localStorage.getItem("hours_is_shown") === "false") {
			localStorage.setItem("hours_is_shown", String(true));
		}
	}, []);

	const executivesTableItems = [
		["Должность", "ФИО", "Статус"],
		...(props.office.executives ?? []).map((executive) => [
			executive.position,
			executive.fio,
			() => <StatusBadge isShownToUsers={executive.isShownToUsers} />,
		]),
	];

	const contactInformation = [
		["Комментарий", "Для связи", "Статус"],
		...(props.office.phones ?? []).map((phone) => [
			phone.description,
			phone.number,
			() => <StatusBadge isShownToUsers={phone.isShownToUsers} />,
		]),
		...(props.office.emails ?? []).map((email) => [
			email.description,
			() => (
				<Link href={`mailto:${email.address}`}>
					<Text type="table-cell-m-regular">{email.address}</Text>
				</Link>
			),
			() => <StatusBadge isShownToUsers={email.isShownToUsers} />,
		]),
	];

	const onEditButtonClick = () => {
		profileUKVisibilityStore.editOffice(props.office);
	};

	const imgData = useOfficeLogo({
		isDefaultImage: !!props.office && !props.office.avatarId,
		avatarId: props.office.avatarId,
	});

	return (
		<ProfileWrapper>
			<ProfileHeader>
				<BasicInformation>
					<OverflowText h={"h4"}>{props.office.name}</OverflowText>
					<StatusBadge isShownToUsers={props.office.isShownToUsers} />
				</BasicInformation>
				<ActionWrapper>
					<Button icon={Pencil} size="medium" onClick={onEditButtonClick}>
						Редактировать
					</Button>
					<PageInfoMenu />
				</ActionWrapper>
			</ProfileHeader>
			<ProfileDescription>
				<ProfileUKImage img={imgData.img} loading={imgData.isFetching} isDefaultImageEnabled />

				<DescriptionWrapper>
					<div>
						<Text type={"base-bold"}>Адрес офиса</Text>
						<Text type={"small-regular"}>{address.data?.value}</Text>
					</div>
					<div>
						<Text type={"base-bold"}>Дополнительная информация</Text>
						<Text type={"small-regular"}>{props.office.description}</Text>
					</div>
				</DescriptionWrapper>
			</ProfileDescription>
			<>
				<InlineHeader>
					<TextHeader>Должностные лица</TextHeader>
					<Button
						icon={isOpenStaff ? (props) => <Eye_on {...props} fillOpacity={0.6} /> : Eye_off}
						typeButton={"secondary"}
						variant={"default"}
						size={"medium"}
						shape="square"
						onClick={() => {
							localStorage.setItem("staff_is_shown", String(!isOpenStaff));
							setIsOpenStaff((init) => !init);
						}}
					/>
				</InlineHeader>
				<RenderByCondition condition={!!isOpenStaff}>
					<TinyTable isOnlyColumnDark rows={executivesTableItems} containerWidth={"100%"} />
				</RenderByCondition>
			</>
			<Spacer px={24} />
			<>
				<InlineHeader>
					<TextHeader>Контактная информация</TextHeader>
					<Button
						icon={isOpenContacts ? (props) => <Eye_on {...props} fillOpacity={0.6} /> : Eye_off}
						typeButton={"secondary"}
						variant={"default"}
						size={"medium"}
						shape="square"
						onClick={() => {
							localStorage.setItem("contacts_is_shown", String(!isOpenContacts));
							setIsOpenContacts((init) => !init);
						}}
					/>
				</InlineHeader>
				<RenderByCondition condition={!!isOpenContacts}>
					<TinyTable isOnlyColumnDark rows={contactInformation} containerWidth={"100%"} />
				</RenderByCondition>
			</>
			<Spacer px={24} />

			<>
				<InlineHeader>
					<TextHeader>Часы работы</TextHeader>
					<Button
						icon={isOpenHours ? (props) => <Eye_on {...props} fillOpacity={0.6} /> : Eye_off}
						typeButton={"secondary"}
						variant={"default"}
						size={"medium"}
						shape="square"
						onClick={() => {
							localStorage.setItem("hours_is_shown", String(!isOpenHours));
							setIsOpenHours((init) => !init);
						}}
					/>
				</InlineHeader>
				{/* //TODO change to Tiny Table component */}
				<RenderByCondition condition={!!isOpenHours}>
					<ContentHours>
						{(props.office.timetables ?? []).map((item) => {
							const startWorkingTime = getTimeWithoutSeconds(item.workingTimePeriod?.startTime);
							const endWorkingTime = getTimeWithoutSeconds(item.workingTimePeriod?.endTime);
							const startBreakTime = getTimeWithoutSeconds(item.breakTimePeriod?.startTime);
							const endBreakTime = getTimeWithoutSeconds(item.breakTimePeriod?.endTime);

							return (
								<WeekDayContent>
									<WeekTitle>
										<TitleDay>{week[item.dayOfWeek]}</TitleDay>
										<WorkDay green={!item.isWeekend}>
											<Text type={"large-regular"}>{item.isWeekend ? "Выходной" : "Рабочий день"}</Text>
										</WorkDay>
									</WeekTitle>
									{!item.isWeekend && (
										<TimeContent>
											<>
												{startWorkingTime && (
													<WeekTitle>
														<Text type={"large-regular"}>Часы работы</Text>
														<Text type={"large-regular"}>{`c ${startWorkingTime} до ${endWorkingTime}`}</Text>
													</WeekTitle>
												)}
												{startBreakTime && (
													<WeekTitle>
														<Text type={"large-regular"}>Перерыв</Text>
														<Text type={"large-regular"}>{`c ${startBreakTime} до ${endBreakTime}`}</Text>
													</WeekTitle>
												)}
												{!!item.receptionHours.length && (
													<>
														<WeekTitle>
															<Text type={"large-bold"}>Приемные часы</Text>
														</WeekTitle>
														{item.receptionHours.map((reception) => {
															const startReceptionTime = getTimeWithoutSeconds(reception.timePeriod.startTime);
															const endReceptionTime = getTimeWithoutSeconds(reception.timePeriod.endTime);
															return (
																<WeekTitleHours key={reception.id}>
																	<TextStyled type={"large-regular"}>{reception.description}</TextStyled>
																	<Text type={"large-regular"}>{`c ${startReceptionTime} до ${endReceptionTime}`}</Text>
																</WeekTitleHours>
															);
														})}
													</>
												)}
											</>
										</TimeContent>
									)}
								</WeekDayContent>
							);
						})}
					</ContentHours>
				</RenderByCondition>
			</>
		</ProfileWrapper>
	);
};

export default PageInfo;

export const ProfileWrapper = styled.div`
	background-color: ${colors.surfaceNeutralBgWhite};
	border-radius: 16px;
	padding: 24px;
`;

export const ProfileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
`;

export const BasicInformation = styled.div`
	display: inline-flex;
	gap: 16px;
`;

export const ActionWrapper = styled.div`
	display: flex;
	align-items: center;
	> * {
		&:first-child {
			margin-right: 20px;
			svg {
				margin-right: 6px;
			}
		}
		&:last-child {
			width: 32px;
			padding: 0;
		}
	}

	> button {
		height: 32px;
	}
`;

const TextStyled = styled(Text)`
	width: 200px;
	word-break: break-word;
`;
const DescriptionWrapper = styled.div`
	background: ${colors.surfaceNeutralBg2};
	border: 0.5px solid ${colors.strokeDivider};
	border-radius: 8px;
	padding: 16px;
	display: grid;
	gap: 12px;
	grid-auto-rows: min-content;
`;

const ContentHours = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 400px;
`;

const ProfileDescription = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	margin-bottom: 26px;
	gap: 16px;
`;

const InlineHeader = styled.div`
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
`;

const WeekDayContent = styled.div`
	height: max-content;
	width: 100%;
	border-radius: 8px;
	border: 0.5px solid ${colors.strokeDivider};
`;

const WeekTitle = styled.div`
	width: 100%;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background: ${colors.surfaceNeutralBg2};
`;

const WeekTitleHours = styled.div`
	width: 100%;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
`;
const TitleDay = styled.div`
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	color: #42474f; //TODO: fix color
`;

const TimeContent = styled.div`
	> * {
		&:nth-child(odd) {
			background: ${(props) => props.theme.theme.absolute_100};
		}
	}
`;

const WorkDay = styled.div<{ green: boolean }>`
	${(props) => {
		switch (props.green) {
			case true:
				return css`
					& p {
						color: ${colors.textSuccessDefault};
					}
				`;
			case false:
				return css`
					& p {
						color: ${(props) => props.theme.theme.absolute_500};
					}
				`;
		}
	}}
`;

const OverflowText = styled(Text)`
	text-overflow: ellipsis;
	max-width: 300px;
	overflow: hidden;
`;

const Link = styled.a`
	color: ${colors.textInfoDefault};
	cursor: pointer;
	width: 250px;
	overflow-wrap: break-word;
`;
