import { IOffice, IOfficeCreateOrUpdate } from "interfaces/IOffice";

import AuthStore from "../../store/Auth.Store";
import apiClient from "../api.client";

export const getOfficeApi: (args: { params: { managingOrganizationId: string } }) => Promise<IOffice[]> = async (args) => {
	try {
		const res = await apiClient.get("managing-organizations/v1/offices", {
			params: {
				managingOrganizationId: args.params.managingOrganizationId,
				isArchived: false,
			},
		});
		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getProfiles = async () => {
	try {
		const res = await apiClient.get("eaa/v1/users/profiles");

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateOfficeApi = (args: { body: IOfficeCreateOrUpdate }) => Promise<IOffice>;

export const createOfficeApi: TCreateOfficeApi = async (args) => {
	try {
		const res = await apiClient.post("managing-organizations/v1/offices", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUpdateOfficeByIdApi = (args: { body: IOfficeCreateOrUpdate; path: { id: string } }) => Promise<IOffice>;

export const updateOfficeByIdApi: TUpdateOfficeByIdApi = async (args) => {
	try {
		const res = await apiClient.patch(`managing-organizations/v1/offices/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteOfficeApi = (args: { path: { id: string } }) => Promise<void>;

export const deleteOfficeApi: TDeleteOfficeApi = async (args) => {
	try {
		const res = await apiClient.delete(`managing-organizations/v1/offices/${args.path.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// ! Need in future
// export type TArchiveOfficeApi = (args: { path: { id: string } }) => Promise<void>;

// export const archiveOfficeApi: TArchiveOfficeApi = async (args) => {
// 	try {
// 		const res = await apiClient.post(`managing-organizations/v1/offices/${args.path.id}/archive`);

// 		return res.data;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };
