import React, { FC } from "react";

import styled from "styled-components";
import { Text } from "tap2visit-ui-kit";

const TableEmptySlug: FC = () => (
	<Container>
		<Text type="large-regular">Нет данных по таблице</Text>
	</Container>
);

export default TableEmptySlug;

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
`;
