import { getClientsListApi } from "api/api.clients";
import { getClientByObjectIdApi } from "api/api.realEstate";
import { TRoleTypes } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";

import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";

const useBuildingClientsByRole = (args: { role: TRoleTypes }) => {
	const buildingObjectsClients = useQuery({
		queryFn: () => getClientByObjectIdApi({ buildingObjectId: CitizenBuildingObjectStore.selectedBuilding.id }),
		queryKey: [QueriesKeys.buildingObjectClients, CitizenBuildingObjectStore.selectedBuilding?.id],
		enabled: !!CitizenBuildingObjectStore.selectedBuilding?.id,
	});

	const clientsIds = (buildingObjectsClients.data ?? [])
		.filter((client) => {
			const roleNames = client.roles.map((role) => role.name);
			return roleNames.includes(args.role);
		})
		.map((i) => i.clientId);

	const buildingObjectClientsDetail = useQuery({
		queryFn: () => {
			if (clientsIds?.length) {
				return getClientsListApi({ params: { clientIds: clientsIds } });
			}
			return [];
		},
		queryKey: [QueriesKeys.buildingObjectClientsDetail, ...clientsIds],
		enabled: buildingObjectsClients.isFetched,
	});

	return buildingObjectClientsDetail;
};

export default useBuildingClientsByRole;
